import React from "react";
import { useSelector } from "react-redux";
import { GPSItem } from "../../../modules/inventory/gps/types";
import { InventoryStatus, InventoryStatusMap } from "../../../modules/inventory/types";
import { AppState } from "../../../store";
import { Tag } from "antd";
import { FoobotItem } from "../../../modules/inventory/foobot/types";

function RenderTag({ value }: { value: InventoryStatus }) {
    switch (value) {
        case InventoryStatus.NEEDS_ORDER:
            return (
                <Tag color="red">{InventoryStatusMap[InventoryStatus.NEEDS_ORDER]}</Tag>
            );
        case InventoryStatus.ORDERED:
            return <Tag color="gold">{InventoryStatusMap[InventoryStatus.ORDERED]}</Tag>;
        case InventoryStatus.IN_STORAGE:
            return (
                <Tag color="orange">{InventoryStatusMap[InventoryStatus.IN_STORAGE]}</Tag>
            );
        case InventoryStatus.READY:
            return <Tag color="blue">{InventoryStatusMap[InventoryStatus.READY]}</Tag>;
        case InventoryStatus.ASSIGNED:
            return (
                <Tag color="green">{InventoryStatusMap[InventoryStatus.ASSIGNED]}</Tag>
            );
        case InventoryStatus.RETRIEVE:
            return <Tag color="cyan">{InventoryStatusMap[InventoryStatus.RETRIEVE]}</Tag>;

        default:
            return null;
    }
}

export default function Total() {
    const statuses = {
        [InventoryStatus.NEEDS_ORDER]: 0,
        [InventoryStatus.ORDERED]: 0,
        [InventoryStatus.IN_STORAGE]: 0,
        [InventoryStatus.CONFIG]: 0,
        [InventoryStatus.CONFIG_MOBILE]: 0,
        [InventoryStatus.CONFIG_FIRM]: 0,
        [InventoryStatus.ISSUE]: 0,
        [InventoryStatus.NIELS]: 0,
        [InventoryStatus.SHIPOUT]: 0,
        [InventoryStatus.READY]: 0,
        [InventoryStatus.ASSIGNED]: 0,
        [InventoryStatus.RETRIEVE]: 0,
    };
    const foobot = useSelector((state: AppState) =>
        state.inventory.foobotIds.map(id => state.inventory.inventoryMap[id]),
    ) as FoobotItem[];

    const stats = foobot.reduce((acc, item) => {
        console.log({ s: item.status, statuses: statuses[item.status] });

        return {
            ...acc,
            [item.status]: acc[item.status] + 1,
        };

        // return acc;
    }, statuses);

    return (
        <div style={{ display: "flex" }}>
            {Object.entries(stats).map(([key, value]) => (
                <div style={{ marginRight: 10 }}>
                    {/* @ts-ignore */}
                    <RenderTag value={Number(key)} />
                    {value}
                </div>
            ))}
        </div>
    );
}
