import React from "react";
import { Row, Col, Card, Avatar, Button, Popconfirm, message, Layout } from "antd";
import gps from "../../assets/images/gps-icon.jpg";
import SideNav from "../../Layout/SideNav";
import HeaderBar from "../../Layout/HeaderBar";
import FooterBar from "../../Layout/FooterBar";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import TaskItem from "./TaskItem";

const { Meta } = Card;
const { Content } = Layout;

function TaskList(props) {
    const tasks = props.tasks.map(task => (
        <TaskItem
            key={task.id}
            title={task.title}
            checked={task.completed}
            createTime={task.createTime}
            onChange={() => {}}
        />
    ));
    return [
        // <SideNav />,
        <Layout>
            <HeaderBar />
            <Content style={{ margin: "50px 24px 0" }}>
                {props.loading ? (
                    <Loader />
                ) : (
                    <Row gutter={16}>
                        <h1>Opgaver:</h1>
                        {tasks}
                    </Row>
                )}
            </Content>
            <FooterBar />
        </Layout>,
    ];
}

export default TaskList;
