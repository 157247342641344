// import { QuerySnapshot } from "@google-cloud/firestore";
import { FEATURES_UPDATE_REQUESTED } from "./types";
import {
    DBFeaturesShape,
    FEATURES_FETCH_REQUESTED,
    FEATURES_FETCH_SUCCEEDED,
} from "./types";

export function fetchFeatures({ orgId }: { orgId: string }) {
    return {
        type: FEATURES_FETCH_REQUESTED,
        payload: { orgId },
    };
}

// @ts-ignore
export function fetchFeaturesSuccess(query: QuerySnapshot) {
    const features = query.docs.map((doc: any) => ({
        id: doc.id,
        ...doc.data(),
    })) as DBFeaturesShape[];

    return {
        type: FEATURES_FETCH_SUCCEEDED,
        payload: {
            data: features,
        },
    };
}

export function updateFeature({
    orgId,
    featureId,
    data,
}: {
    orgId: string;
    featureId: string;
    data: any;
}) {
    return {
        type: FEATURES_UPDATE_REQUESTED,
        payload: { orgId, featureId, data },
    };
}

export function updateAuditSuccess() {
    return {
        type: FEATURES_UPDATE_REQUESTED,
        payload: {},
    };
}
