import React from "react";
import { InputNumber, Input, Form } from "antd";

export const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}: any) => {
    const inputNode =
        inputType === "number" ? <InputNumber placeholder="Mængde" min={0} /> : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Venligst udfyld ${title}`,
                        },
                    ]}>
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};
