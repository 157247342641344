import { useDispatch } from "react-redux";
import useDirectoryReports from "./useDirectoryReports";
import Report from "../Reports/Report";
import { updateReport } from "../../modules/reports/actions";

export default function Reports() {
    const { reports, directories, orgId } = useDirectoryReports();
    const dispatch = useDispatch();
    const dispatchUpdateReport = ({ orgId, reportId, data }: any) =>
        dispatch(
            updateReport({
                orgId,
                reportId,
                data,
            }),
        );
    const render = reports.map((report: any) => (
        <Report
            {...report}
            key={report.id}
            orgId={orgId}
            updateReport={dispatchUpdateReport}
            directories={directories}
        />
    ));
    return <>{render}</>;
}
