import { ModalType } from "./reducer";

export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export interface AdvancedTaskModalContentContext {
    orgId: string;
    taskId: string;
    templateId: string;
}
export interface AdvancedGroupTaskModalContentContext {
    groupId: string;
    taskId: string;
    templateId: string;
}

interface OpenModal {
    type: typeof OPEN_MODAL;
    payload: {
        type: ModalType | null;
        context: AdvancedTaskModalContentContext | AdvancedGroupTaskModalContentContext;
    };
}
interface CloseModal {
    type: typeof CLOSE_MODAL;
    payload: {};
}

export type ModalActionTypes = OpenModal | CloseModal;
