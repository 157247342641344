import { Layout, Row, Col, Space } from "antd";
import { useTranslation } from "react-i18next";

import AuditNote from "./AuditNote";
import AuditGuide from "./AuditGuide";
import AuditTitle from "./AuditTitle";
import AuditTaskList from "./AuditTaskList";
import AuditTemplateNote from "./AuditTemplateNote";
import AuditToggleComplete from "./AuditToggleComplete";
import SideNav from "../../Layout/SideNav";
import HeaderBar from "../../Layout/HeaderBar";
import FooterBar from "../../Layout/FooterBar";
import { AuditSchedule, AuditScheduleTask } from "../../modules/auditSchedule/types";
import { AuditScheduleResponsibleSelect } from "components/ResponsibleSelect";
import AuditDetailStatus from "./AuditDetailStatus";

const { Content } = Layout;

interface Props {
    orgId: string;
    auditId: string;
    updateAuditScheduleTask: (data: Partial<AuditScheduleTask>, id: string) => void;
    updateAuditSchedule: (data: Partial<AuditSchedule>) => void;
    deleteFile: (fileId: string) => void;
}
function AuditDetail(props: Props) {
    const { t } = useTranslation();

    return (
        <>
            <SideNav />
            <Layout>
                <HeaderBar
                    backText={t("audit.detail.back")}
                    backLink={`/orgs/${props.orgId}/audit/audits`}
                />
                <AuditDetailStatus />
                <Content style={{ margin: "50px 24px 0" }}>
                    <Row gutter={16}>
                        <Col md={16} xs={12}>
                            <AuditTitle />
                            <AuditTemplateNote />
                        </Col>

                        <Col md={8} xs={12}>
                            <Space
                                direction="horizontal"
                                style={{ display: "flex", justifyContent: "flex-end" }}>
                                <AuditScheduleResponsibleSelect />
                                <AuditGuide />
                            </Space>
                        </Col>

                        <Col md={24} xs={24}>
                            <Space direction="vertical" style={{ display: "flex" }}>
                                <AuditNote />
                                <AuditTaskList
                                    orgId={props.orgId}
                                    auditId={props.auditId}
                                    updateAuditScheduleTask={
                                        props.updateAuditScheduleTask
                                    }
                                    deleteFile={props.deleteFile}
                                />
                            </Space>
                        </Col>
                        <Col md={24} xs={24}>
                            <div style={{ padding: "10px" }} />
                        </Col>
                        <Col md={24} xs={24}>
                            <AuditToggleComplete
                                updateAuditSchedule={props.updateAuditSchedule}
                            />
                        </Col>
                    </Row>
                </Content>
                <FooterBar />
            </Layout>
        </>
    );
}

export default AuditDetail;
