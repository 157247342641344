import { useMediaQuery } from "react-responsive";

export function useIsMobile() {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile;
}

export function useIsLaptop() {
    const isLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
    });
    return isLaptop;
}
