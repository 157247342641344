import React from "react";
import { Row, Col, Card, Layout, Badge } from "antd";
import SideNav from "../../Layout/SideNav";
import HeaderBar from "../../Layout/HeaderBar";
import FooterBar from "../../Layout/FooterBar";
import Loader from "../../components/Loader";
import { SensorType } from "../../general/enums";
import { FeatureIds } from "../../modules/featureControl/featuresConfig";
import BlurOverlayContainer from "../../components/Overlay/BlurOverlayContainer";
import useDeviceStates from "./useDeviceStates";
import { formatDistanceToNow } from "date-fns";

const { Meta } = Card;
const { Content } = Layout;

interface Props {}

function SensorCard({ id, isActive }: { id: string; isActive: boolean }) {
    const color = isActive ? "green" : "red";
    return (
        <Col md={4}>
            <div
                style={{
                    backgroundColor: "#fff",
                    padding: 20,
                    marginBottom: 10,
                }}>
                <div>
                    <Badge status="processing" color={color} />
                    {id}
                </div>
            </div>
        </Col>
    );
}

function SensorList(_: Props) {
    const devices = useDeviceStates();

    const array = Object.entries(devices?.states || {}).map(([id, isActive]) => ({
        id,
        isActive,
    })) as { id: string; isActive: boolean }[];
    const activeDevices = array
        .filter(({ isActive }) => isActive)
        .sort((a, b) => a.id.localeCompare(b.id));
    const inActiveDevices = array
        .filter(({ isActive }) => !isActive)
        .sort((a, b) => a.id.localeCompare(b.id));
    const activeList = activeDevices.map(({ id, isActive }) => (
        <SensorCard id={id} isActive={isActive} />
    ));
    const inActiveList = inActiveDevices.map(({ id, isActive }) => (
        <SensorCard id={id} isActive={isActive} />
    ));

    return (
        <>
            <Layout>
                <HeaderBar />
                <Content style={{ margin: "50px 24px 0" }}>
                    {devices.isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <div style={{ float: "right" }}>
                                Last updated{" "}
                                {devices?.updateTime &&
                                    formatDistanceToNow(devices?.updateTime?.toDate(), {
                                        addSuffix: true,
                                    })}
                            </div>
                            <div>Active</div>
                            <Row gutter={8}>{activeList}</Row>
                            <div>Inactive</div>
                            <Row gutter={8}>{inActiveList}</Row>
                        </>
                    )}
                </Content>
                <FooterBar />
            </Layout>
        </>
    );
}

export default SensorList;
