import { call, put, takeLatest } from "redux-saga/effects";
import { reduxSagaFirebase } from "../../firebase";
import { fetchAuditBaseCategoriesSuccess, fetchAuditSuccess } from "./actions";
import {
    AUDIT_BASE_CATEGORY_CONFIG_FETCH_FAILED,
    AUDIT_BASE_CATEGORY_CONFIG_FETCH_REQUESTED,
    AUDIT_FETCH_FAILED,
    AUDIT_FETCH_REQUESTED,
    AUDIT_UPDATE_FAILED,
    AUDIT_UPDATE_REQUESTED,
} from "./types";
// FETCH AUDIT
function* fetchAudit({ payload }: any) {
    const { orgId, auditId } = payload;
    try {
        yield call(
            // @ts-ignore
            reduxSagaFirebase.firestore.syncDocument,
            `orgs/${orgId}/audits/${auditId}`,
            { successActionCreator: fetchAuditSuccess },
        );
    } catch (e: any) {
        yield put({ type: AUDIT_FETCH_FAILED, message: e.message });
    }
}

export function* fetchAuditSaga() {
    yield takeLatest(AUDIT_FETCH_REQUESTED, fetchAudit);
}
function* updateAudit({ payload }: any) {
    const { orgId, auditId, data } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.updateDocument,
            `orgs/${orgId}/audits/${auditId}`,
            data,
        );
    } catch (e: any) {
        yield put({ type: AUDIT_UPDATE_FAILED, message: e.message });
    }
}

export function* updateAuditSaga() {
    yield takeLatest(AUDIT_UPDATE_REQUESTED, updateAudit);
}

/// Base Categories
function* fetchAuditBaseCategories() {
    try {
        yield call(
            reduxSagaFirebase.firestore.syncDocument,
            `auditBaseCategories/config`,
            { successActionCreator: fetchAuditBaseCategoriesSuccess },
        );
    } catch (e: any) {
        yield put({ type: AUDIT_BASE_CATEGORY_CONFIG_FETCH_FAILED, message: e.message });
    }
}

export function* fetchAuditBaseCategoriesSaga() {
    yield takeLatest(
        AUDIT_BASE_CATEGORY_CONFIG_FETCH_REQUESTED,
        fetchAuditBaseCategories,
    );
}
