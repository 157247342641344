import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks";
import { format, isAfter } from "date-fns";
import { useDispatch } from "react-redux";
import {
    fetchGpsLogsRequest,
    fetchGpsLogsSuccess,
    fetchSensorLogs,
} from "../../modules/sensors/actions";
import { post } from "../../general/api";

interface Props {
    orgId: string;
    sensorId: string;
    selectedDate: string;
}

export default function useSensorLogs({ orgId, sensorId, selectedDate }: Props) {
    const identifier = useAppSelector(state => state.sensors.currentSensor.identifier);
    // const useBigqueryData = isAfter(new Date(selectedDate), new Date("2022-08-14"));
    const dispatch = useDispatch();
    useEffect(() => {
        async function getGpsLogsBigquery({
            identifier,
            selectedDate,
        }: {
            identifier: string;
            selectedDate: string;
        }) {
            dispatch(fetchGpsLogsRequest());
            const response = await post(
                "getGpsLogs",
                {
                    gpsId: identifier,
                    date: format(new Date(selectedDate), "yyyy-MM-dd"),
                    orgId,
                    sensorId,
                },
                "https://europe-west1-akstotal-v2.cloudfunctions.net",
            );
            const data = await response.json();

            const sensorLogs = data
                ? data.map((log: any) => ({
                      boxId: log.gpsId,
                      lat: log.lat,
                      lng: log.lng,
                      logTime: {
                          toDate: () => new Date(log.logTime || log.serverTime?.value),
                      },
                  }))
                : [];

            dispatch(fetchGpsLogsSuccess(sensorLogs));
        }
        if (identifier && selectedDate) {
            // if (useBigqueryData) {
            getGpsLogsBigquery({ identifier, selectedDate });
            // } else {
            //     dispatch(fetchSensorLogs({ orgId, sensorId, selectedDate }));
            // }
        }

        return () => {};
    }, [identifier, selectedDate]);
}
