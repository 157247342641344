import { fetchOrg } from "../modules/orgs/actions";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../hooks";

function useOrg() {
    const params = useParams<{ orgId: string }>();
    const dispatch = useDispatch();
    const orgId = useAppSelector(state => state.orgs?.currentOrg?.id);
    useEffect(() => {
        /**
         * Only update if orgId is actually changing
         */
        if (params.orgId && orgId !== params.orgId) {
            dispatch(fetchOrg({ orgId: params.orgId }));
        }
    }, [params.orgId, orgId, dispatch]);
}

export default useOrg;
