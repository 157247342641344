import React from "react";

export default function PrivacyPolicy() {
    return (
        <div>
            <h1>PrivacyPolicy</h1>
            <Content />
        </div>
    );
}

function Content() {
    return (
        <div>
            <h3>PERSONDATAFORORDNINGEN</h3>
            General Date Privacy Regulation(EU – GDPR) PERSONDATAFORORDNINGEN Der er
            gældende for aks2tal er udarbejdet på baggrund af informationer fra Danske
            Bank og Dansk industri med flere. Persondataforordningen er gældende for
            aks2tal fra den 25. maj 2018.
            <h3>FORMÅLET MED PERSONDATAFORORDNINGEN </h3>
            Beskyttelse af persondata og deres anvendelse.Med pligt til at underrette
            datatilsynet ved tyveri eller utilsigtet tab, snarest og senest 72 timer efter
            hændelsen.Med den nye persondataforordning er ejerskabet til data og ret til
            indsigt og eller sletning tydeligt defineret.
            <h3>DEFINITIONER</h3>
            <br />
            Datatilsynet:
            <br />
            <br /> Er den centrale uafhængige myndighed, der fører tilsyn med reglerne i
            persondataloven.Rådgiver, vejleder, behandler klager og gennemfører
            inspektioner hos myndigheder og virksomheder. Borgergade 28, 5 – 1300
            København K – 3319 3218 – dt @datatilsynet.dk
            <br />
            <br /> Dataansvarlig i virksomheden:
            <br />
            Den der afgør til hvilke formål og med hvilke hjælpemidler, der må foretages
            behandling af personoplysninger.
            <br />
            <br />
            Databehandler:
            <br /> Den der behandler personoplysninger på den data ansvarliges vegne.
            <br />
            <br />
            Personoplysning:
            <br /> Enhver form for information om identificeret eller identificerbar
            fysisk person. <br />
            <br />
            Kategorier af personoplysninger:
            <br /> Det skal bestemmes, om der behandles almindelige eller følsomme
            personoplysninger. <br />
            <br />
            Behandling:
            <br />
            Indsamling, registrering, organisering, systematisering, opbevaring,
            tilpasning eller ændring, genfinding, søgning, brug, videregivelse ved
            transmission, formidling eller enhver form for overladelse, sammenstilling
            eller samkøring, begrænsning, sletning eller tilintetgørelse.
            <br />
            <br />
            Samtykke:
            <br />
            Frivillig, specifik, informeret og utvetydig viljestilkendegivelse der
            bekræfter, at personoplysninger må behandles. <br />
            <br />
            Konsekvensanalyse:
            <br /> Kortlægning af konsekvenserne for privatlivet ved digital opbevaring og
            iværksættelse af tiltag til beskyttelse og kontrol af beskyttelse af
            personoplysninger. <br />
            <br />
            Profilering:
            <br /> Automatisk behandling af personoplysninger, der har til formål at
            evaluere bestemte personlige forhold.
            <br />
            <br /> Data protection by design og default:
            <br />
            Design af teknologisk løsning således at den reducerer graden af indgriben i
            datasubjekternes privatliv.Tiltagene skal slås til som standard.
            PERSONDATAFORORDNINGEN aks2tal <br />
            <br />
            Databeskyttelsesrådgiver:
            <br /> En person, der har det som sin opgave at sikre beskyttelse af
            personoplysninger og efterlevelse af reglerne.
            <br />
            <br /> Identifikatorer:
            <br /> I forordningens præambel 30 og 64 samt §4, stk.1, litra1 beskrives
            indentifikatorer, som noget der kan bruges til at identificere en person og
            der nævnes bl.a.specifikt IO - adresser, cookies og REFID.I det omfang disse
            indentifikatorer faktisk kan bruges til at identificere en fysisk person må
            det antages at de er omfattet af forordningens definition af
            personoplysninger.Specielt cookies er dog også reguleret i ePrivacy -
            direktivet, jf.henvisningen i artikel 95 til direktiv 2002 / 58 / EF.
            <br />
            <br />
            Persondata:
            <br /> Følsomme persondata omfatter Biometri – Genetik – Etnicitet og race –
            Helbredsoplysninger – Religion – Fagforeningsmæssigt tilhørsforhold – Politisk
            overbevisning – Seksuel orientering. <br />
            <br />
            Almindelige persondata omfatter
            <br /> Alle øvrige oplysninger udover de 8 kategorier, der udgør de følsomme
            persondata, såsom adresse, telefonnummer, e - mail og CPR - nr.med videre.
            <h3>DE REGISTREREDES RETTIGHEDER</h3> Den dataansvarlige skal forberede sig på
            at kunne håndtere rettigheder og vide, hvad der skal oplyses: Når en kunde,
            det være sig en ansat, registreres for at kunne købe en ydelse <br />
            <br />
            Melder sig til et nyhedsbrev <br />
            <br />
            Bruger virksomhedens hjemmeside
            <br />
            <br /> Kræver indsigt i oplysninger
            <br />
            <br /> Hvis en kunde kræver alle oplysninger slettet(retten til at blive
            glemt), dette er ikke en ubetinget ret.Tvivlsspørgsmål kan alene afgøres af
            Datatilsynet. <h3>UNDTAGET FRA PERSONDATA FORORDNINGEN</h3> Er data vedrørende
            juridiske virksomheder <h3>PERSONDATAFORORDNINGEN I aks2tal</h3> aks2tal
            handler med både firmaer og private og har følgeligt kontaktoplysninger i
            kategorien almindelige persondata. <br />
            <br />
            Dataansvarlig:
            <br /> Allan Schmidt <br />
            <br />
            Databehandlere DK:
            <br /> Hallerup net, der er oprettet databehandleraftale(bilagsliste)
            <br />
            <br />
            Databehandler
            <br /> Databehandleraftale er på todo listen.
            <h3>OPBEVARING AF PERSONDATA I VIRKSOMHEDEN</h3> Hardcopy opbevares under
            dobbeltlås Elektroniske data opbevares under adgandskode, password og
            antivirusprogram. <h3>SLETTEFRISTER</h3> Følsomme oplysninger som måtte være
            indeholdt i ansøgninger, slettes / makuleres umiddelbart efter behandling.
            <br />
            <br />
            Almindelige persondata slettes / makuleres når kunde / ansættelsesforholdet
            ophører.
            <br />
            <br /> All data relaterede til kundeforhold(faktureringsoplysninger) skal
            jævnfør bogføringsloven opbevares i mindst 5 år.{" "}
            <h3>INFORMATION TIL ALLE FOR HVEM DER REGISTRERES PERSONDATA </h3>Bedre
            overblik over og kontrol med dine personoplysninger hos aks2tal <br />
            <br />
            Fra den 25. maj 2018 gælder der nye regler for hvordan virksomheder,
            organisationer og offentlige institutioner i EU skal behandle
            personoplysninger, og det har også betydning for dig. <br />
            <br />
            Du får bedre overblik!
            <br />
            <br /> Kort fortalt betyder de nye regler, at alle privatpersoner fremover får
            bedre overblik over og kontrol med de personoplysninger, virksomheder m.m.har
            registreret om dem.Med andre ord bliver det nemmere for dig at <br />
            1. Få udleveret de personoplysninger, vi har registreret om dig <br />
            2. Få at vide, hvordan vi bruger oplysningerne <br />
            3. Få opdateret oplysningerne <br />
            <br />
            Hvilke personoplysninger registrerer vi og hvad bruger vi dem til ? <br />
            <br />
            Typisk registrerer vi oplysninger om dit navn, adresse, telefonnummer og e -
            mailadresse og om de produkter og services du har hos os.
            <br />
            <br /> Oplysningerne bruger vi til at servicere, levere og fakturere vare og
            ydelser til dig. <br />
            <br />
            Vil du se de personoplysninger, vi har registreret, kan du fra den 25. maj
            bestille en oversigt på: info @AKStotal.dk - telefon 29 730 732.
            <br />
            <br /> Venlig hilsen aks2tal<h3> VEDLIGEHOLD & OPDATERING</h3>
            Persondataforordningen opdateres og vedligeholdes i takt med ændring i
            persondataforordningen og firmaets behov for registrering og opbevaring af
            persondata.
            <h3>TO DO LIST IGANGVÆRENDE LØSNINGER</h3> I det omfang løsninger og ændringer
            ikke umiddelbart kan gennemføres / PERSONDATAFORORDNINGEN aks2tal - Side 3 af
            4 implementeres på grund af vente - svartid fra eksterne partnere, optages
            punktet på TODO listen i nærværende dokument. <h3> TODO</h3> Information til
            alle på hjemmeside www.aks2tal.com(INFORMATION TIL ALLE FOR HVEM DER
            REGISTRERES PERSONDATA).Erklæringen ligger som 1. punkt under ”downloads”
            <h3>BILAGSLISTE</h3> A.Databehandleraftale med Hallerup - net 21. maj 2018`
        </div>
    );
}
