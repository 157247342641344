import { call, put, fork, takeLatest } from "redux-saga/effects";
import firebase, { reduxSagaFirebase } from "../../firebase";
import { fetchHourLogsSuccess } from "./actions";
import { HourLog } from "./types";
import {
    HOURLOG_CREATE_REQUESTED,
    HOURLOG_CREATE_FAILED,
    HOURLOG_CREATE_SUCCEEDED,
    HOURLOGS_FETCH_REQUESTED,
    HOURLOGS_FETCH_FAILED,
    HOURLOG_UPDATE_REQUESTED,
    HOURLOG_UPDATE_FAILED,
    HOURLOG_UPDATE_SUCCEEDED,
} from "./types";

function* fetchHourLogs({ payload }: { payload: { orgId: string } }) {
    const { orgId } = payload;
    try {
        yield fork(
            // @ts-ignore
            reduxSagaFirebase.firestore.syncCollection,
            firebase
                .firestore()
                .collection(`orgs/${orgId}/hourLogs`)
                .orderBy("createTime", "desc"),
            {
                successActionCreator: fetchHourLogsSuccess,
            },
        );
    } catch (e: any) {
        yield put({ type: HOURLOGS_FETCH_FAILED, message: e.message });
    }
}

export function* fetchHourLogsSaga() {
    // @ts-ignore
    yield takeLatest(HOURLOGS_FETCH_REQUESTED, fetchHourLogs);
}

function* addHourLog({
    payload,
}: {
    payload: { orgId: string; initials: string; amount: number; productId: string };
}) {
    const { orgId, initials, amount, productId } = payload;
    try {
        yield call(reduxSagaFirebase.firestore.addDocument, `orgs/${orgId}/cleanLogs`, {
            initials,
            amount,
            product: {
                id: productId,
            },
            createTime: new Date(),
        });
        yield put({ type: HOURLOG_CREATE_SUCCEEDED });
    } catch (e: any) {
        yield put({ type: HOURLOG_CREATE_FAILED, message: e.message });
    }
}

export function* addHourLogSaga() {
    // @ts-ignore
    yield takeLatest(HOURLOG_CREATE_REQUESTED, addHourLog);
}

function* updateHourLog({
    payload,
}: {
    payload: { orgId: string; logId: string; data: HourLog };
}) {
    const { orgId, logId, data } = payload;
    try {
        // @ts-ignore
        const orgs = yield call(
            reduxSagaFirebase.firestore.updateDocument,
            `orgs/${orgId}/cleanLogs/${logId}`,
            {
                ...data,
            },
        );
        yield put({ type: HOURLOG_UPDATE_SUCCEEDED });
    } catch (e: any) {
        yield put({ type: HOURLOG_UPDATE_FAILED, message: e.message });
    }
}

export function* updateHourLogSaga() {
    // @ts-ignore
    yield takeLatest(HOURLOG_UPDATE_REQUESTED, updateHourLog);
}
