import React from "react";
import { useSelector } from "react-redux";
import { GPSItem } from "../../../modules/inventory/gps/types";
import { InventoryStatus, InventoryStatusMap } from "../../../modules/inventory/types";
import { AppState } from "../../../store";
import { Tag } from "antd";
import { FoobotItem } from "../../../modules/inventory/foobot/types";
import { EspAqItem } from "../../../modules/inventory/esp_aq/types";
import { SimcardItem } from "modules/inventory/simcards/types";
import { InventoryTagsMap } from "Views/SensorOverview/useInventoryTags";

export default function Total({
    inventoryTagsMap,
}: {
    inventoryTagsMap: InventoryTagsMap;
}) {
    const simcards = useSelector((state: AppState) =>
        state.inventory.simcardIds.map(id => state.inventory.inventoryMap[id]),
    ) as SimcardItem[];

    const countTags = (simcards: SimcardItem[]) => {
        const tagCounts: { [key: string]: number } = {};

        simcards.forEach(simcard => {
            const { tags } = simcard;
            tags.forEach(tag => {
                if (tagCounts[tag]) {
                    tagCounts[tag]++;
                } else {
                    tagCounts[tag] = 1;
                }
            });
        });

        return tagCounts;
    };

    const tagCounts = countTags(simcards);

    return (
        <div style={{ display: "flex" }}>
            {Object.entries(tagCounts).map(([key, value]) => {
                const title = inventoryTagsMap?.[key]?.title;
                const color = inventoryTagsMap?.[key]?.color;
                return (
                    <div style={{ marginRight: 10 }}>
                        <Tag color={color}>{title}</Tag>
                        {value}
                    </div>
                );
            })}
        </div>
    );
}
