import {
    OPEN_PROFILE_DRAWER,
    CLOSE_PROFILE_DRAWER,
    OPEN_GROUP_DRAWER,
    CLOSE_GROUP_DRAWER,
    OPEN_BILLING_DRAWER,
    CLOSE_BILLING_DRAWER,
    CLOSE_ORG_NOTIFICATIONS_DRAWER,
    OPEN_ORG_NOTIFICATIONS_DRAWER,
    OPEN_ORG_MEMBER_DRAWER,
    CLOSE_ORG_MEMBER_DRAWER,
} from "./types";

export function openProfileDrawer() {
    return {
        type: OPEN_PROFILE_DRAWER,
        payload: {},
    };
}
export function closeProfileDrawer() {
    return {
        type: CLOSE_PROFILE_DRAWER,
        payload: {},
    };
}

export function openGroupDrawer({ groupId }: { groupId: string }) {
    return {
        type: OPEN_GROUP_DRAWER,
        payload: {
            groupId,
        },
    };
}

export function closeGroupDrawer() {
    return {
        type: CLOSE_GROUP_DRAWER,
        payload: {},
    };
}

export function openBillingDrawer({
    billingId,
    orgId,
}: {
    orgId: string;
    billingId: string;
}) {
    return {
        type: OPEN_BILLING_DRAWER,
        payload: {
            billingId,
            orgId,
        },
    };
}

export function closeBillingDrawer() {
    return {
        type: CLOSE_BILLING_DRAWER,
        payload: {},
    };
}
export function openOrgNotificationDrawer({
    userId,
    orgId,
}: {
    orgId: string;
    userId: string;
}) {
    return {
        type: OPEN_ORG_NOTIFICATIONS_DRAWER,
        payload: {
            userId,
            orgId,
        },
    };
}

export function closeOrgNotificationDrawer() {
    return {
        type: CLOSE_ORG_NOTIFICATIONS_DRAWER,
        payload: {},
    };
}
export function openOrgMemberDrawer({
    userId,
    orgId,
}: {
    orgId: string;
    userId: string;
}) {
    return {
        type: OPEN_ORG_MEMBER_DRAWER,
        payload: {
            userId,
            orgId,
        },
    };
}

export function closeOrgMemberDrawer() {
    return {
        type: CLOSE_ORG_MEMBER_DRAWER,
        payload: {},
    };
}
