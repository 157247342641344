import React, { useState } from "react";
import { Table, Form } from "antd";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { EditableCell } from "./TableEditCell";
import columns from "./columns";

const EditableTable = ({ data, isShowingHistory, updateOrder }: any) => {
    const [form] = Form.useForm();
    const isMobile = useIsMobile();
    const [editingKey, setEditingKey] = useState("");
    const isEditing = (record: any) => record.key === editingKey;

    const cancel = () => {
        setEditingKey("");
    };

    const edit = (record: any) => {
        form.setFieldsValue({
            name: "",
            age: "",
            address: "",
            ...record,
        });
        setEditingKey(record.key);
    };

    const save = async (key: any) => {
        try {
            const row = await form.validateFields();

            updateOrder({ orderId: key, data: row });

            setEditingKey("");
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };

    const columnsToRender = columns(editingKey, isEditing, save, edit, cancel);

    const mergedColumns = columnsToRender
        .filter(log => (isMobile && log.dataIndex !== "createTime") || log)
        .map(col => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record: any) => ({
                    record,
                    inputType: col.dataIndex === "amount" ? "number" : "text",
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                }),
            };
        });
    return (
        <Form form={form} component={false}>
            {data.length > 0 ? (
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    locale={{
                        emptyText: isShowingHistory
                            ? "Ingen logs"
                            : "Ingen logs fra i dag",
                    }}
                    bordered
                    dataSource={data}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={
                        isShowingHistory
                            ? {
                                  onChange: cancel,
                              }
                            : false
                    }
                />
            ) : (
                <div
                    style={{
                        fontSize: 20,
                        textAlign: "center",
                        color: "#c0c0c0",
                        padding: 12,
                    }}>
                    {isShowingHistory ? "Ingen logs" : "Ingen logs fra i dag"}
                </div>
            )}
        </Form>
    );
};

export default EditableTable;
