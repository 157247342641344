import React, { useState } from "react";
import { Table, Form } from "antd";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { parseAmountToFloat } from "../../../general/helpers";
import { EditableCell } from "./TableEditCell";
import columns from "./columns";
import Message, { Type } from "../../../components/Notification/Message";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks";
import firebase from "firebase/compat/app";
import { Unit } from "../../../components/SelectUnit";

const EditableTable = ({
    orgId,
    data,
    productsMap,
    isShowingHistory,
    updateOrder,
    updateOrgProduct,
}: { orgId: string } & any) => {
    const groupStorage = useAppSelector(state => {
        // return state.orgs.orgMap;
        const groupId = state.orgs.orgMap[orgId]?.groupId;
        if (!groupId) return null;
        return state.groups.groupsMap[groupId];
    });
    const unit = useAppSelector(state => {
        // return state.orgs.orgMap;
        const orgUnit = state.orgs.orgMap[orgId]?.unit;
        console.log({ orgUnit });
        const groupId = state.orgs.orgMap[orgId]?.groupId;
        console.log({ groupId });
        if (!groupId) return orgUnit;
        const group = state.groups.groupsMap[groupId];
        return orgUnit;
        console.log({ group });
    });
    console.log({ groupStorage });

    const [form] = Form.useForm();
    const isMobile = useIsMobile();
    const [editingKey, setEditingKey] = useState("");
    const isEditing = (record: any) => record.key === editingKey;
    const { t } = useTranslation();
    const cancel = () => {
        setEditingKey("");
    };

    const edit = (record: any) => {
        form.setFieldsValue({
            name: "",
            age: "",
            address: "",
            ...record,
        });
        setEditingKey(record.key);
    };

    const save = async (key: any, newAmount: any, record: any) => {
        try {
            const row = await form.validateFields();
            let amount;
            const storage = groupStorage?.storage?.[record.productId]?.storage;

            if (row.status === "completed" && record.status !== "completed") {
                if (storage && storage - newAmount < 0) {
                    return Message({
                        key: "errorOrder",
                        type: Type.ERROR,
                        message: `Der skete en fejl`,
                        description: `Mængden på hovedlageret vil gå under 0 og er lige nu ${storage}, prøv venligst igen`,
                    });
                }
                amount =
                    parseAmountToFloat(productsMap[record.productId]?.storage) +
                    parseAmountToFloat(newAmount);
                updateOrgProduct({
                    productId: record.productId,
                    data: { storage: amount },
                });
                if (storage) {
                    try {
                        await firebase
                            .firestore()
                            .collection("groups")
                            .doc(groupStorage.id)
                            .set(
                                {
                                    storage: {
                                        [record.productId]: {
                                            storage:
                                                parseAmountToFloat(storage) -
                                                parseAmountToFloat(newAmount),
                                        },
                                    },
                                },
                                { merge: true },
                            );
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
            if (row.status !== "completed" && record.status === "completed") {
                amount =
                    parseAmountToFloat(productsMap[record.productId]?.storage) -
                    parseAmountToFloat(newAmount);
                if (amount <= 0)
                    return Message({
                        key: "errorOrder",
                        type: Type.ERROR,
                        message: `Der skete en fejl`,
                        description: `Mængden vil gå under 0, prøv venligst igen`,
                    });
                updateOrgProduct({
                    productId: record.productId,
                    data: { storage: amount },
                });
                if (storage) {
                    try {
                        await firebase
                            .firestore()
                            .collection("groups")
                            .doc(groupStorage.id)
                            .set(
                                {
                                    storage: {
                                        [record.productId]:
                                            parseAmountToFloat(storage) +
                                            parseAmountToFloat(newAmount),
                                    },
                                },
                                { merge: true },
                            );
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
            updateOrder({ orderId: key, data: row });
            setEditingKey("");
            return Message({
                key: "successOrder",
                type: Type.SUCCESS,
                message: `Ordre opdateret`,
                description: `Beholdingen er nu ${amount || "opdateret"}`,
            });
        } catch (errInfo) {
            console.log("Validate Failed:", errInfo);
        }
    };
    const titles = {
        orderTime: t("orders.order_time"),
        orderAmount: t("orders.order_amount"),
        orderProduct: t("orders.order_product"),
        orderStatus: t("orders.order_status"),
    };

    const columnsToRender = columns(
        editingKey,
        isEditing,
        save,
        edit,
        cancel,
        titles,
        unit as Unit,
    );

    const mergedColumns = columnsToRender
        .filter(log => (isMobile && log.dataIndex !== "createTime") || log)
        .map(col => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record: any) => ({
                    record,
                    inputType: col.dataIndex === "status" ? "select" : "text",
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                }),
            };
        });
    return (
        <Form form={form} component={false}>
            {data.length > 0 ? (
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    locale={{
                        emptyText: isShowingHistory
                            ? "Ingen logs"
                            : "Intet logs fra i dag",
                    }}
                    bordered
                    dataSource={data}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={
                        isShowingHistory
                            ? {
                                  onChange: cancel,
                              }
                            : false
                    }
                />
            ) : (
                <div
                    style={{
                        fontSize: 20,
                        textAlign: "center",
                        color: "#c0c0c0",
                        padding: 12,
                    }}>
                    {isShowingHistory ? "Ingen logs" : "Ingen logs fra i dag"}
                </div>
            )}
        </Form>
    );
};

export default EditableTable;
