import React from "react";
import { useEffect } from "react";
import { updateOrder, addOrder } from "../../../modules/orders/actions";
import { fetchOrgProducts, updateOrgProduct } from "../../../modules/products/actions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import EditableTable from "../../OrgOrders/components/Table";
import { AppState } from "../../../store";
import moment from "moment";
import useOrgOrders from "./useOrgOrders";
import AddOrgOrderForm from "../../OrgOrders/AddOrderForm";

export default function OrderModalList({ orgId }: { orgId: string }) {
    const dispatch = useAppDispatch();
    const { orders, loadingOrders } = useOrgOrders(orgId);
    const { uid, products, productsMap } = useAppSelector((state: AppState) => ({
        orders: state.orders.list.map((order: any) => ({
            ...order,
            // @ts-ignore
            orderProductTitle: state.products.orgProductsMap[order.productId]?.title,
        })),
        uid: state.user.details.uid,
        products: state.products.orgProducts,
        productsMap: state.products.orgProductsMap,
    }));
    useEffect(() => {
        if (orgId) {
            dispatch(fetchOrgProducts(orgId));
        } else {
            console.error("No orgId");
        }
    }, [orgId]);

    const userId = uid;

    const data = orders.map((order: any) => {
        return {
            ...order,
            key: order.id,
            createTime: moment(order.createTime.toDate()).format("Do MMM. YYYY - HH:mm"),
            orderProductTitle: productsMap[order.productId]?.title,
        };
    });
    const handleAddOrder = ({ amount, productId }: any) => {
        dispatch(addOrder(orgId, productId, userId, amount));
    };

    const handleUpdateOrder = ({ orderId, data }: any) => {
        dispatch(updateOrder({ orderId, data }));
    };

    const handleUpdateOrgProduct = ({ productId, data }: any) => {
        dispatch(updateOrgProduct({ orgId, productId, data }));
    };

    return (
        <>
            <EditableTable
                orgId={orgId}
                loading={loadingOrders}
                scroll={{ x: true }}
                data={data}
                updateOrder={handleUpdateOrder}
                updateOrgProduct={handleUpdateOrgProduct}
                productsMap={productsMap}
            />
            <div style={{ padding: 8 }} />

            <AddOrgOrderForm addOrder={handleAddOrder} products={products} />
        </>
    );
}
