import { Select } from "antd";
import { If } from "modules/inventory/types";
import { IOrg } from "modules/orgs/interfaces";
import React from "react";

export default function OrgsSelect({
    orgs,
    orgIds,
    setOrgIds,
    mode,
    ...rest
}: {
    orgs: IOrg[];
    orgIds: If<typeof mode, "multiple", string[], string>;
    setOrgIds: (orgIds: If<typeof mode, "multiple", string[], string>) => void;
    mode?: "multiple" | "tags";
    [key: string]: any;
}) {
    const options = orgs
        .sort((a, b) =>
            a.title?.toLowerCase().localeCompare(b.title?.toLowerCase(), "da", {
                sensitivity: "base",
                numeric: true,
            }),
        )
        .map(org => ({ label: org.title, value: org.id }));
    return (
        <Select
            {...rest}
            mode={mode}
            style={{ width: "100%" }}
            value={orgIds}
            options={options}
            onChange={(newValue: If<typeof mode, "multiple", string[], string>) => {
                setOrgIds(newValue);
            }}
            placeholder={"Vælg afdelinger..."}
            maxTagCount={"responsive"}
        />
    );
}
