import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import firebase from "firebase/compat/app";
import { AppState } from "../store";
import { setOnboarding, setOnboardingState } from "modules/user/actions";
import { RequestState } from "interfaces/request";

function useOnboarding() {
    const uid = useSelector((state: AppState) => state.user.details.uid);

    const dispatch = useDispatch();
    let unsub: any = null;
    useEffect(() => {
        if (!uid) return;
        dispatch(setOnboardingState(RequestState.PENDING));
        unsub = firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .collection("onboarding")
            .doc("onboarding")
            .onSnapshot(
                doc => {
                    const data = doc.data();
                    dispatch(setOnboarding(data));
                    // update language
                },
                error => {
                    dispatch(setOnboardingState(RequestState.REJECTED));
                    console.error(error);
                },
            );
        return () => unsub?.();
    }, [uid]);
}

export default useOnboarding;
