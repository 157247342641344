export enum InvoiceStatus {
    DRAFT = "DRAFT",
    APPROVED = "APPROVED",
    SENT = "SENT",
    PAID = "PAID",
    OVERDUE = "OVERDUE",
}

export interface ProductLine {
    description: string;

    quantity: number;

    rate: number;
    excludeVAT?: boolean;
}

// TODO: fix shape
export interface DBInvoiceShape {
    id: string;
    // @ts-ignore
    createTime?: firebase.firestore.Timestamp;
    clientName: string;
    updateTime: Date;
    status: InvoiceStatus;
    productLines: ProductLine[];
    invoiceTitle: string;
}

export interface InvoiceState {
    invoiceIds: string[];
    invoiceMap: { [id: string]: DBInvoiceShape };
    loading: boolean;
}

export const INVOICES_FETCH_REQUESTED = "INVOICES_FETCH_REQUESTED";
export const INVOICES_FETCH_SUCCEEDED = "INVOICES_FETCH_SUCCEEDED";
export const INVOICES_FETCH_FAILED = "INVOICES_FETCH_FAILED";

export const INVOICE_FETCH_REQUESTED = "INVOICE_FETCH_REQUESTED";
export const INVOICE_FETCH_SUCCEEDED = "INVOICE_FETCH_SUCCEEDED";
export const INVOICE_FETCH_FAILED = "INVOICE_FETCH_FAILED";

interface FetchInvoicesSuccess {
    type: typeof INVOICES_FETCH_REQUESTED;
    payload: {};
}
interface FetchInvoices {
    type: typeof INVOICES_FETCH_SUCCEEDED;
    payload: DBInvoiceShape[];
}
interface FetchInvoicesFailed {
    type: typeof INVOICES_FETCH_FAILED;
    payload: {};
}
interface FetchInvoiceSuccess {
    type: typeof INVOICE_FETCH_REQUESTED;
    payload: {};
}
interface FetchInvoice {
    type: typeof INVOICE_FETCH_SUCCEEDED;
    payload: DBInvoiceShape[];
}
interface FetchInvoiceFailed {
    type: typeof INVOICE_FETCH_FAILED;
    payload: {};
}

export type InvoiceActionTypes =
    | FetchInvoices
    | FetchInvoicesSuccess
    | FetchInvoicesFailed
    | FetchInvoiceSuccess
    | FetchInvoice
    | FetchInvoiceFailed;
