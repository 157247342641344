import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Layout, Menu, Button } from "antd";
import {
    AimOutlined,
    AreaChartOutlined,
    ArrowLeftOutlined,
    CreditCardOutlined,
    DashboardOutlined,
    ShareAltOutlined,
    TabletOutlined,
} from "@ant-design/icons";
import { InventoryType } from "../../modules/inventory/types";
import { useTranslation } from "react-i18next";
import { StyleGuide } from "styles/StyleGuide";
const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

interface IProps {
    location: {
        pathname: string;
    };
    match: {
        params: {
            orgId: string;
        };
    };
}

function InventoryNav({ location }: IProps) {
    const { t } = useTranslation();
    return (
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
            style={{
                textAlign: "left",
                borderRight: `1px solid ${StyleGuide.palette.grey200}`,
                paddingTop: 4,
            }}
            onCollapse={() => {}}>
            <Menu mode="inline" defaultSelectedKeys={[location.pathname]}>
                <Menu.Item key={`/inventory/${InventoryType.IPAD}`}>
                    <Link to={`/inventory/${InventoryType.IPAD}`}>
                        <TabletOutlined />
                        <span className="nav-text">{t("sidenavInventory.ipad")}</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key={`/inventory/${InventoryType.GPS}`}>
                    <Link to={`/inventory/${InventoryType.GPS}`}>
                        <AimOutlined />
                        <span className="nav-text">{t("sidenavInventory.gps")}</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key={`/inventory/${InventoryType.FOOBOT}`}>
                    <Link to={`/inventory/${InventoryType.FOOBOT}`}>
                        <AreaChartOutlined />
                        <span className="nav-text">{t("sidenavInventory.foobot")}</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key={`/inventory/${InventoryType.ESP_AQ}`}>
                    <Link to={`/inventory/${InventoryType.ESP_AQ}`}>
                        <AreaChartOutlined />
                        <span className="nav-text">{t("sidenavInventory.dust")}</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key={`/inventory/${InventoryType.PUMP}`}>
                    <Link to={`/inventory/${InventoryType.PUMP}`}>
                        <ShareAltOutlined />
                        <span className="nav-text">{t("sidenavInventory.pumps")}</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key={`/inventory/${InventoryType.SIM_CARDS}`}>
                    <Link to={`/inventory/${InventoryType.SIM_CARDS}`}>
                        <CreditCardOutlined />
                        <span className="nav-text">{t("sidenavInventory.simcards")}</span>
                    </Link>
                </Menu.Item>
            </Menu>
        </Sider>
    );
}

export default withRouter(InventoryNav);
