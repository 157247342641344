import React, { useState } from "react";
import { Form, Input, Button, Select, InputNumber, message } from "antd";

import { post } from "../../../general/api";
import { useParams } from "react-router";
import Message from "../../../components/Notification/Message";
import { Type } from "../../../components/Notification/Message";
import { SensorType } from "../../../general/enums";

const { Option } = Select;

const AddFormGsmPlant = ({ onClose }: { onClose: () => void }) => {
    const { orgId } = useParams<{ orgId: string }>();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);

    const onFinish = async (values: any) => {
        setIsLoading(true);
        Message({
            key: "addSensor",
            message: "Loading...",
            // description: "Sensoren blev tilføjet korrekt",
            type: Type.LOADING,
        });
        const payload = {
            orgId,
            title: values.title,
            deviceId: values.deviceId,
            type: SensorType.GSM_PLANT,
            phoneNumber: values.phoneNumber || null,
        };
        const response = await post("setupSensorGsmPlant", payload);
        setIsLoading(false);
        if (response.status === 200) {
            form.resetFields();
            Message({
                key: "addSensor",
                message: "Sensor tilføjet",
                description: "Sensoren blev tilføjet korrekt",
            });
            onClose();
        } else {
            Message({
                key: "addSensor",
                message: "Fejl!",
                description: "Noget gik galt, prøv venligst igen",
            });
        }
    };

    return (
        <Form form={form} layout="vertical" onFinish={onFinish}>
            <Form.Item name="title" label="Sensor navn" rules={[{ required: true }]}>
                <Input placeholder="Sensor navn" />
            </Form.Item>
            <Form.Item name="deviceId" label="Sensor id" rules={[{ required: true }]}>
                <Input placeholder="Sensor id" />
            </Form.Item>
            <Form.Item
                name="phoneNumber"
                label="Telefonnummer til SMS alarmer"
                rules={[{ required: false }]}>
                <Input placeholder="45XXXXXXXX" />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" block loading={isLoading}>
                    Tilføj
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddFormGsmPlant;
