import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store";
import featureConfig, { FeatureIds } from "../../modules/featureControl/featuresConfig";
import FeatureToggles from "./FeatureToggles";
import { updateFeature } from "../../modules/featureControl/actions";
import Loader from "../../components/Loader";

function FeatureControlView() {
    const featuresMap = useSelector((state: AppState) => state.features.featuresMap);
    const loading = useSelector((state: AppState) => state.features.loading);
    const orgId = useSelector((state: AppState) => state.orgs.currentOrg.id);
    const dispatch = useDispatch();

    const onUpdateFeatures = (featureId: string, shouldEnable: boolean) => {
        console.log({ featureId, shouldEnable });

        if (shouldEnable) {
            return dispatch(
                updateFeature({ orgId, featureId, data: { isEnabled: true } }),
            );
        }
        return dispatch(updateFeature({ orgId, featureId, data: { isEnabled: false } }));
    };
    const onUpdateDisplayFeatures = (featureId: string, shouldEnable: boolean) => {
        console.log({ featureId, shouldEnable });

        if (shouldEnable) {
            return dispatch(
                updateFeature({ orgId, featureId, data: { isDisplayed: true } }),
            );
        }
        return dispatch(
            updateFeature({ orgId, featureId, data: { isDisplayed: false } }),
        );
    };

    const selectedOptions = Object.keys(featureConfig).map(key =>
        featuresMap[key as FeatureIds]?.isEnabled !== false ? key : null,
    );
    const selectedDisplayOptions = Object.keys(featureConfig).map(key =>
        featuresMap[key as FeatureIds]?.isDisplayed !== false ? key : null,
    );
    if (loading) return <Loader />;
    return (
        <div>
            <h2>Feature controller</h2>
            <FeatureToggles
                key="enable"
                options={featureConfig}
                selected={selectedOptions}
                onUpdateFeatures={onUpdateFeatures}
            />
            <h2>Hvis features ikke skal vises</h2>
            <FeatureToggles
                key="display"
                options={featureConfig}
                selected={selectedDisplayOptions}
                onUpdateFeatures={onUpdateDisplayFeatures}
            />
        </div>
    );
}

export default FeatureControlView;
