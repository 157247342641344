import React from "react";
import { Menu } from "antd";
import { useDispatch } from "react-redux";
import { signOut } from "../modules/user/actions";
import { openProfileDrawer } from "../modules/drawer/actions";
import { useTranslation } from "react-i18next";
import { useIsLaptop } from "../hooks/useIsMobile";
import AdminOnly from "../components/AdminOnly";
import { Link } from "react-router-dom";

function MenuHeader() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isLaptop = useIsLaptop();

    const openProfile = () => dispatch(openProfileDrawer());

    const dispatchSignOut = () => {
        dispatch(signOut());
        window.location.reload();
    };

    return (
        <Menu>
            {!isLaptop && (
                <>
                    <Menu.Item key="header-menu-tasks">
                        <AdminOnly>
                            <Link style={{ marginRight: 20 }} to="/tasks">
                                {t("header.tasks")}
                            </Link>
                        </AdminOnly>
                    </Menu.Item>
                    <Menu.Item key="header-menu-billing">
                        <AdminOnly>
                            <Link style={{ marginRight: 20 }} to="/billing">
                                {t("header.billing")}
                            </Link>
                        </AdminOnly>
                    </Menu.Item>
                    <Menu.Item key="header-menu-inventory">
                        <AdminOnly>
                            <Link style={{ marginRight: 20 }} to="/inventory">
                                {t("header.inventory")}
                            </Link>
                        </AdminOnly>
                    </Menu.Item>
                    <Menu.Item key="header-menu-groups">
                        <AdminOnly>
                            <Link style={{ marginRight: 20 }} to="/groups">
                                {t("header.groups")}
                            </Link>
                        </AdminOnly>
                    </Menu.Item>
                    <Menu.Item key="header-menu-depts">
                        <Link style={{ marginRight: 20 }} to="/depts">
                            {t("header.depts")}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="header-menu-issues">
                        <Link style={{ marginRight: 20 }} to="/issues">
                            {t("header.issuesOverview")}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="header-menu-pipedrive">
                        <Link style={{ marginRight: 20 }} to="/pipedrive">
                            {t("header.pipedrive")}
                        </Link>
                    </Menu.Item>
                </>
            )}
            <Menu.Item key="header-menu-profile">
                <div role="button" onClick={openProfile}>
                    {t("header.profile")}
                </div>
            </Menu.Item>
            <Menu.Item key="header-menu-signout">
                <div onClick={dispatchSignOut}>{t("header.signout")}</div>
            </Menu.Item>
            <AdminOnly>
                <Menu.Item key="header-menu-tasks">
                    <Link style={{ marginRight: 20 }} to="/tasks">
                        {t("header.tasks")}
                    </Link>
                </Menu.Item>
            </AdminOnly>
            <AdminOnly>
                <Menu.Item key="header-menu-billing">
                    <Link style={{ marginRight: 20 }} to="/billing">
                        {t("header.billing")}
                    </Link>
                </Menu.Item>
            </AdminOnly>
            <AdminOnly>
                <Menu.Item key="header-menu-inventory">
                    <Link style={{ marginRight: 20 }} to="/inventory">
                        {t("header.inventory")}
                    </Link>
                </Menu.Item>
            </AdminOnly>
            <AdminOnly>
                <Menu.Item key="header-menu-pipedrive">
                    <Link style={{ marginRight: 20 }} to="/pipedrive">
                        {t("header.pipedrive")}
                    </Link>
                </Menu.Item>
            </AdminOnly>
        </Menu>
    );
}

export default MenuHeader;
