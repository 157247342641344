import {
    formatDistanceToNow,
    format,
    add,
    setDate,
    getDate as getDateFns,
    startOfMonth,
    endOfMonth,
    getDaysInYear,
    isWeekend,
} from "date-fns";
import { Intl, RecurringOptions } from "./enums";
import { getLocale } from "./intl";
import range from "lodash.range";

export function getDayOfWeek(date: Date, intl: Intl): string {
    if (!date) return "";
    const locale = getLocale(intl);
    return format(date, "iiii", { locale });
}
export function getDateOfMonth(date: Date, intl: Intl): string {
    if (!date) return "";
    const locale = getLocale(intl);
    return format(date, "do", { locale });
}
export function getDateAndMonth(date: Date, intl: Intl): string {
    if (!date) return "";
    const locale = getLocale(intl);
    return format(date, "do LLL", { locale });
}

export function fromNow(date: Date, intl: Intl) {
    if (!date) return null;
    const locale = getLocale(intl);
    return formatDistanceToNow(date, { locale });
}

export function getDateString(date: Date, intl: Intl): string {
    console.log({ date });

    if (!date) return "";
    const locale = getLocale(intl);
    return format(date, "dd-MM-yyyy HH:mm", { locale });
}
export function getDate(date: Date, intl: Intl): string {
    if (!date) return "";
    const locale = getLocale(intl);
    return format(date, "dd-MM-yyyy", { locale });
}

// Mirror of backend recurring options
enum Faktor {
    WEEKLY = 1,
    BI_WEEKLY = 2,
    TRI_WEEKLY = 3,
    MONTHLY = 4,
    BI_MONTHLY = 8,
    QUARTERLY = 12,
}

// SYNC WITH BACKEND VERSION!
export function generateRecurringAudits(
    recurringOption: RecurringOptions,
    dueDate: string,
): Date[] | [] {
    const weeksPrYear = 52;
    const biWeeksPrYear = weeksPrYear / Faktor.BI_WEEKLY;
    const triWeeksPrYear = weeksPrYear / Faktor.TRI_WEEKLY;
    const monthsPrYear = weeksPrYear / Faktor.MONTHLY;
    const biMonthlyPrYear = weeksPrYear / Faktor.BI_MONTHLY;
    const quaterlyPrYear = weeksPrYear / Faktor.QUARTERLY;
    const date = new Date(dueDate);
    const daysInAYear = getDaysInYear(date);
    switch (recurringOption) {
        case RecurringOptions.DAILY:
            return range(0, daysInAYear).map((number: number) =>
                add(date, { days: number }),
            );

        case RecurringOptions.WEEK_DAILY:
            return range(0, daysInAYear)
                .map((number: number) => {
                    const dateToAdd = add(date, { days: 1 * number });
                    if (!isWeekend(dateToAdd)) {
                        return dateToAdd;
                    }
                    return false;
                })
                .filter(Boolean) as Date[];
        case RecurringOptions.WEEKLY:
            return range(0, weeksPrYear).map((number: number) =>
                add(date, { days: 7 * Faktor.WEEKLY * number }),
            );

        case RecurringOptions.BI_WEEKLY:
            return range(0, biWeeksPrYear).map((number: number) =>
                add(date, { days: 7 * Faktor.BI_WEEKLY * number }),
            );

        case RecurringOptions.TRI_WEEKLY:
            return range(0, triWeeksPrYear).map((number: number) =>
                add(date, { days: 7 * Faktor.TRI_WEEKLY * number }),
            );

        case RecurringOptions.MONTHLY:
            return range(0, monthsPrYear).map((number: number) =>
                add(date, { days: 7 * Faktor.MONTHLY * number }),
            );
        case RecurringOptions.DATE_MONTHLY:
            return range(0, monthsPrYear).map((number: number) =>
                add(date, { months: number }),
            );

        case RecurringOptions.PRIMO_MONTH:
            const primo = setDate(date, getDateFns(startOfMonth(date)));
            return range(0, monthsPrYear).map((number: number) =>
                add(primo, { months: number }),
            );

        case RecurringOptions.ULTIMO_MONTH:
            const ultimo = setDate(date, getDateFns(endOfMonth(date)));

            return range(0, monthsPrYear).map((number: number) =>
                add(ultimo, { months: number }),
            );

        case RecurringOptions.BI_MONTHLY:
            return range(0, biMonthlyPrYear).map((number: number) =>
                add(date, { days: 7 * Faktor.BI_MONTHLY * number }),
            );
        case RecurringOptions.DATE_BI_MONTHLY:
            return range(0, monthsPrYear).map((number: number) =>
                add(date, { months: 2 * number }),
            );
        case RecurringOptions.PRIMO_BI_MONTH:
            const primoBi = setDate(date, getDateFns(startOfMonth(date)));
            return range(0, monthsPrYear).map((number: number) =>
                add(primoBi, { months: 2 * number }),
            );
        case RecurringOptions.ULTIMO_BI_MONTH:
            const ultimoBi = setDate(date, getDateFns(endOfMonth(date)));
            return range(0, monthsPrYear).map((number: number) =>
                add(ultimoBi, { months: 2 * number }),
            );
        case RecurringOptions.QUARTERLY:
            return range(0, quaterlyPrYear).map((number: number) =>
                add(date, { days: 7 * Faktor.QUARTERLY * number }),
            );
        case RecurringOptions.DATE_QUARTERLY:
            return range(0, quaterlyPrYear).map((number: number) =>
                add(date, { months: 3 * number }),
            );
        case RecurringOptions.PRIMO_QUARTERLY:
            const primoQu = setDate(date, getDateFns(startOfMonth(date)));
            return range(0, quaterlyPrYear).map((number: number) =>
                add(primoQu, { months: 3 * number }),
            );
        case RecurringOptions.ULTIMO_QUARTERLY:
            const ultimoQu = setDate(date, getDateFns(endOfMonth(date)));
            return range(0, quaterlyPrYear).map((number: number) =>
                add(ultimoQu, { months: 3 * number }),
            );
        case RecurringOptions.SEMI_YEARLY:
            return range(0, 4).map((number: number) => add(date, { months: number * 6 }));

        case RecurringOptions.YEARLY:
            return range(0, 4).map((number: number) => add(date, { years: number }));

        case RecurringOptions.BI_YEARLY:
            return range(0, 4).map((number: number) => add(date, { years: number * 2 }));

        case RecurringOptions.NEVER:
            return [date];

        default:
            return [];
    }
}
