import { useEffect } from "react";
import { useParams } from "react-router";
import InventoryView from "./index";
import Loader from "../../../components/Loader";

import { IOrg } from "../../../modules/orgs/interfaces";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../store";
import { fetchOrgs } from "../../../modules/orgs/actions";
import useInventoryEspAq from "./useInventoryEspAq";
import useDeviceStates from "../../SensorOverview/useDeviceStates";
import useSensorStatus from "../../SensorOverview/useSensorStatus";
import useInventoryTags from "Views/SensorOverview/useInventoryTags";
import { InventoryType } from "modules/inventory/types";
import useInventorySimcards from "../Simcards/useInventorySimcards";

interface IProps {
    loading: boolean;
    uid: string;
    email: string;
    orgs: IOrg[];
    addOrg: (uid: string, email: string, title: string) => void;
    updateOrg: ({ orgId, data }: { orgId: string; data: { isDeleted: boolean } }) => void;
}

function EspAqInventoryContainer() {
    const disptach = useDispatch();
    const loading = false;
    const devices = useDeviceStates();
    const { inventoryTagsMap } = useInventoryTags(InventoryType.ESP_AQ);
    const { deviceStateMap } = useSensorStatus();
    console.log({ deviceStateMap });
    const { simcards } = useInventorySimcards();
    const { espAq } = useInventoryEspAq();
    console.log({ espAq, devices });

    const uid = useSelector((state: AppState) => state.user.details.uid);
    const hasOrgs = useSelector((state: AppState) => state.orgs.orgs?.length > 0);
    // useEffect(() => {
    //     if (!hasOrgs) {
    //         disptach(fetchOrgs(uid));
    //     }
    //     return () => {};
    // }, [uid]);

    if (loading) {
        return <Loader />;
    }

    const formattedEspAQ = espAq.map(e => ({
        ...e,
        isActive: deviceStateMap[e.deviceId]?.isActive,
        lastSeen: deviceStateMap[e.deviceId]?.lastSeen,
        // inventoryTagsMap,
    }));

    console.log({ inventoryTagsMap });

    console.log({ formattedEspAQ });

    return (
        <InventoryView
            simcards={simcards}
            espAq={formattedEspAQ}
            inventoryTagsMap={inventoryTagsMap}
        />
    );
}

export default EspAqInventoryContainer;
