import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../hooks";
import { useState } from "react";
import { PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { StyleGuide } from "styles/StyleGuide";
import { QUICK_TASK } from "general/enums";

export default function SelectTemplate({ ...rest }) {
    const { t } = useTranslation();
    const { intl, groupAuditTemplates, auditTemplates } = useAppSelector(state => ({
        auditTemplates: state.auditTemplates.auditTemplates,
        groupAuditTemplates: state.groupAuditTemplates.groupAuditTemplates,
        intl: state.user.userDoc.intl,
    }));
    const [inputValue, setInputValue] = useState("");
    const filteredAuditTemplates = auditTemplates.filter(template =>
        template.title.toLowerCase().includes(inputValue.toLowerCase()),
    );
    const filteredGroupAuditTemplates = groupAuditTemplates.filter(template =>
        template.title.toLowerCase().includes(inputValue.toLowerCase()),
    );
    return (
        <Select
            {...rest}
            onSearch={setInputValue}
            placeholder={t("audit.schedule.placeholder")}
            size="large"
            onFocus={() => setInputValue("")}
            showSearch
            filterOption={false}>
            {filteredAuditTemplates.map((template: any) => (
                <Select.Option key={template.id} value={template.id}>
                    {template.title}
                </Select.Option>
            ))}
            {filteredGroupAuditTemplates.length > 0 && (
                <Select.OptGroup key={"groupTemplate"} label={"Gruppe skabeloner"}>
                    {filteredGroupAuditTemplates.map((template: any) => (
                        <Select.Option key={template.id} value={template.id}>
                            {template.title}
                        </Select.Option>
                    ))}
                </Select.OptGroup>
            )}
            <Select.OptGroup key={"oneOffTask"} label={"Engangsopgave"}>
                <Select.Option key={QUICK_TASK.OPTION} value={QUICK_TASK.OPTION}>
                    <PlusCircleOutlined style={{ color: StyleGuide.palette.info }} />{" "}
                    <span style={{ color: StyleGuide.palette.info }}>
                        {t("audit.schedule.task.quickTaskOption")}
                    </span>
                </Select.Option>
            </Select.OptGroup>
        </Select>
    );
}
