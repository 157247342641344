import { useState } from "react";
import moment from "moment";
import {
    Layout,
    Row,
    Col,
    List,
    Tabs,
    Avatar,
    Tooltip,
    Input,
    Card,
    Typography,
    Button,
    Switch,
    Space,
} from "antd";
import SideNav from "../../Layout/SideNav";
import HeaderBar from "../../Layout/HeaderBar";
import FooterBar from "../../Layout/FooterBar";
import { Link, useHistory } from "react-router-dom";
import BlurOverlayContainer from "../../components/Overlay/BlurOverlayContainer";
import { FeatureIds } from "../../modules/featureControl/featuresConfig";
import { useTranslation } from "react-i18next";
import { AuditSchedule } from "modules/auditSchedule/types";
import { isBefore, isWithinInterval } from "date-fns";
import { Colors } from "Layout/Colors";
import { COMPLETE_AUDIT_LIMIT_NUMBER } from "modules/audits/reducer";
import { useAppDispatch, useAppSelector } from "hooks";
import { setCompleteAuditLimit } from "modules/audits/actions";
import done from "../../assets/svg/done.svg";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { getInitials } from "general/initials";
import { StyleGuide } from "styles/StyleGuide";

const { Content } = Layout;
const { TabPane } = Tabs;
const { Search } = Input;

function sortByCompleteTime(a: any, b: any) {
    return b.completeTime?.toDate()?.getTime() - a.completeTime?.toDate()?.getTime();
}

function AuditList(props: any) {
    const history = useHistory();
    const { orgId } = props;
    console.log({ props, userId: props.userId });

    const [onlyMine, setonlyMine] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const { t } = useTranslation();
    const onSearch = (value: string) => setSearchTerm(value);

    const overDueAuditTasks = props.overDueAuditTasks
        // .sort(sortByCompleteTime)
        .filter((t: any) => (onlyMine ? t?.responsible?.id === props.userId : true))
        .filter((t: any) =>
            (t.templateTitle || props.auditTemplatesByKey?.[t.templateId]?.title)
                ?.toLowerCase()
                ?.includes(searchTerm),
        );

    const onHoldAuditTasks = props.onHoldAuditTasks
        .sort(sortByCompleteTime)
        .filter((t: any) => (onlyMine ? t?.responsible?.id === props.userId : true))
        .filter((t: any) =>
            (t.templateTitle || props.auditTemplatesByKey?.[t.templateId]?.title)
                ?.toLowerCase()
                ?.includes(searchTerm),
        );
    const needsResoltionAuditTasks = props.needsResoltionAuditTasks
        .sort(sortByCompleteTime)
        .filter((t: any) => (onlyMine ? t?.responsible?.id === props.userId : true))
        .filter((t: any) =>
            (t.templateTitle || props.auditTemplatesByKey?.[t.templateId]?.title)
                ?.toLowerCase()
                ?.includes(searchTerm),
        );
    const completeAuditTasks = props.completeAuditTasks
        .sort(sortByCompleteTime)
        .filter((t: any) => (onlyMine ? t?.responsible?.id === props.userId : true))
        .filter((t: any) =>
            (t.templateTitle || props.auditTemplatesByKey?.[t.templateId]?.title)
                ?.toLowerCase()
                ?.includes(searchTerm),
        );

    console.log({ onlyMine, searchTerm });

    const renderCompleteItem = (item: any) => {
        const auditorName =
            props.members?.[item.auditor]?.name ||
            props.members?.[item.auditor]?.email ||
            "unknown";

        return (
            <Card
                style={{ borderLeft: "5px solid #00D86D" }}
                bodyStyle={{ padding: 8, cursor: "pointer" }}
                onClick={() => history.push(`/orgs/${orgId}/audit/audits/${item.id}`)}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}>
                    <div>
                        <div>
                            <Typography.Text strong>
                                {item?.templateTitle ||
                                    props.auditTemplatesByKey[item.templateId]?.title}
                            </Typography.Text>
                        </div>

                        <Typography.Text type="secondary">
                            <span>
                                {"Deadline: "}
                                {t("general.dateTime", { date: item.dueDate.toDate() })}
                            </span>
                        </Typography.Text>
                        <Typography.Text type="secondary">
                            <span>
                                {" - Afsluttet: "}
                                {t("general.dateTime", {
                                    date: item.completeTime?.toDate(),
                                })}
                            </span>
                        </Typography.Text>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Tooltip
                            placement="top"
                            title={`${t("audit.list.completeby")} ${auditorName}`}>
                            <Avatar
                                style={{
                                    backgroundColor: "#00D86D",
                                }}
                                size="large">
                                {auditorName?.substring?.(0, 1)}
                            </Avatar>
                        </Tooltip>
                        <div style={{ marginRight: 8 }} />
                        <Link to={`/orgs/${orgId}/audit/audits/${item.id}`}>
                            {t("audit.list.readmore")}
                        </Link>
                    </div>
                </div>
            </Card>
        );
    };
    return (
        <>
            <SideNav />
            <Layout>
                <HeaderBar />
                <BlurOverlayContainer featureKey={FeatureIds.AUDITS}>
                    <Content style={{ margin: "50px 24px 0" }}>
                        <Row gutter={16}>
                            <Col md={12} xs={24}>
                                <Search
                                    placeholder={t("audit.search.placeholder")}
                                    onChange={e => setSearchTerm(e.target.value)}
                                    value={searchTerm}
                                    allowClear
                                />
                            </Col>
                            <Col
                                md={12}
                                xs={24}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                }}>
                                <Space>
                                    <Typography.Text strong>
                                        {t("audit.list.onlyMine")}
                                    </Typography.Text>
                                    <Switch
                                        checked={onlyMine}
                                        onChange={checked => setonlyMine(checked)}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                    />
                                </Space>
                            </Col>
                            <Col md={24} xs={24}>
                                <Tabs
                                    activeKey={props.currentTab}
                                    onChange={key => props.setCurrentTab({ key })}>
                                    <TabPane
                                        tab={t("audit.tabs.incomplete")}
                                        key="incompleted">
                                        {overDueAuditTasks.length > 0 && (
                                            <>
                                                <p style={{ fontWeight: "bold" }}>
                                                    {t("general.overdue")}
                                                </p>
                                                <TodoAuditList
                                                    loading={props.loading}
                                                    orgId={orgId}
                                                    auditTemplatesByKey={
                                                        props.auditTemplatesByKey
                                                    }
                                                    auditTasks={overDueAuditTasks}
                                                />
                                            </>
                                        )}
                                        {Object.entries(props.inCompleteAuditTasks).map(
                                            ([week, tasks]: any) => {
                                                const formattedTasks = tasks
                                                    .sort(sortByCompleteTime)
                                                    .filter((t: any) =>
                                                        t.templateTitle
                                                            ?.toLowerCase()
                                                            ?.includes(searchTerm),
                                                    )
                                                    .filter((t: any) => {
                                                        return onlyMine
                                                            ? t.responsible?.id ===
                                                                  props.userId
                                                            : true;
                                                    });
                                                if (formattedTasks.length < 1) {
                                                    return null;
                                                }
                                                return (
                                                    <>
                                                        <p style={{ fontWeight: "bold" }}>
                                                            {t("general.week")} {week}{" "}
                                                            {Number(week) ===
                                                                props.currentWeek &&
                                                                "(denne uge)"}
                                                            {Number(week) ==
                                                                props.currentWeek + 1 &&
                                                                "(næste uge)"}
                                                        </p>
                                                        <TodoAuditList
                                                            loading={props.loading}
                                                            orgId={orgId}
                                                            auditTemplatesByKey={
                                                                props.auditTemplatesByKey
                                                            }
                                                            auditTasks={formattedTasks}
                                                        />
                                                    </>
                                                );
                                            },
                                        )}
                                        {!props.loading && props.noInCompleteTasks && (
                                            <div style={{ textAlign: "center" }}>
                                                <img src={done} width={300} />
                                                <h3
                                                    style={{
                                                        textAlign: "center",
                                                        color: Colors.aks,
                                                    }}>
                                                    {t("audit.list.noTasks")}
                                                </h3>
                                            </div>
                                        )}
                                        <p
                                            style={{
                                                textAlign: "center",
                                                color: "#c0c0c0",
                                            }}>
                                            {t("audit.list.incompleteInfo")}
                                        </p>
                                    </TabPane>
                                    <TabPane tab={t("audit.tabs.onhold")} key="onhold">
                                        {onHoldAuditTasks.length > 0 && (
                                            <>
                                                {/* <p style={{ fontWeight: "bold" }}>
                                                    {t("general.overdue")}
                                                </p> */}
                                                <OnHoldAuditList
                                                    loading={props.loading}
                                                    orgId={orgId}
                                                    auditTemplatesByKey={
                                                        props.auditTemplatesByKey
                                                    }
                                                    auditTasks={onHoldAuditTasks}
                                                />
                                            </>
                                        )}
                                        {!props.loading &&
                                            onHoldAuditTasks.length < 1 && (
                                                <div style={{ textAlign: "center" }}>
                                                    <img src={done} width={300} />
                                                    <h3
                                                        style={{
                                                            textAlign: "center",
                                                            color: Colors.aks,
                                                        }}>
                                                        {t("audit.list.noTasks")}
                                                    </h3>
                                                </div>
                                            )}
                                        {/* <p
                                            style={{
                                                textAlign: "center",
                                                color: "#c0c0c0",
                                            }}>
                                            {t("audit.list.incompleteInfo")}
                                        </p> */}
                                    </TabPane>
                                    <TabPane
                                        tab={t("audit.tabs.needsresolution")}
                                        key="needsresolution">
                                        {needsResoltionAuditTasks.length > 0 && (
                                            <>
                                                {/* <p style={{ fontWeight: "bold" }}>
                                                    {t("general.overdue")}
                                                </p> */}
                                                <NeedsResolutionAuditList
                                                    loading={props.loading}
                                                    orgId={orgId}
                                                    auditTemplatesByKey={
                                                        props.auditTemplatesByKey
                                                    }
                                                    auditTasks={needsResoltionAuditTasks}
                                                />
                                            </>
                                        )}
                                        {!props.loading &&
                                            needsResoltionAuditTasks.length < 1 && (
                                                <div style={{ textAlign: "center" }}>
                                                    <img src={done} width={300} />
                                                    <h3
                                                        style={{
                                                            textAlign: "center",
                                                            color: Colors.aks,
                                                        }}>
                                                        {t("audit.list.noTasks")}
                                                    </h3>
                                                </div>
                                            )}
                                        {/* <p
                                            style={{
                                                textAlign: "center",
                                                color: "#c0c0c0",
                                            }}>
                                            {t("audit.list.incompleteInfo")}
                                        </p> */}
                                    </TabPane>
                                    <TabPane
                                        tab={t("audit.tabs.complete")}
                                        key="completed">
                                        <List
                                            className="demo-loadmore-list"
                                            loading={props.loading}
                                            itemLayout="horizontal"
                                            locale={{ emptyText: t("audit.list.empty") }}
                                            // loadMore={loadMore}
                                            dataSource={completeAuditTasks}
                                            renderItem={renderCompleteItem}
                                            footer={
                                                <CompleteListFooter
                                                    completeListLength={
                                                        completeAuditTasks.length
                                                    }
                                                />
                                            }
                                        />
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                    </Content>
                </BlurOverlayContainer>
                <FooterBar />
            </Layout>
        </>
    );
}

export default AuditList;

function CompleteListFooter({ completeListLength }: { completeListLength: number }) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const limit = useAppSelector(state => state.audits.completeAuditLimit);
    const onLoadMore = () => {
        dispatch(setCompleteAuditLimit({ limit: limit + COMPLETE_AUDIT_LIMIT_NUMBER }));
    };
    if (completeListLength >= limit) {
        return (
            <div
                style={{
                    textAlign: "center",
                    color: Colors.info,
                }}>
                <Button type="primary" onClick={onLoadMore}>
                    {t("audit.list.loadmore")}
                </Button>
            </div>
        );
    }
    return null;
}

function TodoAuditList({ loading, orgId, auditTemplatesByKey, auditTasks }: any) {
    const history = useHistory();
    const { t } = useTranslation();
    console.log({ auditTasks });
    const renderIncompleteItem = (item: AuditSchedule & { startDate: Date }) => {
        const isDelayed = isBefore(item.dueDate.toDate(), new Date());
        const isMultiDayAudit = Boolean(item.startDaysBeforeDueDate);
        const isInProgress = isMultiDayAudit
            ? isWithinInterval(new Date(), {
                  start: item.startDate,
                  end: item.dueDate.toDate(),
              })
            : false;

        return (
            <Card
                style={
                    isDelayed
                        ? { borderLeft: `5px solid ${Colors.danger}` }
                        : isInProgress
                        ? {
                              borderLeft: `5px solid ${StyleGuide.palette.statusInprogressDark}`,
                          }
                        : { paddingLeft: 4 }
                }
                bodyStyle={{ padding: 8, cursor: "pointer" }}
                onClick={() => history.push(`/orgs/${orgId}/audit/audits/${item.id}`)}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}>
                    <div>
                        <div>
                            <Typography.Text strong>
                                {item?.templateTitle ||
                                    auditTemplatesByKey[item.templateId]?.title}
                            </Typography.Text>
                        </div>
                        {isMultiDayAudit && (
                            <Typography.Text type="secondary">
                                <span>
                                    {t("audit.list.start", { date: item.startDate })} -{" "}
                                </span>
                            </Typography.Text>
                        )}
                        <Typography.Text type="secondary">
                            <span>
                                {t("audit.list.deadline", {
                                    date: item.dueDate.toDate(),
                                })}
                            </span>
                        </Typography.Text>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {item.responsible?.id && (
                            <>
                                <Tooltip
                                    placement="top"
                                    title={`${t("audit.responsible.tooltip.assigned")} ${
                                        item.responsible?.displayName
                                    }`}>
                                    <Avatar
                                        style={{
                                            backgroundColor: StyleGuide.palette.grey200,
                                            color: StyleGuide.palette.infoDark,
                                            fontWeight: "bold",
                                        }}
                                        size="large">
                                        {getInitials(item.responsible?.displayName)}
                                    </Avatar>
                                </Tooltip>
                                <div style={{ marginRight: 8 }} />
                            </>
                        )}
                        <Link to={`/orgs/${orgId}/audit/audits/${item.id}`}>
                            {t("audit.list.readmore")}
                        </Link>
                    </div>
                </div>
            </Card>
        );
    };
    return (
        <List
            className="demo-loadmore-list"
            loading={loading}
            itemLayout="horizontal"
            locale={{ emptyText: t("audit.list.empty") }}
            // loadMore={loadMore}
            dataSource={auditTasks}
            renderItem={renderIncompleteItem}
        />
    );
}
function OnHoldAuditList({ loading, orgId, auditTemplatesByKey, auditTasks }: any) {
    const history = useHistory();
    const { t } = useTranslation();

    const renderIncompleteItem = (item: AuditSchedule & { startDate: Date }) => {
        const isMultiDayAudit = Boolean(item.startDaysBeforeDueDate);
        return (
            <Card
                style={{ borderLeft: `5px solid ${StyleGuide.palette.warn}` }}
                bodyStyle={{ padding: 8, cursor: "pointer" }}
                onClick={() => history.push(`/orgs/${orgId}/audit/audits/${item.id}`)}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}>
                    <div>
                        <div>
                            <Typography.Text strong>
                                {item?.templateTitle ||
                                    auditTemplatesByKey[item.templateId]?.title}
                            </Typography.Text>
                        </div>
                        {isMultiDayAudit && (
                            <Typography.Text type="secondary">
                                <span>
                                    {t("audit.list.start", { date: item.startDate })} -{" "}
                                </span>
                            </Typography.Text>
                        )}
                        <Typography.Text type="secondary">
                            <span>
                                {t("audit.list.deadline", {
                                    date: item.dueDate.toDate(),
                                })}
                            </span>
                        </Typography.Text>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {item.responsible?.id && (
                            <>
                                <Tooltip
                                    placement="top"
                                    title={`${t("audit.responsible.tooltip.assigned")} ${
                                        item.responsible?.displayName
                                    }`}>
                                    <Avatar
                                        style={{
                                            backgroundColor: StyleGuide.palette.grey200,
                                            color: StyleGuide.palette.infoDark,
                                            fontWeight: "bold",
                                        }}
                                        size="large">
                                        {getInitials(item.responsible?.displayName)}
                                    </Avatar>
                                </Tooltip>
                                <div style={{ marginRight: 8 }} />
                            </>
                        )}
                        <Link to={`/orgs/${orgId}/audit/audits/${item.id}`}>
                            {t("audit.list.readmore")}
                        </Link>
                    </div>
                </div>
            </Card>
        );
    };
    return (
        <List
            className="demo-loadmore-list"
            loading={loading}
            itemLayout="horizontal"
            locale={{ emptyText: t("audit.list.empty") }}
            // loadMore={loadMore}
            dataSource={auditTasks}
            renderItem={renderIncompleteItem}
        />
    );
}
function NeedsResolutionAuditList({
    loading,
    orgId,
    auditTemplatesByKey,
    auditTasks,
}: any) {
    const history = useHistory();
    const { t } = useTranslation();

    const renderIncompleteItem = (item: AuditSchedule & { startDate: Date }) => {
        const isMultiDayAudit = Boolean(item.startDaysBeforeDueDate);
        return (
            <Card
                style={{ borderLeft: `5px solid ${StyleGuide.palette.amber}` }}
                bodyStyle={{ padding: 8, cursor: "pointer" }}
                onClick={() => history.push(`/orgs/${orgId}/audit/audits/${item.id}`)}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}>
                    <div>
                        <div>
                            <Typography.Text strong>
                                {item?.templateTitle ||
                                    auditTemplatesByKey[item.templateId]?.title}
                            </Typography.Text>
                        </div>
                        {isMultiDayAudit && (
                            <Typography.Text type="secondary">
                                <span>
                                    {t("audit.list.start", { date: item.startDate })} -{" "}
                                </span>
                            </Typography.Text>
                        )}
                        <Typography.Text type="secondary">
                            <span>
                                {t("audit.list.deadline", {
                                    date: item.dueDate.toDate(),
                                })}
                            </span>
                        </Typography.Text>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {item.responsible?.id && (
                            <>
                                <Tooltip
                                    placement="top"
                                    title={`${t("audit.responsible.tooltip.assigned")} ${
                                        item.responsible?.displayName
                                    }`}>
                                    <Avatar
                                        style={{
                                            backgroundColor: StyleGuide.palette.grey200,
                                            color: StyleGuide.palette.infoDark,
                                            fontWeight: "bold",
                                        }}
                                        size="large">
                                        {getInitials(item.responsible?.displayName)}
                                    </Avatar>
                                </Tooltip>
                                <div style={{ marginRight: 8 }} />
                            </>
                        )}
                        <Link to={`/orgs/${orgId}/audit/audits/${item.id}`}>
                            {t("audit.list.readmore")}
                        </Link>
                    </div>
                </div>
            </Card>
        );
    };
    return (
        <List
            className="demo-loadmore-list"
            loading={loading}
            itemLayout="horizontal"
            locale={{ emptyText: t("audit.list.empty") }}
            // loadMore={loadMore}
            dataSource={auditTasks}
            renderItem={renderIncompleteItem}
        />
    );
}
