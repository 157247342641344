import React, { useEffect, useState } from "react";
import { DBDirectoryShape } from "../../modules/directories/types";
import {
    Col,
    Card,
    Popconfirm,
    message,
    Dropdown,
    Menu,
    Typography,
    Avatar,
    List,
    Modal,
    Form,
    Input,
    Button,
    Tooltip,
} from "antd";
import Meta from "antd/lib/card/Meta";
import moment from "moment";
import { connect, useSelector, useDispatch } from "react-redux";
import { updateReport } from "../../modules/reports/actions";
import {
    EditOutlined,
    EditTwoTone,
    FolderOutlined,
    FolderTwoTone,
    MenuOutlined,
    PaperClipOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
    DownloadOutlined,
    DeleteOutlined,
    FilePdfOutlined,
    FileImageOutlined,
    FileWordOutlined,
    FileExcelOutlined,
    FileExclamationOutlined,
} from "@ant-design/icons";
import { fromNow } from "../../general/dates";
import { AppState } from "../../store";
import { Divider } from "antd";
import confirmModal from "../../general/confirmModal";
import { Link } from "react-router-dom";
import { DirectoryMenu } from "Views/Directories/DirectoryMenu";
import { updateDirectory } from "modules/directories/actions";
import firebase from "firebase/compat/app";
import { MenuProps } from "antd/lib";
import useGetDownloadUrl from "hooks/useGetDownloadUrl";
import Loader from "components/Loader";
import * as Sentry from "@sentry/react";
import { ContentType } from "general/enums";
import { getExtentionByMimeType } from "general/files";
import { getContentTypeIconMap } from "./Report";

const { Paragraph } = Typography;

// Usage:

function getExtensionFromFileName(fileName: string): string {
    if (fileName.includes(".msg")) {
        return "msg";
    }
    return "";
}
// function downloadFile(url: string, mimeType: string) {
//     const ext = getExtensionFromMimeType(mimeType);

//     FileSaver.saveAs(url, `image.${ext}`);
// }
export async function downloadConvertedFile(
    filePath: string,
    title: string,
    mimeType: ContentType,
) {
    console.log({ filePath });
    const ext = getExtentionByMimeType(mimeType);
    try {
        message.loading(`Downloader: ${title}`);
        const fileUrl = await firebase
            .storage()
            // @ts-ignore
            .ref(filePath)
            // .child("images/stars.jpg")
            .getDownloadURL();
        const response = await fetch(fileUrl);

        const blob = await response.blob(); // get file contents as Blob

        // Convert Blob to new type
        const newBlob = new Blob([blob], { type: mimeType });

        // try getting ext by title
        const alternativeExt = getExtensionFromFileName(title);

        // Generate filename with new extension
        const filename = `${title}.${ext || alternativeExt}`;
        console.log({ filename, ext });

        // Trigger download
        const url = URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;

        // Download file
        document.body.appendChild(link);
        link.click();
        console.log({ link });

        document.body.removeChild(link);

        URL.revokeObjectURL(url);
        message.success(`${title} downloaded`);
    } catch (error) {
        console.error(error);
        Sentry.captureMessage(
            `Failed to download file: filePath=${filePath} title=${title} mimeType=${mimeType}`,
        );
        message.error("Failed to download file, try again");
    }
}
export async function openFileInBrowser(
    filePath: string,
    title: string,
    mimeType: ContentType,
) {
    console.log({ filePath });
    const ext = getExtentionByMimeType(mimeType);
    try {
        message.loading(`Åbner fil: ${title}`);
        const fileUrl = await firebase
            .storage()
            // @ts-ignore
            .ref(filePath)
            .getDownloadURL();
        window.open(fileUrl, "_blank");
        message.success(`${title} downloaded`);
    } catch (error) {
        console.error(error);
        message.error("Failed to open file, try again");
    }
}

// Usage:
//   downloadConvertedFile(fileUrl, 'application/pdf');

export function DownloadReport({
    path,
    // filePath,
    fileName,
    title,
    contentType,
}: {
    path: string;
    // filePath: string;
    fileName: string;
    title: string;
    contentType: ContentType;
}) {
    // console.log({ filePath });
    const { t } = useTranslation();
    const getFileName = async () => {
        try {
            console.log(path, title);
            downloadConvertedFile(path, title, contentType);
            // const url = await firebase.storage().ref(`${fileName}`).getDownloadURL();
            console.log(fileName);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div key={`download-${fileName}`} onClick={getFileName}>
            {t("reports.file_menu_download")}: <DownloadOutlined />
        </div>
    );
}

export const supportedOpenInBrowserContentTypes = [
    "application/pdf",
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/webp",
];

export function OpenFileInBrowser({
    path,
    // filePath,
    fileName,
    title,
    contentType,
    buttonType,
}: {
    path: string;
    // filePath: string;
    fileName: string;
    title: string;
    contentType: ContentType;
    buttonType?: boolean;
}) {
    // console.log({ filePath });
    const { t } = useTranslation();
    if (!supportedOpenInBrowserContentTypes.includes(contentType)) return null;
    const getFileName = async () => {
        try {
            console.log(path, title);
            await openFileInBrowser(path, title, contentType);
            // downloadConvertedFile(path, title, contentType);
            // const url = await firebase.storage().ref(`${fileName}`).getDownloadURL();
            console.log(fileName);
        } catch (error) {
            console.error(error);
        }
    };

    if (buttonType) {
        return (
            <Button style={{ width: "100%" }} onClick={getFileName}>
                <PaperClipOutlined style={{ fontSize: 16 }} />
                {/* {getContentTypeIconOnlyMap(contentType, {
                    // marginTop: 20,
                    fontSize: 16,
                })} */}
                {title || fileName || t("reports.file_menu_open")}
            </Button>
        );
    }

    return (
        <div key={`download-${fileName}`} onClick={getFileName}>
            {t("reports.file_menu_open")}: <DownloadOutlined />
        </div>
        // <Button type="text" onClick={getFileName}>
        // </Button>
        // <a href={path + ".docx"} target="_blank" download={title}>
        //     {t("reports.file_menu_download")}: <DownloadOutlined />
        // </a>
    );
}

function ImageStorage({ filePath }: { filePath: string }) {
    const { loading, fileUrl } = useGetDownloadUrl(filePath);
    if (loading) return <Loader />;

    return (
        <Avatar
            shape="square"
            size={64}
            style={{ fontSize: 32, marginTop: -8, marginBottom: -8, marginLeft: -12 }}
            src={fileUrl}
        />
    );
}

// export const getContentTypeIconOnlyMap = (type: string, style: {}) => {
//     switch (type) {
//         case "application/pdf":
//             return <FilePdfOutlined style={style} />;

//         case "image/jpeg":
//         case "image/jpg":
//         case "image/png":
//             return <FileImageOutlined style={style} />; // <FileImageOutlined style={style} />;

//         case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
//             return <FileWordOutlined style={style} />;
//         case "application/msword":
//             return <FileWordOutlined style={style} />;
//         case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
//             return <FileExcelOutlined style={style} />;

//         default:
//             return <FileExclamationOutlined style={style} />;
//     }
// };

const MenuDirectories = ({
    directories,
    updateReport,
    orgId,
    reportId,
    path,
    // filePath,
    title,
    fileName,
    contentType,
}: {
    directories: DBDirectoryShape[];
    updateReport: any;
    orgId: string;
    reportId: string;
    path: string;
    // filePath: string;
    title: string;
    fileName: string;
    contentType: ContentType;
}) => {
    const { t } = useTranslation();
    console.log({ directories, updateReport, orgId, reportId });
    const confirmDelete = () => {
        confirmModal({
            title: "Er du sikker på du vil slette denne fil?",
            onOk: () => {
                console.log({ orgId, reportId });
                updateReport({
                    orgId,
                    reportId,
                    data: { isDeleted: true },
                });
            },
        });
    };
    const items: MenuProps["items"] = [
        {
            key: "2",
            label: t("reports.file_menu_move"),
            // @ts-ignore
            children: (directories || []).map(directory => ({
                key: directory.id,
                label: (
                    <div
                        key={directory.id}
                        onClick={() =>
                            updateReport({
                                orgId,
                                reportId,
                                data: { directoryId: directory.id },
                            })
                        }>
                        <div>
                            {t("reports.file_menu_move")}: <b>{directory.title}</b>
                        </div>
                    </div>
                ),
            })),
        },
        {
            key: "download",
            label: (
                <DownloadReport
                    path={path}
                    title={title}
                    fileName={fileName}
                    contentType={contentType}
                />
            ),
        },
        {
            key: "open",
            label: (
                <OpenFileInBrowser
                    path={path}
                    title={title}
                    fileName={fileName}
                    contentType={contentType}
                />
            ),
        },
        {
            key: "delete",
            label: (
                <div style={{ color: "red" }} onClick={confirmDelete}>
                    <DeleteOutlined /> {t("reports.file_menu_delete")}
                </div>
            ),
        },
    ];
    return (
        <Dropdown menu={{ items }}>
            <a onClick={e => e.preventDefault()}>
                {t("reports.folder_menu")} <MenuOutlined />
            </a>
        </Dropdown>
    );
};

function DeleteReport({ onDelete }: any) {
    return (
        <Popconfirm
            title="Er du sikker på du vil slette denne report?"
            onConfirm={() => {
                onDelete();
                message.success("Report slettet");
            }}
            okText="Ja"
            cancelText="Nej">
            <DeleteOutlined />
        </Popconfirm>
    );
}

interface IReportProps {
    id: string;
    orgId: string;
    title: string;
    // @ts-ignore
    createTime: firebase.firestore.Timestamp;
    path: string;
    contentType: string;
    directories: DBDirectoryShape[];
    updateReport: ({
        orgId,
        reportId,
        data: { title },
    }: {
        orgId: string;
        reportId: string;
        data: { title?: string; isDeleted?: boolean };
    }) => void;
}

function ListItems({
    // id,
    // orgId,
    // title,
    // createTime,
    updateReport,
    // path,
    // contentType,
    // directories,
    data,
}: any) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const directories = useSelector((state: AppState) => state.directories.directories);
    const intl = useSelector((state: AppState) => state.user.userDoc.intl);

    const dispatchUpdateDirectories = ({ orgId, directoryId, data }: any) =>
        dispatch(
            updateDirectory({
                orgId,
                directoryId,
                data,
            }),
        );
    const [item, setitem] = useState<any | null>(null);

    const handleSubmit = async (values: any) => {
        const { title } = values;
        try {
            dispatchUpdateDirectories({
                orgId: item.orgId,
                directoryId: item.id,
                data: { title: title },
            });
            setitem(null);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        form.setFieldsValue({ title: item?.title });
    }, [form, item?.title]);
    return (
        <>
            <List
                // header={<div>Header</div>}
                // footer={<div>Footer</div>}
                bordered={true}
                dataSource={data}
                renderItem={(item: any) => {
                    if (item.isDirectory) {
                        return (
                            <>
                                <List.Item
                                    style={{ alignItems: "center" }}
                                    actions={[
                                        // <Typography.Text>
                                        //     {t("reports.folder_files")}: {totalFiles}
                                        // </Typography.Text>,
                                        <Tooltip title={t("reports.folder_edit")}>
                                            <Button
                                                type="text"
                                                onClick={() => setitem(item)}>
                                                <EditTwoTone style={{ fontSize: 16 }} />
                                            </Button>
                                        </Tooltip>,
                                        <Dropdown
                                            overlay={
                                                <DirectoryMenu
                                                    directoryId={item.id}
                                                    orgId={item.orgId}
                                                    updateDirectory={
                                                        dispatchUpdateDirectories
                                                    }
                                                />
                                            }>
                                            <a
                                                className="ant-dropdown-link"
                                                onClick={e => e.preventDefault()}>
                                                {t("reports.folder_menu")}{" "}
                                                <MenuOutlined />
                                            </a>
                                        </Dropdown>,
                                        // <Dropdown
                                        //     overlay={
                                        //         <MenuDirectories
                                        //             path={item.path}
                                        //             directories={item.directories}
                                        //             orgId={item.orgId}
                                        //             reportId={item.id}
                                        //             updateReport={updateReport}
                                        //         />
                                        //     }>
                                        //     <a
                                        //         className="ant-dropdown-link"
                                        //         onClick={e => e.preventDefault()}>
                                        //         {t("reports.folder_menu")} <MenuOutlined />
                                        //     </a>
                                        // </Dropdown>,
                                    ]}>
                                    <List.Item.Meta
                                        avatar={
                                            <Link
                                                style={{ cursor: "pointer" }}
                                                to={`/orgs/${item.orgId}/reports/${item.id}`}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        height: "48px",
                                                        cursor: "pointer",
                                                        marginLeft: -4,
                                                    }}>
                                                    <FolderTwoTone
                                                        style={{
                                                            fontSize: 32,
                                                            cursor: "pointer",
                                                        }}
                                                        // @ts-ignore
                                                        theme="twoTone"
                                                    />
                                                </div>
                                            </Link>
                                        }
                                        title={
                                            <Link
                                                style={{ cursor: "pointer" }}
                                                to={`/orgs/${item.orgId}/reports/${item.id}`}>
                                                {item.title}
                                            </Link>
                                        }
                                        description={`${t(
                                            "reports.file_createtime",
                                        )}: ${fromNow(
                                            item.createTime.toDate(),
                                            intl,
                                        )} ${t("reports.file_createtimeend")}`}
                                    />
                                </List.Item>
                                <Divider style={{ margin: 0 }} />
                            </>
                        );
                    }
                    return (
                        <>
                            <List.Item
                                style={{ alignItems: "center" }}
                                actions={[
                                    <MenuDirectories
                                        path={item.filePath}
                                        title={item.title}
                                        fileName={item.fileName}
                                        directories={item.directories}
                                        orgId={item.orgId}
                                        reportId={item.id}
                                        contentType={item.contentType}
                                        updateReport={updateReport}
                                    />,
                                    //     }>
                                    //     <a
                                    //         className="ant-dropdown-link"
                                    //         onClick={e => e.preventDefault()}>
                                    //         {t("reports.folder_menu")} <MenuOutlined />
                                    //     </a>
                                    // </Dropdown>,
                                ]}>
                                <List.Item.Meta
                                    style={{ alignItems: "center" }}
                                    avatar={getContentTypeIconMap(
                                        item.contentType,
                                        item.thumbFilePath || item.filePath,
                                        {
                                            // marginTop: 20,
                                            height: 32,
                                            width: 32,
                                            fontSize: 32,
                                        },
                                    )}
                                    title={item.title}
                                    description={`${t(
                                        "reports.file_createtime",
                                    )}: ${fromNow(item.createTime.toDate(), intl)} ${t(
                                        "reports.file_createtimeend",
                                    )}`}
                                />
                            </List.Item>
                            <Divider style={{ margin: 0 }} />
                        </>
                    );
                }}
            />
            <Modal
                title={t("group.addForm.header")}
                open={Boolean(item)}
                // onOk={e => handleOk(e, email)}
                onCancel={() => setitem(null)}
                // width={isMobile ? "100%" : "50%"}
                centered
                footer={[]}>
                {item && (
                    <Form
                        // {...formLayout}
                        key={item.id}
                        form={form}
                        onFinish={handleSubmit}
                        initialValues={{
                            title: item?.title,
                        }}>
                        <Form.Item
                            label={t("group.addForm.nameLabel")}
                            name="title"
                            rules={[
                                { required: true, message: "Venligst udfyld navn!" },
                            ]}>
                            <Input placeholder={t("group.addForm.namePlaceholder")} />
                        </Form.Item>

                        <Form.Item wrapperCol={{ span: 24 }}>
                            <Button type="primary" htmlType="submit" block>
                                {t("reports.folder_save")}
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </Modal>
        </>
    );
}
// sort by directory title
export function sortByTitle(a: { title: string }, b: { title: string }) {
    return (a.title as string).localeCompare(b.title as string);
}

const mapActionsToProps = {
    updateReport,
};

export default connect(undefined, mapActionsToProps)(ListItems);
