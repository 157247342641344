import { useState } from "react";
import firebase from "firebase/compat/app";
import { Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Message, { Type } from "components/Notification/Message";

export default function useDuplicateTemplateTask({
    orgId,
    templateId,
    taskId,
}: {
    orgId: string;
    templateId: string;
    taskId: string;
}) {
    const [loading, setloading] = useState(false);
    const { t } = useTranslation();
    const handleDuplicateTemplate = async () => {
        try {
            if (!orgId || !templateId || !taskId) {
                throw new Error(
                    `[AUDIT_TEMPLATE_DUPLICATE]: Missing orgId=${orgId} or templateId=${templateId} or taskId=${taskId}`,
                );
            }
            setloading(true);
            const taskDoc = await firebase
                .firestore()
                .collection(`orgs/${orgId}/auditTemplates/${templateId}/tasks`)
                .doc(taskId)
                .get();
            const task = taskDoc.data();
            if (!task) {
                throw new Error(
                    `Task not found for orgId=${orgId} templateId=${templateId} id=${taskId}`,
                );
            }
            await firebase
                .firestore()
                .collection(`orgs/${orgId}/auditTemplates/${templateId}/tasks`)
                .add({
                    ...task,
                    createTime: new Date(),
                    description: `${task.description} (copy)`,
                });
        } catch (error) {
            console.error(error);
            Message({
                key: "deleteAuditTemplateTask",
                type: Type.ERROR,
                message: `Der skete en fejl`,
            });
        } finally {
            setloading(false);
        }
    };
    return {
        loading,
        handleDuplicateTemplate,
    };
}
