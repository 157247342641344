import ClickEditTextArea from "components/TextArea/ClickEditTextArea";
import React from "react";
import firebase from "firebase/compat/app";
import { useParams } from "react-router";
import Message, { Type } from "components/Notification/Message";

const messageKey = "AuditTemplateNote";

export default function AuditTemplateNote({ note }: { note?: string }) {
    const { orgId, auditTemplateId } = useParams<{
        orgId: string;
        auditTemplateId: string;
    }>();
    const handleSave = async (note: string) => {
        console.log({ note });
        try {
            Message({
                type: Type.LOADING,
                key: messageKey,
                message: `Loading...`,
                // description: `${payload.title} blev oprettet og kan nu søges frem i listen her`,
            });
            if (!orgId || !auditTemplateId) {
                throw new Error("orgId or auditTemplateId is undefined");
            }

            await firebase
                .firestore()
                .collection("orgs")
                .doc(orgId)
                .collection("auditTemplates")
                .doc(auditTemplateId)
                .update({ note });
            Message({
                key: messageKey,
                message: `Noten blev gemt`,
            });
        } catch (e) {
            console.error(e);
            Message({
                key: messageKey,
                type: Type.ERROR,
                message: `Der skete en fejl`,
                description: `Noten blev ikke oprettet, prøv venligst igen`,
            });
        }
    };
    return <ClickEditTextArea text={note || ""} onSave={handleSave} />;
}
