import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import firebase from "firebase/compat/app";
import { AppState } from "../store";
import { setOnboarding, setOnboardingState } from "modules/user/actions";
import { RequestState } from "interfaces/request";
import { setAuditTemplateSync } from "modules/auditTemplates/actions";
import { AuditTemplateSync } from "modules/auditTemplates/types";

function useAuditTemplateSync(orgId: string, auditTemplateId: string) {
    const uid = useSelector((state: AppState) => state.user.details.uid);

    const dispatch = useDispatch();
    let unsub: any = null;
    useEffect(() => {
        if (!orgId || !auditTemplateId) return;
        dispatch(setOnboardingState(RequestState.PENDING));
        unsub = firebase
            .firestore()
            .collection("auditTemplateSync")
            .where("auditTemplateId", "==", auditTemplateId)
            .where("orgId", "==", orgId)
            .limit(1)
            .onSnapshot(
                snap => {
                    if (snap.empty) {
                        dispatch(setAuditTemplateSync({ payload: null }));
                    } else {
                        const data = snap.docs.map(d => d.data())[0] as AuditTemplateSync;
                        dispatch(setAuditTemplateSync({ payload: data }));
                    }
                    // update language
                },
                error => {
                    dispatch(setOnboardingState(RequestState.REJECTED));
                    console.error(error);
                },
            );
        return () => unsub?.();
    }, [uid]);
}

export default useAuditTemplateSync;
