import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useCanChangeMemberSettings } from "components/AdminOnly";
import { useAppDispatch, useAppSelector } from "hooks";
import { openOrgMemberDrawer } from "modules/drawer/actions";
import React from "react";

export default function MemberSettings({ member }: any) {
    const orgId = useAppSelector(state => state.orgs.currentOrg.id);
    const dispatch = useAppDispatch();
    const allowedToEditMember = useCanChangeMemberSettings(member?.uid);
    return (
        <Button
            // type="primary"
            // ghost
            style={{ border: "none" }}
            icon={<EditOutlined />}
            disabled={!allowedToEditMember}
            onClick={() => dispatch(openOrgMemberDrawer({ orgId, userId: member?.uid }))}>
            {/* {t("access.notification_edit")} */}
        </Button>
    );
}
