import { spacing } from "./Spacing";
import { palette } from "./Palette";

export const StyleGuide = {
    spacing,
    palette,
    // typography,
};

type PaletteColorNames = keyof typeof StyleGuide.palette;
export type PaletteColors = (typeof StyleGuide.palette)[PaletteColorNames];

// const typography = {
//   largeTitle1: {
//     ...withFont(FontName.Bold),
//     fontSize: 28,
//     lineHeight: 40,
//     letterSpacing: -0.5,
//     color: palette.basePepper,
//   },
//   title1: {
//     ...withFont(FontName.Light),
//     fontSize: 28,
//     lineHeight: 40,
//     letterSpacing: -0.5,
//     color: palette.basePepper,
//   },
//   title2: {
//     ...withFont(FontName.Bold),
//     fontSize: 20,
//     lineHeight: 30,
//     letterSpacing: 0.15,
//     color: palette.basePepper,
//   },
//   title3: {
//     ...withFont(FontName.Regular),
//     fontSize: 20,
//     lineHeight: 30,
//     letterSpacing: 0.15,
//     color: palette.basePepper,
//   },
//   title4: {
//     ...withFont(FontName.Bold),
//     fontSize: 14,
//     lineHeight: 20,
//     // letterSpacing: 1.25,
//     color: palette.basePepper,
//   },
//   headline1: {
//     ...withFont(FontName.Medium),
//     fontSize: 16,
//     lineHeight: 24,
//     // letterSpacing: 1,
//     color: palette.basePepper,
//   },
//   headline2: {
//     ...withFont(FontName.Medium),
//     fontSize: 14,
//     lineHeight: 20,
//     // letterSpacing: 1.25,
//     color: palette.basePepper,
//   },
//   body1: {
//     ...withFont(FontName.Regular),
//     fontSize: 16,
//     lineHeight: 24,
//     // letterSpacing: 0.25,
//     color: palette.basePepper,
//   },
//   body2: {
//     ...withFont(FontName.Regular),
//     fontSize: 14,
//     lineHeight: 20,
//     // letterSpacing: -1.25,
//     color: palette.basePepper,
//   },
//   detail1: {
//     ...withFont(FontName.Regular),
//     fontSize: 12,
//     lineHeight: 18,
//     // letterSpacing: 2,
//     color: palette.basePepper,
//   },
//   detail2: {
//     ...withFont(FontName.Regular),
//     fontSize: 10,
//     lineHeight: 16,
//     // letterSpacing: 2,
//     color: palette.basePepper,
//   },
// };
