import { useMemo, useState } from "react";
import { AutoComplete, Avatar, Input, Tooltip } from "antd";
import debounce from "lodash.debounce";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import useSearch from "hooks/useSearch";
import { SearchKeyNames } from "modules/user/types";
import useStorageImage from "hooks/useStorageImage";
import { LoadingOutlined } from "@ant-design/icons";
import { Colors } from "Layout/Colors";
import { getInitials } from "general/initials";
import { RenderTags } from "./IssueItem";
import { SearchParams } from "typesense/lib/Typesense/Documents";
import { StyleGuide } from "styles/StyleGuide";
import NoImage from "assets/icons/NoImage";

const borderStyle = {
    position: "absolute" as const,
    left: 0,
    height: "100%",
    width: 4,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
};

const IssuesSearch = ({ searchParams }: { searchParams?: Partial<SearchParams> }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [results, setResults] = useState<any[]>([]);
    const { loading, onSearch } = useSearch({
        type: SearchKeyNames.ORG_ISSUES,
    });

    const handleSearch = async (value: string = "") => {
        // if (value.length < 2) return;
        const result = await onSearch(value, searchParams);
        console.log({ result });

        setResults(
            result.map((r: any, index: number) => ({
                value: r.value,
                orgId: r.context.orgId,
                label: (
                    <div
                        key={r.value}
                        style={{
                            minHeight: 42,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}>
                            {r?.context?.image ? (
                                <IssueSearchImage
                                    path={
                                        r?.context?.image?.thumbFilePath ||
                                        // TODO: update this in the DB to be thumbnail
                                        r?.context?.image?.thumnFilePath
                                    }
                                />
                            ) : (
                                <Avatar
                                    shape="square"
                                    icon={
                                        <NoImage
                                            width={18}
                                            height={18}
                                            color={StyleGuide.palette.grey600}
                                        />
                                    }
                                    style={{
                                        backgroundColor: StyleGuide.palette.grey200,
                                        border: "1px solid #f0f0f0",
                                        marginRight: 8,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                />
                            )}
                            <div>{r.label}</div>
                        </div>
                        {r.context?.assignee && (
                            <div
                                style={{
                                    ...borderStyle,
                                    borderLeft: `4px solid ${Colors.warning}`,
                                }}
                            />
                        )}
                        {r?.context?.isCompleted && (
                            <div
                                style={{
                                    ...borderStyle,
                                    borderLeft: `4px solid ${Colors.done}`,
                                }}
                            />
                        )}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}>
                            <RenderTags tags={r?.context?.displayTags} />
                            {r?.context?.assignee && (
                                <Tooltip title={r?.context?.assignee?.name}>
                                    <div>
                                        <Avatar>
                                            {getInitials(r?.context?.assignee?.name)}
                                        </Avatar>
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                        {index < results.length - 1 && (
                            <div
                                style={{
                                    position: "absolute",
                                    left: 0,
                                    bottom: 0,
                                    backgroundColor: StyleGuide.palette.grey200,
                                    width: "100%",
                                    height: 1,
                                }}
                            />
                        )}
                    </div>
                ),
            })),
        );
    };

    const debounceSearch = useMemo(() => {
        return debounce(handleSearch, 300);
    }, []);

    return (
        <>
            <AutoComplete
                onSelect={value => {
                    const result = results.find(r => r.value === value);
                    console.log({ result });

                    history.push(`/orgs/${result?.orgId}/issues/${value}`);
                }}
                disabled={loading}
                options={results}
                style={{ width: "100%" }}
                onFocus={() => handleSearch()}
                onSearch={debounceSearch}>
                <Input.Search
                    placeholder={t("issues.search.placeholder")}
                    enterButton={loading && <LoadingOutlined />}
                />
            </AutoComplete>
        </>
    );
};

export default IssuesSearch;

function IssueSearchImage({ path }: { path: string }) {
    const { loading, image } = useStorageImage({ path });
    return (
        <Avatar
            style={{ border: "1px solid #f0f0f0", marginRight: 8 }}
            shape="square"
            src={image}
            icon={loading && <LoadingOutlined />}
        />
    );
}
