import { RequestState } from "interfaces/request";

export interface DBAuditShape {
    title?: string;
    thumbs?: [];
    tags?: [];
}

export type auditTab = "completed" | "incompleted";

export type CustomFieldType =
    | "economy"
    | "energy"
    | "volume"
    | "weight"
    | "length_distance"
    | "time"
    | "temperature"
    | "pressure"
    | "amount";

export type CustomField = {
    unit: string;
    fieldType: FieldType;
    type: CustomFieldType;
    description: string;
    required: boolean;
};
export type FieldConfig = {
    fields: {
        [id: string]: {
            id: string;
            fieldType: FieldType;
            options: string[];
            defaultOption: string;
        };
    };
};

export type FieldType =
    | "text"
    | "number"
    | "date"
    | "checkbox"
    | "select"
    | "radio"
    | "textarea";

export interface AuditState extends DBAuditShape {
    audits: DBAuditShape[];
    loading: boolean;
    currentAudit: {
        thumbs?: [];
        tags?: [];
    };
    currentTab: auditTab;
    completeAuditLimit: number;
    auditBaseCategoriesRequestState: RequestState;
    auditBaseCategories: FieldConfig;
}

export const AUDITS_FETCH_SUCCEEDED = "AUDITS_FETCH_SUCCEEDED";
export const AUDITS_FETCH_FAILED = "AUDITS_FETCH_FAILED";
export const AUDITS_FETCH_REQUESTED = "AUDITS_FETCH_REQUESTED";

export const AUDIT_FETCH_SUCCEEDED = "AUDIT_FETCH_SUCCEEDED";
export const AUDIT_FETCH_FAILED = "AUDIT_FETCH_FAILED";
export const AUDIT_FETCH_REQUESTED = "AUDIT_FETCH_REQUESTED";

export const AUDIT_UPDATE_SUCCEEDED = "AUDIT_UPDATE_SUCCEEDED";
export const AUDIT_UPDATE_FAILED = "AUDIT_UPDATE_FAILED";
export const AUDIT_UPDATE_REQUESTED = "AUDIT_UPDATE_REQUESTED";

export const AUDIT_CREATE_SUCCEEDED = "AUDIT_CREATE_SUCCEEDED";
export const AUDIT_CREATE_FAILED = "AUDIT_CREATE_FAILED";
export const AUDIT_CREATE_REQUESTED = "AUDIT_CREATE_REQUESTED";

export const AUDIT_DELETE_SUCCEEDED = "AUDIT_DELETE_SUCCEEDED";
export const AUDIT_DELETE_FAILED = "AUDIT_DELETE_FAILED";
export const AUDIT_DELETE_REQUESTED = "AUDIT_DELETE_REQUESTED";

export const SET_CURRENT_TAB = "SET_CURRENT_TAB";
export const COMPLETE_AUDIT_LIMIT = "COMPLETE_AUDIT_LIMIT";

export const AUDIT_BASE_CATEGORY_CONFIG_FETCH_SUCCEEDED =
    "AUDIT_BASE_CATEGORY_CONFIG_FETCH_SUCCEEDED";
export const AUDIT_BASE_CATEGORY_CONFIG_FETCH_FAILED =
    "AUDIT_BASE_CATEGORY_CONFIG_FETCH_FAILED";
export const AUDIT_BASE_CATEGORY_CONFIG_FETCH_REQUESTED =
    "AUDIT_BASE_CATEGORY_CONFIG_FETCH_REQUESTED";

interface FetchAudit {
    type: typeof AUDIT_FETCH_REQUESTED;
    payload: { orgId: string };
}
interface FetchAuditSuccess {
    type: typeof AUDIT_FETCH_SUCCEEDED;
    payload: { data: DBAuditShape };
}
interface FetchAuditFailed {
    type: typeof AUDIT_FETCH_FAILED;
    payload: {};
}
interface SetCurrentTab {
    type: typeof SET_CURRENT_TAB;
    payload: { key: auditTab };
}

interface AuditCompleteLimit {
    type: typeof COMPLETE_AUDIT_LIMIT;
    payload: { limit: number };
}

interface FetchAuditBaseCategoriesConfig {
    type: typeof AUDIT_BASE_CATEGORY_CONFIG_FETCH_REQUESTED;
    payload: { orgId: string };
}
interface FetchAuditBaseCategoriesConfigSuccess {
    type: typeof AUDIT_BASE_CATEGORY_CONFIG_FETCH_SUCCEEDED;
    payload: FieldConfig;
}
interface FetchAuditBaseCategoriesConfigFailed {
    type: typeof AUDIT_BASE_CATEGORY_CONFIG_FETCH_FAILED;
    payload: {};
}

export type AuditActionTypes =
    | FetchAudit
    | FetchAuditSuccess
    | FetchAuditFailed
    | SetCurrentTab
    | AuditCompleteLimit
    | FetchAuditBaseCategoriesConfig
    | FetchAuditBaseCategoriesConfigSuccess
    | FetchAuditBaseCategoriesConfigFailed;
