import { addMonths, endOfWeek, startOfWeek, subMonths } from "date-fns";
import { firestore } from "../../firebase";
import { useEffect, useState } from "react";
import { AuditSchedule } from "./types";

export default function useCompletedAudits(orgId: string, currentDate: Date) {
    const [audits, setIssues] = useState<(AuditSchedule & { orgId: string })[]>([]);
    const [isLoading, setisLoading] = useState(true);
    const startOfWeekDate = startOfWeek(currentDate, { weekStartsOn: 1 });
    const endOfWeekDate = endOfWeek(currentDate, { weekStartsOn: 1 });
    useEffect(() => {
        let unsubscribe: any = null;
        if (!orgId || !currentDate) return;
        try {
            console.log(orgId);

            const query = firestore
                .collection("orgs")
                .doc(orgId)
                .collection("auditSchedules")
                .where("isCompleted", "==", true)
                .where("dueDate", "<=", addMonths(endOfWeekDate, 1))
                .where("dueDate", ">=", subMonths(startOfWeekDate, 1))
                .orderBy("dueDate", "desc")
                .limit(100);

            // if (orgIds && orgIds?.length > 0) {
            //     query = query.where("orgId", "in", orgIds);
            // }

            unsubscribe = query.onSnapshot(
                querySnapshot => {
                    console.log("LOADING....");
                    if (!querySnapshot.empty) {
                        const list = querySnapshot.docs.map(
                            doc =>
                                ({
                                    id: doc.id,
                                    ...doc.data(),
                                    orgId: doc.ref.parent.parent?.id,
                                } as AuditSchedule & { orgId: string }),
                        );
                        setIssues(list);
                    } else {
                        setIssues([]);
                    }
                    setisLoading(false);
                },
                error => {
                    console.error(error);
                },
            );
        } catch (error) {
            console.error(error);
        }
        return () => unsubscribe?.();
    }, [orgId, currentDate]);

    return { audits, isLoading };
}
