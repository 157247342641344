import React, { PureComponent, useState } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";
import styled from "styled-components";
import { StyleGuide } from "styles/StyleGuide";

const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        // fill,
        payload,
        percent,
        value,
        index,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    const fill = payload.fill;
    console.log({ fill, value });

    return (
        <g>
            <text x={cx} y={cy - 10} dy={8} textAnchor="middle" fill={fill} fontSize={24}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="#333">{`${value}`}</text>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 0}
                y={ey}
                dy={18}
                textAnchor={textAnchor}
                fill="#999">
                {`${(percent * 100).toFixed(2)}%`}
            </text>
        </g>
    );
};

export default function SummartChart({
    stats,
}: {
    stats: {
        completed: number;
        overdue: number;
        awating: number;
        onHold: number;
        needsResolution: number;
    };
}) {
    const [activeIndex, setActiveIndex] = useState(0);
    const data = [
        {
            name: "Ikke afsluttet",
            value: stats.overdue,
            fill: StyleGuide.palette.statusErrorDark,
        },
        {
            name: "På hold",
            value: stats.onHold,
            fill: StyleGuide.palette.statusWarningDark,
        },
        {
            name: "Kræver handling",
            value: stats.needsResolution,
            fill: StyleGuide.palette.statusHardWarningDark,
        },
        {
            name: "Afsluttet",
            value: stats.completed,
            fill: StyleGuide.palette.statusSuccessDark,
        },
        // {
        //     name: "Afventer udførsel",
        //     value: stats.awating,
        //     fill: StyleGuide.palette.statusProcessingLight,
        // },
    ];
    console.log({ data });

    const onPieEnter = (_: any, index: number) => {
        setActiveIndex(index);
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart width={200} height={200}>
                <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={data}
                    cx="50%"
                    cy="50%"
                    innerRadius={100}
                    outerRadius={120}
                    fill="#8884d8"
                    dataKey="value"
                    startAngle={180}
                    endAngle={0}
                    onMouseEnter={onPieEnter}
                />
            </PieChart>
        </ResponsiveContainer>
    );
}

export const AvoidSvgOverflow = styled.div`
    svg {
        overflow: visible !important;
        position: relative;
    }
`;
