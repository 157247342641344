import React from "react";
import { Chart } from "react-google-charts";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { useGsmPlantLog } from "./useLatestGsmPlantLog";

function formatData(data: any) {
    return data
        .sort((a: any, b: any) =>
            a?.createTime?.toDate().getTime() > b?.createTime?.toDate().getTime()
                ? 1
                : -1,
        )
        .map((o: any, index: number) => {
            if (index === 0) {
                const binder = Number(o.binder_total_l);
                const water = Number(o.vand_total_l) / 1000 + Number(o.vand_total_1000_l);
                return [
                    t("dates.getDate", { date: o.createTime?.toDate?.() }),
                    binder,
                    water,
                ];
            }
            const previous = data[index - 1];

            const binder = Number(o.binder_total_l) - Number(previous.binder_total_l);
            const water =
                (Number(o.vand_total_l) - Number(previous.vand_total_l)) / 1000 +
                (Number(o.vand_total_1000_l) - Number(previous.vand_total_1000_l));
            // TODO: make different intl
            return [
                t("dates.getDate", { date: o.createTime?.toDate?.() }),
                binder,
                water,
            ];
        })
        .filter(Boolean);
}

export default function UsageChart({
    isLoading,
    data,
}: {
    isLoading: boolean;
    data: any[];
}) {
    const { t } = useTranslation();

    const types = [
        t("gsm.usagechart.series_time"),
        t("gsm.usagechart.series_binder"),
        t("gsm.usagechart.series_water"),
    ];
    const newData = formatData(data);

    const usage = data.reduce((acc, i) => (acc += i.binder_total_l), 0);
    if (isLoading) {
        return <div>Loadding...</div>;
    }
    if (usage < 1 || data.length < 1) {
        return <div>No usage data to display yet</div>;
    }
    return (
        <Chart
            chartType="Bar"
            width="100%"
            height="400px"
            data={[types, ...newData]}
            options={{
                chart: {
                    title: t("gsm.usagechart.title"),
                    subtitle: t("gsm.usagechart.subtitle"),
                },
                colors: ["#1890ff", "#bbdefb"],
            }}
        />
    );
}
