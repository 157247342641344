import React from "react";
import SideNav from "../../Layout/SideNav";
import { Layout, Space, Typography } from "antd";
import HeaderBar from "../../Layout/HeaderBar";
import { Content } from "antd/lib/layout/layout";
import FooterBar from "../../Layout/FooterBar";
import BillingTable from "./Table";
import ModalBilling from "./Modal";
import useAppProducts from "../../hooks/useAppProducts";
import useAppBillingsOverview from "../../hooks/useAppBillingOverview";

export default function Billing() {
    // const { appProducts } = useAppProducts();
    const { appBilling } = useAppBillingsOverview();
    return (
        <>
            <Layout>
                <HeaderBar />
                <Content style={{ margin: "50px 24px 0" }}>
                    <Typography.Title>
                        Viser ikke betalt og forfaldne betalinger
                    </Typography.Title>
                    <BillingTable appBilling={appBilling} />
                </Content>
                <FooterBar />
            </Layout>
        </>
    );
}
