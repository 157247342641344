import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import firebase from "firebase/compat/app";
import { AppState } from "../../store";
import { getUser } from "../../modules/user/actions";
import i18n from "i18next";
import { mapIntlToi18n } from "../../general/intl";

function useProfile() {
    const loading = useSelector((state: AppState) => state.user.loading);
    const error = useSelector((state: AppState) => state.user.error);
    const uid = useSelector((state: AppState) => state.user.details.uid);

    const dispatch = useDispatch();
    let unsub: any = null;
    useEffect(() => {
        unsub = firebase
            .firestore()
            .collection("users")
            .doc(uid)
            .onSnapshot(doc => {
                const data = doc.data();
                dispatch(getUser(data));
                // update language
                i18n.changeLanguage(mapIntlToi18n(data?.intl));
            });
        return () => unsub?.();
    }, [uid]);

    return {
        loading,
        error,
    };
}

export default useProfile;
