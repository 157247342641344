import React, { useEffect, useState } from "react";
import { realtimedb } from "../../firebase";
import { subHours, isAfter, formatDistanceToNow } from "date-fns";
import { locales } from "../../languages/i18n";

export default function useSensorStatus() {
    const [states, setStates] = useState<any>({});
    useEffect(() => {
        const ref = realtimedb.ref(`/`);
        const listener = ref.on(
            "value",
            snapshot => {
                const data = snapshot.val();
                console.log("Realtime");
                console.log({ data });
                setStates(data);
            },
            error => {
                console.error(error);
            },
        );

        return () => {
            ref.off("value", listener);
        };
    }, []);
    const compareTime = subHours(new Date(), 1).getTime();
    return {
        deviceStateMap: Object.keys(states).reduce(
            (acc: any, i: any) => ({
                ...acc,
                [i]: {
                    // ...states[i],
                    isActive: isAfter(states[i].updateTime, compareTime),
                    lastSeen: formatDistanceToNow(states[i].updateTime, {
                        addSuffix: true,
                        locale: locales.da,
                    }),
                },
            }),
            {},
        ),
    };
}
