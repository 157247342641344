import { IOrg } from "./interfaces";
export const ORGS_FETCH_SUCCEEDED = "ORGS_FETCH_SUCCEEDED";
export const ORGS_FETCH_FAILED = "ORGS_FETCH_FAILED";
export const ORGS_FETCH_REQUESTED = "ORGS_FETCH_REQUESTED";

export const ORG_CREATE_SUCCEEDED = "ORG_CREATE_SUCCEEDED";
export const ORG_CREATE_FAILED = "ORG_CREATE_FAILED";
export const ORG_CREATE_REQUESTED = "ORG_CREATE_REQUESTED";

export const ORG_DELETE_SUCCEEDED = "ORG_DELETE_SUCCEEDED";
export const ORG_DELETE_FAILED = "ORG_DELETE_FAILED";
export const ORG_DELETE_REQUESTED = "ORG_DELETE_REQUESTED";

export const ORG_FETCH_SUCCEEDED = "ORG_FETCH_SUCCEEDED";
export const ORG_FETCH_FAILED = "ORG_FETCH_FAILED";
export const ORG_FETCH_REQUESTED = "ORG_FETCH_REQUESTED";

export const MEMBERS_UPDATE_FAILED = "MEMBERS_UPDATE_FAILED";
export const MEMBERS_UPDATE_REQUESTED = "MEMBERS_UPDATE_REQUESTED";
export const MEMBERS_UPDATE_SUCCEEDED = "MEMBERS_UPDATE_SUCCEEDED";

export const MEMBER_ADD_FAILED = "MEMBER_ADD_FAILED";
export const MEMBER_ADD_REQUESTED = "MEMBER_ADD_REQUESTED";
export const MEMBER_ADD_SUCCEEDED = "MEMBER_ADD_SUCCEEDED";

export const MEMBER_DELETE_FAILED = "MEMBER_DELETE_FAILED";
export const MEMBER_DELETE_REQUESTED = "MEMBER_DELETE_REQUESTED";
export const MEMBER_DELETE_SUCCEEDED = "MEMBER_DELETE_SUCCEEDED";

export const ORG_UPDATE_FAILED = "ORG_UPDATE_FAILED";
export const ORG_UPDATE_REQUESTED = "ORG_UPDATE_REQUESTED";
export const ORG_UPDATE_SUCCEEDED = "ORG_UPDATE_SUCCEEDED";

export interface OrgState {
    orgs: IOrg[];
    orgMap: { [id: string]: IOrg };
    loading: boolean;
    currentOrg: IOrg;
}

interface FetchOrgLoading {
    type:
        | typeof ORGS_FETCH_REQUESTED
        | typeof ORG_FETCH_REQUESTED
        | typeof ORGS_FETCH_FAILED
        | typeof ORG_FETCH_FAILED;
    payload: {};
}
interface FetchOrgsSucceeded {
    type: typeof ORGS_FETCH_SUCCEEDED;
    payload: {
        data: IOrg[];
    };
}
interface FetchOrgSucceeded {
    type: typeof ORG_FETCH_SUCCEEDED;
    payload: {
        data: any;
    };
}

export type OrgActionTypes = FetchOrgLoading | FetchOrgsSucceeded | FetchOrgSucceeded;
