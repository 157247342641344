import React, { useEffect } from "react";
import { Checkbox, Row, Col } from "antd";
import { useParams } from "react-router";
import useProducts from "../../hooks/useProducts";
import { useTranslation } from "react-i18next";

function ProductList() {
    const { orgId } = useParams<{ orgId: string }>();
    const { t } = useTranslation();
    const {
        products,
        orgProducts,
        dispatchFetchProducts,
        dispatchFetchOrgProducts,
        dispatchAddOrgProduct,
        dispatchDeleteOrgProduct,
    } = useProducts();

    useEffect(() => {
        dispatchFetchProducts();
        dispatchFetchOrgProducts(orgId);
    }, [orgId]);

    const onChange = (e: any, productId: string, productTitle: string) => {
        if (e.target.checked) {
            dispatchAddOrgProduct(orgId, productId, productTitle);
        } else {
            dispatchDeleteOrgProduct(orgId, productId);
        }
    };

    return (
        <div>
            <label>
                {t("settings.products_label")}:
                <Row>
                    {products
                        .sort((a, b) => {
                            const aProduct = a?.title?.toLowerCase();
                            const bProduct = b?.title?.toLowerCase();
                            if (aProduct < bProduct) {
                                return -1;
                            }
                            if (aProduct > bProduct) {
                                return 1;
                            }
                            return 0;
                        })
                        .map((product: any) => {
                            const isChecked = Boolean(orgProducts[product.id]);
                            return (
                                <Col span={24} key={product.id}>
                                    <Checkbox
                                        value={product.id}
                                        checked={isChecked}
                                        onChange={e =>
                                            onChange(e, product.id, product.title)
                                        }>
                                        {product.title}
                                    </Checkbox>
                                </Col>
                            );
                        })}
                </Row>
            </label>
        </div>
    );
}

export default ProductList;
