import { Button, Tooltip } from "antd";
import { useParams } from "react-router";
import firebase from "firebase/compat/app";
import Message, { Type } from "components/Notification/Message";
import { useAppSelector } from "hooks";
import confirmModal from "general/confirmModal";
import { useTranslation } from "react-i18next";
import { ApartmentOutlined } from "@ant-design/icons";
import AdminOnly from "components/AdminOnly";
import useIsReadonlyPath from "hooks/useIsReadonlyPath";

const messageKey = "SaveToGroup";

export default function SaveToGroup() {
    const isReadOnly = useIsReadonlyPath();
    const { t } = useTranslation();
    const { orgId, auditTemplateId } = useParams<{
        orgId: string;
        auditTemplateId: string;
    }>();
    const groupId = useAppSelector(state => state.orgs.currentOrg?.groupId);
    const currentAuditTemplate = useAppSelector(
        state => state.auditTemplates.currentAuditTemplate,
    );
    const currentAuditTemplateTasks = useAppSelector(
        state => state.auditTemplates.currentAuditTemplateTasks,
    );

    const onSyncConfirm = () => {
        confirmModal({
            title: t("audit.templates.sync.confirm.title"),
            content: (
                <>
                    <div>{t("audit.templates.sync.confirm.content")}</div>
                    <ol style={{ paddingLeft: 16 }}>
                        <li>{t("audit.templates.sync.confirm.item1")}</li>
                        <li>{t("audit.templates.sync.confirm.item2")}</li>
                        <li>{t("audit.templates.sync.confirm.item3")}</li>
                    </ol>
                </>
            ),
            onOk: () => {
                onSyncTemplate();
            },
        });
    };

    console.log({
        currentAuditTemplate,
        currentAuditTemplateTasks,
    });

    const onSyncTemplate = async () => {
        try {
            if (!orgId || !auditTemplateId || !groupId) {
                throw new Error("orgId or auditTemplateId or groupId is undefined");
            }
            if (currentAuditTemplate.id !== auditTemplateId) {
                throw new Error("currentAuditTemplate.id !== auditTemplateId");
            }
            Message({
                key: messageKey,
                type: Type.LOADING,
                message: "Syncing template",
            });
            const batch = firebase.firestore().batch();

            // const groupAuditTemplateRef = firebase
            //     .firestore()
            //     .collection("groups")
            //     .doc(groupId)
            //     .collection("groupAuditTemplates")
            //     .doc();
            // const groupAuditTemplateTasksRef = firebase
            //     .firestore()
            //     .collection("groups")
            //     .doc(groupId)
            //     .collection("groupAuditTemplates")
            //     .doc(groupAuditTemplateRef.id)
            //     .collection("groupAuditTemplateTasks");

            // const auditTemplateRef = firebase
            //     .firestore()
            //     .collection("orgs")
            //     .doc(orgId)
            //     .collection("auditTemplates")
            //     .doc(currentAuditTemplate.id)
            //     .get();

            // .set({
            //     createTime: new Date(),
            //     orgId,
            //     auditTemplateId,
            //     dataToSync: {
            //         guide: currentAuditTemplate.guide ?? null,
            //         templateTitle: currentAuditTemplate.title,
            //         templateNote: currentAuditTemplate.note || "",
            //         tasks: currentAuditTemplateTasks,
            //         // has to convert to a date on backend
            //         // notifyDaysAfterDueDate:
            //         //     currentAuditTemplate.notifyDaysAfterDueDate ?? null,
            //         auditTemplateCategory:
            //             currentAuditTemplate.auditTemplateCategory ?? null,
            //     },
            //     status: RequestState.PENDING,
            // });
            Message({
                key: messageKey,
                type: Type.SUCCESS,
                message: t("audit.templates.sync.success"),
            });
        } catch (e) {
            console.error(e);
            Message({
                key: messageKey,
                type: Type.ERROR,
                message: "Error syncing template",
            });
        }
    };
    return (
        <AdminOnly>
            <Tooltip placement="left" title={t("audit.templates.sync.group.tooltip")}>
                <Button
                    disabled={isReadOnly}
                    icon={<ApartmentOutlined />}
                    block
                    onClick={onSyncConfirm}
                    type="default">
                    {t("audit.templates.sync.group.button")}
                </Button>
            </Tooltip>
        </AdminOnly>
    );
}
