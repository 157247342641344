import {
    AUDIT_TEMPLATES_FETCH_REQUESTED,
    AUDIT_TEMPLATES_FETCH_SUCCEEDED,
    AUDIT_TEMPLATE_FETCH_REQUESTED,
    AUDIT_TEMPLATE_FETCH_SUCCEEDED,
    AUDIT_TEMPLATE_UPDATE_REQUESTED,
    AUDIT_TEMPLATE_UPDATE_SUCCEEDED,
    // AUDIT_TEMPLATE_CREATE_REQUESTED,
    AUDIT_TEMPLATE_DELETE_REQUESTED,
    AUDIT_TEMPLATE_TASKS_FETCH_REQUESTED,
    AUDIT_TEMPLATE_TASKS_FETCH_SUCCEEDED,
    // SET_CURRENT_TAB,
    AUDIT_TEMPLATE_TASK_ADD_REQUESTED,
    AUDIT_TEMPLATE_TASK_DELETE_REQUESTED,
    AUDIT_TEMPLATE_TASK_UPDATE_REQUESTED,
    AUDIT_TEMPLATE_ADD_REQUESTED,
    AUDIT_TEMPLATE_FILE_DELETE_REQUESTED,
    AUDIT_TEMPLATE_SYNC_SUCCEEDED,
    AuditTemplateSync,
} from "./types";

export function fetchAuditTemplates({ orgId }: { orgId: string }) {
    return {
        type: AUDIT_TEMPLATES_FETCH_REQUESTED,
        payload: { orgId },
    };
}

// TODO: works when upgrading to v9
// @ts-ignore
export function fetchAuditTemplatesSuccess(auditTemplateList: QuerySnapshot) {
    const auditTemplates = auditTemplateList.docs.map((template: any) => ({
        ...template.data(),
        id: template.id,
    }));
    return {
        type: AUDIT_TEMPLATES_FETCH_SUCCEEDED,
        payload: {
            data: auditTemplates,
        },
    };
}

export function fetchAuditTemplate({
    orgId,
    auditTemplateId,
}: {
    orgId: string;
    auditTemplateId: string;
}) {
    return {
        type: AUDIT_TEMPLATE_FETCH_REQUESTED,
        payload: { orgId, auditTemplateId },
    };
}
// TODO: works when upgrading to v9
// @ts-ignore
export function fetchAuditTemplateSuccess(auditTemplateDoc: DocumentSnapshot) {
    const auditTemplate = { id: auditTemplateDoc.id, ...auditTemplateDoc.data() };

    return {
        type: AUDIT_TEMPLATE_FETCH_SUCCEEDED,
        payload: {
            data: auditTemplate,
        },
    };
}

export function addAuditTemplate({ orgId, title }: { orgId: string; title: string }) {
    return {
        type: AUDIT_TEMPLATE_ADD_REQUESTED,
        payload: {
            orgId,
            title,
        },
    };
}

export function deleteAuditTemplate(id: string) {
    return {
        type: AUDIT_TEMPLATE_DELETE_REQUESTED,
        payload: id,
    };
}

export function updateAuditTemplate({
    orgId,
    auditTemplateId,
    title,
}: {
    orgId: string;
    auditTemplateId: string;
    title: string;
}) {
    return {
        type: AUDIT_TEMPLATE_UPDATE_REQUESTED,
        payload: { orgId, auditTemplateId, title },
    };
}

export function updateAuditTemplateSuccess() {
    return {
        type: AUDIT_TEMPLATE_UPDATE_SUCCEEDED,
        payload: {},
    };
}
export function removeAuditTemplateFile({
    orgId,
    auditTemplateId,
    guide,
}: {
    orgId: string;
    auditTemplateId: string;
    guide: string | null;
}) {
    return {
        type: AUDIT_TEMPLATE_FILE_DELETE_REQUESTED,
        payload: { orgId, auditTemplateId, guide },
    };
}

export function fetchAuditTemplateTasks({
    orgId,
    auditTemplateId,
}: {
    orgId: string;
    auditTemplateId: string;
}) {
    return {
        type: AUDIT_TEMPLATE_TASKS_FETCH_REQUESTED,
        payload: { orgId, auditTemplateId },
    };
}

// TODO: works when upgrading to v9
export function fetchAuditTemplateTasksSuccess(
    // @ts-ignore
    auditTemplateTaskList: QuerySnapshot,
) {
    const auditTemplateTasks = auditTemplateTaskList.docs.map((task: any) => ({
        ...task.data(),
        id: task.id,
    }));
    return {
        type: AUDIT_TEMPLATE_TASKS_FETCH_SUCCEEDED,
        payload: {
            data: auditTemplateTasks,
        },
    };
}

export function addAuditTemplateTask({
    orgId,
    auditTemplateId,
    title,
    order,
}: {
    orgId: string;
    auditTemplateId: string;
    title: string;
    order: number;
}) {
    return {
        type: AUDIT_TEMPLATE_TASK_ADD_REQUESTED,
        payload: {
            orgId,
            auditTemplateId,
            title,
            order,
        },
    };
}
export function deleteAuditTemplateTask({
    orgId,
    auditTemplateId,
    taskId,
}: {
    orgId: string;
    auditTemplateId: string;
    taskId: string;
}) {
    return {
        type: AUDIT_TEMPLATE_TASK_DELETE_REQUESTED,
        payload: {
            orgId,
            auditTemplateId,
            taskId,
        },
    };
}

export function updateAuditTemplateTask({
    orgId,
    auditTemplateId,
    taskId,
    title,
}: {
    orgId: string;
    auditTemplateId: string;
    taskId: string;
    title: string;
}) {
    return {
        type: AUDIT_TEMPLATE_TASK_UPDATE_REQUESTED,
        payload: {
            orgId,
            auditTemplateId,
            taskId,
            title,
        },
    };
}
export function setAuditTemplateSync({ payload }: { payload: AuditTemplateSync | null }) {
    return {
        type: AUDIT_TEMPLATE_SYNC_SUCCEEDED,
        payload,
    };
}
