import {
    CHECKIN_TYPES,
    CHECKOUT_FETCH_REQUESTED,
    CHECKOUT_FETCH_SUCCEEDED,
} from "./types";
import {
    CHECKIN_FETCH_REQUESTED,
    CHECKIN_FETCH_SUCCEEDED,
    DBCheckinShape,
    CHECKIN_ADD_REQUESTED,
} from "./types";

export interface DocumentData {
    [field: string]: any;
}

export function fetchCheckins({
    orgId,
    uid,
    type,
}: {
    orgId: string;
    uid: string;
    type: CHECKIN_TYPES.CHECK_IN;
}) {
    return {
        type: CHECKIN_FETCH_REQUESTED,
        payload: {
            orgId,
            uid,
            type,
        },
    };
}

export function fetchCheckinsSuccess(
    // @ts-ignore
    reportList: firebase.firestore.QuerySnapshot,
): { type: string; payload: DBCheckinShape[] } {
    // @ts-ignore
    const reports = reportList.docs.map((report: firebase.firestore.DocumentData) => ({
        ...report.data(),
        id: report.id,
    }));
    return {
        type: CHECKIN_FETCH_SUCCEEDED,
        payload: reports,
    };
}

export function fetchCheckouts({
    orgId,
    uid,
    type,
}: {
    orgId: string;
    uid: string;
    type: CHECKIN_TYPES.CHECK_OUT;
}) {
    console.log({ fetchCheckouts: orgId, uid, type });

    return {
        type: CHECKOUT_FETCH_REQUESTED,
        payload: {
            orgId,
            uid,
            type,
        },
    };
}

export function fetchCheckoutsSuccess(
    // @ts-ignore
    reportList: firebase.firestore.QuerySnapshot,
): { type: string; payload: DBCheckinShape[] } {
    console.log({ fetchCheckoutsSuccess: reportList });
    // @ts-ignore
    const reports = reportList.docs.map((report: firebase.firestore.DocumentData) => ({
        ...report.data(),
        id: report.id,
    }));
    return {
        type: CHECKOUT_FETCH_SUCCEEDED,
        payload: reports,
    };
}
export function addCheckin({
    orgId,
    uid,
    type,
    displayName,
    lat,
    lng,
    accuracy,
}: {
    orgId: string;
    uid: string;
    type: CHECKIN_TYPES;
    displayName: string;
    lat: number;
    lng: number;
    accuracy: number;
}) {
    return {
        type: CHECKIN_ADD_REQUESTED,
        payload: {
            orgId,
            data: {
                createTime: new Date(),
                userId: uid,
                type,
                displayName,
                lat,
                lng,
                accuracy,
            },
        },
    };
}
