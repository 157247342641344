import React, { useState } from "react";
import { Layout, Button, Row, Col } from "antd";
import Table from "./components/Table";
import SideNav from "../../Layout/SideNav";
import HeaderBar from "../../Layout/HeaderBar";
import FooterBar from "../../Layout/FooterBar";
import Loader from "../../components/Loader";
import AddCleanLogForm from "./AddCleanLogForm";
import moment from "moment";
import { parseAmountToFloat } from "general/helpers";
import { isToday } from "date-fns";
import { useIsMobile } from "../../hooks/useIsMobile";
import { FeatureIds } from "../../modules/featureControl/featuresConfig";
import BlurOverlayContainer from "../../components/Overlay/BlurOverlayContainer";
import { useTranslation } from "react-i18next";
import { Product } from "../../modules/products/types";
import { CleaningLog } from "../../modules/cleanLogs/types";
import { CleanLogsOptionsFormatted } from "../../hooks/useCleanLogOptions";
import { useAppSelector } from "../../hooks";

const { Content } = Layout;

interface Props {
    cleanLogs: CleaningLog[];
    cleanLogOptions: CleanLogsOptionsFormatted[];
    products: Product[];
    orgId: string;
    loading: boolean;
    locale: string;
    updateOrgProduct: (params: { productId: string; data: Partial<Product> }) => void;
    updateCleanLog: (params: {
        productId: string;
        logId: string;
        data: Partial<Product>;
    }) => void;
}

function CleanLogList(props: Props) {
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    const org = useAppSelector(state => state.orgs.currentOrg);
    const [isShowingHistory, setisShowingHistory] = useState(!isMobile);
    const data = props.cleanLogs
        .filter((log: any) => (isShowingHistory ? log : isToday(log.createTime.toDate())))
        .map((log: any) => {
            return {
                ...log,
                key: log.id,
                createTime: moment(log.createTime.toDate()).format(
                    "Do MMM. YYYY - HH:mm",
                ),
            };
        });

    const updateOrgProductFn = (diffAmount: number, product: Product) =>
        props.updateOrgProduct({
            productId: product.id,
            data: {
                storage:
                    parseAmountToFloat(product.storage) - parseAmountToFloat(diffAmount),
            },
        });

    return (
        <>
            <SideNav />
            <Layout>
                <HeaderBar />
                <BlurOverlayContainer featureKey={FeatureIds.CLEAN_LOGS}>
                    <Content style={{ margin: "50px 24px 0" }}>
                        {props.loading ? (
                            <Loader />
                        ) : (
                            <React.Fragment>
                                {props.products.map((product: any) => {
                                    const logs = data.filter(
                                        (log: any) => log.productId === product.id,
                                    );
                                    return (
                                        <Row
                                            key={product.id}
                                            style={{ marginBottom: 40 }}>
                                            <Col
                                                md={24}
                                                xs={24}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}>
                                                <h3 style={{ margin: 0 }}>
                                                    {product.title}
                                                </h3>
                                            </Col>
                                            <Col
                                                md={24}
                                                xs={24}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}>
                                                <h2>
                                                    {t("cleanlog.headline", {
                                                        amount: new Intl.NumberFormat(
                                                            props.locale,
                                                        ).format(product.storage),
                                                    })}{" "}
                                                    {org?.unit}
                                                    {/* {product.title} beholdning:{" "}
                                                {product.storage?.toFixed(2)} */}
                                                </h2>
                                            </Col>
                                            <Col md={24}>
                                                <div style={{ textAlign: "right" }}>
                                                    <Button
                                                        type="link"
                                                        onClick={() =>
                                                            setisShowingHistory(
                                                                !isShowingHistory,
                                                            )
                                                        }>
                                                        {isShowingHistory
                                                            ? t(
                                                                  "cleanlog.table.history.hide",
                                                              )
                                                            : t(
                                                                  "cleanlog.table.history.show",
                                                              )}
                                                    </Button>
                                                </div>
                                                <Table
                                                    loading={props.loading}
                                                    scroll={{ x: true }}
                                                    data={logs}
                                                    isShowingHistory={isShowingHistory}
                                                    // removeMember={props.removeMember}
                                                    updateCleanLog={props.updateCleanLog}
                                                    updateOrgProduct={(
                                                        diffAmount: number,
                                                    ) =>
                                                        updateOrgProductFn(
                                                            diffAmount,
                                                            product,
                                                        )
                                                    }
                                                />
                                            </Col>
                                            <Col md={24}>
                                                <AddCleanLogForm
                                                    cleanLogOptions={
                                                        props.cleanLogOptions
                                                    }
                                                    product={product}
                                                />
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </React.Fragment>
                        )}
                    </Content>
                </BlurOverlayContainer>
                <FooterBar />
            </Layout>
        </>
    );
}

export default CleanLogList;

CleanLogList.defaultProps = {
    logs: [],
};
