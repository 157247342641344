import {
    CLEANLOG_CREATE_REQUESTED,
    CLEANLOGS_FETCH_REQUESTED,
    CLEANLOGS_FETCH_SUCCEEDED,
    CLEANLOG_UPDATE_REQUESTED,
} from "./types";

export function fetchCleanLogs({ orgId }: { orgId: string }) {
    return {
        type: CLEANLOGS_FETCH_REQUESTED,
        payload: { orgId },
    };
}
// @ts-ignore
export function fetchCleanLogsSuccess(cleanLogs: firebase.firestore.QuerySnapshot) {
    const logs = cleanLogs.docs.map((log: any) => {
        const { product } = log.data();
        return {
            ...log.data(),
            id: log.id,
            productId: product.id,
            productTitle: product.title,
        };
    });
    return {
        type: CLEANLOGS_FETCH_SUCCEEDED,
        payload: {
            data: logs,
        },
    };
}

export function addCleanLog({ orgId, initials, amount, productId, optionId }: any) {
    return {
        type: CLEANLOG_CREATE_REQUESTED,
        payload: {
            orgId,
            initials,
            amount,
            productId,
            optionId,
        },
    };
}

export function updateCleanLog({ orgId, logId, data }: any) {
    return {
        type: CLEANLOG_UPDATE_REQUESTED,
        payload: {
            orgId,
            logId,
            data,
        },
    };
}
