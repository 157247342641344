export const SENSORS_FETCH_REQUESTED = "SENSORS_FETCH_REQUESTED";
export const SENSORS_FETCH_SUCCEEDED = "SENSORS_FETCH_SUCCEEDED";
export const SENSORS_FETCH_FAILED = "SENSORS_FETCH_FAILED";

export const SENSOR_FETCH_REQUESTED = "SENSOR_FETCH_REQUESTED";
export const SENSOR_FETCH_SUCCEEDED = "SENSOR_FETCH_SUCCEEDED";
export const SENSOR_FETCH_FAILED = "SENSOR_FETCH_FAILED";

export const SENSORLOGS_FETCH_REQUESTED = "SENSORLOGS_FETCH_REQUESTED";
export const SENSORLOGS_FETCH_SUCCEEDED = "SENSORLOGS_FETCH_SUCCEEDED";
export const SENSORLOGS_FETCH_FAILED = "SENSORLOGS_FETCH_FAILED";

export const SENSOR_CREATE_REQUESTED = "SENSOR_CREATE_REQUESTED";
export const SENSOR_CREATE_SUCCEEDED = "SENSOR_CREATE_SUCCEEDED";
export const SENSOR_CREATE_FAILED = "SENSOR_CREATE_FAILED";

export const SENSORMEASURES_FETCH_REQUESTED = "SENSORMEASURES_FETCH_REQUESTED";
export const SENSORMEASURES_FETCH_SUCCEEDED = "SENSORMEASURES_FETCH_SUCCEEDED";
export const SENSORMEASURES_FETCH_FAILED = "SENSORMEASURES_FETCH_FAILED";

export const GPS_SENSORLOGS_FETCH = "GPS_SENSORLOGS_FETCH";
