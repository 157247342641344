import React from "react";
import TableEditCancel from "../../../components/Table/TableEditCancel";
import { useTranslation } from "react-i18next";
import { Unit } from "../../../components/SelectUnit";

function getStyle(status: string) {
    switch (status) {
        case "voided":
            return { color: "red" };

        case "completed":
            return { color: "green" };

        default:
            return {};
    }
}

export function Status({ status }: any) {
    const { t } = useTranslation();
    const statuses = {
        awaiting: t("orders.status_awaiting"),
        processing: t("orders.status_processing"),
        confirmed: t("orders.status_approved"),
        completed: t("orders.status_completed"),
        voided: t("orders.status_cancel"),
    };
    console.log({ statuses });

    const style = getStyle(status);
    //@ts-ignore
    return <span style={style}>{statuses[status]}</span>;
}

export default function columns(
    editingKey: string,
    isEditing: (record: any) => boolean,
    save: (key: string, amount: number, status?: string) => void,
    edit: (record: any) => void,
    cancel: () => void,
    titles: {
        orderTime: string;
        orderAmount: string;
        orderProduct: string;
        orderStatus: string;
    },
    unit: Unit,
) {
    return [
        {
            title: titles.orderTime,
            dataIndex: "createTime",
            // width: 300,
            editable: false,
        },
        {
            title: `${titles.orderAmount} (${unit})`,
            dataIndex: "amount",
            // width: 300,
            editable: false,
        },
        {
            title: titles.orderProduct,
            dataIndex: "productId",
            render: (_: any, record: any) => <span>{record.orderProductTitle}</span>,
            // width: 200,
            editable: false,
        },
        {
            title: titles.orderStatus,
            dataIndex: "status",
            render: (_: any, record: any) => <Status status={record.status} />,

            // width: 200,
            editable: true,
        },
        {
            title: "",
            dataIndex: "operation",
            // width: 100,
            render: (_: any, record: any) => (
                <TableEditCancel
                    record={record}
                    editingKey={editingKey}
                    isEditing={isEditing}
                    save={save}
                    edit={edit}
                    cancel={cancel}
                />
            ),
        },
    ];
}
