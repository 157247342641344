import React, { useEffect } from "react";
import { compose } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import OrderList from "./OrderList";
import {
    fetchOrders,
    updateOrder,
    addOrder,
    deleteOrder,
} from "../../modules/orders/actions";
import { fetchOrgs } from "../../modules/orgs/actions";
import { AppState } from "../../store";

function OrderListContainer(props: any) {
    useEffect(() => {
        // props.fetchOrgs(props.uid);
        props.fetchOrders();

        return () => {};
    }, []);

    return (
        <OrderList
            {...props}
            loading={props.loading}
            addOrder={({ initials, amount, productId }: any) =>
                props.addOrder({ initials, amount, productId })
            }
            updateOrder={({ orderId, data }: any) => props.updateOrder({ orderId, data })}
        />
    );
}

function mapOrders(orders: any, orgs: any) {
    return orders.map((order: any) => ({
        ...order,
        org: orgs[order.orgId] && orgs[order.orgId].title,
    }));
}

function mapStateToProps(state: AppState) {
    return {
        orders: mapOrders(state.orders.list, state.orgs.orgMap),
        loading: state.orders.loading,
        uid: state.user.details.uid,
    };
}

const mapActionsToProps = {
    fetchOrgs,
    fetchOrders,
    updateOrder,
    addOrder,
    deleteOrder,
};

const withConnect = connect(mapStateToProps, mapActionsToProps);

export default compose(withConnect, withRouter)(OrderListContainer);
