import React from "react";
import { useAppSelector } from "../../hooks";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";

export default function AuditTemplateNote() {
    const { t } = useTranslation();
    const templateNote = useAppSelector(
        state => state.auditSchedules.currentAuditSchedule?.templateNote,
    );
    if (templateNote) {
        return (
            <Typography.Paragraph
                ellipsis={{
                    expandable: true,
                    rows: 2,
                    symbol: t("general.readMore"),
                }}>
                {templateNote}
            </Typography.Paragraph>
        );
    }
    return null;
}
