import { call, put, fork, takeLatest } from "redux-saga/effects";
import omit from "lodash.omit";
import firebase, { reduxSagaFirebase } from "../../firebase";
import { fetchOrgsSuccess, fetchOrgSuccess } from "./actions";
import {
    ORG_FETCH_FAILED,
    ORG_FETCH_REQUESTED,
    ORGS_FETCH_FAILED,
    ORGS_FETCH_REQUESTED,
    ORG_CREATE_SUCCEEDED,
    ORG_CREATE_FAILED,
    ORG_CREATE_REQUESTED,
    ORG_DELETE_SUCCEEDED,
    ORG_DELETE_FAILED,
    ORG_DELETE_REQUESTED,
    MEMBERS_UPDATE_FAILED,
    MEMBERS_UPDATE_REQUESTED,
    MEMBER_DELETE_FAILED,
    MEMBER_DELETE_REQUESTED,
    ORG_UPDATE_REQUESTED,
    ORG_UPDATE_FAILED,
    ORG_UPDATE_SUCCEEDED,
} from "./types";
import Message, { Type } from "components/Notification/Message";
import { post } from "general/api";
import { Product } from "../products/types";
import { Member } from "./interfaces";

export const ROOT = "https://us-central1-akstotal-v2.cloudfunctions.net";
// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchOrgs({ payload }: { payload: { uid: string; groupId?: string } }) {
    try {
        if (payload.groupId) {
            yield fork(
                reduxSagaFirebase.firestore.syncCollection,
                firebase
                    .firestore()
                    .collection("orgs")
                    .where(`members.${payload.uid}.uid`, "==", payload.uid)
                    .where("groupId", "==", payload.groupId)
                    .where("isDeleted", "==", false),
                {
                    successActionCreator: fetchOrgsSuccess,
                },
            );
        } else {
            yield fork(
                reduxSagaFirebase.firestore.syncCollection,
                firebase
                    .firestore()
                    .collection("orgs")
                    .where(`members.${payload.uid}.uid`, "==", payload.uid)
                    .where("isDeleted", "==", false),
                {
                    successActionCreator: fetchOrgsSuccess,
                },
            );
        }
    } catch (e: any) {
        yield put({ type: ORGS_FETCH_FAILED, message: e.message });
        Message({
            key: "fetchOrgs",
            type: Type.ERROR,
            message: `Der skete en fejl`,
            description: `Kunne ikke loade virksomhederne, prøv venligst igen`,
        });
    }
}

export function* fetchOrgsSaga() {
    // @ts-ignore
    yield takeLatest(ORGS_FETCH_REQUESTED, fetchOrgs);
}

function addOrganization({
    title,
    members,
    products,
    area,
    storageAlert,
    unit,
    groupId,
}: {
    title: string;
    members: { [id: string]: Member };
    products: Product[];
    area: string;
    storageAlert: number;
    unit: string;
    groupId: string | null;
}) {
    return post(`addOrg`, {
        title,
        members,
        products,
        area,
        storageAlert,
        unit,
        groupId,
    });
}

function* addOrg({
    payload,
}: {
    payload: {
        uid: string;
        email: string;
        title: string;
        members: { [id: string]: Member };
        products: Product[];
        area: string;
        storageAlert: number;
        unit: string;
        groupId: string | null;
    };
}) {
    const messageKey = "addOrg";
    try {
        Message({
            type: Type.LOADING,
            key: messageKey,
            message: `Loading...`,
            // description: `${payload.title} blev oprettet og kan nu søges frem i listen her`,
        });
        // @ts-ignore
        yield call(addOrganization, {
            title: payload.title,
            // isDeleted: false,
            // createTime: new Date(),
            area: payload.area,
            storageAlert: payload.storageAlert,
            unit: payload.unit,
            products: payload.products,
            groupId: payload.groupId,
            members: {
                [payload.uid]: {
                    uid: payload.uid,
                    email: payload.email,
                    role: "admin",
                },
            },
        });
        yield put({ type: ORG_CREATE_SUCCEEDED });
        Message({
            key: messageKey,
            message: `${payload.title} oprettet`,
            description: `${payload.title} blev oprettet og kan nu søges frem i listen her`,
        });
    } catch (e: any) {
        yield put({ type: ORG_CREATE_FAILED, message: e.message });
        Message({
            key: messageKey,
            type: Type.ERROR,
            message: `Der skete en fejl`,
            description: `${payload.title} blev ikke oprettet, prøv venligst igen`,
        });
    }
}

export function* addOrgSaga() {
    // @ts-ignore
    yield takeLatest(ORG_CREATE_REQUESTED, addOrg);
}

function* deleteOrg(action: any) {
    const messageKey = "deleteOrg";
    try {
        yield call(reduxSagaFirebase.firestore.updateDocument, `orgs/${action.payload}`, {
            isDeleted: true,
        });
        yield put({ type: ORG_DELETE_SUCCEEDED });
        Message({
            key: messageKey,
            message: `Virksomheden blev slettet`,
            description: `Virksomheden blev slettet og er ikke længere i listen`,
        });
    } catch (e: any) {
        yield put({ type: ORG_DELETE_FAILED, message: e.message });
        Message({
            key: messageKey,
            type: Type.ERROR,
            message: `Der skete en fejl`,
            description: `Virksomheden blev ikke slettet, prøv venligst igen`,
        });
    }
}

export function* deleteOrgSaga() {
    yield takeLatest(ORG_DELETE_REQUESTED, deleteOrg);
}

function* fetchOrg({ payload }: { payload: { orgId: string } }) {
    try {
        yield fork(
            reduxSagaFirebase.firestore.syncDocument,
            firebase.firestore().collection("orgs").doc(payload.orgId),
            {
                successActionCreator: fetchOrgSuccess,
            },
        );
    } catch (e: any) {
        yield put({ type: ORG_FETCH_FAILED, message: e.message });
        Message({
            key: "fetchOrg",
            type: Type.ERROR,
            message: `Der skete en fejl`,
            description: `Virksomheden kunne ikke loades, prøv venligst igen`,
        });
    }
}

export function* fetchOrgSaga() {
    // @ts-ignore
    yield takeLatest(ORG_FETCH_REQUESTED, fetchOrg);
}
// TODO: replace with fetch
// function checkUser(email, password) {
//     return axios.post(
//         `${ROOT}/assignUser`,
//         qs.stringify({
//             email,
//             password,
//         }),
//     );
// }

// function* addMember({ payload }) {
//     const { orgId, email, password, role, name } = payload;
//     try {
//         const { data } = yield call(checkUser, email, password);
//         const result = yield call(updateMembers, {
//             payload: {
//                 orgId,
//                 member: { [data.uid]: { email, role, uid: data.uid, name } },
//             },
//         });
//         Message({
//             message: `${name} blev tilføjet`,
//             description: `${name} blev tilføjet og kan nu findes i listen`,
//         });
//     } catch (e) {
//         yield put({ type: MEMBER_ADD_FAILED, message: e.message });
//         Message({
//             type: Type.ERROR,
//             message: `Der skete en fejl`,
//             description: `${name} blev ikke tilføjet, prøv venligst igen`,
//         });
//     }
// }

// export function* addMemberSaga() {
//     yield takeLatest(MEMBER_ADD_REQUESTED, addMember);
// }

function* updateMembers({ payload }: { payload: { orgId: string; member: Member } }) {
    const messageKey = "updateMembers";
    try {
        yield fork(
            reduxSagaFirebase.firestore.setDocument,
            `orgs/${payload.orgId}`,
            {
                members: payload.member,
            },
            { merge: true },
        );
        Message({
            key: messageKey,
            message: `Brugeren opdateret`,
            description: `Brugeren blev opdateret korrekt`,
        });
    } catch (e: any) {
        yield put({ type: MEMBERS_UPDATE_FAILED, message: e.message });
        Message({
            key: messageKey,
            type: Type.ERROR,
            message: `Der skete en fejl`,
            description: `Brugere blev ikke opdateret, prøv venligst igen`,
        });
    }
}

export function* updateMembersSaga() {
    // @ts-ignore
    yield takeLatest(MEMBERS_UPDATE_REQUESTED, updateMembers);
}

function* removeMember({
    payload,
}: {
    payload: { orgId: string; members: { [id: string]: Member }; memberId: string };
}) {
    const messageKey = "removeMember";
    const members = omit(payload.members, [payload.memberId]);
    try {
        yield call(reduxSagaFirebase.firestore.updateDocument, `orgs/${payload.orgId}`, {
            members,
        });
        Message({
            key: messageKey,
            message: `Brugeren blev fjernet`,
            description: `Brugeren blev fjernet og har ikke længere adgang`,
        });
    } catch (e: any) {
        yield put({ type: MEMBER_DELETE_FAILED, message: e.message });
        Message({
            key: messageKey,
            type: Type.ERROR,
            message: `Der skete en fejl`,
            description: `Brugeren blev ikke fjernet, prøv venligst igen`,
        });
    }
}

export function* removeMemberSaga() {
    // @ts-ignore
    yield takeLatest(MEMBER_DELETE_REQUESTED, removeMember);
}

function* updateOrg({ payload }: { payload: { orgId: string; data: any } }) {
    const messageKey = "updateOrg";
    try {
        yield call(reduxSagaFirebase.firestore.updateDocument, `orgs/${payload.orgId}`, {
            ...payload.data,
        });
        yield put({ type: ORG_UPDATE_SUCCEEDED });
        payload.data?.hasOwnProperty("isDeleted")
            ? Message({
                  key: messageKey,
                  message: `Virksomhed fjernet`,
                  description: `Virksomheden blev fjernet korrekt`,
              })
            : Message({
                  key: messageKey,
                  message: `Virksomhed opdateret`,
                  description: `Virksomheden blev opdateret korrekt`,
              });
    } catch (e: any) {
        yield put({ type: ORG_UPDATE_FAILED, message: e.message });
        Message({
            key: messageKey,
            type: Type.ERROR,
            message: `Der skete en fejl`,
            description: `Virksomheden blev ikke oprettet korrekt, prøv venligst igen`,
        });
    }
}

export function* updateOrgSaga() {
    // @ts-ignore
    yield takeLatest(ORG_UPDATE_REQUESTED, updateOrg);
}
