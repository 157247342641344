import React, { useEffect, useState } from "react";
import {
    Drawer,
    Col,
    Row,
    Input,
    Typography,
    List,
    Form,
    Select,
    Button,
    Tag,
    InputNumber,
    Space,
    DatePicker,
} from "antd";
import { AppState } from "../../store";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { getStatusColor, StatusMap } from "./Table";
import Message, { Type } from "components/Notification/Message";
import firebase from "firebase/compat/app";
import { TagStatus } from "./Table";
import { Checkbox } from "antd";
import { AppProducts } from "../../hooks/useAppProducts";
import { AppBilling } from "../../hooks/useOrgAppBillings";
import TextArea from "antd/lib/input/TextArea";
import dayjs, { Dayjs } from "dayjs";
const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
    wrapperCol: { span: 24 },
};

const tagOptions: TagStatus[] = ["overdue", "paid", "pending", "unpaid"];

export default function DrawerForm({
    appBilling,
    appProducts,
    closeDrawer,
}: {
    appBilling: AppBilling;
    appProducts: AppProducts[];
    closeDrawer: VoidFunction;
}) {
    const [loading, setloading] = useState(false);
    const [form] = Form.useForm();
    const nameValue = Form.useWatch("products", form);
    console.log({ nameValue });

    const ids = useAppSelector((state: AppState) => state.drawer.billing);

    // @ts-ignore

    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        form.validateFields();
        return () => {};
    }, []);

    const onFinish = async (values: {
        products: {
            product: string;
            price: number;
            recurring: boolean;
            paid?: boolean;
        }[];
        lastPayDate: Dayjs | null;
        nextPayDate: Dayjs;
        status: TagStatus;
        note: string;
    }) => {
        // remove key as it will not be store like this

        if (!ids?.orgId || !ids?.billingId) return alert("something went wrong");
        if (!values.products || values.products?.length < 1) {
            return alert("Need at least one product");
        }
        try {
            setloading(true);
            Message({
                key: "addAppProduct",
                message: "Loading...",
                // description: "Sensoren blev tilføjet korrekt",
                type: Type.LOADING,
            });

            await firebase
                .firestore()
                .collection("appBilling")
                .doc(ids?.billingId)
                .set({
                    org: {
                        id: appBilling.org.id,
                        title: appBilling.org.title,
                    },
                    products: values.products.map(p => ({
                        id: p.product,
                        name: appProducts.find(ap => ap.id === p.product)?.name,
                        price: p.price,
                        recurring: p.recurring,
                        paid: p.paid || null,
                    })),
                    lastPayDate: values.lastPayDate?.toDate() || null,
                    nextPayDate: values.nextPayDate?.toDate(),
                    status: values.status,
                    createTime: new Date(),
                    updateTime: new Date(),
                    note: values.note || "",
                });
            await firebase
                .firestore()
                .collection("appBilling")
                .doc(ids?.billingId)
                .collection("history")
                .add({
                    org: {
                        id: appBilling.org.id,
                        title: appBilling.org.title,
                    },
                    products: values.products.map(p => ({
                        id: p.product,
                        name: appProducts.find(ap => ap.id === p.product)?.name,
                        price: p.price,
                        recurring: p.recurring,
                        paid: p.paid || null,
                    })),
                    lastPayDate: values.lastPayDate?.toDate() || null,
                    nextPayDate: values.nextPayDate?.toDate(),
                    status: values.status,
                    createTime: new Date(),
                    updateTime: new Date(),
                    note: values.note || "",
                });

            closeDrawer();
            form.resetFields();
            Message({
                key: "addAppProduct",
                message: `Alt blev tilføjet korrekt`,
            });
        } catch (error) {
            console.error(error);
            Message({
                key: "addAppProduct",
                type: Type.ERROR,
                message: `Der skete en fejl`,
                description: `Kunne ikke oprette Abonnement, prøv venligst igen`,
            });
        } finally {
            setloading(false);
        }
    };

    return (
        <Row gutter={16}>
            <Col span={24}>
                <Typography.Title level={3}>{t("Produkter")}</Typography.Title>
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        nextPayDate: dayjs(appBilling.nextPayDate),
                        lastPayDate: !appBilling.lastPayDate
                            ? undefined
                            : dayjs(appBilling.lastPayDate),
                        status: appBilling.status,

                        products: appBilling.products.map(p => ({
                            product: p.id,
                            price: p.price,
                            recurring: p.recurring,
                            paid: p.paid || null,
                        })),
                        note: appBilling.note,
                    }}>
                    {/* <FormItem
                        {...formItemLayout}
                        name="serial"
                        rules={[{ required: true, message: "Please input serial!" }]}>
                        <Input type="text" placeholder={t("inventory.ipad.serial")} />
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        name="model"
                        rules={[{ required: true, message: "Please input model!" }]}>
                        <Input type="text" placeholder={t("inventory.ipad.model")} />
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        name="name"
                        rules={[{ required: true, message: "Please input name!" }]}>
                        <Input type="text" placeholder={t("inventory.ipad.name")} />
                    </FormItem> */}
                    <Form.List name="products">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => {
                                    console.log({ name, key, restField });

                                    return (
                                        <Space
                                            key={key}
                                            style={{
                                                display: "flex",
                                                marginBottom: 8,
                                                width: "100%",
                                            }}
                                            align="baseline">
                                            <Form.Item
                                                {...restField}
                                                style={{ minWidth: "150px" }}
                                                name={[name, "product"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Vælg produkt",
                                                    },
                                                ]}>
                                                <Select placeholder="Select product">
                                                    {appProducts.map(product => {
                                                        return (
                                                            <Option value={product.id}>
                                                                {product.name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                {...restField}
                                                style={{ minWidth: "150px" }}
                                                name={[name, "price"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Vælg pris",
                                                    },
                                                ]}>
                                                <InputNumber
                                                    min={0}
                                                    placeholder={"Pris"} //t("inventory.ipad.sim")}
                                                />
                                            </Form.Item>
                                            {nameValue?.[key]?.recurring === false && (
                                                <Form.Item
                                                    {...restField}
                                                    // label="Genta"
                                                    name={[name, "paid"]}
                                                    valuePropName="checked">
                                                    <Checkbox>Betalt</Checkbox>
                                                </Form.Item>
                                            )}
                                            <Form.Item
                                                {...restField}
                                                // label="Genta"
                                                name={[name, "recurring"]}
                                                valuePropName="checked">
                                                <Checkbox>Gentages</Checkbox>
                                            </Form.Item>
                                            <MinusCircleOutlined
                                                onClick={() => remove(name)}
                                            />
                                        </Space>
                                    );
                                })}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add({ recurring: true })}
                                        block
                                        icon={<PlusOutlined />}>
                                        Tilføj produkt
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>

                    {/* <FormItem
                        {...formItemLayout}
                        style={{ minWidth: "150px" }}
                        name="product"
                        rules={[{ required: true, message: "Please select a product!" }]}>
                        <Select placeholder="Select product">
                            {appProducts.map(product => {
                                return <Option value={product.id}>{product.name}</Option>;
                            })}
                        </Select>
                    </FormItem> */}
                    {/* <FormItem
                        {...formItemLayout}
                        name="price"
                        rules={[{ required: true, message: "Please input price!" }]}>
                        <InputNumber
                            type="text"
                            min={0}
                            placeholder={"Pris"} //t("inventory.ipad.sim")}
                        />
                    </FormItem> */}
                    <FormItem
                        {...formItemLayout}
                        style={{ minWidth: "150px" }}
                        name="lastPayDate"
                        label="Seneste betalingsdato (kan være blank)"
                        rules={[
                            {
                                required: false,
                                message: "Vælg næste betalingsdato!",
                            },
                        ]}>
                        <DatePicker />
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label="Betalings status"
                        style={{ minWidth: "150px" }}
                        name="status"
                        rules={[{ required: true, message: "Vælg status" }]}>
                        <Select placeholder="Vælg status">
                            {tagOptions.map(tag => {
                                const color = getStatusColor(tag);
                                return (
                                    <Option value={tag}>
                                        <Tag color={color}>{StatusMap[tag]}</Tag>
                                    </Option>
                                );
                            })}
                        </Select>
                    </FormItem>

                    <FormItem
                        {...formItemLayout}
                        style={{ minWidth: "150px" }}
                        name="nextPayDate"
                        label="Næste betalingsdato"
                        rules={[
                            {
                                required: true,
                                message: "Vælg næste betalingsdato!",
                            },
                        ]}>
                        <DatePicker />
                    </FormItem>

                    <FormItem
                        {...formItemLayout}
                        style={{ minWidth: "150px" }}
                        name="note">
                        <TextArea rows={4} placeholder={t("Note")} />
                    </FormItem>

                    <FormItem>
                        <Button type="primary" block htmlType="submit" loading={loading}>
                            Gem
                        </Button>
                    </FormItem>
                </Form>
                {error && <div style={{ color: "red" }}>{error}</div>}
            </Col>
        </Row>
    );
}
