import { Unit } from "components/SelectUnit";

export enum Roles {
    EXTERNAL = "external",
    CLEANER = "cleaner",
    DEPARTMENT_EXECUTIVE = "departmentExecutive",
    SALES = "sales",
    ADMIN = "admin",
}

export interface Member {
    [id: string]: {
        uid: string;
        role: Roles;
        name?: string;
        email: string;
        phone?: string;
    };
}
export interface IOrg {
    id: string;
    title: string;
    logo: string;
    groupId?: string;
    subGroup?: { id: string; title: string };
    // @ts-ignore
    createTime: firebase.firestore.Timestamp;
    members: Member;

    status: OrgStatus;
    unit: Unit;
    storageAlert: number;
}

export enum OrgStatus {
    DEFAULT = 0,
    IN_PROGRESS = 1,
    ACTIVE = 2,
    INACTIVE = 3,
}

export const OrgStatusText = {
    [OrgStatus.DEFAULT]: "org.state.default",
    [OrgStatus.IN_PROGRESS]: "org.state.in_progress",
    [OrgStatus.ACTIVE]: "org.state.active",
    [OrgStatus.INACTIVE]: "org.state.inactive",
};
