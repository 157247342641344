import { AppState } from "../../store";

interface Stats {
    none: number;
    progress: number;
    active: number;
    inactive: number;
}

export const selectOrgsStatusCount = (state: AppState) =>
    state.orgs.orgs.reduce(
        (acc: Stats, org: { status: number }) => {
            if (org.status === 1) {
                return {
                    ...acc,
                    progress: acc.progress + 1,
                };
            }
            if (org.status === 2) {
                return {
                    ...acc,
                    active: acc.active + 1,
                };
            }
            if (org.status === 3) {
                return {
                    ...acc,
                    inactive: acc.inactive + 1,
                };
            }
            return {
                ...acc,
                none: acc.none + 1,
            };
        },
        {
            none: 0,
            progress: 0,
            active: 0,
            inactive: 0,
        },
    );
