import { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import Overview from "./Overview";
import { fetchCompletedIssues } from "../../../modules/issues/actions";
import { useAppSelector } from "../../../hooks";
import { fetchCategories } from "modules/categories/actions";
import {
    addWeeks,
    endOfMonth,
    endOfYear,
    startOfMonth,
    startOfYear,
    subWeeks,
} from "date-fns";

function OverviewContainer() {
    const dispatch = useDispatch();
    const { orgId } = useParams<{ orgId: string }>();
    const initDate = useAppSelector(state => state.issues.calendarDate);
    const { issues, loading } = useAppSelector(state => ({
        issues: state.issues.issuesCompleted,
        loading: state.issues.loading,
    }));

    const { fromDate, toDate } = useAppSelector(state => {
        const cDate = state.issues.calendarDate;
        const cMode = state.issues.calendarMode;
        if (cMode === "month") {
            return {
                fromDate: startOfMonth(cDate).getTime(),
                toDate: endOfMonth(cDate).getTime(),
            };
        }
        if (cMode === "year") {
            return {
                fromDate: startOfYear(cDate).getTime(),
                toDate: endOfYear(cDate).getTime(),
            };
        }
        return {
            fromDate: startOfMonth(cDate).getTime(),
            toDate: endOfMonth(cDate).getTime(),
        };
    });

    const options = useAppSelector(state =>
        (state.categories.list || [])
            .sort((a, b) =>
                a.title.localeCompare(b.title, "da", {
                    sensitivity: "base",
                    numeric: true,
                }),
            )
            .map(category => ({
                value: category.id,
                label: category.title,
            })),
    );

    useEffect(() => {
        dispatch(
            fetchCompletedIssues({
                orgId,
                fromDate: subWeeks(new Date(fromDate), 1),
                toDate: addWeeks(new Date(toDate), 1),
            }),
        );
        dispatch(fetchCategories({ orgId }));

        return () => {};
    }, [orgId, dispatch, toDate, fromDate]);

    return (
        <Overview
            orgId={orgId}
            loading={loading}
            issues={issues}
            initialDate={initDate}
            options={options}
        />
    );
}

export default OverviewContainer;
