import { InventoryItem, InventoryType } from "../types";

export const PUMP_INVENTORY_FETCH_REQUESTED = "PUMP_INVENTORY_FETCH_REQUESTED";
export const PUMP_INVENTORY_FETCH_SUCCEEDED = "PUMP_INVENTORY_FETCH_SUCCEEDED";
export const PUMP_INVENTORY_FETCH_FAILED = "PUMP_INVENTORY_FETCH_FAILED";

export interface FetchPumpInventorySuccess {
    type: typeof PUMP_INVENTORY_FETCH_SUCCEEDED;
    payload: PumpItem[];
}
export interface FetchPumpInventory {
    type: typeof PUMP_INVENTORY_FETCH_REQUESTED;
    payload: {};
}
export interface FetchPumpInventoryFailed {
    type: typeof PUMP_INVENTORY_FETCH_FAILED;
    payload: {};
}

export interface PumpItem extends InventoryItem {
    serial: string;
    sim: string;
    type: InventoryType.IPAD;
    orgId?: string;
    orgTitle?: string;
}
