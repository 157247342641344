import {
    FETCH_GROUPS_REQUESTED,
    DBGroupShape,
    FETCH_GROUPS_SUCCEEDED,
    FETCH_GROUPS_FAILED,
} from "./types";

export function fetchGroups() {
    return {
        type: FETCH_GROUPS_REQUESTED,
        payload: {},
    };
}

export function fetchGroupsSuccess(groups: DBGroupShape[]): {
    type: string;
    payload: DBGroupShape[];
} {
    return {
        type: FETCH_GROUPS_SUCCEEDED,
        payload: groups,
    };
}

export function fetchGroupsFailed() {
    return {
        type: FETCH_GROUPS_FAILED,
        payload: {},
    };
}
