import React, { useEffect, useState } from "react";
import { Drawer, Form, Button, Popconfirm } from "antd";
import { AppState } from "../../store";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { closeBillingDrawer } from "../../modules/drawer/actions";
import useAppBilling from "../../hooks/useAppBilling";
import useAppProducts from "../../hooks/useAppProducts";
import DrawerForm from "./DrawerForm";
import firebase from "firebase/compat/app";

export default function DrawerBilling() {
    const [form] = Form.useForm();
    const isVisible = useAppSelector((state: AppState) => state.drawer.billing);
    const ids = useAppSelector((state: AppState) => state.drawer.billing);
    console.log({ ids, isVisible });
    const { appProducts } = useAppProducts();
    // @ts-ignore
    const { appBilling } = useAppBilling({ billingId: ids?.billingId });
    console.log(appBilling);
    const dispatch = useAppDispatch();

    useEffect(() => {
        form.validateFields();
        return () => {};
    }, []);
    const closeDrawer = () => {
        dispatch(closeBillingDrawer());
    };
    const onDelete = async () => {
        try {
            await firebase
                .firestore()
                .collection("appBilling")
                .doc(ids?.billingId)
                .delete();
            closeDrawer();
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <Drawer
            title={"Abonnement"}
            width={720}
            onClose={closeDrawer}
            open={Boolean(isVisible)}
            bodyStyle={{ paddingBottom: 80 }}
            extra={
                ids?.billingId && (
                    <Popconfirm
                        title={"Er du sikker på at du vil slette dette abonnement?"}
                        onConfirm={onDelete}
                        okText={"Slet"}
                        cancelText={"Annuler"}>
                        <Button>Slet</Button>
                    </Popconfirm>
                )
            }>
            {appBilling && (
                <DrawerForm
                    key={ids?.billingId}
                    appBilling={appBilling}
                    appProducts={appProducts}
                    closeDrawer={closeDrawer}
                />
            )}
        </Drawer>
    );
}
