import React, { useState } from "react";
import { Upload, Button, message, Modal } from "antd";
import {
    DeleteOutlined,
    ExclamationCircleOutlined,
    LoadingOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import firebase from "../firebase";
import { getContentTypeIconMap } from "Views/Reports/Report";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import confirmModal from "../general/confirmModal";
import useGetDownloadUrl from "hooks/useGetDownloadUrl";
import { ContentType } from "general/enums";

export function isContentImage(contentType: ContentType) {
    return [
        ContentType.IMAGE_JPEG,
        ContentType.IMAGE_JPG,
        ContentType.IMAGE_PNG,
    ].includes(contentType);
}

interface Props {
    files: any[];
    orgId: string;
    contextId: string;
    context: string;
    subContextId?: string;
    deleteFile: (fileId: string) => void;
}

function PicturesWall(props: Props) {
    console.log({ props });
    const [preview, setPreview] = useState<{
        previewVisible: boolean;
        previewImage?: string;
    }>({ previewVisible: false, previewImage: "" });
    const [uploading, setUploading] = useState(false);

    const handleCancel = () => setPreview({ ...preview, previewVisible: false });

    const handlePreview = (file: UploadFile<any>) => {
        setPreview({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    };

    const beforeUpload = (file: RcFile) => {
        setUploading(true);
        firebase
            .storage()
            .ref("orgs/" + props.orgId + "/" + props.context)
            // @ts-ignore
            .putFiles(
                [file],
                props.orgId,
                props.contextId,
                props.context,
                props.subContextId,
            )
            .then(function (metadatas: any[]) {
                // Get an array of file metadata
                const stats = {
                    files: metadatas.map(file => file.metadata.name),
                };

                // firebase.firestore().collection(`orgs/${'test'}/issues`).doc('issueId').set(stats)
                // self.setState({
                //     uploading: false,
                // });
                message.success("Fil uploadet");
                return true;
            })
            .catch(function (error: any) {
                console.log(error);
                // self.setState({
                //     uploading: false,
                // });
                return false;
            })
            .finally(function () {
                setUploading(false);
            });
    };

    // const customRequest = () => console.log("lol");
    // render() {
    const { previewVisible, previewImage } = preview;
    const uploadButton = (
        <div>
            {uploading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="ant-upload-text">Upload</div>
        </div>
    );
    const confirmDelete = (fileId: string) => {
        confirmModal({
            title: "Er du sikker på du vil slette billedet?",
            onOk: () => {
                props.deleteFile(fileId);
            },
        });
    };

    console.log({ files: props.files });
    return (
        <div className="clearfix">
            <Upload
                // customRequest={() => console.log("UPLOADING")}
                listType="picture-card"
                fileList={props.files}
                onPreview={handlePreview}
                // onChange={handleChange}
                beforeUpload={beforeUpload}
                onRemove={file => confirmDelete(file.uid)}
                itemRender={(originNode, file: any, currFileList) => {
                    console.log({ fileType: file.contentType });
                    const icon = getContentTypeIconMap(
                        file?.contentType,
                        file?.thumbFilePath,
                        {
                            height: 100,
                            width: isContentImage(file.contentType) ? 100 : 50,
                            // marginTop: 20,
                            borderRadius: 8,
                            fontSize: 55,
                        },
                    );
                    console.log({ originNode, file, currFileList });
                    // if (file.contentType?.startsWith("image/")) {
                    //     return originNode; //<Image width={50} src={file.url} />;
                    // }
                    return (
                        <div
                            className="picture-wall-card-issue"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                borderRadius: 8,
                                border: "1px solid #d9d9d9",
                                position: "relative",
                                height: "100%",
                            }}>
                            <Button
                                style={{
                                    position: "absolute",
                                    zIndex: 2,
                                    top: 0,
                                    right: 0,
                                }}
                                danger
                                // type="primary"
                                size="small"
                                // shape="circle"7
                                icon={<DeleteOutlined />}
                                onClick={() => confirmDelete(file.uid)}
                            />

                            <ViewFile icon={icon} filePath={file.filePath} />
                        </div>
                    );
                }}>
                {uploadButton}
            </Upload>
            <Modal open={previewVisible} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
        </div>
    );
}

function ViewFile({ icon, filePath }: { icon: any; filePath: string }) {
    console.log({ filePath });
    const { fileUrl } = useGetDownloadUrl(filePath);
    console.log({ icon, fileUrl });
    if (!fileUrl) return null;
    return (
        <a
            // style={{ background: "#333", opacity: 0.5 }}
            href={fileUrl}
            target="_blank">
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                {icon}
            </div>
            <Button
                type="link"
                style={{
                    position: "absolute",
                    textAlign: "center",
                    bottom: 30,
                    left: 0,
                    right: 0,
                    zIndex: 100,
                    color: "white",
                    fontWeight: "bold",

                    // margin: "auto",
                }}>
                Åbn fil
            </Button>
        </a>
    );
}

export default PicturesWall;
