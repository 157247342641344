import React, { useMemo } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import interactionPlugin from "@fullcalendar/interaction";
import daLocale from "@fullcalendar/core/locales/da";
import { DateSelectArg, EventClickArg, EventContentArg } from "@fullcalendar/core";
import { AuditSchedule } from "modules/auditSchedule/types";
import { Badge, Tooltip } from "antd";
import {
    TaskStatus,
    getBadgeColorStatus,
    getDarkColorStatus,
    getLightColorStatus,
    getTextColorStatus,
} from "general/colors";
import "./AuditCalendar.css";
import { setAuditCalendar } from "modules/auditSchedule/actions";
import { useAppDispatch, useAppSelector } from "hooks";
import { HistoryOutlined } from "@ant-design/icons";
import { getScheduleByEnum } from "../scheduleHelpers";
import { useTranslation } from "react-i18next";
import { RecurringOptions } from "general/enums";
import { convertAuditScheduleToEventApi } from "./helpers";

export default function AuditCalendar({
    auditSchedules,
    addAuditSchedule,
    editAuditSchedule,
}: {
    auditSchedules: AuditSchedule[];
    addAuditSchedule: (date: Date) => void;
    editAuditSchedule: (id: string) => void;
}) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const auditSchedulesMap = useAppSelector(
        state => state.auditSchedules.auditSchedulesMap,
    );
    const calendarMode = useAppSelector(state => state.auditSchedules.calendarMode);
    const intl = useAppSelector(state => state.user.userDoc.intl);

    const today = new Date();
    const evnt = useMemo(
        () => convertAuditScheduleToEventApi(auditSchedules),
        [auditSchedules],
    );

    function handleDateSelect(selectInfo: DateSelectArg) {
        addAuditSchedule(selectInfo.start);
    }
    function handleEventClick(clickInfo: EventClickArg) {
        editAuditSchedule(clickInfo.event.id);
    }

    // This function is called whenever the view or the visible date range changes.
    const handleDatesSet = (arg: any) => {
        // Update the current view and date
        const mode = arg.view.type;
        dispatch(setAuditCalendar({ dateStart: arg.start, dateEnd: arg.end, mode }));

        // Perform any additional logic you need when the view or date range changes
        console.log(`View changed to: ${arg.view.type}`);
        console.log(
            `Date range is now: ${arg.start.toISOString()} to ${arg.end.toISOString()}`,
        );
    };

    function renderEventContent(eventInfo: EventContentArg) {
        const status = eventInfo.backgroundColor as TaskStatus; //getTaskStatus({ item, isDelayed, startDate });
        const lightColor = getLightColorStatus(status);
        const darkColor = getDarkColorStatus(status);
        const textColor = getTextColorStatus(status);
        const badgeColor = getBadgeColorStatus(status);
        const audit = auditSchedulesMap[eventInfo.event.id];

        return (
            <Tooltip title={eventInfo.event.title}>
                <div
                    // className={"audit-multiday"}
                    style={{
                        backgroundColor: lightColor,
                        width: "100%",
                        padding: "2px 4px",
                        borderRadius: 4,
                        color: textColor,
                        borderLeft: `4px solid ${darkColor}`,
                        borderRight: `4px solid ${darkColor}`,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}>
                    {audit?.recurringOption !== RecurringOptions.NEVER && (
                        <Tooltip
                            title={
                                <>
                                    {t("audit.schedule.repeatlabel")}
                                    {": "}
                                    {getScheduleByEnum(
                                        audit?.recurringOption,
                                        audit?.dueDate?.toDate(),
                                        intl,
                                    )}
                                </>
                            }>
                            <HistoryOutlined style={{ marginRight: 4 }} />
                        </Tooltip>
                    )}
                    <Badge style={{ marginTop: -2 }} color={badgeColor} />
                    <span style={{ marginLeft: 4 }}>{eventInfo.event.title}</span>

                    {/* <b style={{ color: "red" }}>{eventInfo.timeText}</b> */}
                    {/* <i style={{ color: "red" }}>{eventInfo.event.title}</i> */}
                </div>
            </Tooltip>
        );
    }

    return (
        <FullCalendar
            plugins={[multiMonthPlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
                left: "title",
                center: "",
                right: "today dayGridMonth,multiMonthYear prev,next",
            }}
            locale={daLocale}
            initialView={calendarMode}
            initialDate={today}
            editable={false}
            selectable={true}
            // @ts-ignore
            events={evnt}
            weekNumbers
            firstDay={1}
            // dateClick={handleDateSelect}
            datesSet={handleDatesSet} // Set the datesSet callback
            selectMirror={false}
            dayMaxEvents={true}
            // weekends={weekendsVisible}
            // initialEvents={currentEvents} // alternatively, use the `events` setting to fetch from a feed
            // @ts-ignore
            select={handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={handleEventClick}
            // eventsSet={handleEvents} // called after events are initialized/added/changed/removed
            /* you can update a remote database when these fire:
                eventAdd={function(){}}
                eventChange={function(){}}
                eventRemove={function(){}}
            */
        />
    );
}
