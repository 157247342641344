import React from "react";
import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export enum Type {
    SUCCESS = "success",
    INFO = "info",
    WARNING = "warning",
    ERROR = "error",
    LOADING = "loading",
}

interface Props {
    key: string;
    message: string;
    description?: string;
    type?: Type;
}

export default ({ key, message, description, type = Type.SUCCESS }: Props) => {
    if (type === Type.LOADING) {
        return notification.open({
            key,
            message,
            description,
            icon: <LoadingOutlined style={{ fontSize: 24 }} spin />,
        });
    }
    return notification[type]({
        key,
        message,
        description,
    });
};
