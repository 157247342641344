import React from "react";
import TableEditCancel from "../../../components/Table/TableEditCancel";
import { t } from "i18next";

export default function columns() {
    // editingKey: string,
    // isEditing: (record: any) => boolean,
    // save: (key: string, amount: number) => void,
    // edit: (record: any) => void,
    // cancel: () => void,
    return [
        {
            title: t("hourLogs.table.time"),
            dataIndex: "createTime",
            editable: false,
        },

        {
            // TODO: use actual unit here
            title: t("hourLogs.table.count"),
            dataIndex: "count",
            editable: true,
        },
        // {
        //     title: t("cleanlog.table.action"),
        //     dataIndex: "operation",
        //     render: (_: any, record: any) => (
        //         <TableEditCancel
        //             record={record}
        //             editingKey={editingKey}
        //             isEditing={isEditing}
        //             save={save}
        //             edit={edit}
        //             cancel={cancel}
        //         />
        //     ),
        // },
    ];
}
