import React from "react";
import { Button, Card, Dropdown, Menu, Space, Typography } from "antd";
import { DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Meta from "antd/lib/card/Meta";
import confirmModal from "../../general/confirmModal";
import { DownloadReport, OpenFileInBrowser } from "Views/Reports/ListItems";
import { ContentType } from "general/enums";

const { Paragraph } = Typography;

interface Props {
    icon: any;
    fileName: string;
    title: string;
    path: string;
    removeAuditTemplateFile?: any;
    contentType: ContentType;
    small?: boolean;
}

export default function FileCard({
    icon,
    fileName,
    title,
    path,
    removeAuditTemplateFile,
    contentType,
    small,
}: Props) {
    const { t } = useTranslation();
    const confirmDelete = () => {
        confirmModal({
            title: t("Er du sikker på du vil slette denne fil?"),
            onOk: removeAuditTemplateFile,
        });
    };
    console.log({ path });
    const menu = (
        <Menu>
            <Menu.Item>
                <DownloadReport
                    path={path}
                    fileName={fileName}
                    title={title}
                    contentType={contentType}
                />
            </Menu.Item>
            <Menu.Item>
                <OpenFileInBrowser
                    path={path}
                    title={title}
                    fileName={fileName}
                    contentType={contentType}
                />
            </Menu.Item>

            {removeAuditTemplateFile && (
                <Menu.Item key={`delete`} onClick={confirmDelete}>
                    <div style={{ color: "red" }}>
                        <DeleteOutlined /> {t("reports.file_menu_delete")}
                    </div>
                </Menu.Item>
            )}
        </Menu>
    );
    if (small) {
        return (
            <Dropdown.Button
                style={{ width: "100%" }}
                buttonsRender={([_, rightButton]) => [
                    // <Button
                    //     style={{ display: "flex", alignItems: "center" }}
                    //     icon={getContentTypeIconOnlyMap(contentType, {
                    //         // marginTop: 20,
                    //         fontSize: 18,
                    //     })}>
                    <OpenFileInBrowser
                        path={path}
                        title={title}
                        buttonType
                        fileName={fileName}
                        contentType={contentType}
                    />,
                    // </Button>,
                    React.cloneElement(rightButton as React.ReactElement<any, string>),
                ]}
                dropdownRender={() => menu}>
                Actions
            </Dropdown.Button>
        );
    }
    return (
        <Card
            cover={icon}
            extra={
                <Dropdown overlay={menu}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        {t("reports.folder_menu")} <MenuOutlined />
                    </a>
                </Dropdown>
            }>
            <Meta title={<Paragraph ellipsis>{fileName}</Paragraph>} />
        </Card>
    );
}
