import firebase from "firebase/compat/app";
import { useEffect, useState } from "react";
import { AppBilling } from "./useOrgAppBillings";

export default function useAppBillingsOverview() {
    const [appBilling, setappBilling] = useState<AppBilling[]>([]);
    useEffect(() => {
        let unsubscribe: any = null;
        unsubscribe = firebase
            .firestore()
            .collection(`appBilling`)
            .where("status", "in", ["unpaid", "overdue"])
            .onSnapshot(
                query => {
                    const list: AppBilling[] = query.docs.map(
                        doc =>
                            ({
                                ...doc.data(),
                                id: doc.id,
                                nextPayDate: doc.data().nextPayDate.toDate(),
                                lastPayDate: doc.data().lastPayDate?.toDate(),
                                createTime: doc.data().createTime.toDate(),
                                updateTime: doc.data().updateTime.toDate(),
                            } as AppBilling),
                    );
                    setappBilling(list);
                },
                error => {
                    console.error(error);
                },
            );
        return unsubscribe;
    }, []);
    return { appBilling };
}
