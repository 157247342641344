import { useEffect, useState } from "react";
import { post, ROOT } from "../../general/api";

type sensors = "time" | "pm" | "tmp" | "hum" | "co2" | "voc" | "allpollu";

type units = "s" | "ugm3" | "C" | "pc" | "ppm" | "ppb" | "%";

export interface Logs {
    uuid: string;
    start: number;
    end: number;
    sensors: sensors[];
    units: units[];
    datapoints: number[][];
}
export function useGetAirQuality(
    companyId: string,
    deviceId: string,
    date: string | null,
) {
    const [sensor, setSensor] = useState<Logs | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const response = await post("getLogs", { companyId, deviceId, date });
            const data = (await response.json()) as Logs;
            setSensor(data);
            setIsLoading(false);
        };
        if (companyId && deviceId) {
            fetchData();
        }
    }, [companyId, deviceId, date]);

    return { sensor, isLoading };
}
