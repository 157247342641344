import { Button, Card, Timeline, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { StyleGuide } from "styles/StyleGuide";
import { CheckOutlined } from "@ant-design/icons";
import { Schema } from "./useLatestGsmPlantLog";

export default function AlertsCard({
    isLoading,
    cardTitle = "gsm.logs.card_title",
    markResolved,
    data,
}: {
    isLoading: boolean;
    cardTitle?: string;
    markResolved?: (logId: string) => void;
    data: Schema[];
}) {
    const { t } = useTranslation();
    const groupedLogs = groupLogsByDate(data);
    console.log({ groupedLogs });

    return (
        <Card
            loading={isLoading}
            title={<div>{t(cardTitle)}</div>}
            bordered={false}
            bodyStyle={{
                padding: 16,
                paddingLeft: 16,
                height: 370,
                overflowY: "scroll",
            }}>
            {Object.entries(groupedLogs).map(([_, values], index) => {
                return (
                    <div>
                        <h3
                            style={{
                                margin: 0,
                                marginBottom: 16,
                                textTransform: "capitalize",
                            }}>
                            {values[0]?.createTime &&
                                t("date.exactFormatDate", {
                                    dayOfWeek: values[0].createTime,
                                    dayOfMonth: values[0].createTime,
                                    month: values[0].createTime,
                                })}
                        </h3>
                        <div>
                            <Timeline
                                // @ts-ignore
                                items={values.map((log, i) => {
                                    const createTime = log.createTime
                                        ? log.createTime
                                        : "";
                                    return {
                                        color: log.resolveTime ? "green" : "red",
                                        children: (
                                            <div
                                                style={{
                                                    color: log.resolveTime
                                                        ? StyleGuide.palette.successDark
                                                        : !index && !i
                                                        ? StyleGuide.palette.redish
                                                        : "black",
                                                    paddingLeft: 8,
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}>
                                                <div>
                                                    <div>
                                                        <ul
                                                            style={{
                                                                listStyleType: "none",
                                                                paddingLeft: 0,
                                                            }}>
                                                            {log.formattedAlertsMessages?.map(
                                                                alert =>
                                                                    alert && (
                                                                        <li>{alert}</li>
                                                                    ),
                                                            )}
                                                        </ul>
                                                    </div>
                                                    <div
                                                        style={{
                                                            color: StyleGuide.palette
                                                                .grey500,
                                                        }}>
                                                        {t("date.exactFormat", {
                                                            dayOfWeek: createTime,
                                                            dayOfMonth: createTime,
                                                            month: createTime,
                                                            time: createTime,
                                                        })}
                                                    </div>
                                                </div>
                                                {markResolved && (
                                                    <div>
                                                        <Tooltip
                                                            title={
                                                                log.resolveTime ? (
                                                                    <>
                                                                        <div>
                                                                            {t(
                                                                                "gsm.alert.alert_resolve_time",
                                                                            )}
                                                                        </div>
                                                                        <div>
                                                                            {t(
                                                                                "date.exactFormat",
                                                                                {
                                                                                    dayOfWeek:
                                                                                        log.resolveTime,
                                                                                    dayOfMonth:
                                                                                        log.resolveTime,
                                                                                    month: log.resolveTime,
                                                                                    time: log.resolveTime,
                                                                                },
                                                                            )}
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    t(
                                                                        "gsm.alert.mark_resolved",
                                                                    )
                                                                )
                                                            }>
                                                            <Button
                                                                onClick={() =>
                                                                    markResolved(log.id)
                                                                }
                                                                disabled={Boolean(
                                                                    log.resolveTime,
                                                                )}
                                                                style={{
                                                                    backgroundColor:
                                                                        log.resolveTime
                                                                            ? StyleGuide
                                                                                  .palette
                                                                                  .successLight
                                                                            : "inherit",
                                                                }}
                                                                icon={
                                                                    <CheckOutlined
                                                                        style={{
                                                                            fontSize: 12,
                                                                            color: StyleGuide
                                                                                .palette
                                                                                .successDark,
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                )}
                                            </div>
                                        ),
                                    };
                                })}
                            />
                        </div>
                    </div>
                );
            })}
        </Card>
    );
}

//

function groupLogsByDate(logs: Schema[]): Record<string, Schema[]> {
    return logs
        .sort((a, b) => b.createTime.getTime() - a.createTime.getTime())
        .reduce((groupedLogs, log) => {
            const dateKey = format(log.createTime, "yyyy-MM-dd"); // Format the date as "yyyy-MM-dd"
            if (!groupedLogs[dateKey]) {
                groupedLogs[dateKey] = [];
            }
            groupedLogs[dateKey].push(log);
            return groupedLogs;
        }, {} as Record<string, Schema[]>);
}
