import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";
import { fetchInventoryPumps } from "../../../modules/inventory/pumps/actions";
import { PumpItem } from "../../../modules/inventory/pumps/types";

function useInventoryPumps() {
    const dispatch = useDispatch();
    const pumps = useSelector((state: AppState) =>
        state.inventory.pumpIds.map(id => state.inventory.inventoryMap[id]),
    ) as PumpItem[];

    useEffect(() => {
        dispatch(fetchInventoryPumps());
    }, [dispatch]);
    return { pumps };
}

export default useInventoryPumps;
