import React, { useState } from "react";
import { Form, Input, Button, Select, InputNumber, message } from "antd";

import { post } from "../../../general/api";
import { useParams } from "react-router";
import Message from "../../../components/Notification/Message";
import { Type } from "../../../components/Notification/Message";
import { SensorType } from "../../../general/enums";

const { Option } = Select;

const initialValues = {
    type: SensorType.ESP_AQ,
    pm_2_5: 250,
    pm_10: 350,
    temperature: 30,
    humidity: 60,
    co2: 1300,
    decibel: 60,
    lux: 100,
};

const AddFormAqSensor = ({ onClose }: { onClose: () => void }) => {
    const { orgId } = useParams<{ orgId: string }>();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);

    const onFinish = async (values: any) => {
        setIsLoading(true);
        Message({
            key: "addSensor",
            message: "Loading...",
            // description: "Sensoren blev tilføjet korrekt",
            type: Type.LOADING,
        });
        const payload = {
            orgId,
            title: values.title,
            deviceId: values.deviceId,
            type: values.type,
            thresholds: {
                pm_2_5: values.pm_2_5,
                pm_10: values.pm_10,
                temperature: values.temperature,
                humidity: values.humidity,
                co2: values.co2,
                decibel: values.decibel,
                lux: values.lux,
            },
        };
        const response = await post("setupAqSensor", payload);
        setIsLoading(false);
        if (response.status === 200) {
            form.resetFields();
            Message({
                key: "addSensor",
                message: "Sensor tilføjet",
                description: "Sensoren blev tilføjet korrekt",
            });
            onClose();
        } else {
            Message({
                key: "addSensor",
                message: "Fejl!",
                description: "Noget gik galt, prøv venligst igen",
            });
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={initialValues}>
            <Form.Item name="title" label="Sensor navn" rules={[{ required: true }]}>
                <Input placeholder="Sensor navn" />
            </Form.Item>
            <Form.Item name="deviceId" label="Sensor id" rules={[{ required: true }]}>
                <Input placeholder="Sensor id" />
            </Form.Item>
            <Form.Item name="type" label="Sensor type" rules={[{ required: true }]}>
                <Select placeholder="Sensor type" allowClear>
                    <Option value={SensorType.ESP_AQ}>Støv sensor</Option>
                </Select>
            </Form.Item>
            <Form.Item>
                <h2>Grænseværdier</h2>
            </Form.Item>
            <Form.Item name="pm_2_5" label="PM 2.5" rules={[{ required: true }]}>
                <InputNumber placeholder={`${initialValues.pm_2_5}`} />
            </Form.Item>
            <Form.Item name="pm_10" label="PM 10" rules={[{ required: true }]}>
                <InputNumber placeholder={`${initialValues.pm_10}`} />
            </Form.Item>
            <Form.Item
                name="temperature"
                label="Temperatur (C)"
                rules={[{ required: true }]}>
                <InputNumber placeholder={`${initialValues.temperature}`} />
            </Form.Item>
            <Form.Item
                name="humidity"
                label="LuftFugtighed (%)"
                rules={[{ required: true }]}>
                <InputNumber placeholder={`${initialValues.humidity}`} />
            </Form.Item>
            <Form.Item name="co2" label="CO2" rules={[{ required: true }]}>
                <InputNumber placeholder={`${initialValues.co2}`} />
            </Form.Item>
            <Form.Item name="decibel" label="Decibel (dB)" rules={[{ required: true }]}>
                <InputNumber placeholder={`${initialValues.decibel}`} />
            </Form.Item>
            <Form.Item name="lux" label="Lys intensitet" rules={[{ required: true }]}>
                <InputNumber placeholder={`${initialValues.lux}`} />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" block loading={isLoading}>
                    Tilføj
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddFormAqSensor;
