import { Select } from "antd";
import { useAppSelector } from "hooks";
import useQueryParams from "hooks/useQueryParams";
import useAuditTemplateCategories from "modules/auditTemplateCategories/useAuditTemplateCategories";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function SearchCategory({ ...rest }) {
    const { t } = useTranslation();
    const query = useQueryParams();
    const groupId = query.get("groupId") as string;

    const [inputValue, setInputValue] = useState("");

    const { loading, auditTemplateCategories } = useAuditTemplateCategories({
        orgId: "_",
        groupId,
    });

    const filteredCategories = auditTemplateCategories
        .filter(category =>
            category.name.toLowerCase().includes(inputValue.toLowerCase()),
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    return (
        <Select
            style={{ width: "100%" }}
            {...rest}
            mode={"multiple"}
            onSearch={setInputValue}
            placeholder={t("audit.reports.categoryPlaceholder")}
            size="large"
            onFocus={() => setInputValue("")}
            showSearch
            filterOption={false}>
            {filteredCategories.map((template: any) => (
                <Select.Option key={template.id} value={template.id}>
                    {template.name}
                </Select.Option>
            ))}
        </Select>
    );
}
