import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store";
import { useEffect } from "react";
import { fetchReports } from "../../modules/reports/actions";

function useDirectoryReports() {
    const orgId = useSelector((state: AppState) => state.orgs.currentOrg.id);
    const reports = useSelector((state: AppState) =>
        state.reports.reports.filter(report => !report.directoryId),
    );
    const directories = useSelector((state: AppState) => state.directories.directories);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchReports({ orgId }));
    }, [orgId, dispatch]);

    return { reports, directories, orgId };
}

export default useDirectoryReports;
