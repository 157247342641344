import { call, put, takeLatest } from "redux-saga/effects";
import firebase, { reduxSagaFirebase } from "../../firebase";
import { fetchProductsSuccess, fetchOrgProductsSuccess } from "./actions";
import * as actionType from "./types";
import { Product, OrgProduct } from "./types";

interface ProductPayload {
    payload: {
        orgId: string;
    };
}

function* fetchProducts() {
    try {
        yield call(
            reduxSagaFirebase.firestore.syncCollection,
            firebase.firestore().collection(`products`),
            { successActionCreator: fetchProductsSuccess },
        );
    } catch (e: any) {
        yield put({ type: actionType.PRODUCTS_FETCH_FAILED, message: e.message });
    }
}

export function* fetchProductsSaga() {
    // @ts-ignore
    yield takeLatest(actionType.PRODUCTS_FETCH_REQUESTED, fetchProducts);
}

function* addProduct({ payload }: ProductPayload & { payload: { title: string } }) {
    const { orgId, title } = payload;
    try {
        yield call(reduxSagaFirebase.firestore.addDocument, `orgs/${orgId}/products`, {
            title,
        });
        yield put({ type: actionType.PRODUCTS_CREATE_SUCCEEDED });
    } catch (e: any) {
        yield put({ type: actionType.PRODUCTS_CREATE_FAILED, message: e.message });
    }
}

export function* addProductSaga() {
    // @ts-ignore
    yield takeLatest(actionType.PRODUCTS_CREATE_REQUESTED, addProduct);
}
function* updateProduct({
    payload,
}: {
    payload: { orderId: string; data: Partial<Product> };
}) {
    const { orderId, data } = payload;
    try {
        yield call(reduxSagaFirebase.firestore.updateDocument, `products/${orderId}`, {
            ...data,
        });
        yield put({ type: actionType.PRODUCTS_UPDATE_SUCCEEDED });
    } catch (e: any) {
        yield put({ type: actionType.PRODUCTS_UPDATE_FAILED, message: e.message });
    }
}

export function* updateProductSaga() {
    // @ts-ignore
    yield takeLatest(actionType.PRODUCTS_UPDATE_REQUESTED, updateProduct);
}

function* deleteProduct({ payload }: { payload: { productId: string } }) {
    const { productId } = payload;
    try {
        yield call(reduxSagaFirebase.firestore.deleteDocument, `products/${productId}`);
        yield put({ type: actionType.PRODUCTS_DELETE_SUCCEEDED });
    } catch (e: any) {
        yield put({ type: actionType.PRODUCTS_DELETE_FAILED, message: e.message });
    }
}

export function* deleteProductSaga() {
    // @ts-ignore
    yield takeLatest(actionType.PRODUCTS_DELETE_REQUESTED, deleteProduct);
}

// ORG PRODUCTS

function* fetchOrgProducts({ payload }: ProductPayload) {
    const { orgId } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.syncCollection,
            firebase.firestore().collection(`orgs/${orgId}/products`),
            { successActionCreator: fetchOrgProductsSuccess },
        );
    } catch (e: any) {
        yield put({ type: actionType.ORG_PRODUCTS_FETCH_FAILED, message: e.message });
    }
}

export function* fetchOrgProductsSaga() {
    // @ts-ignore
    yield takeLatest(actionType.ORG_PRODUCTS_FETCH_REQUESTED, fetchOrgProducts);
}

function* addOrgProduct({
    payload,
}: ProductPayload & { payload: { productId: string; title: string } }) {
    const { orgId, productId, title } = payload;
    try {
        yield call(
            // @ts-ignore
            reduxSagaFirebase.firestore.setDocument,
            `orgs/${orgId}/products/${productId}`,
            {
                title,
                storage: 0,
            },
        );
        yield put({ type: actionType.ORG_PRODUCTS_CREATE_SUCCEEDED });
    } catch (e: any) {
        yield put({ type: actionType.ORG_PRODUCTS_CREATE_FAILED, message: e.message });
    }
}

export function* addOrgProductSaga() {
    // @ts-ignore
    yield takeLatest(actionType.ORG_PRODUCTS_CREATE_REQUESTED, addOrgProduct);
}
function* updateOrgProduct({
    payload,
}: ProductPayload & {
    payload: { productId: string; data: Partial<OrgProduct> };
}) {
    const { orgId, productId, data } = payload;
    try {
        // @ts-ignore
        const sensor = yield call(
            reduxSagaFirebase.firestore.updateDocument,
            `orgs/${orgId}/products/${productId}`,
            {
                ...data,
            },
        );
        yield put({ type: actionType.ORG_PRODUCTS_UPDATE_SUCCEEDED });
    } catch (e: any) {
        yield put({ type: actionType.ORG_PRODUCTS_UPDATE_FAILED, message: e.message });
    }
}

export function* updateOrgProductSaga() {
    // @ts-ignore
    yield takeLatest(actionType.ORG_PRODUCTS_UPDATE_REQUESTED, updateOrgProduct);
}

function* deleteOrgProduct({
    payload,
}: ProductPayload & { payload: { productId: string } }) {
    const { orgId, productId } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.deleteDocument,
            `orgs/${orgId}/products/${productId}`,
        );
        yield put({ type: actionType.ORG_PRODUCTS_DELETE_SUCCEEDED });
    } catch (e: any) {
        yield put({ type: actionType.ORG_PRODUCTS_DELETE_FAILED, message: e.message });
    }
}

export function* deleteOrgProductSaga() {
    // @ts-ignore
    yield takeLatest(actionType.ORG_PRODUCTS_DELETE_REQUESTED, deleteOrgProduct);
}
