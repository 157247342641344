import React, { useState } from "react";
import { Layout, Row, Col, Select } from "antd";
// import SideNav from "../../Layout/SideNav";
import HeaderBar from "../../Layout/HeaderBar";
import FooterBar from "../../Layout/FooterBar";
// import SensorWidget from "./SensorWidget";
// import IssuesWidget from "./IssuesWidget";
// import StorageWidget from "./StorageWidget";
// import { FeatureIds } from "../../modules/featureControl/featuresConfig";
// import BlurOverlayContainer from "../../components/Overlay/BlurOverlayContainer";
import AuditWithIssuesWidget from "./AuditWithIssuesWidget";
import AuditOverdueWidget from "./AuditOverdueWidget";
import AuditOnHoldWidget from "./AuditOnHoldWidget";
import useOrgsByGroup from "hooks/useOrgsByGroup";
import AuditCompletedTimeline from "./AuditCompletedTimeline";
// import { useParams } from "react-router";
import useQueryParams from "hooks/useQueryParams";
import AdminOnly from "components/AdminOnly";
import AuditWidgetUpcoming from "./AuditWidgetUpcoming";
import ReportModal from "components/ReportModal";
import OrgsSelect from "./OrgsSelect";

const { Content } = Layout;

export default function DashboardOverAllContainer() {
    const query = useQueryParams();
    const groupId = query.get("groupId");
    //"RuXfdQ77eGHqAsQSHK5m"; // TEST group "3Ms1n6TSIErIIct17LTa"; //RGS group
    const [orgIds, setOrgIds] = useState<string[]>([]);
    const { orgs, isLoading: orgsLoading } = useOrgsByGroup(groupId);

    // const features = useSelector((state: AppState) => state.features.featuresMap);
    const orgMap = orgs.reduce((acc, org) => {
        return {
            ...acc,
            [org.id]: {
                title: org.title,
                members: org.members,
            },
        };
    }, {});
    if (!groupId) return null;
    return (
        <>
            <Layout>
                <HeaderBar />

                <Content style={{ margin: "50px 24px 0" }}>
                    <Row gutter={[16, 16]}>
                        <Col lg={14} md={24} sm={24}></Col>
                        {/* <AdminOnly> */}

                        <Col lg={10} md={24} sm={24}>
                            <div style={{ display: "flex" }}>
                                <div style={{ flexGrow: 1 }}>
                                    <OrgsSelect
                                        orgs={orgs}
                                        orgIds={orgIds}
                                        mode="multiple"
                                        setOrgIds={(newValue: string[] | string) => {
                                            setOrgIds(newValue as string[]);
                                        }}
                                    />
                                </div>
                                <div style={{ width: 20 }}></div>

                                <ReportModal groupId={groupId} orgs={orgs} />
                            </div>
                        </Col>

                        {/* </AdminOnly> */}
                        <Col lg={6} md={24} sm={24}>
                            {/* <SensorWidget /> */}
                            <AuditWidgetUpcoming
                                orgMap={orgMap}
                                groupId={groupId}
                                orgIds={orgIds}
                            />
                        </Col>
                        <Col lg={6} md={24} sm={24}>
                            {/* <SensorWidget /> */}
                            <AuditWithIssuesWidget
                                orgMap={orgMap}
                                groupId={groupId}
                                orgIds={orgIds}
                            />
                        </Col>
                        <Col lg={6} md={24} sm={24}>
                            {/* <SensorWidget /> */}
                            <AuditOnHoldWidget
                                orgMap={orgMap}
                                groupId={groupId}
                                orgIds={orgIds}
                            />
                        </Col>
                        <Col lg={6} md={24} sm={24}>
                            <AuditOverdueWidget
                                orgIds={orgIds}
                                orgMap={orgMap}
                                groupId={groupId}
                            />
                        </Col>
                        <Col lg={24} md={24} sm={24}>
                            <AuditCompletedTimeline
                                groupId={groupId}
                                orgIds={orgIds}
                                orgMap={orgMap}
                            />
                        </Col>
                        {/* <Col lg={12} md={24} sm={24}>
                            <IssuesWidget />
                        </Col> */}
                        {/* <Col lg={8} md={24} sm={24}></Col> */}

                        {/* {features?.cleanLogs?.isDisplayed !== false && (
                            <Col lg={12} md={24} sm={24}>
                                <StorageWidget />
                            </Col>
                        )} */}
                    </Row>
                </Content>

                <FooterBar />
            </Layout>
        </>
    );
}
