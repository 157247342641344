import { firestore } from "../firebase";
import { collection, getCountFromServer, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";

export default function useOrgsCountByGroup(groupId: string) {
    const [count, setcount] = useState(0);
    useEffect(() => {
        const getCount = async () => {
            const coll = query(
                collection(firestore, "orgs"),
                where("groupId", "==", groupId),
            );
            const snapshot = await getCountFromServer(coll);
            setcount(snapshot.data().count);
        };
        if (groupId) {
            getCount();
        }

        return () => {};
    }, [groupId]);

    return count;
}
