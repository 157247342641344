import IssueOverviewList from "./IssueOverviewList";
import useIssueOverview from "../../modules/issues/useIssueOverview";
import { useEffect } from "react";
import firebase from "firebase/compat/app";

function IssueOverviewListContainer() {
    const { isLoading, issues } = useIssueOverview();
    let unsub: any = null;
    useEffect(() => {
        unsub = firebase
            .firestore()
            .collectionGroup("categories")

            .onSnapshot(
                doc => {
                    const data = doc.docs.map(d => d.data());
                    // update language
                    console.log(data);
                },
                error => console.log(error),
            );
        return () => unsub?.();
    }, []);

    return <IssueOverviewList loading={isLoading} issues={issues} />;
}

export default IssueOverviewListContainer;
