import React from "react";
import { Divider, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
interface DataType {
    key: React.Key;
    water: number;
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
}

const columns: ColumnsType<DataType> = [
    {
        title: (
            <div>
                Dosering % / <br />
                Vandmængde/ltr
            </div>
        ),
        dataIndex: "water",
    },
    {
        title: "0,50%",
        dataIndex: "1",
    },
    {
        title: "0,75%",
        dataIndex: "2",
    },
    {
        title: "1,00%",
        dataIndex: "3",
    },
    {
        title: "1,50%",
        dataIndex: "4",
    },
    {
        title: "2,00%",
        dataIndex: "5",
    },
];
const data: DataType[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30].map(i => ({
    key: i,
    water: i,
    1: i * 5,
    2: Math.round(i * 8 - i / 2),
    3: i * 10,
    4: i * 15,
    5: i * 20,
}));

export default function Schema() {
    return (
        <>
            <Divider>Dosering af Klinatur Støvfanger ( angivet i ML )</Divider>
            <Table columns={columns} dataSource={data} size="small" pagination={false} />
        </>
    );
}
