import { call, put, takeLatest } from "redux-saga/effects";
import firebase, { reduxSagaFirebase } from "../../firebase";
import { fetchCategoriesSuccess } from "./actions";
import {
    CATEGORIES_FETCH_FAILED,
    CATEGORIES_FETCH_REQUESTED,
    CATEGORIES_CREATE_SUCCEEDED,
    CATEGORIES_CREATE_FAILED,
    CATEGORIES_CREATE_REQUESTED,
    CATEGORIES_DELETE_SUCCEEDED,
    CATEGORIES_DELETE_FAILED,
    CATEGORIES_DELETE_REQUESTED,
} from "./types";

function* fetchCategories({ payload }: { payload: { orgId: string } }) {
    const { orgId } = payload;
    try {
        yield call(
            // @ts-ignore
            reduxSagaFirebase.firestore.syncCollection,
            firebase.firestore().collection(`orgs/${orgId}/categories`),
            { successActionCreator: fetchCategoriesSuccess },
        );
    } catch (e: any) {
        yield put({ type: CATEGORIES_FETCH_FAILED, message: e.message });
    }
}

export function* fetchCategoriesSaga() {
    // @ts-ignore
    yield takeLatest(CATEGORIES_FETCH_REQUESTED, fetchCategories);
}

function* addCategories({ payload }: { payload: { orgId: string; title: string } }) {
    const { orgId, title } = payload;
    try {
        yield call(reduxSagaFirebase.firestore.addDocument, `orgs/${orgId}/categories`, {
            title,
        });
        yield put({ type: CATEGORIES_CREATE_SUCCEEDED });
    } catch (e: any) {
        yield put({ type: CATEGORIES_CREATE_FAILED, message: e.message });
    }
}

export function* addCategoriesSaga() {
    // @ts-ignore
    yield takeLatest(CATEGORIES_CREATE_REQUESTED, addCategories);
}

function* deleteCategory({
    payload,
}: {
    payload: { orgId: string; categoryId: string };
}) {
    const { orgId, categoryId } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.deleteDocument,
            `orgs/${orgId}/categories/${categoryId}`,
        );
        yield put({ type: CATEGORIES_DELETE_SUCCEEDED });
    } catch (e: any) {
        yield put({ type: CATEGORIES_DELETE_FAILED, message: e.message });
    }
}

export function* deleteCategorySaga() {
    // @ts-ignore
    yield takeLatest(CATEGORIES_DELETE_REQUESTED, deleteCategory);
}
