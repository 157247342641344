export const Colors = {
    danger: "#ef5350",
    warning: "#FFCA28",
    hardWarning: "#D46B08",
    info: "#42A5F5",
    processing: "#1677ff",
    done: "#66BB6A",
    aks: "#009ce1",
    inprogress: "#A0E6E6",
};
