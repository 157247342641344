import { useEffect, useState } from "react";
import { Input, Button, Form, Select, Space, Typography, Checkbox, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { StyleGuide } from "styles/StyleGuide";
import { useAppSelector } from "../../hooks";
import { CustomFieldType, FieldConfig } from "modules/audits/types";
import { AdvancedTaskModalContentContext } from "modules/modal/types";
import Message, { Type } from "components/Notification/Message";
import firebase from "firebase/compat/app";
import { closeModal } from "modules/modal/actions";

export default function AdvancedTaskModalContent() {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [category, setCategory] = useState<CustomFieldType | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const context = useAppSelector(
        state => state.modals.context as AdvancedTaskModalContentContext,
    );
    const task = useAppSelector(state =>
        state.auditTemplates.currentAuditTemplateTasks.find(
            t => t.id === context?.taskId,
        ),
    );
    const categoryOptions = useAppSelector(state => {
        if (!state.audits.auditBaseCategories.fields) return [];
        return Object.keys(state.audits.auditBaseCategories.fields)
            .map(id => ({
                id,
                label: t(`audit_base_category.${id}`),
                optionsPreview: state.audits.auditBaseCategories.fields[id].options.map(
                    o => `audit_base_category_option.${id}.${o}`,
                ),
            }))
            .sort((a, b) => a.label.localeCompare(b.label, "da"));
    });

    const categoryMap = useAppSelector(state => state.audits.auditBaseCategories.fields);
    // const uid = useSelector((state: AppState) => state.user.details.uid);
    // const email = useSelector((state: AppState) => state.user.details.email);

    const [isAdding, setIsAdding] = useState(false);

    useEffect(() => {
        form.validateFields();
    }, []);
    useEffect(() => {
        form.setFieldsValue({ customFields: task?.customFields || [] });
    }, []);

    const handleCancel = () => dispatch(closeModal());

    const handleSubmit = async (values: { customFields: CustomFieldType[] }) => {
        try {
            const { customFields } = values;
            if (!context?.orgId || !context.taskId || !context.templateId) {
                throw new Error(`Context is not defined ${JSON.stringify(context)}`);
            }
            console.log({ context, customFields });
            setIsSubmitting(true);
            Message({
                key: "AdvancedTaskModalContent",
                message: t("Loading..."),
                // description: "Sensoren blev tilføjet korrekt",
                type: Type.LOADING,
            });

            await firebase
                .firestore()
                .collection("orgs")
                .doc(context.orgId)
                .collection("auditTemplates")
                .doc(context.templateId)
                .collection("tasks")
                .doc(context.taskId)
                .update({
                    customFields,
                });

            Message({
                key: "AdvancedTaskModalContent",
                message: "Opgaven blev opdateret",
                // description: "Sensoren blev tilføjet korrekt",
                type: Type.SUCCESS,
            });

            handleCancel();
        } catch (e) {
            console.error(e);
            Message({
                key: "AdvancedTaskModalContent",
                type: Type.ERROR,
                message: `Der skete en fejl`,
            });
        } finally {
            setIsSubmitting(false);
        }
    };
    const formLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    const customFields = Form.useWatch("customFields", { form });

    return (
        <>
            <Typography.Title level={4} style={{ margin: 0 }}>
                {task?.description} - {t("Tilføj registrerings felter")}
            </Typography.Title>
            <div style={{ marginTop: StyleGuide.spacing.base }} />
            <Form
                {...formLayout}
                layout="vertical"
                form={form}
                onFinish={handleSubmit}
                initialValues={{
                    dataDocuments: ["A", "B"],
                    customFields: [],
                    // unit: Unit.LITER,
                }}>
                <Form.List name="customFields">
                    {(fields, { add, remove }) => (
                        <div
                            style={{
                                display: "flex",
                                rowGap: 16,
                                flexDirection: "column",
                            }}>
                            {fields.map(field => {
                                const currentItem = form.getFieldValue([
                                    "customFields",
                                    field.name,
                                ]);
                                console.log({ currentItem });

                                const currentType = currentItem.type;

                                const { options } = categoryMap[currentType];
                                console.log({
                                    options,
                                    field,
                                    currentType,
                                });

                                return (
                                    <>
                                        {/* <Typography>
                                        Vælg enhed og beskriv hvad der skal udfyldes
                                    </Typography> */}
                                        <Space
                                            key={field.key}
                                            style={{
                                                width: "100%",
                                                justifyContent: "space-between",
                                            }}>
                                            <Space>
                                                <Form.Item
                                                    noStyle
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                "Venligst beskriv feltet",
                                                        },
                                                    ]}
                                                    name={[field.name, "description"]}>
                                                    <Input
                                                        size="large"
                                                        style={{ minWidth: 400 }}
                                                        placeholder="Beskriv hvad der skal udfyldes"
                                                        addonBefore={
                                                            <Form.Item
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message:
                                                                            "Venligst vælg enhed!",
                                                                    },
                                                                ]}
                                                                name={[
                                                                    field.name,
                                                                    "unit",
                                                                ]}
                                                                noStyle>
                                                                <Select
                                                                    placeholder="Enhed"
                                                                    style={{
                                                                        minWidth: 115,
                                                                    }}>
                                                                    {options.map(o => (
                                                                        <Select.Option
                                                                            value={o}>
                                                                            {t(
                                                                                `audit_base_category_option.${currentType}.${o}`,
                                                                            )}
                                                                        </Select.Option>
                                                                    ))}
                                                                </Select>
                                                            </Form.Item>
                                                        }
                                                    />
                                                </Form.Item>
                                                <Tooltip title="Skal feltet udfyldes før opgaven kan godkendes?">
                                                    <div>
                                                        <Form.Item
                                                            noStyle
                                                            name={[
                                                                field.name,
                                                                "required",
                                                            ]}
                                                            valuePropName="checked">
                                                            <Checkbox>
                                                                Påkrævet{" "}
                                                                <InfoCircleOutlined />
                                                            </Checkbox>
                                                        </Form.Item>
                                                    </div>
                                                </Tooltip>
                                            </Space>
                                            <Space>
                                                <Tooltip title="Fjern felt">
                                                    <Button
                                                        type="text"
                                                        shape="circle"
                                                        icon={<DeleteOutlined />}
                                                        onClick={() => {
                                                            remove(field.name);
                                                            // subOpt.remove(
                                                            //     subField.name,
                                                            // );
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Space>
                                        </Space>
                                    </>
                                );
                            })}
                            {isAdding && (
                                <Select
                                    size="large"
                                    defaultOpen
                                    placeholder="Vælg opgave type"
                                    value={category}
                                    onChange={(value: CustomFieldType) => {
                                        console.log({ value });
                                        const { defaultOption, fieldType } =
                                            categoryMap[value];
                                        add({
                                            type: value,
                                            unit: defaultOption,
                                            required: false,
                                            fieldType,
                                        });
                                        setCategory(undefined);
                                        setIsAdding(false);
                                    }}>
                                    {categoryOptions.map(unit => {
                                        return (
                                            <Select.Option value={unit.id}>
                                                {unit.label}
                                                <span
                                                    style={{
                                                        fontSize: 12,
                                                        color: StyleGuide.palette.grey600,
                                                    }}>
                                                    {" - "}(
                                                    {unit.optionsPreview
                                                        .map(o => t(o))
                                                        .slice(0, 3)
                                                        .join(", ")}
                                                    {unit.optionsPreview.length > 3
                                                        ? "..."
                                                        : ""}
                                                    )
                                                </span>
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            )}
                            {!isAdding && (
                                <Button size="large" onClick={() => setIsAdding(true)}>
                                    Tilføj felt
                                </Button>
                            )}

                            {/* </Form.List>
                                    </Form.Item>
                                </>
                            ))} */}
                        </div>
                    )}
                </Form.List>

                {/* <Form.Item noStyle shouldUpdate>
                    {() => (
                        <Typography>
                            <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
                        </Typography>
                    )}
                </Form.Item> */}

                {/* <Input
                    placeholder="Firmanavn"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                /> */}
                <div style={{ marginTop: 16 }} />
                <Form.Item
                    wrapperCol={{ span: 24 }}
                    style={{ margin: 0, display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        loading={isSubmitting}
                        disabled={isSubmitting || customFields?.length < 1}>
                        {t("Tilføj til opgave")}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

type OptionType = {
    label: string;
    id: string;
    optionsPreview: string[];
};

// const options: OptionType[] = [
//     { id: "economy", label: "Økonomi" },
//     { id: "energy", label: "Energi" },
//     { id: "volume", label: "Volumen" },
//     { id: "weight", label: "Vægt" },
//     { id: "length_distance", label: "Længde/Afstand" },
//     { id: "time", label: "Tid" },
//     { id: "temperature", label: "Temperatur" },
//     { id: "pressure", label: "Tryk" },
//     { id: "amount", label: "Antal og stk" },
// ];

// function getUnitOptions(category: Category): {
//     options: UnitOption[];
//     defaultId: string;
// } {
//     let options: UnitOption[] = [];
//     let defaultId: string = "";

//     switch (category) {
//         case "economy":
//             options = [
//                 { id: "dkk", label: "DKK" },
//                 { id: "eur", label: "EUR" },
//                 { id: "usd", label: "USD" },
//             ];
//             defaultId = "dkk";
//             break;
//         case "energy":
//             options = [
//                 { id: "kwh", label: "KWh" },
//                 { id: "mj", label: "MJ" },
//             ];
//             defaultId = "kwh";
//             break;
//         case "volume":
//             options = [
//                 { id: "liters", label: "Liter" },
//                 { id: "cubic_meters", label: "m³" },
//             ];
//             defaultId = "liters";
//             break;
//         case "weight":
//             options = [
//                 { id: "grams", label: "Gram" },
//                 { id: "kilograms", label: "Kilogram" },
//                 { id: "tons", label: "Ton" },
//             ];
//             defaultId = "grams";
//             break;
//         case "length_distance":
//             options = [
//                 { id: "cm", label: "Cm" },
//                 { id: "meters", label: "Meter" },
//                 { id: "kilometers", label: "Kilometer" },
//                 { id: "feet", label: "Fod" },
//                 { id: "miles", label: "Mil" },
//             ];
//             defaultId = "cm";
//             break;
//         case "time":
//             options = [
//                 { id: "seconds", label: "Sekunder" },
//                 { id: "minutes", label: "Minutter" },
//                 { id: "hours", label: "Timer" },
//                 { id: "days", label: "Dage" },
//                 { id: "weeks", label: "Uger" },
//                 { id: "months", label: "Måneder" },
//                 { id: "years", label: "År" },
//             ];
//             defaultId = "seconds";
//             break;
//         case "amount":
//             options = [
//                 { id: "pieces", label: "Stk" },
//                 { id: "dozen", label: "Dusin" },
//                 { id: "packs", label: "Pakker" },
//                 { id: "pallets", label: "Paller" },
//                 { id: "tanks", label: "Tanke" },
//             ];
//             defaultId = "pieces";
//             break;
//         case "temperature":
//             options = [
//                 { id: "celsius", label: "Celsius" },
//                 { id: "fahrenheit", label: "Fahrenheit" },
//                 { id: "kelvin", label: "Kelvin" },
//             ];
//             defaultId = "celsius";
//             break;
//         case "pressure":
//             options = [
//                 { id: "pascals", label: "Pascal" },
//                 { id: "bars", label: "Bar" },
//                 { id: "psi", label: "PSI" },
//             ];
//             defaultId = "pascals";
//             break;
//         default:
//             break;
//     }

//     return { options, defaultId };
// }

// Usage example
// const { options, defaultId } = getUnitOptions("Energy");
// console.log(options); // Output: [{ id: 'kwh', label: 'KWh' }, { id: 'mj', label: 'MJ' }]
// console.log(defaultId); // Output: 'kwh'
