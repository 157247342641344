import { call, put, fork, takeLatest } from "redux-saga/effects";
import firebase, { reduxSagaFirebase } from "../../firebase";
import { fetchHourTrackersSuccess } from "./actions";
import { HourTracker } from "./types";
import {
    HOURTRACKER_CREATE_REQUESTED,
    HOURTRACKER_CREATE_FAILED,
    HOURTRACKER_CREATE_SUCCEEDED,
    HOURTRACKERS_FETCH_REQUESTED,
    HOURTRACKERS_FETCH_FAILED,
    HOURTRACKER_UPDATE_REQUESTED,
    HOURTRACKER_UPDATE_FAILED,
    HOURTRACKER_UPDATE_SUCCEEDED,
} from "./types";

function* fetchHourTrackers({ payload }: { payload: { orgId: string } }) {
    const { orgId } = payload;
    try {
        yield fork(
            // @ts-ignore
            reduxSagaFirebase.firestore.syncCollection,
            firebase
                .firestore()
                .collection(`orgs/${orgId}/hourTrackers`)
                .orderBy("createTime", "desc"),
            {
                successActionCreator: fetchHourTrackersSuccess,
            },
        );
    } catch (e: any) {
        yield put({ type: HOURTRACKERS_FETCH_FAILED, message: e.message });
    }
}

export function* fetchHourTrackersSaga() {
    // @ts-ignore
    yield takeLatest(HOURTRACKERS_FETCH_REQUESTED, fetchHourTrackers);
}

// function* addHourTracker({
//     payload,
// }: {
//     payload: { orgId: string; initials: string; amount: number; productId: string };
// }) {
//     const { orgId, initials, amount, productId } = payload;
//     try {
//         yield call(reduxSagaFirebase.firestore.addDocument, `orgs/${orgId}/cleanLogs`, {
//             initials,
//             amount,
//             product: {
//                 id: productId,
//             },
//             createTime: new Date(),
//         });
//         yield put({ type: HOURTRACKER_CREATE_SUCCEEDED });
//     } catch (e: any) {
//         yield put({ type: HOURTRACKER_CREATE_FAILED, message: e.message });
//     }
// }

// export function* addHourTrackerSaga() {
//     // @ts-ignore
//     yield takeLatest(HOURTRACKER_CREATE_REQUESTED, addHourTracker);
// }

// function* updateHourTracker({
//     payload,
// }: {
//     payload: { orgId: string; logId: string; data: HourTracker };
// }) {
//     const { orgId, logId, data } = payload;
//     try {
//         // @ts-ignore
//         const orgs = yield call(
//             reduxSagaFirebase.firestore.updateDocument,
//             `orgs/${orgId}/cleanLogs/${logId}`,
//             {
//                 ...data,
//             },
//         );
//         yield put({ type: HOURTRACKER_UPDATE_SUCCEEDED });
//     } catch (e: any) {
//         yield put({ type: HOURTRACKER_UPDATE_FAILED, message: e.message });
//     }
// }

// export function* updateHourTrackerSaga() {
//     // @ts-ignore
//     yield takeLatest(HOURTRACKER_UPDATE_REQUESTED, updateHourTracker);
// }
