import React from "react";
import { Space, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { AppBilling } from "../../hooks/useOrgAppBillings";
import { useAppDispatch } from "../../hooks";
import { openBillingDrawer } from "../../modules/drawer/actions";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

export type TagStatus = "paid" | "unpaid" | "pending" | "overdue";
export type PaymentInterval = "yearly" | "monthly";
interface Product {
    id: string;
    name: string;
}

interface DataType {
    id: string;
    orgId: string;
    orgTitle: string;
    products: Product[];
    lastPayDate: Date | null;
    nextPayDate: Date;
    // paymentInterval: PaymentInterval;
    priceOnce: number;
    priceRecurring: number;
    tags: TagStatus[];
}

export const StatusMap = {
    paid: "Betalt",
    unpaid: "Ikke betalt",
    pending: "Afventer",
    overdue: "Forfalden",
};

const columns: ColumnsType<DataType> = [
    {
        title: "Produkter",
        dataIndex: "products",
        key: "products",
        render: (_, { products }) => products.map(p => <Tag key={p.id}>{p.name}</Tag>),
    },
    {
        title: "Firma",
        dataIndex: "orgTitle",
        key: "orgTitle",
        render: (_, { orgTitle, orgId }) => (
            <Link to={`/orgs/${orgId}/billing`}>{orgTitle}</Link>
        ),
    },
    {
        title: "Seneste betaling",
        dataIndex: "lastPayDate",
        key: "lastPayDate",
        render: (_, { lastPayDate }) => <>{lastPayDate?.toLocaleDateString() || "-"}</>,
    },
    {
        title: "Kommende betaling",
        dataIndex: "nextPayDate",
        key: "nextPayDate",
        render: (_, { nextPayDate }) => <>{nextPayDate?.toLocaleDateString()}</>,
    },
    {
        title: "Pris (engangsbeløb)",
        dataIndex: "price",
        key: "priceOnce",
        render: (_, { priceOnce }) => (
            <>
                {new Intl.NumberFormat("da-DK", {
                    style: "currency",
                    currency: "DKK",
                    maximumFractionDigits: 0,
                }).format(priceOnce)}{" "}
            </>
        ),
    },
    {
        title: "Pris (gentages)",
        dataIndex: "price",
        key: "priceRecurring",
        render: (_, { priceRecurring }) => (
            <>
                {new Intl.NumberFormat("da-DK", {
                    style: "currency",
                    currency: "DKK",
                    maximumFractionDigits: 0,
                }).format(priceRecurring)}{" "}
            </>
        ),
    },
    // {
    //     title: "Betales år/md",
    //     dataIndex: "paymentInterval",
    //     key: "paymentInterval",
    //     render: (_, { paymentInterval }) => <>{paymentInterval}</>,
    // },
    {
        title: "Status",
        key: "tags",
        dataIndex: "tags",
        render: (_, { tags }) => (
            <>
                {tags.map(tag => {
                    const color = getStatusColor(tag);
                    return (
                        <Tag color={color} key={tag}>
                            {StatusMap[tag] || "Ukendt status"}
                        </Tag>
                    );
                })}
            </>
        ),
    },
    {
        title: "",
        key: "action",
        render: (_, record) => (
            <Space size="middle">
                <EditDrawer orgId={record.orgId} billingId={record.id} />
            </Space>
        ),
    },
];

export function getStatusColor(tag: TagStatus) {
    switch (tag) {
        case "paid":
            return "green";

        case "pending":
            return "gold";

        case "unpaid":
            return "volcano";

        case "overdue":
            return "red";

        default:
            return "geekblue";
    }
}

function EditDrawer({ orgId, billingId }: { orgId: string; billingId: string }) {
    const dispatch = useAppDispatch();
    const handleOpenDrawer = () => {
        dispatch(openBillingDrawer({ orgId, billingId }));
    };
    return <a onClick={handleOpenDrawer}>Rediger</a>;
}

export default function BillingTable({ appBilling }: { appBilling: AppBilling[] }) {
    const { pathname } = useLocation();
    console.log({ pathname });
    const isOverview = pathname === "/billing";
    const data = appBilling.map(billing => ({
        id: billing.id,
        orgId: billing.org.id,
        orgTitle: billing.org.title,
        products: billing.products,
        lastPayDate: billing.lastPayDate,
        nextPayDate: billing.nextPayDate,
        priceOnce: billing.products.reduce(
            (acc, product) => (!product.recurring ? acc + product.price : acc),
            0,
        ),
        priceRecurring: billing.products.reduce(
            (acc, product) => (product.recurring ? acc + product.price : acc),
            0,
        ),
        tags: [billing.status],
    }));
    return (
        <Table
            columns={columns.filter(c => (!isOverview ? c.key !== "orgTitle" : c))}
            dataSource={data}
        />
    );
}
