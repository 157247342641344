import { useEffect, useState } from "react";
import { post } from "../../../general/api";
import { format, subHours } from "date-fns";

type sensors = "time" | "pm" | "tmp" | "hum" | "co2" | "voc" | "allpollu";

type units = "s" | "ugm3" | "C" | "pc" | "ppm" | "ppb" | "%";

export interface Stats {
    co2Avg: number;
}

export interface Logs {
    co2: { time: string; co2: number }[];
    humidity: { time: string; humidity: number }[];
    lux: { time: string; lux: number }[];
    pm_2_5: { time: string; pm_2_5: number }[];
    pm_10: { time: string; pm_10: number }[];
    temperature: { time: string; temperature: number }[];
    decibel: { time: string; tvoc: number }[];
}

interface RawLogs {
    co2: number;
    humidity: number;
    lightIntensity: number;
    pm_2_5: number;
    pm_10: number;
    temperature: number;
    timekey: { value: string };
    decibel: number;
}

function getTimeName(value: string) {
    return format(subHours(new Date(value), 2), "MMM dd - HH:mm");
}

export function useAqSensor(deviceId: string, date: string | null) {
    const [logsAndStats, setLogsAndStats] = useState<(Logs & Stats) | undefined>(
        undefined,
    );
    const [isLoading, setIsLoading] = useState<boolean>(true);
    console.log({ deviceId });

    useEffect(() => {
        try {
            const fetchData = async () => {
                setIsLoading(true);
                const response = await post("getAqLogs", {
                    deviceId,
                    date,
                });
                const data = (await response.json()) as RawLogs[];
                console.log({ data: data.length });
                const result = data
                    .reverse()
                    // filter in case of null values
                    .filter(d => Boolean(d.timekey?.value))
                    .reduce(
                        (acc: any, i) => {
                            return {
                                ...acc,
                                pm_2_5: [
                                    ...acc.pm_2_5,
                                    {
                                        name: getTimeName(i.timekey?.value),
                                        time: new Date(i.timekey?.value).getTime(),
                                        pm_2_5: i.pm_2_5,
                                    },
                                ],
                                pm_10: [
                                    ...acc.pm_10,
                                    {
                                        name: getTimeName(i.timekey?.value),
                                        time: new Date(i.timekey?.value).getTime(),
                                        pm_10: i.pm_10,
                                    },
                                ],
                                co2: [
                                    ...acc.co2,
                                    {
                                        name: getTimeName(i.timekey?.value),
                                        time: new Date(i.timekey?.value).getTime(),
                                        co2: i.co2,
                                    },
                                ],
                                co2Avg: acc.co2Avg + i.co2 > 0 ? i.co2 : 0,
                                humidity: [
                                    ...acc.humidity,
                                    {
                                        name: getTimeName(i.timekey?.value),
                                        time: new Date(i.timekey?.value).getTime(),
                                        humidity: i.humidity,
                                    },
                                ],
                                temperature: [
                                    ...acc.temperature,
                                    {
                                        name: getTimeName(i.timekey?.value),
                                        time: new Date(i.timekey?.value).getTime(),
                                        temperature: i.temperature,
                                    },
                                ],
                                decibel: [
                                    ...acc.decibel,
                                    {
                                        name: getTimeName(i.timekey?.value),
                                        time: new Date(i.timekey?.value).getTime(),
                                        decibel: i.decibel,
                                    },
                                ],
                                lux: [
                                    ...acc.lux,
                                    {
                                        name: getTimeName(i.timekey?.value),
                                        time: new Date(i.timekey?.value).getTime(),
                                        lux: i.lightIntensity,
                                    },
                                ],
                            };
                        },
                        {
                            pm_2_5: [],
                            pm_10: [],
                            co2: [],
                            humidity: [],
                            temperature: [],
                            decibel: [],
                            lux: [],
                            co2Avg: 0,
                        },
                    );
                console.log({ result });
                // name: format(log[0] * 1000, "MMM dd - HH:mm"),
                // [sensor.units[1]]: log[1],
                // [sensor.units[2]]: log[2],
                // [sensor.units[3]]: log[3],
                // [sensor.units[4]]: log[4],
                // [sensor.units[5]]: log[5],
                // [sensor.units[6]]: log[6],
                // time: log[0],
                setLogsAndStats(result);
                setIsLoading(false);
            };
            if (deviceId) {
                fetchData();
            }
        } catch (error) {
            console.error(error);
        }
    }, [deviceId, date]);

    return { logsAndStats, isLoading };
}
