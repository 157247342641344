import React, { useEffect } from "react";
// import { useFirestoreConnect } from "react-redux-firebase";
import { Layout, Row, Col, Table, Card, Badge, Statistic } from "antd";
import SideNav from "../../../Layout/SideNav";
// import { getGsmPlantData, getSensor } from "../../../modules/sensors/queries";
import HeaderBar from "../../../Layout/HeaderBar";
import { Content } from "antd/lib/layout/layout";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../store";
import { useParams } from "react-router";
import { fetchSensor } from "../../../modules/sensors/actions";
import { addDays, subDays } from "date-fns";
import AlertCard from "./AlertCard";
import useLatestCustomLog from "./useLatestGsmPlantLog";
import { useAppSelector } from "../../../hooks";
import LogsCard from "./LogsCard";
import UsageChart from "./UsageChart";

const latestDummy = {
    module: "Test plant",
    timestamp: new Date(),
    //
    nodtryk: 1,
    alarm: 1,
    binder_mangel: 1,
    noedtryk: 1,
    termo_fejl: 1,
    vand_mangel: 1,
    //
    mix_1_1000: 50,
    bindermiddel_total_l: 50,
    vand_1000l: 0,
    vand_1l: 1000,
    //
    pumpe_drift_tid: 20,
    varme_on_total_tid: 0,
    kompressor_drift_tid: 10,
    //
    temp_ude: 0,
    service_tryk_pumpe: 0,
    service_kompressor: 0,
};
const weekData = [
    {
        ...latestDummy,
        timestamp: subDays(new Date(), 8),
        bindermiddel_total_l: 5,
        vand_1l: 100,
    },
    {
        ...latestDummy,
        timestamp: subDays(new Date(), 7),
        bindermiddel_total_l: 10,
        vand_1l: 200,
    },
    {
        ...latestDummy,
        timestamp: subDays(new Date(), 6),
        bindermiddel_total_l: 15,
        vand_1l: 300,
    },
    {
        ...latestDummy,
        timestamp: subDays(new Date(), 5),
        bindermiddel_total_l: 20,
        vand_1l: 400,
    },
    {
        ...latestDummy,
        timestamp: subDays(new Date(), 4),
        bindermiddel_total_l: 25,
        vand_1l: 500,
    },
    {
        ...latestDummy,
        timestamp: subDays(new Date(), 3),
        bindermiddel_total_l: 30,
        vand_1l: 600,
    },
    {
        ...latestDummy,
        timestamp: subDays(new Date(), 2),
        bindermiddel_total_l: 35,
        vand_1l: 700,
    },
    {
        ...latestDummy,
        timestamp: subDays(new Date(), 1),
        bindermiddel_total_l: 50,
        vand_1l: 1000,
    },
];
const data = [{}];
/**
 * GSM plant schema:
 *  Module
    Timestamp
    // ALARMS
    Nodtryk (BM VM WORD96)
    Alarm (BM DM58)
    Binder Mangel (BM DI8)
    Noedtryk (BM DI4)
    Termo fejl (BM DI5)
    Vand mangel (BM VM DWORD32)
    // mængde
    Mix 1/1000 (BM VM DWORD100)
    Bindermiddel total L. (BM VM DWORD50)
    Vand 1000L (BM VM DWORD104)
    Vand 1L (BM VM DWORD108)
    // tid
    Pumpe drift tid (BM VM WORD56)
    Varme ON total tid (BM VM DWORD92)
    Temp Ude (BM VM WORD90)
    Kompressor drift tid
    // what type is this?
    Service  tryk pumpe
    Service  kompressor
 */

export default function GSMCustomContainer() {
    const dispatch = useDispatch();
    const { orgId, sensorId } = useParams<{ orgId: string; sensorId: string }>();
    const deviceId = useAppSelector(state => state.sensors.currentSensor?.deviceId);
    console.log({ deviceId });

    const { isLoading, data } = useLatestCustomLog({ deviceId });

    useEffect(() => {
        dispatch(fetchSensor({ orgId, sensorId }));
        return () => {};
    }, [orgId, sensorId, dispatch]);

    return (
        <>
            <SideNav />
            <Layout>
                <HeaderBar />
                <Content style={{ margin: "50px 24px 0" }}>
                    <Row gutter={16}>
                        <Col md={12} xs={24} style={{ marginBottom: 20 }}>
                            <AlertCard isLoading={isLoading} data={data[0]} />
                        </Col>
                        <Col md={12} xs={24}>
                            <LogsCard isLoading={isLoading} data={data} />
                        </Col>
                        <Col md={24} xs={24} style={{ marginTop: 20 }}>
                            <Card bordered={false}>
                                <UsageChart isLoading={isLoading} data={data} />
                            </Card>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </>
    );
}
