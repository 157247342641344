import firebase from "../../firebase";
import { useEffect, useState } from "react";
import { AuditSchedule } from "./types";
import { useAppDispatch } from "hooks";
import { fetchNeedsResolutionAuditSchedulesSuccess } from "./actions";
import { addDays, addMonths } from "date-fns";

/**
 * Filtering out if it is on hold
 * @param orgId
 * @returns
 */

export default function useUpcomingAudits(orgId: string) {
    const dispatch = useAppDispatch();
    const [audits, setAudits] = useState<any[]>([]);
    const [isLoading, setisLoading] = useState(true);
    useEffect(() => {
        let unsubscribe: any = null;
        unsubscribe = firebase
            .firestore()
            .collection(`orgs`)
            .doc(orgId)
            .collection("auditSchedules")
            .where("isCompleted", "==", false)
            .where("dueDate", ">", new Date())
            .where("dueDate", "<", addMonths(new Date(), 1))
            .orderBy("dueDate", "asc")
            .limit(100)
            .onSnapshot(query => {
                if (!query.empty) {
                    const list: any[] = query.docs.map(
                        doc =>
                            ({
                                id: doc.id,
                                ...doc.data(),
                            } as AuditSchedule),
                    );

                    setAudits(list);
                    // dispatch(fetchNeedsResolutionAuditSchedulesSuccess(query));
                } else {
                    setAudits([]);
                }
                setisLoading(false);
            });
        return unsubscribe;
    }, [orgId]);

    return { audits, isLoading };
}
