import React from "react";
import { Statistic, Card } from "antd";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "hooks";

export default function UsageCard({
    isLoading,
    data,
}: {
    isLoading: boolean;
    data: any;
}) {
    const { t } = useTranslation();
    const locale = useAppSelector(state => state.user.userDoc.intl);
    const formatter = new Intl.NumberFormat(locale.replace("_", "-"), {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
    });

    const waterCount = formatter.format(
        Number(data?.vand_total_l) / 1000 + Number(data?.vand_total_1000_l),
    );
    return (
        <Card
            loading={isLoading}
            // TODO: fix intl
            title={<div>{t("gsm.usage.card_title")}</div>}
            bordered={false}>
            <div>
                <Statistic
                    title={t("gsm.usage.binder_total_title")}
                    value={data?.binder_total_l}
                    groupSeparator="."
                    decimalSeparator=","
                    suffix={<div style={{ fontSize: 16 }}>{t("gsm.usage.liter")}</div>}
                />
            </div>
            <div>
                <Statistic
                    title={t("gsm.usage.water_title")}
                    value={waterCount}
                    groupSeparator="."
                    decimalSeparator=","
                    suffix={<div style={{ fontSize: 16 }}>{t("gsm.usage.m3")}</div>}
                />
            </div>
            <div>
                <Statistic
                    title={t("gsm.usage.mix_title")}
                    value={data?.mix / 1}
                    suffix={
                        <div style={{ fontSize: 16 }}>/ 1000 {t("gsm.usage.liter")}</div>
                    }
                />
            </div>
        </Card>
    );
}
