import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import AuditTemplateDetail from "./AuditTemplateDetail";
import { useAppSelector } from "../../hooks";
import {
    fetchGroupAuditTemplate,
    fetchGroupAuditTemplateTasks,
    updateGroupAuditTemplate,
    addGroupAuditTemplateTask,
    removeGroupAuditTemplateFile,
} from "../../modules/groupAuditTemplates/actions";

function AuditTemplateDetailContainer() {
    const dispatch = useDispatch();
    const { groupId, auditTemplateId } = useParams<{
        groupId: string;
        auditTemplateId: string;
    }>();
    const { currentAuditTemplate, loading } = useAppSelector(state => ({
        currentAuditTemplate: state.groupAuditTemplates.currentGroupAuditTemplate,
        loading: state.groupAuditTemplates.loading,
    }));
    const currentAuditTemplateTasks = useAppSelector(
        state => state.groupAuditTemplates.currentGroupAuditTemplateTasks,
    );
    useEffect(() => {
        dispatch(fetchGroupAuditTemplate({ groupId, auditTemplateId }));
        dispatch(fetchGroupAuditTemplateTasks({ groupId, auditTemplateId }));
    }, [groupId, auditTemplateId, dispatch]);

    const dispatchAddAuditTemplateTask = (title: string, order: number) => {
        console.log({ title, order });

        dispatch(addGroupAuditTemplateTask({ groupId, auditTemplateId, title, order }));
    };

    const dispatchUpdateAuditTemplate = (title: string) => {
        dispatch(updateGroupAuditTemplate({ groupId, auditTemplateId, title }));
    };
    const dispatchRemoveAuditTemplateFile = () => {
        dispatch(removeGroupAuditTemplateFile({ groupId, auditTemplateId, guide: null }));
    };

    return (
        <AuditTemplateDetail
            {...currentAuditTemplate}
            auditTemplateCategory={currentAuditTemplate?.auditTemplateCategory ?? null}
            tasks={currentAuditTemplateTasks}
            loading={loading}
            groupId={groupId}
            addAuditTemplateTask={dispatchAddAuditTemplateTask}
            updateAuditTemplate={dispatchUpdateAuditTemplate}
            removeAuditTemplateFile={dispatchRemoveAuditTemplateFile}
        />
    );
}

export default AuditTemplateDetailContainer;
