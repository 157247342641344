import { call, put, takeLatest } from "redux-saga/effects";
import firebase, { reduxSagaFirebase } from "../../firebase";
import { fetchReportsSuccess } from "./actions";
import { REPORT_UPDATE_REQUESTED } from "./types";
import {
    REPORT_FETCH_FAILED,
    REPORT_FETCH_REQUESTED,
    REPORT_UPDATE_FAILED,
} from "./types";

function* fetchReports({ payload }: { payload: { orgId: string } }) {
    const { orgId } = payload;
    try {
        yield call(
            // @ts-ignore
            reduxSagaFirebase.firestore.syncCollection,
            firebase
                .firestore()
                .collection("orgs")
                .doc(orgId)
                .collection(`reports`)
                .where("isDeleted", "==", false)
                .orderBy("createTime", "desc"),
            { successActionCreator: fetchReportsSuccess },
        );
    } catch (e: any) {
        yield put({ type: REPORT_FETCH_FAILED, message: e.message });
    }
}

export function* fetchReportsSaga() {
    // @ts-ignore
    yield takeLatest(REPORT_FETCH_REQUESTED, fetchReports);
}

function* updateReport({
    payload,
}: {
    payload: { orgId: string; reportId: string; data: { title: string } };
}) {
    const { orgId, reportId, data } = payload;
    try {
        yield call(
            // @ts-ignore
            reduxSagaFirebase.firestore.updateDocument,
            `orgs/${orgId}/reports/${reportId}`,
            data,
        );
    } catch (e: any) {
        yield put({ type: REPORT_UPDATE_FAILED, message: e.message });
    }
}

export function* updateReportsSaga() {
    // @ts-ignore
    yield takeLatest(REPORT_UPDATE_REQUESTED, updateReport);
}
