import { useEffect } from "react";
import moment from "moment";
import { Form, Select, Row, Col, Button, Modal, TimePicker, List, Tooltip } from "antd";
import { getDayOfWeek, getDateOfMonth, getDate } from "../../general/dates";
import { Intl, RecurringOptions } from "../../general/enums";
import {
    getHours,
    getMinutes,
    isSameDay,
    setHours,
    setMinutes,
    setSeconds,
    subDays,
} from "date-fns";
import { Moment } from "moment";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../hooks";
import { format } from "date-fns";
import keyBy from "lodash.keyby";
import DeleteAuditScheduleButton from "./DeleteAuditScheduleButton";
import { sortOnStartAndDueDate } from "general/sort";
import { AuditSchedule } from "modules/auditSchedule/types";
import { LinkOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { getScheduleByEnum } from "./scheduleHelpers";

interface Props {
    id: string | null;
    handleClose: VoidFunction;
}
export default function EditScheduleModal({ id, handleClose }: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    const d = id;
    const orgId = useSelector((state: AppState) => state.orgs.currentOrg.id);
    const intl = useSelector((state: AppState) => state.user.userDoc.intl);

    const planned = useAppSelector(state => {
        const audit = state.auditSchedules.auditSchedules.find(a => a.id === id);
        return audit ? [audit] : [];
    });

    useEffect(() => {
        if (planned?.length < 1) {
            handleClose();
        }
    }, [planned.length]);
    const [form] = Form.useForm();

    useEffect(() => {
        form.validateFields();
    }, []);

    if (!d || !planned.length) return null;
    return (
        <Modal
            width="50%"
            style={{ position: "relative" }}
            open={Boolean(id)}
            onCancel={handleClose}
            footer={[]}>
            <Row>
                <Col span={24}>
                    <h1>{t("audit.general")}</h1>
                </Col>
                <Col span={24}>
                    <List
                        size="large"
                        bordered
                        dataSource={planned}
                        renderItem={item => (
                            <List.Item
                                actions={[
                                    <Tooltip title={t("audit.goTo")}>
                                        <Button
                                            onClick={() => {
                                                history.push(
                                                    `/orgs/${orgId}/audit/audits/${item.id}`,
                                                );
                                            }}
                                            shape="circle"
                                            icon={<LinkOutlined />}
                                        />
                                    </Tooltip>,
                                    <DeleteAuditScheduleButton
                                        auditScheduleId={item.id}
                                        // handleClose={handleClose}
                                    />,
                                ]}>
                                <List.Item.Meta
                                    title={item.templateTitle}
                                    description={
                                        <div>
                                            <div>
                                                {item?.startDate?.toDate() &&
                                                    `${t("general.dateTime", {
                                                        date: item.startDate.toDate(),
                                                    })}`}
                                                {item?.startDate?.toDate()?.getTime() !==
                                                    item?.dueDate?.toDate()?.getTime() &&
                                                    ` - ${t("general.dateTime", {
                                                        date: item.dueDate?.toDate(),
                                                    })}`}
                                            </div>
                                            {item.recurringId && (
                                                <div>
                                                    {t("audit.schedule.repeatlabel")}
                                                    {": "}
                                                    {getScheduleByEnum(
                                                        item.recurringOption,
                                                        item.dueDate.toDate(),
                                                        intl,
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </Modal>
    );
}
