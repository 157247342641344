import omitBy from "lodash.omitby";
import isUndefined from "lodash.isundefined";

export function omitUndefined(object: object) {
    return omitBy(object, isUndefined);
}

export function parseAmountToFloat(number: number | string) {
    return parseFloat(`${number}`.replace(",", "."));
}

export function getUniqueNumbers(numbers: number[]) {
    const uniqueNumbers = [];
    for (let i = 0; i < numbers.length; i++) {
        if (uniqueNumbers.indexOf(numbers[i]) === -1) {
            uniqueNumbers.push(numbers[i]);
        }
    }
    return uniqueNumbers;
}

export const fakeAPICall = (delay: number = 2000): Promise<void> => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, delay);
    });
};
