export const CATEGORIES_FETCH_REQUESTED = "CATEGORIES_FETCH_REQUESTED";
export const CATEGORIES_FETCH_SUCCEEDED = "CATEGORIES_FETCH_SUCCEEDED";
export const CATEGORIES_FETCH_FAILED = "CATEGORIES_FETCH_FAILED";

export const CATEGORIES_CREATE_REQUESTED = "CATEGORIES_CREATE_REQUESTED";
export const CATEGORIES_CREATE_SUCCEEDED = "CATEGORIES_CREATE_SUCCEEDED";
export const CATEGORIES_CREATE_FAILED = "CATEGORIES_CREATE_FAILED";

export const CATEGORIES_DELETE_REQUESTED = "CATEGORIES_DELETE_REQUESTED";
export const CATEGORIES_DELETE_SUCCEEDED = "CATEGORIES_DELETE_SUCCEEDED";
export const CATEGORIES_DELETE_FAILED = "CATEGORIES_DELETE_FAILED";

export interface Category {
    id: string;
    title: string;
}

export interface CategoriesState {
    loading: boolean;
    list: Category[];
    categoriesMap: { [id: string]: Category };
}

export interface CategorySuccessPayload {
    categories: Category[];
}

interface FetchCategories {
    type: typeof CATEGORIES_FETCH_REQUESTED;
    payload: { orgId: string };
}
interface FetchCategoriesSuccess {
    type: typeof CATEGORIES_FETCH_SUCCEEDED;
    payload: CategorySuccessPayload;
}
interface FetchCategoriesFailed {
    type: typeof CATEGORIES_FETCH_FAILED;
    payload: {};
}

export type CategoryActionTypes =
    | FetchCategories
    | FetchCategoriesSuccess
    | FetchCategoriesFailed;
