import React from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { useTranslation } from "react-i18next";

const FormItem = Form.Item;

function CategoryInput(props: any) {
    const { t } = useTranslation();
    const handleSubmit = (values: any) => {
        console.log("Received values of form: ", values);
        props.addCategories(values.category);
    };

    return (
        <Form onFinish={handleSubmit}>
            <FormItem
                name="category"
                rules={[{ required: true, message: "Tilføj kategori" }]}>
                <Input placeholder={t("settings.categories_placeholder")} />
            </FormItem>
            <Button type="primary" htmlType="submit" className="login-form-button">
                {t("settings.categories_button")}
            </Button>
        </Form>
    );
}

export default CategoryInput;
