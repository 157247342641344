import { useEffect } from "react";
import { useParams } from "react-router";
import InventoryView from "./index";
import Loader from "../../../components/Loader";

import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../store";
import { fetchOrgs } from "../../../modules/orgs/actions";
import useInventoryGPS from "./useInventoryGPS";
import useInventoryTags from "Views/SensorOverview/useInventoryTags";
import { InventoryType } from "modules/inventory/types";
import useInventorySimcards from "../Simcards/useInventorySimcards";

function GPSInventoryContainer() {
    const disptach = useDispatch();
    const loading = false;
    const { gps } = useInventoryGPS();
    const { simcards } = useInventorySimcards();
    const { inventoryTagsMap } = useInventoryTags();
    const uid = useSelector((state: AppState) => state.user.details.uid);
    const hasOrgs = useSelector((state: AppState) => state.orgs.orgs?.length > 0);
    // useEffect(() => {
    //     if (!hasOrgs) {
    //         disptach(fetchOrgs(uid));
    //     }
    //     return () => {};
    // }, [uid]);

    if (loading) {
        return <Loader />;
    }
    return (
        <InventoryView
            gps={gps}
            simcards={simcards}
            inventoryTagsMap={inventoryTagsMap}
        />
    );
}

export default GPSInventoryContainer;
