import firebase from "firebase/compat/app";
import { useEffect, useState } from "react";

export default function useEmailDigestNotifications(userId: string) {
    const [user, setuser] = useState<{ dailyDigestEmail?: boolean }>({});
    const hasDailyDigestEmail = user?.dailyDigestEmail;
    useEffect(() => {
        let sub: () => void;
        if (userId) {
            sub = firebase
                .firestore()
                .collection("users")
                .doc(userId)
                .onSnapshot(doc => {
                    const userData = doc.data() as { dailyDigestEmail?: boolean };
                    setuser(userData);
                });
        }
        return () => {
            sub?.();
        };
    }, [userId]);

    const onUpdateDailyDigestEmail = async (checked: boolean) => {
        if (userId) {
            await firebase.firestore().collection("users").doc(userId).set(
                {
                    dailyDigestEmail: checked,
                },
                { merge: true },
            );
        }
    };
    return {
        hasDailyDigestEmail,
        onUpdateDailyDigestEmail,
    };
}
