import React from "react";
import { Chart } from "react-google-charts";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { CustomLog } from "./useLatestGsmPlantLog";
import { format } from "date-fns";

function formatData(data: CustomLog[]) {
    return data
        .reverse()
        .map(l => ({ date: new Date(l.senttime * 1000), message: l.message }));
}

function getAllUniqAlert(data: CustomLog[]) {
    const alerts = data.map(i => i.message);
    return Array.from(new Set(alerts));
}

export default function UsageChart({
    isLoading,
    data,
}: {
    isLoading: boolean;
    data: CustomLog[];
}) {
    const { t } = useTranslation();
    const alertTypes = getAllUniqAlert(data);

    console.log({ alertTypes });

    const types = [
        t("gsm.usagechart.series_time"),
        ...alertTypes,
        // t("gsm.usagechart.series_water"),
    ];
    const newData = formatData(data);
    console.log({ newData });
    const aggregated = aggregateAlertsByDate(newData, alertTypes);
    console.log({ aggregated });

    // @ts-ignore
    // const usage = data.reduce((acc, i) => (acc += i.binder_total_l), 0);
    if (isLoading) {
        return <div>Loadding...</div>;
    }
    // if (usage < 1 || data.length < 1) {
    //     return <div>No usage data to display yet</div>;
    // }
    return (
        <Chart
            chartType="Bar"
            width="100%"
            height="400px"
            data={[types, ...aggregated]}
            options={{
                // hAxis: {
                //     title: "Date",
                //     format: "MMM yyyy", // Format the date as needed
                // },
                chart: {
                    title: t("gsm.logChart.title"),
                    subtitle: t("gsm.logChart.subtitle"),
                },
                // colors: ["#1890ff", "#bbdefb"],
            }}
        />
    );
}

function aggregateAlertsByDate(
    alerts: Array<{ date: Date; message: string }>,
    alertTypes: string[],
) {
    const counts: { [key: string]: { [key: string]: number } } = {}; // Date string to type to count mapping
    alerts.forEach(({ date, message }) => {
        const dateStr = format(date, "dd-MM-yyyy");
        if (!counts[dateStr]) {
            counts[dateStr] = {
                [message]: 1,
            };
        } else if (!counts[dateStr][message]) {
            counts[dateStr][message] = 1;
        } else {
            counts[dateStr][message]++;
        }
    });

    console.log({ counts });

    // const counts: { [key: string]: { [key: string]: number } } = {}; // Date string to type to count mapping

    // alerts.forEach(({ date, message }) => {
    //     // Convert date to YYYY-MM-DD format for consistent grouping
    //     const dateKey = format(date, "MM-dd-yyyy");
    //     if (!counts[dateKey]) {
    //         counts[dateKey] = {};
    //     }
    //     if (!counts[dateKey][message]) {
    //         counts[dateKey][message] = 1;
    //     } else {
    //         counts[dateKey][message]++;
    //     }
    // });

    // // Convert counts object to an array suitable for the chart
    const aggregatedData: Array<[string, number, number]> = []; // Chart header
    Object.entries(counts).forEach(([dateStr, typeCounts]) => {
        console.log({ dateStr, typeCounts, alerts });
        // @ts-ignore
        aggregatedData.push([
            dateStr,
            ...alertTypes.map(message => typeCounts[message] || 0),
        ]);
    });

    console.log({ aggregatedData });

    return aggregatedData;
}
