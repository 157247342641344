import { EditOutlined, NotificationOutlined } from "@ant-design/icons";
import { Avatar, Card, Skeleton } from "antd";
import Meta from "antd/es/card/Meta";
import React from "react";
import NotificationSettings from "./components/NotificationSettings";
import { getInitials } from "general/initials";
import { RoleText } from "./components/columns";
import MemberSettings from "./components/MemberSettings";
import { StyleGuide } from "styles/StyleGuide";

export default function MemberCard({ member }: any) {
    const loading = false;
    return (
        <Card
            style={{ marginTop: 8 }}
            bodyStyle={{ minHeight: 139 }}
            actions={[
                <NotificationSettings
                    key="notifications"
                    record={member}
                    // onUpdateNotifications={e => onUpdateNotifications(e, record)}
                />,
                // <NotificationOutlined key="setting" />,
                <MemberSettings member={member} />,
            ]}>
            <Skeleton loading={loading} avatar active>
                <Meta
                    style={{ height: "100%" }}
                    avatar={
                        <Avatar
                            size="large"
                            shape="square"
                            style={{
                                backgroundColor: StyleGuide.palette.infoLighter,
                                color: StyleGuide.palette.info,
                                fontWeight: "bold",
                            }}>
                            {getInitials(member?.name || member.email)}
                        </Avatar>
                    }
                    title={member?.name || member?.email}
                    description={
                        <div>
                            <div>{member?.name && member?.email}</div>
                            <div>{member?.phone}</div>
                            <div>
                                <RoleText role={member?.role} />
                            </div>
                        </div>
                    }
                />
            </Skeleton>
        </Card>
    );
}
