import { Colors } from "Layout/Colors";
import { Popover } from "antd";
import { AuditScheduleTask } from "modules/auditSchedule/types";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyleGuide } from "styles/StyleGuide";

export default function PopoverTaskDetails({
    title,
    tasks,
    color,
    children,
}: {
    title: string;
    tasks: AuditScheduleTask[];
    color: string;
    children: React.ReactNode;
}) {
    const { t } = useTranslation();
    console.log({ tasks });

    return (
        <Popover
            placement="topRight"
            content={() => (
                <div>
                    <h3 style={{ marginTop: 0 }}>{t(title)}</h3>
                    <ol>
                        {tasks?.map(t => (
                            <li
                                style={{
                                    color,
                                    fontSize: 16,
                                    fontWeight: "bold",
                                }}>
                                <div
                                    style={{
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        lineHeight: 1.5,
                                        color,
                                    }}>
                                    {t?.description}
                                </div>
                                <div
                                    style={{
                                        fontSize: 14,
                                        fontWeight: "normal",
                                        color: StyleGuide.palette.grey600,
                                    }}>
                                    {t.issueDescription}
                                </div>
                                <div
                                    style={{
                                        fontSize: 14,
                                        fontWeight: "normal",
                                        color: StyleGuide.palette.grey600,
                                    }}>
                                    {t.onHoldDescription}
                                </div>
                            </li>
                        ))}
                    </ol>
                </div>
            )}
            title=""
            trigger="hover"
            overlayStyle={{ minWidth: 400 }}>
            {children}
        </Popover>
    );
}
