import ForgotPasswordForm from "./Form";
import usePasswordReset from "../../hooks/usePasswordReset";

function ForgotPasswordFormContainer() {
    const { loading, error, success, dispatchSendPasswordResetEmail } =
        usePasswordReset();

    return (
        <ForgotPasswordForm
            loading={loading}
            error={error}
            success={success}
            sendPasswordResetEmail={dispatchSendPasswordResetEmail}
        />
    );
}

export default ForgotPasswordFormContainer;
