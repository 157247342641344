import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Layout, Menu, Button } from "antd";
import { AppState } from "../store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isAdmin, isAdminAndMichael } from "../components/AdminOnly";
import {
    ArrowLeftOutlined,
    ShopOutlined,
    UserOutlined,
    CheckCircleOutlined,
    SettingOutlined,
    ClearOutlined,
    WifiOutlined,
    FileOutlined,
    FireOutlined,
    DashboardOutlined,
    ControlOutlined,
    FieldTimeOutlined,
    SafetyCertificateOutlined,
    AuditOutlined,
    AlertOutlined,
    DollarCircleOutlined,
} from "@ant-design/icons";
import { FeatureIds } from "../modules/featureControl/featuresConfig";
import { StyleGuide } from "styles/StyleGuide";
import SensorIcon from "assets/icons/SensorIcon";
const SubMenu = Menu.SubMenu;
const { Sider } = Layout;

interface IProps {
    location: {
        pathname: string;
    };
    match: {
        params: {
            orgId: string;
        };
    };
}

const navigationConfig = [
    {
        id: FeatureIds.DASHBOARD,
        link: (orgId: string) => `/orgs/${orgId}/dashboard`,
        title: "sidenav.dashboard",
        icon: <DashboardOutlined />,
        isAdminOnly: false,
    },
    {
        id: FeatureIds.CLEAN_LOGS,
        link: (orgId: string) => `/orgs/${orgId}/clean-logs`,
        title: "sidenav.cleanlog",
        icon: <ClearOutlined />,
    },
    {
        id: FeatureIds.HOUR_TRACKER,
        link: (orgId: string) => `/orgs/${orgId}/hour-tracker`,
        title: "sidenav.hourTracker",
        icon: <FieldTimeOutlined />,
    },
    {
        id: FeatureIds.ISSUES,
        // link: (orgId: string) => `/orgs/${orgId}/clean-logs`,
        title: "sidenav.issuesTitle",
        icon: <AlertOutlined />,
        subMenu: [
            {
                link: (orgId: string) => `/orgs/${orgId}/issues`,
                title: "sidenav.reportedIssues",
            },
            {
                link: (orgId: string) => `/orgs/${orgId}/create-issue`,
                title: "sidenav.reportIssue",
            },
            {
                link: (orgId: string) => `/orgs/${orgId}/issues-overview`,
                title: "sidenav.overviewIssue",
            },
        ],
    },
    {
        id: FeatureIds.AUDITS,
        // link: (orgId: string) => `/orgs/${orgId}/clean-logs`,
        title: "sidenav.audit",
        icon: <AuditOutlined />,
        subMenu: [
            {
                link: (orgId: string) => `/orgs/${orgId}/audit/templates`,
                title: "sidenav.auditTemplate",
            },
            {
                link: (orgId: string) => `/orgs/${orgId}/audit/schedule`,
                title: "sidenav.auditSchedule",
            },
            {
                link: (orgId: string) => `/orgs/${orgId}/audit/audits`,
                title: "sidenav.auditUpcoming",
            },
        ],
    },
    {
        id: FeatureIds.SENSORS,
        link: (orgId: string) => `/orgs/${orgId}/sensors`,
        title: "sidenav.sensors",
        icon: (
            <span role="img" aria-label="file" className="anticon anticon-file">
                <SensorIcon width={16} height={16} color={StyleGuide.palette.infoDark} />
            </span>
        ),
    },
    {
        id: FeatureIds.REPORTS,
        link: (orgId: string) => `/orgs/${orgId}/reports`,
        title: "sidenav.reports",
        icon: <FileOutlined />,
    },
    {
        id: FeatureIds.ORDERS,
        link: (orgId: string) => `/orgs/${orgId}/orders`,
        title: "sidenav.orders",
        icon: <ShopOutlined />,
    },
    {
        id: FeatureIds.CERTIFICATION,
        link: (orgId: string) => `/orgs/${orgId}/certification`,
        title: "sidenav.certification",
        icon: <SafetyCertificateOutlined />, // <ScheduleOutlined /> or <SolutionOutlined /> or //<FileProtectOutlined /> or <FileDoneOutlined />
        isAdminAndMichaelOnly: false,
    },
    {
        id: FeatureIds.MEMBERS,
        link: (orgId: string) => `/orgs/${orgId}/members`,
        title: "sidenav.access",
        icon: <UserOutlined />,
    },
    {
        id: FeatureIds.CHECK_IN,
        link: (orgId: string) => `/orgs/${orgId}/checkin`,
        title: "sidenav.checkin",
        icon: <CheckCircleOutlined />,
    },
    {
        id: FeatureIds.SETTINGS,
        link: (orgId: string) => `/orgs/${orgId}/settings`,
        title: "sidenav.settings",
        icon: <SettingOutlined />,
    },
    {
        id: FeatureIds.BILLING,
        link: (orgId: string) => `/orgs/${orgId}/billing`,
        title: "sidenav.billing",
        icon: <DollarCircleOutlined />,
        isAdminAndMichaelOnly: true,
    },
    {
        id: FeatureIds.ACTIVITY,
        link: (orgId: string) => `/orgs/${orgId}/activity`,
        title: "sidenav.activity",
        icon: <FireOutlined />,
        isAdminOnly: true,
    },
    {
        id: "",
        link: (orgId: string) => `/orgs/${orgId}/featureControl`,
        title: "sidenav.featureControl",
        icon: <ControlOutlined />,
        isAdminOnly: true,
    },
];

function SideNav({ location, match }: IProps) {
    const userId = useSelector((state: AppState) => state.user.details.uid);
    const features = useSelector((state: AppState) => state.features.featuresMap);
    const { orgId } = match.params;
    const { t } = useTranslation();

    return (
        <Sider
            collapsible
            breakpoint="lg"
            // collapsedWidth="0"
            style={{
                textAlign: "left",
                borderRight: `1px solid ${StyleGuide.palette.grey200}`,
                paddingTop: 4,
            }}
            onCollapse={() => {}}>
            {/* <div className="sidenav_logo">
                <Link to="/depts">
                    <Button
                        ghost
                        style={{
                            padding: "0 10px",
                            width: "100%",
                            // color: "#fff",
                            display: "flex",
                            alignItems: "center",
                            // backgroundColor: "rgba(255, 255, 255, 0.2)",
                            // height: 20,
                        }}>
                        <ArrowLeftOutlined
                            style={{ display: "flex", fontSize: "20px" }}
                        />
                        {t("sidenav.back")}
                    </Button>
                </Link>
            </div> */}
            <Menu
                mode="inline"
                style={{ border: "none" }}
                defaultSelectedKeys={[location.pathname]}>
                {navigationConfig.map(nav => {
                    // if it is an admin only page check if admin else render
                    const shouldDisplay = nav.isAdminOnly
                        ? isAdmin(userId)
                        : nav.isAdminAndMichaelOnly
                        ? isAdminAndMichael(userId)
                        : features[nav?.id as FeatureIds]?.isDisplayed !== false;
                    if (nav.link && shouldDisplay) {
                        return (
                            <Menu.Item key={nav.link(orgId)}>
                                <Link to={nav.link(orgId)}>
                                    {nav.icon}
                                    <span className="nav-text">{t(nav.title)}</span>
                                </Link>
                            </Menu.Item>
                        );
                    }
                    if (nav.subMenu && shouldDisplay) {
                        return (
                            <SubMenu
                                key={nav.title}
                                title={
                                    <span>
                                        {nav.icon}
                                        <span>{t(nav.title)}</span>
                                    </span>
                                }>
                                {nav.subMenu.map(subNav => (
                                    <Menu.Item
                                        style={{ paddingLeft: 24 }}
                                        key={subNav.link(orgId)}>
                                        <Link to={subNav.link(orgId)}>
                                            <span className="nav-text">
                                                {t(subNav.title)}
                                            </span>
                                        </Link>
                                    </Menu.Item>
                                ))}
                            </SubMenu>
                        );
                    }
                    return null;
                })}
            </Menu>
        </Sider>
    );
}

export default withRouter(SideNav);
