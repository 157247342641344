import keyBy from "lodash.keyby";
import { IOrg } from "./interfaces";
import { OrgActionTypes, OrgState } from "./types";
import {
    ORGS_FETCH_REQUESTED,
    ORGS_FETCH_SUCCEEDED,
    ORGS_FETCH_FAILED,
    ORG_FETCH_REQUESTED,
    ORG_FETCH_SUCCEEDED,
    ORG_FETCH_FAILED,
} from "./types";

const initialState: OrgState = {
    orgs: [],
    orgMap: {},
    loading: true,
    currentOrg: {} as IOrg,
};

export default function user(state = initialState, action: OrgActionTypes) {
    switch (action.type) {
        case ORGS_FETCH_REQUESTED:
        case ORG_FETCH_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case ORGS_FETCH_SUCCEEDED:
            return {
                ...state,
                orgs: action.payload.data,
                orgMap: keyBy(action.payload.data, org => org.id),
                loading: false,
            };
        case ORGS_FETCH_FAILED:
        case ORG_FETCH_FAILED:
            return {
                ...state,
                loading: false,
            };
        case ORG_FETCH_SUCCEEDED:
            console.log({ currentOrg: action.payload.data });

            return {
                ...state,
                currentOrg: action.payload.data as IOrg,
                loading: false,
            };
        default:
            return state;
    }
}
