import React from "react";
import SignInForm from "./Form";
import useSignin from "../../hooks/useSignin";

function SignInFormContainer() {
    const { loading, error, dispatchLogin } = useSignin();
    return <SignInForm loading={loading} error={error} login={dispatchLogin} />;
}

export default SignInFormContainer;
