import { isDev } from "config";
import firebase from "firebase/compat/app";
export const ROOT = isDev
    ? "http://127.0.0.1:5001/akstotal-v2/us-central1"
    : "https://us-central1-akstotal-v2.cloudfunctions.net";
export const ROOT_EU3 = isDev
    ? "http://127.0.0.1:5001/akstotal-v2/europe-west3"
    : "https://europe-west3-akstotal-v2.cloudfunctions.net";

async function post(endpoint: string, body: any, base: string = ROOT): Promise<Response> {
    const token = (await firebase.auth().currentUser?.getIdToken()) ?? null;
    const response = await fetch(`${base}/${endpoint}`, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, cors, *same-origin
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    });
    return response;
}

export { post };
