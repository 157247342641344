import React from "react";
import { Layout } from "antd";
import SideNav from "../../Layout/SideNav";
import HeaderBar from "../../Layout/HeaderBar";
import FooterBar from "../../Layout/FooterBar";
import FeatureControlView from "./FeatureControlView";
import Loader from "../../components/Loader";

const { Content } = Layout;

function FeatureControl(props: any) {
    return (
        <>
            <SideNav />
            <Layout>
                <HeaderBar />
                <Content style={{ margin: "50px 24px 0" }}>
                    {props.loading ? <Loader /> : <FeatureControlView />}
                </Content>
                <FooterBar />
            </Layout>
        </>
    );
}

export default FeatureControl;
