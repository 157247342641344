import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Layout, Menu, Button } from "antd";
import { ArrowLeftOutlined, ProfileOutlined } from "@ant-design/icons";
const { Sider } = Layout;

interface IProps {
    location: {
        pathname: string;
    };
}

function SideNav({ location }: IProps) {
    return (
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
            style={{ textAlign: "left" }}
            onCollapse={() => {}}>
            <div className="sidenav_logo">
                <Link to="/depts">
                    <Button
                        ghost
                        style={{
                            padding: "0 10px",
                            width: "100%",
                            color: "#fff",
                            display: "flex",
                            alignItems: "center",
                        }}>
                        <ArrowLeftOutlined style={{ fontSize: "20px" }} />
                        Tilbage til overblik
                    </Button>
                </Link>
            </div>
            <Menu theme="dark" mode="inline" defaultSelectedKeys={[location.pathname]}>
                <Menu.Item key="4">
                    <Link to={`/orders`}>
                        <ProfileOutlined />
                        <span className="nav-text">Ordre</span>
                    </Link>
                </Menu.Item>
            </Menu>
        </Sider>
    );
}

export default withRouter(SideNav);
