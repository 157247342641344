import {
    GROUP_AUDIT_TEMPLATES_FETCH_REQUESTED,
    GROUP_AUDIT_TEMPLATES_FETCH_SUCCEEDED,
    GROUP_AUDIT_TEMPLATES_FETCH_FAILED,
    GROUP_AUDIT_TEMPLATE_FETCH_REQUESTED,
    GROUP_AUDIT_TEMPLATE_FETCH_SUCCEEDED,
    GROUP_AUDIT_TEMPLATE_FETCH_FAILED,
    // SET_CURRENT_TAB,
    GROUP_AUDIT_TEMPLATE_TASKS_FETCH_REQUESTED,
    GROUP_AUDIT_TEMPLATE_TASKS_FETCH_SUCCEEDED,
    GroupAuditTemplateActionTypes,
    GroupAuditTemplateState,
    GROUP_AUDIT_TEMPLATE_SYNC_SUCCEEDED,
} from "./types";

const initialState: GroupAuditTemplateState = {
    groupAuditTemplates: [],
    currentGroupAuditTemplate: {
        id: "",
        title: "",
        note: "",
        guide: null,
        createTime: new Date(),
        // thumbs: [],
        // tags: [],
    },
    currentGroupAuditTemplateTasks: [],
    groupAuditTemplateSync: null,
    loading: false,
    // currentTab: "incompleted",
};

export default function groupAuditTemplate(
    state = initialState,
    action: GroupAuditTemplateActionTypes,
): GroupAuditTemplateState {
    switch (action.type) {
        case GROUP_AUDIT_TEMPLATES_FETCH_REQUESTED:
        case GROUP_AUDIT_TEMPLATE_TASKS_FETCH_REQUESTED:
        case GROUP_AUDIT_TEMPLATE_FETCH_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case GROUP_AUDIT_TEMPLATES_FETCH_SUCCEEDED:
            return {
                ...state,
                groupAuditTemplates: action.payload.data,
                loading: false,
            };
        case GROUP_AUDIT_TEMPLATES_FETCH_FAILED:
        case GROUP_AUDIT_TEMPLATE_FETCH_FAILED:
            return {
                ...state,
                loading: false,
            };
        case GROUP_AUDIT_TEMPLATE_FETCH_SUCCEEDED:
            return {
                ...state,
                currentGroupAuditTemplate: action.payload.data,
                loading: false,
            };
        case GROUP_AUDIT_TEMPLATE_TASKS_FETCH_SUCCEEDED:
            return {
                ...state,
                currentGroupAuditTemplateTasks: action.payload.data,
            };
        case GROUP_AUDIT_TEMPLATE_SYNC_SUCCEEDED:
            return {
                ...state,
                groupAuditTemplateSync: action.payload,
            };
        default:
            return state;
    }
}
