import React, { useState } from "react";
import { Form, Input, Button, Select, InputNumber, message } from "antd";

import { post } from "../../../general/api";
import { useParams } from "react-router";
import Message from "../../../components/Notification/Message";
import { Type } from "../../../components/Notification/Message";

const { Option } = Select;

const initialValues = {
    type: "foobot",
    ugm3: 25,
    C: 30,
    pc: 60,
    ppm: 1300,
    ppb: 300,
    "%": 50,
};

const AddForm = ({ onClose }: { onClose: () => void }) => {
    const { orgId } = useParams<{ orgId: string }>();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);

    const onFinish = async (values: any) => {
        setIsLoading(true);
        Message({
            key: "addSensor",
            message: "Loading...",
            // description: "Sensoren blev tilføjet korrekt",
            type: Type.LOADING,
        });
        const payload = {
            orgId,
            title: values.title,
            deviceId: values.deviceId,
            type: values.type,
            thresholds: {
                ugm3: values.ugm3,
                C: values.C,
                pc: values.pc,
                ppm: values.ppm,
                ppb: values.ppb,
                "%": values["%"],
            },
        };
        const response = await post("setupSensor", payload);
        setIsLoading(false);
        if (response.status === 200) {
            form.resetFields();
            Message({
                key: "addSensor",
                message: "Sensor tilføjet",
                description: "Sensoren blev tilføjet korrekt",
            });
            onClose();
        } else {
            Message({
                key: "addSensor",
                message: "Fejl!",
                description: "Noget gik galt, prøv venligst igen",
            });
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={initialValues}>
            <Form.Item name="title" label="Sensor navn" rules={[{ required: true }]}>
                <Input placeholder="Sensor navn" />
            </Form.Item>
            <Form.Item name="deviceId" label="Sensor id" rules={[{ required: true }]}>
                <Input placeholder="Sensor id" />
            </Form.Item>
            <Form.Item name="type" label="Sensor type" rules={[{ required: true }]}>
                <Select placeholder="Sensor type" allowClear>
                    <Option value="foobot">Støv sensor</Option>
                </Select>
            </Form.Item>
            <Form.Item>
                <h2>Grænseværdier</h2>
            </Form.Item>
            <Form.Item name="%" label="Global index" rules={[{ required: true }]}>
                <InputNumber placeholder={`${initialValues["%"]}`} />
            </Form.Item>
            <Form.Item name="C" label="Temperatur (C)" rules={[{ required: true }]}>
                <InputNumber placeholder={`${initialValues.C}`} />
            </Form.Item>
            <Form.Item name="pc" label="LuftFugtighed (%)" rules={[{ required: true }]}>
                <InputNumber placeholder={`${initialValues.pc}`} />
            </Form.Item>
            <Form.Item name="ppm" label="CO2" rules={[{ required: true }]}>
                <InputNumber placeholder={`${initialValues.ppm}`} />
            </Form.Item>
            <Form.Item name="ppb" label="tVOC" rules={[{ required: true }]}>
                <InputNumber placeholder={`${initialValues.ppb}`} />
            </Form.Item>
            <Form.Item name="ugm3" label="PM2.5" rules={[{ required: true }]}>
                <InputNumber placeholder={`${initialValues.ugm3}`} />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" block loading={isLoading}>
                    Tilføj
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddForm;
