export const OPEN_PROFILE_DRAWER = "OPEN_PROFILE_DRAWER";
export const CLOSE_PROFILE_DRAWER = "CLOSE_PROFILE_DRAWER";

export const OPEN_GROUP_DRAWER = "OPEN_GROUP_DRAWER";
export const CLOSE_GROUP_DRAWER = "CLOSE_GROUP_DRAWER";

export const OPEN_BILLING_DRAWER = "OPEN_BILLING_DRAWER";
export const CLOSE_BILLING_DRAWER = "CLOSE_BILLING_DRAWER";

export const OPEN_ORG_NOTIFICATIONS_DRAWER = "OPEN_ORG_NOTIFICATIONS_DRAWER";
export const CLOSE_ORG_NOTIFICATIONS_DRAWER = "CLOSE_ORG_NOTIFICATIONS_DRAWER";

export const OPEN_ORG_MEMBER_DRAWER = "OPEN_ORG_MEMBER_DRAWER";
export const CLOSE_ORG_MEMBER_DRAWER = "CLOSE_ORG_MEMBER_DRAWER";

interface OpenGroupDrawer {
    type: typeof OPEN_GROUP_DRAWER;
    payload: {
        groupId: string;
    };
}
interface CloseGroupDrawer {
    type: typeof CLOSE_GROUP_DRAWER;
    payload: {};
}

interface OpenProfileDrawer {
    type: typeof OPEN_PROFILE_DRAWER;
    payload: {};
}
interface CloseProfileDrawer {
    type: typeof CLOSE_PROFILE_DRAWER;
    payload: {};
}

interface OpenBillingDrawer {
    type: typeof OPEN_BILLING_DRAWER;
    payload: {
        billingId: string;
        orgId: string;
    };
}
interface CloseBillingDrawer {
    type: typeof CLOSE_BILLING_DRAWER;
    payload: {};
}
interface OpenOrgNotificationsDrawer {
    type: typeof OPEN_ORG_NOTIFICATIONS_DRAWER;
    payload: {
        userId: string;
        orgId: string;
    };
}
interface CloseOrgNotificationsDrawer {
    type: typeof CLOSE_ORG_NOTIFICATIONS_DRAWER;
    payload: {};
}
interface OpenOrgMemberDrawer {
    type: typeof OPEN_ORG_MEMBER_DRAWER;
    payload: {
        userId: string;
        orgId: string;
    };
}
interface CloseOrgMemberDrawer {
    type: typeof CLOSE_ORG_MEMBER_DRAWER;
    payload: {};
}

export type DrawerActionTypes =
    | OpenProfileDrawer
    | CloseProfileDrawer
    | OpenGroupDrawer
    | CloseGroupDrawer
    | OpenBillingDrawer
    | CloseBillingDrawer
    | OpenOrgNotificationsDrawer
    | CloseOrgNotificationsDrawer
    | OpenOrgMemberDrawer
    | CloseOrgMemberDrawer;
