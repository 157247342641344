import React, { useState, CSSProperties } from "react";
import ReactPlayer from "react-player/lazy";
import { Row, Col, Typography, Button, Checkbox } from "antd";
import { Colors } from "../../../Layout/Colors";
import {
    CheckOutlined,
    CheckSquareOutlined,
    CheckCircleOutlined,
} from "@ant-design/icons";

const { Title, Paragraph } = Typography;

export default function Step1({ next }: { next: () => void }) {
    const [startedVideo, setStartedVideo] = useState(false);
    return (
        <Row>
            <Col md={24}>
                <Title style={styles.body}>Sådan virker støvbinder</Title>
            </Col>
            <Col xs={24} md={12}>
                <div style={styles.wrapper}>
                    {/* @ts-ignore */}
                    <ReactPlayer
                        // height={"70%"}
                        onStart={() => {}} //setStartedVideo(true)}
                        width={"100%"}
                        style={styles.player}
                        url="https://www.youtube.com/watch?v=j3TW1eAF7DQ"
                        onEnded={() => setStartedVideo(true)}
                    />
                </div>
                {/* <div style={styles.wrapper}>
                <ReactPlayer
                    width={"75%"}
                    url="https://www.youtube.com/watch?v=ZzCdu611SFg"
                    onEnded={() => alert("Ended")}
                /> */}
            </Col>
            <Col xs={24} md={12} style={{ paddingTop: 24 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        justifyContent: "space-between",
                        paddingBottom: 24,
                    }}>
                    <div>
                        <Title level={4} style={styles.body}>
                            Gulvet som filter
                        </Title>
                        <Paragraph style={styles.body}>
                            Når støvbinder anvendes på gulvet reduceres støv i luften, da
                            det bindes til gulvet og der dermed skabes et bedre indeklima.
                            {/* Ved ikke om
                    der er plads, men det kunne være fint om bruger også kendte til de
                    gængse fordele. Fx. kun et produkt til alle vaskbare gulve, mindre
                    støv i luftveje og på overfalder osv. */}
                        </Paragraph>
                        <Title level={4} style={styles.body}>
                            Fordele
                        </Title>
                        <Paragraph style={styles.body}>
                            <ul style={{ listStyleType: "none" }}>
                                <li style={styles.listItem}>
                                    <CheckCircleOutlined style={styles.icon} />
                                    Et produkt til alle vaskbare gulve
                                </li>
                                <li style={styles.listItem}>
                                    <CheckCircleOutlined style={styles.icon} />
                                    Mindre støv i luftveje og på overfalder
                                </li>
                                <li style={styles.listItem}>
                                    <CheckCircleOutlined style={styles.icon} />
                                    100% biologisk nedbrydeligt produkt og er PH-neutral
                                </li>
                            </ul>
                        </Paragraph>
                    </div>
                    <div>
                        <Button
                            type="primary"
                            block
                            onClick={next}
                            disabled={!startedVideo}>
                            {startedVideo ? "Næste" : "Se videoen for at forsætte"}
                        </Button>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

const styles: { [key: string]: CSSProperties } = {
    wrapper: {
        position: "relative",
        padding: 24,
    },
    player: {
        position: "relative",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        transform: "none",
    },
    body: {
        textAlign: "left",
        paddingLeft: 24,
        paddingRight: 24,
    },
    listItem: { margin: 0, marginBottom: 16, display: "flex", alignItems: "center" },
    icon: {
        fontSize: 18,
        marginRight: 8,
        color: Colors.info,
    },
};
