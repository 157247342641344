import { useEffect, useState } from "react";
import { Input, Button, Modal, Form, InputNumber, Select } from "antd";
import useProducts from "../../hooks/useProducts";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { Unit } from "../../components/SelectUnit/index";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import firebase from "firebase/compat/app";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

export default function AddGroup() {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    const [visible, setvisible] = useState(false);
    const uid = useSelector((state: AppState) => state.user.details.uid);
    const email = useSelector((state: AppState) => state.user.details.email);
    const name = useSelector((state: AppState) => state.user.details.name);
    const { products, dispatchFetchProducts } = useProducts();

    useEffect(() => {
        dispatchFetchProducts();
        form.validateFields();
        return () => {};
    }, []);
    const showModal = () => setvisible(true);

    const handleCancel = () => {
        setvisible(false);
    };

    const handleSubmit = async (values: any) => {
        const { title, products: productsToSubmit, unit } = values;

        const storage = productsToSubmit.reduce(
            (
                acc: {
                    [id: string]: { storage: number; storageAlert: number; unit: Unit };
                },
                i: { productId: string; storageAlert: number },
            ) => {
                return {
                    ...acc,
                    [i.productId]: {
                        storage: 0,
                        storageAlert: i.storageAlert,
                        unit,
                    },
                };
            },
            {},
        );
        const members = {
            [uid]: {
                uid,
                email,
                name: name || "",
            },
        };
        console.log({ title, unit, storage, productsToSubmit });
        try {
            if (productsToSubmit?.length > 0) {
                await firebase.firestore().collection("groups").add({
                    title,
                    storage,
                    members,
                    count: 0,
                });
            } else {
                await firebase.firestore().collection("groups").add({
                    title,
                    members,
                    count: 0,
                });
            }
        } catch (error) {
            console.error(error);
        }

        form.resetFields();
        handleCancel();
    };
    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 24 },
    };

    return (
        <>
            <Button size="large" type="primary" onClick={showModal}>
                {t("group.addgroup")}
            </Button>
            <Modal
                title={t("group.addForm.header")}
                open={visible}
                // onOk={e => handleOk(e, email)}
                onCancel={handleCancel}
                width={isMobile ? "100%" : "50%"}
                centered
                footer={[]}>
                <Form
                    {...formLayout}
                    form={form}
                    onFinish={handleSubmit}
                    initialValues={
                        {
                            // unit: Unit.LITER,
                        }
                    }>
                    <Form.Item
                        label={t("group.addForm.nameLabel")}
                        name="title"
                        rules={[{ required: true, message: "Venligst udfyld navn!" }]}>
                        <Input placeholder={t("group.addForm.namePlaceholder")} />
                    </Form.Item>

                    <Form.Item
                        name="unit"
                        label={t("group.addForm.unitLabel")}
                        hasFeedback
                        rules={[{ required: true, message: "Venligst vælg enhed!" }]}>
                        <Select
                            placeholder={t("group.addForm.unitPlaceholder")}
                            style={{ width: "100%" }}>
                            <Select.Option value={Unit.LITER}>
                                {t("units.liter")}
                            </Select.Option>
                            <Select.Option value={Unit.MILILITER}>
                                {t("units.milliliter")}
                            </Select.Option>
                            <Select.Option value={Unit.PIECE}>
                                {t("units.stk")}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Form.List name="products">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <div
                                            key={key}
                                            style={{
                                                marginTop: 8,
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "baseline",
                                                marginBottom: 8,
                                                width: "100%",
                                            }}>
                                            <Form.Item
                                                style={{
                                                    width: "calc(50% - 8px)",
                                                    marginRight: 8,
                                                }}
                                                {...restField}
                                                name={[name, "productId"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Vælg venligst enhed",
                                                    },
                                                ]}>
                                                <Select
                                                    placeholder={t(
                                                        "group.addForm.unitPlaceholder",
                                                    )}
                                                    style={{ width: "100%" }}>
                                                    {products.map(p => (
                                                        <Select.Option value={p.id}>
                                                            {p.title}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                style={{
                                                    width: "calc(50% - 8px)",
                                                    marginRight: 8,
                                                }}
                                                {...restField}
                                                name={[name, "storageAlert"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Venligst udfyld lav beholding",
                                                    },
                                                ]}>
                                                <InputNumber
                                                    min={1}
                                                    placeholder={t(
                                                        "group.addForm.storagePlaceholder",
                                                    )}
                                                />
                                            </Form.Item>
                                            <MinusCircleOutlined
                                                onClick={() => remove(name)}
                                            />
                                        </div>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            block
                                            icon={<PlusCircleOutlined />}>
                                            Tilføj produkt
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                    {/* <Form.Item name="products" label={t("group.addForm.products")}>
                    <Checkbox.Group>
                        <Row>
                            {products.map(({ id, title }) => (
                                <Col span={24} key={id}>
                                    <Checkbox
                                        value={id}
                                        style={{ lineHeight: "32px" }}>
                                        {title}
                                    </Checkbox>
                                </Col>
                            ))}
                        </Row>
                    </Checkbox.Group>
                </Form.Item> */}

                    {/* <Input
                    placeholder="Firmanavn"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                /> */}
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Button type="primary" htmlType="submit" block>
                            {t("group.addForm.add")}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}
