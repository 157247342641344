import React, { useEffect } from "react";
import Typesense, { Client } from "typesense";
import { ROOT_EU3, post } from "general/api";
import {
    SET_SEARCH_KEYS_FAILED,
    SET_SEARCH_KEYS_REQUESTED,
    SearchKeyNames,
} from "modules/user/types";
import { useAppDispatch, useAppSelector } from "hooks";
import { RequestState } from "interfaces/request";
import { setSearchKeyRequestState } from "modules/user/actions";
import { SearchParams } from "typesense/lib/Typesense/Documents";
import { useParams } from "react-router";

let client: Client | null = null;
let attempts = 0;

type Props = {
    type: SearchKeyNames;
};

export default function useSearch({ type }: Props) {
    const dispatch = useAppDispatch();
    const { orgId } = useParams<{ orgId: string }>();
    const keyCreationParamsByType = useAppSelector(() => {
        switch (type) {
            case SearchKeyNames.ORG_ISSUES:
                return {
                    type,
                    orgId,
                };
            default:
                return { type };
        }
    });
    // console.log({ orgId, type, attempts });

    const searchKey = useAppSelector(state => state.user.searchKeys[type]?.key);
    const isSearchKeyValid = useAppSelector(state => {
        if (type === SearchKeyNames.ORG_ISSUES) {
            // Must be same orgId else we need a new search key
            return (
                state.user.searchKeys[type]?.state === "valid" &&
                state.user.searchKeys[type]?.orgId === orgId
            );
        }
        return state.user.searchKeys[type]?.state === "valid";
    });
    const isSearchKeyLoading = useAppSelector(
        state =>
            state.user.searchKeyRequestState === RequestState.INITIAL ||
            state.user.searchKeyRequestState === RequestState.PENDING ||
            // for now this to prevent errors in UX
            state.user.searchKeyRequestState === RequestState.REJECTED,
    );

    useEffect(() => {
        if (searchKey) {
            const typesenseClient = new Typesense.Client({
                nodes: [
                    {
                        host: "ri9udbthwm57pev6p-1.a1.typesense.net",
                        port: 443,
                        protocol: "https",
                    },
                ],
                apiKey: searchKey,
                connectionTimeoutSeconds: 2,
            });
            client = typesenseClient;
        }
        return () => {};
    }, [searchKey]);

    /**
     * Try to get new key if key is invalid and attempts is less than 3
     */
    useEffect(() => {
        // if key is invalid and attempts is less than 3
        // get new key
        if (isSearchKeyLoading) return;
        if (!isSearchKeyValid && attempts < 3) {
            getNewKey();
        }
    }, [isSearchKeyValid, isSearchKeyLoading]);

    /**
     * Get new key from api
     */
    const getNewKey = async () => {
        try {
            dispatch(setSearchKeyRequestState(SET_SEARCH_KEYS_REQUESTED));
            attempts = attempts + 1;
            const response = await post(
                "getScopedTypesenseKey",
                keyCreationParamsByType,
                ROOT_EU3,
            );
            // if (response.status === 404) {
            // }
            if (response.status === 200) {
                // console.log({ STATUS: response.status, response });
                // if success, attempts reset
                attempts = 0;
            }
        } catch (error) {
            console.error(error);
            dispatch(setSearchKeyRequestState(SET_SEARCH_KEYS_FAILED));
            // setKeyStatus("invalid");
        }
    };

    const getSearchConfigByType = (type: SearchKeyNames): Omit<SearchParams, "q"> => {
        switch (type) {
            case SearchKeyNames.ORGS:
                return {
                    query_by: "title",
                    infix: "fallback",
                    filter_by: "isDeleted:false",
                    // sort_by: "title:asc",
                };
            default:
                return {
                    query_by: "description",
                    sort_by: "description:asc",
                };
        }
    };

    const getCollectionByType = (type: SearchKeyNames) => {
        switch (type) {
            case SearchKeyNames.ORGS:
                return "orgs";
            case SearchKeyNames.ORG_ISSUES:
                return "issues";
            default:
                return "orgs";
        }
    };

    const onSearch = async (value: string, searchParams?: Partial<SearchParams>) => {
        if (!client) return [];
        try {
            const searchConfig = getSearchConfigByType(type);
            const collection = getCollectionByType(type);
            const results = await client
                .collections(collection)
                .documents()
                .search({ ...searchConfig, ...searchParams, q: value });

            const orgs = results.hits?.map((hit: any) => ({
                label: hit.highlight?.[searchConfig.query_by as string]?.snippet ? (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: hit.highlight[searchConfig.query_by as string]
                                .snippet,
                        }}
                    />
                ) : (
                    hit.document[searchConfig.query_by as string]
                ),
                value: hit.document.id,
                context: hit.document,
            }));
            // console.log({ results });
            if (orgs) {
                return orgs;
            } else {
                return [];
            }
        } catch (error) {
            console.error(error);
            // setKeyStatus("invalid");
            return [];
        }
    };

    return {
        loading: isSearchKeyLoading,
        onSearch,
    };
}
