import React from "react";
import useTrips from "./useTrips";
import { Card, Timeline } from "antd";
import {
    differenceInMinutes,
    format,
    formatDistanceStrict,
    formatDistance,
    addMinutes,
    intervalToDuration,
    formatDuration,
} from "date-fns";
import { locales } from "../../../languages/i18n";
import { FieldTimeOutlined, PauseCircleOutlined, PauseOutlined } from "@ant-design/icons";
import { StyleGuide } from "styles/StyleGuide";

const formatKm = new Intl.NumberFormat("da-DK", {
    style: "unit",
    unit: "kilometer",
    maximumFractionDigits: 1,
    // unitDisplay: "long",
});

export default function TripCard({ trips }: { trips: any[] }) {
    console.log({ trips });
    const totalDistance = trips.reduce((acc, trip) => acc + trip.totalDistance, 0);
    const { totalMinutes, totalIdleMinutes } = trips.reduce(
        (acc, trip) => {
            if (trip.isIdle) {
                acc.totalIdleMinutes += differenceInMinutes(
                    new Date(trip.endTrackPoint.utc),
                    new Date(trip.startTrackPoint.utc),
                );
            }
            if (!trip.isIdle) {
                acc.totalMinutes += differenceInMinutes(
                    new Date(trip.endTrackPoint.utc),
                    new Date(trip.startTrackPoint.utc),
                );
            }
            return acc; // Add return statement here
        },
        {
            totalMinutes: 0,
            totalIdleMinutes: 0,
        },
    );
    console.log({ totalMinutes, totalIdleMinutes });

    const list = trips.map(trip => ({
        dot: trip.isIdle ? <PauseCircleOutlined /> : <FieldTimeOutlined />,
        color: trip.isIdle ? "red" : "green",
        children: trip.isIdle ? (
            <div style={{ color: StyleGuide.palette.grey600 }}>
                {formatDistanceStrict(
                    new Date(trip.startTrackPoint.utc),
                    new Date(trip.endTrackPoint.utc),
                    {
                        locale: locales.da,
                    },
                ) + " stilstand"}
            </div>
        ) : (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    {format(new Date(trip.startTrackPoint.utc), "HH:mm") +
                        " → " +
                        format(new Date(trip.endTrackPoint.utc), "HH:mm")}
                </div>
                <div>
                    <b>{formatKm.format(trip.totalDistance / 1000)}</b>
                    {" - "}
                    {formatDistanceStrict(
                        new Date(trip.startTrackPoint.utc),
                        new Date(trip.endTrackPoint.utc),
                        {
                            locale: locales.da,
                        },
                    )}
                </div>
            </div>
        ),
    }));
    // Calculate the duration between two dates
    const duration = intervalToDuration({
        start: new Date(),
        end: addMinutes(new Date(), totalMinutes),
    });

    // Format the duration to a string (automatically includes hours and minutes)
    const formattedDuration = formatDuration(duration, {
        format: ["hours", "minutes"],
        locale: locales.da,
    });
    const durationIdle = intervalToDuration({
        start: new Date(),
        end: addMinutes(new Date(), totalIdleMinutes),
    });

    // Format the duration to a string (automatically includes hours and minutes)
    const formattedDurationIdle = formatDuration(durationIdle, {
        format: ["hours", "minutes"],
        locale: locales.da,
    });
    return (
        <Card
            title={
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                    <div>Oversigt</div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            fontSize: 12,
                            fontWeight: "normal",
                        }}>
                        <div>
                            <FieldTimeOutlined style={{ color: "green" }} /> = Bevægelse
                        </div>
                        <div>
                            <PauseCircleOutlined style={{ color: "red" }} /> = Stilstand
                        </div>
                    </div>
                </div>
            }
            bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}>
            <div
                style={{
                    paddingTop: 24,
                    paddingBottom: 24,
                    maxHeight: 600,
                    overflowY: "scroll",
                }}>
                <Timeline style={{ paddingLeft: 4 }} items={list} />
            </div>
            <div
                style={{
                    position: "absolute",
                    width: "100%",
                    left: 0,
                    backgroundColor: "white",
                    bottom: 0,
                    borderTop: `1px solid ${StyleGuide.palette.grey200}`,
                }}>
                <div
                    style={{
                        width: "100%",
                        padding: 16,
                        paddingTop: 8,
                        paddingBottom: 8,
                        fontWeight: "normal",
                    }}>
                    <div
                        style={{
                            fontWeight: "bold",
                            fontSize: 14,
                            color: StyleGuide.palette.grey700,
                        }}>
                        Summary
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <FieldTimeOutlined style={{ color: "green" }} />{" "}
                            {formattedDuration} - {formatKm.format(totalDistance / 1000)}
                        </div>
                        <div>
                            <PauseCircleOutlined style={{ color: "red" }} />{" "}
                            {formattedDurationIdle}
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
}
