export const PRODUCTS_FETCH_REQUESTED = "PRODUCTS_FETCH_REQUESTED";
export const PRODUCTS_FETCH_SUCCEEDED = "PRODUCTS_FETCH_SUCCEEDED";
export const PRODUCTS_FETCH_FAILED = "PRODUCTS_FETCH_FAILED";

export const PRODUCTS_CREATE_REQUESTED = "PRODUCTS_CREATE_REQUESTED";
export const PRODUCTS_CREATE_SUCCEEDED = "PRODUCTS_CREATE_SUCCEEDED";
export const PRODUCTS_CREATE_FAILED = "PRODUCTS_CREATE_FAILED";

export const PRODUCTS_UPDATE_REQUESTED = "PRODUCTS_UPDATE_REQUESTED";
export const PRODUCTS_UPDATE_SUCCEEDED = "PRODUCTS_UPDATE_SUCCEEDED";
export const PRODUCTS_UPDATE_FAILED = "PRODUCTS_UPDATE_FAILED";

export const PRODUCTS_DELETE_REQUESTED = "PRODUCTS_DELETE_REQUESTED";
export const PRODUCTS_DELETE_SUCCEEDED = "PRODUCTS_DELETE_SUCCEEDED";
export const PRODUCTS_DELETE_FAILED = "PRODUCTS_DELETE_FAILED";

export const ORG_PRODUCTS_FETCH_REQUESTED = "ORG_PRODUCTS_FETCH_REQUESTED";
export const ORG_PRODUCTS_FETCH_SUCCEEDED = "ORG_PRODUCTS_FETCH_SUCCEEDED";
export const ORG_PRODUCTS_FETCH_FAILED = "ORG_PRODUCTS_FETCH_FAILED";

export const ORG_PRODUCTS_CREATE_REQUESTED = "ORG_PRODUCTS_CREATE_REQUESTED";
export const ORG_PRODUCTS_CREATE_SUCCEEDED = "ORG_PRODUCTS_CREATE_SUCCEEDED";
export const ORG_PRODUCTS_CREATE_FAILED = "ORG_PRODUCTS_CREATE_FAILED";

export const ORG_PRODUCTS_UPDATE_REQUESTED = "ORG_PRODUCTS_UPDATE_REQUESTED";
export const ORG_PRODUCTS_UPDATE_SUCCEEDED = "ORG_PRODUCTS_UPDATE_SUCCEEDED";
export const ORG_PRODUCTS_UPDATE_FAILED = "ORG_PRODUCTS_UPDATE_FAILED";

export const ORG_PRODUCTS_DELETE_REQUESTED = "ORG_PRODUCTS_DELETE_REQUESTED";
export const ORG_PRODUCTS_DELETE_SUCCEEDED = "ORG_PRODUCTS_DELETE_SUCCEEDED";
export const ORG_PRODUCTS_DELETE_FAILED = "ORG_PRODUCTS_DELETE_FAILED";

export interface Product {
    id: string;
    title: string;
    storage: number;
}

export interface OrgProduct {
    id: string;
    title: string;
    storage: number;
}

export interface ProductState {
    loading: boolean;
    list: Product[];
    productsMap: { [id: string]: Product };
    orgProducts: OrgProduct[];
    orgProductsMap: { [id: string]: OrgProduct };
}

interface FetchProductsSuccess {
    type: typeof PRODUCTS_FETCH_SUCCEEDED;
    payload: { products: Product[] };
}
interface FetchProducts {
    type: typeof PRODUCTS_FETCH_REQUESTED;
    payload: { orgId: string };
}
interface FetchProductsFailed {
    type: typeof PRODUCTS_FETCH_FAILED;
    payload: { orgId: string };
}

interface FetchOrgProductsSuccess {
    type: typeof ORG_PRODUCTS_FETCH_SUCCEEDED;
    payload: { products: OrgProduct[] };
}
interface FetchOrgProducts {
    type: typeof ORG_PRODUCTS_FETCH_REQUESTED;
    payload: { orgId: string };
}
interface FetchOrgProductsFailed {
    type: typeof ORG_PRODUCTS_FETCH_FAILED;
    payload: { orgId: string };
}

export type ProductActionTypes =
    | FetchProducts
    | FetchProductsSuccess
    | FetchProductsFailed
    | FetchOrgProductsSuccess
    | FetchOrgProducts
    | FetchOrgProductsFailed;
