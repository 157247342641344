import {
    OPEN_MODAL,
    CLOSE_MODAL,
    ModalActionTypes,
    AdvancedTaskModalContentContext,
    AdvancedGroupTaskModalContentContext,
} from "./types";

export enum ModalType {
    ADVANCED_TASK_SETTINGS = "ADVANCED_TASK_SETTINGS",
    ADVANCED_GROUP_TASK_SETTINGS = "ADVANCED_GROUP_TASK_SETTINGS",
}

interface ModalState {
    type: ModalType | null;
    context: AdvancedTaskModalContentContext | AdvancedGroupTaskModalContentContext | {};
}

const initialState: ModalState = {
    type: null,
    context: {},
};

export default function modals(state = initialState, action: ModalActionTypes) {
    switch (action.type) {
        case OPEN_MODAL:
            return {
                ...state,
                type: action.payload.type,
                context: action.payload.context,
            };
        case CLOSE_MODAL:
            return {
                ...state,
                type: null,
            };

        default:
            return state;
    }
}
