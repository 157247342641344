import keyBy from "lodash.keyby";
import { RequestState } from "../../interfaces/request";
import {
    GroupState,
    DBGroupShape,
    FETCH_GROUPS_REQUESTED,
    FETCH_GROUPS_SUCCEEDED,
    FETCH_GROUPS_FAILED,
    ActionType,
} from "./types";
import { GroupActionTypes } from "./types";

const initialState: {
    groups: DBGroupShape[];
    groupsMap: { [id: string]: DBGroupShape };
    state: RequestState;
} = {
    groups: [],
    groupsMap: {},
    state: RequestState.INITIAL,
};

export default function groups(
    state = initialState,
    action: GroupActionTypes,
): GroupState {
    switch (action.type) {
        case FETCH_GROUPS_REQUESTED:
            return {
                ...state,
                state: RequestState.PENDING,
            };
        case FETCH_GROUPS_SUCCEEDED:
            return {
                ...state,
                groups: action.payload,
                groupsMap: keyBy(action.payload, "id"),
                state: RequestState.RESOLVED,
            };
        case FETCH_GROUPS_FAILED:
            return {
                ...state,
                state: RequestState.REJECTED,
            };
        default:
            return state;
    }
}
