import React from "react";
import { Layout, Row, Col, List } from "antd";
import SideNav from "../../Layout/SideNav";
import HeaderBar from "../../Layout/HeaderBar";
import FooterBar from "../../Layout/FooterBar";
import BlurOverlayContainer from "../../components/Overlay/BlurOverlayContainer";
import { FeatureIds } from "../../modules/featureControl/featuresConfig";

const { Content } = Layout;

interface Item {
    time: string;
    icon: React.ReactNode;
    title: string;
    description: string;
}
interface Props {
    activity: Item[];
}

function ActivityList({ activity }: Props) {
    const renderItem = (item: Item) => (
        <List.Item style={{ borderBottom: "1px solid #c0c0c0" }}>
            <List.Item.Meta
                avatar={item.icon}
                title={item.title}
                description={item.description}
            />
            <div>{item.time}</div>
        </List.Item>
    );
    return (
        <>
            <SideNav />
            <Layout>
                <HeaderBar />
                <BlurOverlayContainer featureKey={FeatureIds.ACTIVITY}>
                    <Content style={{ margin: "50px 24px 0" }}>
                        <Row gutter={16}>
                            <Col md={24} xs={24}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={activity}
                                    renderItem={renderItem}
                                />
                            </Col>
                        </Row>
                    </Content>
                </BlurOverlayContainer>
                <FooterBar />
            </Layout>
        </>
    );
}

export default ActivityList;
