import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
// import AirQualitySensor from "./AirQualitySensor";
// import { AppState } from "../../store";
import { endOfWeek, format, getISOWeek } from "date-fns";
// import { useGetAirQuality } from "./useGetAirQualityData";
import {
    fetchSensor,
    // fetchSensorLogs,
    // fetchSensorMeasures,
    // setDate,
} from "../../../modules/sensors/actions";
import isEmpty from "lodash.isempty";
import SideNav from "../../../Layout/SideNav";
import { Layout, Row, Col, DatePicker, Flex } from "antd";
import HeaderBar from "../../../Layout/HeaderBar";
import AdminOnly, { AdminAndMichael } from "../../../components/AdminOnly";
import { startOfWeek } from "date-fns/esm";
import locale from "antd/es/date-picker/locale/da_DK";
import { useAqSensor } from "./useAqSensors";
import { AppState } from "../../../store";
import LineChart from "./Chart";
import Realtime from "./Realtime";
import ReportCheckbox from "./Report";
import { Link } from "react-router-dom";
import { LinkOutlined } from "@ant-design/icons";

const { Content } = Layout;

function Page({ children, backLink, backText }: any) {
    return (
        <>
            <SideNav />
            <Layout>
                <HeaderBar backLink={backLink} backText={backText} />
                <Content style={{ margin: "50px 24px 0" }}>
                    <Row gutter={16}>
                        <Col md={24} xs={24} style={{ minHeight: "600px" }}>
                            {children}
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </>
    );
}

function AQSensorContainer(props: any) {
    console.log({ AQSensorContainer: "AQSensorContainer" });

    const { orgId, sensorId } = props.match.params;
    const [date, setdate] = useState<string | null>(null);
    useEffect(() => {
        props.fetchSensor({ orgId, sensorId });
        return () => {};
    }, []);

    const { logsAndStats, isLoading } = useAqSensor(props.deviceId, date);

    if (isLoading) {
        return (
            <Page>
                <div>Loading...</div>
            </Page>
        );
    }
    if (!logsAndStats?.pm_2_5 || logsAndStats?.pm_2_5?.length < 1) {
        return (
            <Page>
                <div>Ingen data fra sensoren endnu</div>
            </Page>
        );
    }
    // const data = sensor?.datapoints
    //     .map(log => ({
    //         name: format(log[0] * 1000, "MMM dd - HH:mm"),
    //         [sensor.units[1]]: log[1],
    //         [sensor.units[2]]: log[2],
    //         [sensor.units[3]]: log[3],
    //         [sensor.units[4]]: log[4],
    //         [sensor.units[5]]: log[5],
    //         [sensor.units[6]]: log[6],
    //         time: log[0],
    //     }))
    //     .sort((a, b) => (a.time < b.time ? -1 : 1));

    const onChangeDate = (date: any) => {
        console.log({ date, type: typeof date });
        if (!date) return;
        const dateObj = date.toDate();

        const dateToUse = format(startOfWeek(dateObj), "yyyy-MM-dd");

        setdate(`${dateToUse}T00:00:00.000Z`);
    };
    const getDateDisplay = () => {
        if (!date) return null;
        const week = getISOWeek(new Date(date)) + 1;
        return `Viser data fra ${format(new Date(date), "d. MMM yyyy")} til ${format(
            endOfWeek(new Date(date)),
            "d. MMM yyyy",
        )} - Uge ${week}`;
    };

    // @ts-ignore
    const units = Object.keys(logsAndStats).filter(key => key !== "co2Avg");
    console.log(units);

    return (
        <Page backLink={`/orgs/${orgId}/sensors`} backText={props.title}>
            {/* <AdminOnly> */}
            <h2>{getDateDisplay()}</h2>
            <div
                style={{
                    marginTop: -28,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 8,
                }}>
                <div>
                    {/* @ts-ignore */}
                    <DatePicker
                        // Locale result in +1 week number for some reason
                        // locale={locale}
                        onChange={onChangeDate}
                        picker="week"
                        allowClear={false}
                    />
                </div>
                <Flex align="center" gap="small">
                    <AdminAndMichael>
                        <ReportCheckbox />
                    </AdminAndMichael>
                    <Flex vertical align="end" gap="middle">
                        <Link to={`/sensor-legend`} target="_blank">
                            <LinkOutlined /> Sensor information
                        </Link>
                        <Realtime deviceId={props.deviceId} />
                    </Flex>
                </Flex>
            </div>
            {/* @ts-ignore */}
            {/* <DatePicker
                key="day"
                locale={locale}
                onChange={onChangeDate}
                allowClear={false}
            /> */}
            {/* </AdminOnly> */}

            {/* <AirQualitySensor
                title={props.title}
                data={data}
                units={sensor?.units}
                thresholds={props.thresholds}
            /> */}
            <LineChart
                pm_2_5={logsAndStats?.pm_2_5}
                pm_10={logsAndStats?.pm_10}
                co2={logsAndStats?.co2}
                co2Avg={logsAndStats?.co2Avg}
                decibel={logsAndStats?.decibel}
                lux={logsAndStats?.lux}
                temperature={logsAndStats?.temperature}
                humidity={logsAndStats?.humidity}
                units={units}
                thresholds={props.thresholds}
            />
        </Page>
    );
}

function mapStateToProps(state: AppState) {
    return {
        title: state.sensors.currentSensor.title,
        deviceId: state.sensors.currentSensor.deviceId,
        thresholds: state.sensors.currentSensor.thresholds,
        loading: state.sensors.loading,
    };
}

const mapActionsToProps = {
    fetchSensor,
    // fetchSensorLogs,
    // fetchSensorMeasures,
    // setDate,
};

const withConnect = connect(mapStateToProps, mapActionsToProps);

export default compose(withConnect, withRouter)(AQSensorContainer);
