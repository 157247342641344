import React, { FormEvent, useState } from "react";
import { Layout, Row, Col, Button, Input, Collapse, Tooltip } from "antd";
import SideNav from "../../../Layout/SideNav";
import HeaderBar from "../../../Layout/HeaderBar";
import FooterBar from "../../../Layout/FooterBar";
import { ApartmentOutlined, LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import BlurOverlayContainer from "../../../components/Overlay/BlurOverlayContainer";
import { FeatureIds } from "../../../modules/featureControl/featuresConfig";
import { AuditTemplate } from "../../../modules/auditTemplates/types";
import AuditTemplateItem from "./AuditTemplateItem";
import { StyleGuide } from "styles/StyleGuide";

const { Content } = Layout;
interface Props {
    orgId: string;
    loading: boolean;
    groupLoading: boolean;
    groupAuditTemplates: AuditTemplate[];
    auditTemplates: AuditTemplate[];
    addAuditTemplate: (title: string) => void;
}
function AuditTemplateList(props: Props) {
    const { t } = useTranslation();
    const [title, setTitle] = useState("");

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.addAuditTemplate(title);
        setTitle("");
    };

    const { orgId } = props;

    return (
        <>
            <SideNav />
            <Layout>
                <HeaderBar />
                <BlurOverlayContainer featureKey={FeatureIds.AUDITS}>
                    <Content style={{ margin: "24px 24px 0" }}>
                        <Row gutter={16}>
                            <Col md={24} xs={24}>
                                <h1>{t("audit.templates.headline")}</h1>

                                {props.groupLoading ? (
                                    <LoadingOutlined />
                                ) : (
                                    props?.groupAuditTemplates?.length > 0 && (
                                        <Collapse
                                            defaultActiveKey={["1"]}
                                            style={{ border: "none" }}
                                            expandIconPosition="end">
                                            <Collapse.Panel
                                                style={{
                                                    border: `1px solid ${StyleGuide.palette.grey200}`,
                                                    borderRadius: 8,
                                                    backgroundColor: "#fff",
                                                }}
                                                header={
                                                    <Tooltip
                                                        title={t(
                                                            "audit.templates.groupTemplatesTooltip",
                                                        )}>
                                                        <div>
                                                            <ApartmentOutlined />
                                                            <span
                                                                style={{ marginLeft: 8 }}
                                                            />
                                                            {t(
                                                                "audit.templates.groupTemplates",
                                                            )}
                                                        </div>
                                                    </Tooltip>
                                                }
                                                key="1">
                                                {props.groupAuditTemplates.map(item => (
                                                    <AuditTemplateItem
                                                        readOnly
                                                        key={`${orgId}-${item.id}`}
                                                        orgId={orgId}
                                                        item={item}
                                                    />
                                                ))}
                                            </Collapse.Panel>
                                        </Collapse>
                                    )
                                )}
                                {props.loading ? (
                                    <LoadingOutlined />
                                ) : (
                                    props.auditTemplates.map(item => (
                                        <AuditTemplateItem
                                            key={`${orgId}-${item.id}`}
                                            orgId={orgId}
                                            item={item}
                                        />
                                    ))
                                )}
                            </Col>
                            <Col md={24} xs={24}>
                                <form onSubmit={onSubmit} style={{ marginTop: "50px" }}>
                                    <Input
                                        size="large"
                                        placeholder={t("audit.templates.addplaceholder")}
                                        value={title}
                                        onChange={e => setTitle(e.target.value)}
                                        required
                                    />
                                    <div style={{ marginTop: 4 }} />
                                    <Button
                                        size="large"
                                        type="primary"
                                        style={{ width: "100%" }}
                                        htmlType="submit">
                                        {t("audit.templates.submit")}
                                    </Button>
                                </form>
                            </Col>
                        </Row>
                    </Content>
                </BlurOverlayContainer>
                <FooterBar />
            </Layout>
        </>
    );
}

export default AuditTemplateList;
