import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";

export default function useStorageImage({ path }: { path: string }) {
    const [loading, setloading] = useState(false);
    const [image, setImage] = useState<string | null>(null);
    useEffect(() => {
        if (!path) return;

        setloading(true);
        firebase
            .storage()
            .ref(path)
            .getDownloadURL()
            .then(url => {
                console.log({ url });
                setImage(url);
            })
            .catch(error => {
                // Handle any errors
                console.warn(error);
            })
            .finally(() => setloading(false));
    }, [path]);
    return {
        loading,
        image,
    };
}
