import React from "react";
import { InputNumber, Input, Form } from "antd";
import { t } from "i18next";

export const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}: any) => {
    const inputNode =
        inputType === "number" ? (
            <InputNumber
                placeholder={t("cleanlog.table.edit.amountplaceholder")}
                min={0}
                decimalSeparator=","
            />
        ) : (
            <Input disabled={record?.optionId} />
        );
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `${t("cleanlog.table.edit.require")} ${title}`,
                        },
                    ]}>
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};
