import "./triangle.css";

type classNameType =
    | "triangle-pulsing-red"
    | "triangle-pulsing-green"
    | "triangle-solid-grey"
    | "triangle-solid-blue"
    | "triangle-solid-green"
    | "triangle-solid-red"
    | "triangle-solid-yellow"
    | "triangle-solid-white";

export default function Triangle({ className }: { className: classNameType }) {
    return (
        <svg width={50} height={40} viewBox="0 0 1356.27 839.81">
            <g id="uuid-4f72eb45-3291-4846-ba88-f8f6586582dc">
                <path
                    className={className}
                    d="m37.33,0h1281.61c31.63,0,48.87,36.93,28.57,61.18l-640.81,765.29c-14.89,17.78-42.24,17.78-57.13,0L8.76,61.18C-11.54,36.93,5.7,0,37.33,0Z"
                />
            </g>
        </svg>
    );
}
