import React, { useState } from "react";
import { Popconfirm, Layout, Row, Col, Modal } from "antd";
import HeaderBar from "../../../Layout/HeaderBar";
import FooterBar from "../../../Layout/FooterBar";
import { IOrg } from "../../../modules/orgs/interfaces";

import { DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import AddOrg from "../../Home/AddOrg";
import TableList from "./Table";
import InventoryNav from "../InventoryNav";
import {
    InventoryContextForType,
    InventoryItem,
    InventoryType,
} from "../../../modules/inventory/types";
import EditForm from "./EditForm";
import AddForm from "./AddForm";
import Total from "./Total";

const { Content } = Layout;

export function DeleteCompany({ deleteOrg }: { deleteOrg: () => void }) {
    const { t } = useTranslation();
    return (
        <Popconfirm
            title={t("home.org.delete.title")}
            onConfirm={deleteOrg}
            okText={t("home.org.delete.confirm")}
            cancelText={t("home.org.delete.cancel")}>
            <DeleteOutlined />
        </Popconfirm>
    );
}

interface IHomeViewProps {
    espAq: InventoryContextForType<InventoryType.ESP_AQ>[];
    simcards: InventoryContextForType<InventoryType.SIM_CARDS>[];
    inventoryTagsMap: { [id: string]: { id: string; title: string } };
}

function InventoryEspAqView({ espAq, simcards, inventoryTagsMap }: IHomeViewProps) {
    const { t } = useTranslation();
    const [visible, setVisible] = useState<string | null>(null);
    const onClose = () => setVisible(null);

    return (
        <>
            <InventoryNav />
            <Layout>
                <HeaderBar />
                <Content style={{ margin: "24px 24px 0" }}>
                    <Row>
                        <Col span={24} style={{ marginBottom: 24 }}>
                            <div style={{ float: "right" }}>
                                <AddForm
                                    simcards={simcards}
                                    inventoryTagsMap={inventoryTagsMap}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ marginBottom: 24 }}>
                            <div style={{ float: "left" }}>
                                <Total />
                            </div>
                        </Col>
                    </Row>
                    <TableList
                        data={espAq}
                        setVisible={setVisible}
                        inventoryTagsMap={inventoryTagsMap}
                    />
                </Content>
                <FooterBar />
            </Layout>
            <Modal
                title="Ret ESP_AQ"
                open={!!visible}
                // onOk={e => handleOk(e, email)}
                confirmLoading={false}
                onCancel={onClose}
                footer={[]}>
                {visible && (
                    <EditForm
                        key={visible}
                        id={visible}
                        onClose={onClose}
                        simcards={simcards}
                        inventoryTagsMap={inventoryTagsMap}
                    />
                )}
            </Modal>
        </>
    );
}

export default InventoryEspAqView;
