import { useState, useRef } from "react";
import { Table, Input, Button, Tooltip, Space } from "antd";
import Highlighter from "react-highlight-words";
import { Link } from "react-router-dom";
import {
    RightOutlined,
    SearchOutlined,
    AlertOutlined,
    DashboardOutlined,
    AuditOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../hooks";
import { openGroupDrawer } from "../../modules/drawer/actions";
import { GroupStorage } from "../../modules/groups/types";
import CountOrgsByGroup from "./CountOrgsByGroup";
import { useHistory } from "react-router";

interface IData {
    id: string;
    title: string;
}

interface IProps {
    data: IData[];
}

function TableList(props: IProps) {
    const dispatch = useAppDispatch();
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const { t } = useTranslation();
    const history = useHistory();

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }: {
            setSelectedKeys: (event: string | string[]) => void;
            selectedKeys: string[];
            confirm: () => void;
            clearFilters: () => void;
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={t("home.table.searchPlaceholder")}
                    value={selectedKeys[0]}
                    onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 200, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    {t("home.table.search")}
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}>
                    {t("home.table.searchReset")}
                </Button>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value: string, record: any) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
                // @ts-ignore
                setTimeout(() => searchInput.current.select());
            }
        },
        render: (text: string, record: any) => {
            if (searchedColumn === dataIndex) {
                return (
                    <Link to={`depts?groupId=${record.id}`}>
                        <Highlighter
                            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                            searchWords={[searchText]}
                            autoEscape
                            textToHighlight={text.toString()}
                        />
                    </Link>
                );
            }
            return <Link to={`depts?groupId=${record.id}`}>{text}</Link>;
        },
    });

    const handleSearch = (
        selectedKeys: string[],
        confirm: () => void,
        dataIndex: string,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        // this.setState({
        //     searchText: selectedKeys[0],
        //     searchedColumn: dataIndex,
        // });
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText("");
    };

    const columns = [
        {
            title: t("Gruppenavn"),
            dataIndex: "title",
            key: "title",
            ...getColumnSearchProps("title"),
        },
        {
            title: t("Antal afdelinger"),
            dataIndex: "id",
            key: "count",
            render: (id: string) => <CountOrgsByGroup groupId={id} />,
            // width: "20%",
        },
        {
            title: t("Hovedlager status"),
            dataIndex: "storage",
            key: "storage",
            render: (storage: GroupStorage) => {
                if (!storage) return <div></div>;
                const hasAlert = Object.entries(storage).some(
                    ([_, val]) => val.storage <= val?.storageAlert,
                );
                if (hasAlert) {
                    return (
                        <div>
                            <AlertOutlined style={{ color: "red", fontSize: 18 }} />{" "}
                            Beholdning lav
                        </div>
                    );
                }
                return "";
            },
            // width: "20%",
        },
        {
            title: t(""),
            dataIndex: "id",
            key: "id",
            render: (id: string) => (
                <div style={{ textAlign: "right" }}>
                    <Space>
                        <Tooltip title={t("Opgave overblik")}>
                            <Button
                                // type="primary"
                                // size="small"
                                // shape="circle"
                                icon={<DashboardOutlined />}
                                onClick={() => history.push(`/dashboard?groupId=${id}`)}>
                                {t("Dashboard")}
                            </Button>
                        </Tooltip>
                        <Tooltip title={t("Opgave skabeloner")}>
                            <Button
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                // type="primary"
                                // size="small"
                                // shape="circle"
                                icon={<AuditOutlined style={{ fontSize: 18 }} />}
                                onClick={() =>
                                    history.push(`/groups/${id}/audits/templates`)
                                }
                            />
                        </Tooltip>
                        <Tooltip title={t("lager overblik")}>
                            <Button
                                type="primary"
                                size="small"
                                shape="circle"
                                icon={<RightOutlined />}
                                onClick={() => dispatch(openGroupDrawer({ groupId: id }))}
                            />
                        </Tooltip>
                    </Space>
                </div>
            ),
            // width: "20%",
        },
    ];
    // @ts-ignore
    return <Table columns={columns} rowKey="id" dataSource={props.data} />;
}

export default TableList;
