import React, { ReactNode, useState } from "react";
import {
    AlertOutlined,
    CaretDownFilled,
    CaretDownOutlined,
    CaretUpOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    DownOutlined,
    FileAddOutlined,
    FormOutlined,
    SwapOutlined,
    SwitcherOutlined,
    TagOutlined,
    UpOutlined,
    UserAddOutlined,
    UserSwitchOutlined,
} from "@ant-design/icons";
import { Button, Timeline } from "antd";
import useIssueActivity from "./useIssueActivity";
import { ActivityType } from "general/enums";
import { StyleGuide } from "../../styles/StyleGuide";
import { Trans, useTranslation } from "react-i18next";
import { t } from "i18next";
import { responsibleMap } from "./IssueItem";

const displayLimit = 5;

export default function IssueActivity() {
    const { t } = useTranslation();
    const { activity, isLoading } = useIssueActivity();
    // collapse activity
    const [collapsed, setCollapsed] = useState<boolean>(true);
    // filter oly last 5 activities when collapsed
    const renderActivity = collapsed ? activity.slice(0, displayLimit) : activity;

    return (
        <div>
            <Timeline items={formatActivity(renderActivity)} />
            {activity.length > displayLimit && (
                <Button
                    block
                    icon={collapsed ? <DownOutlined /> : <UpOutlined />}
                    onClick={() => setCollapsed(!collapsed)}>
                    {collapsed ? t("general.showMore") : t("general.showLess")}
                </Button>
            )}
        </div>
    );
}

type Activity = {
    children: string | JSX.Element | undefined;
    dot?: JSX.Element;
    color?: string | undefined;
};

function ActivityItem({ label, time }: { label: string; time: Date }) {
    const { t } = useTranslation();
    return (
        <>
            <div>
                <Trans i18nKey={label} />
            </div>
            <div style={{ fontSize: 12, color: StyleGuide.palette.grey600 }}>
                {t("date.exactFormat", {
                    relativeTime: time,
                    dayOfWeek: time,
                    dayOfMonth: time,
                    month: time,
                    time: time,
                })}
            </div>
        </>
    );
}

function formatActivity(activity: any[]): Activity[] {
    const activities: Activity[] = [];
    activity.forEach((item: any) => {
        switch (item.type) {
            case ActivityType.ACTIVITY_ISSUE_CREATE:
                activities.push({
                    children: (
                        <ActivityItem
                            label={t("issues.activity.issueCreate")}
                            time={item.createTime}
                        />
                    ),
                    dot: <AlertOutlined style={{ color: StyleGuide.palette.redish }} />,
                });
                break;
            case ActivityType.ACTIVITY_ISSUE_ASSIGNEE_INIT:
                activities.push({
                    children: (
                        <ActivityItem
                            label={t("issues.activity.issueAssigneeInit", {
                                name: item.context?.assigneeName,
                            })}
                            time={item.createTime}
                        />
                    ),
                    dot: (
                        <UserAddOutlined style={{ color: StyleGuide.palette.success }} />
                    ),
                });
                break;
            case ActivityType.ACTIVITY_ISSUE_ASSIGNEE_CHANGED:
                activities.push({
                    children: (
                        <ActivityItem
                            label={t("issues.activity.issueAssigneeChanged", {
                                name: item.context?.assigneeName,
                            })}
                            time={item.createTime}
                        />
                    ),
                    dot: (
                        <UserSwitchOutlined style={{ color: StyleGuide.palette.amber }} />
                    ),
                });
                break;
            case ActivityType.ACTIVITY_ISSUE_DOCUMENTATION_ADDED:
                activities.push({
                    children: (
                        <ActivityItem
                            label={t("issues.activity.issueDocumentationAdded")}
                            time={item.createTime}
                        />
                    ),
                    dot: (
                        <FileAddOutlined style={{ color: StyleGuide.palette.success }} />
                    ),
                });
                break;
            case ActivityType.ACTIVITY_ISSUE_DOCUMENTATION_DELETED:
                activities.push({
                    children: (
                        <ActivityItem
                            label={t("issues.activity.issueDocumentationDeleted")}
                            time={item.createTime}
                        />
                    ),
                    dot: (
                        <ClockCircleOutlined
                            style={{ color: StyleGuide.palette.redish }}
                        />
                    ),
                });
                break;
            case ActivityType.ACTIVITY_ISSUE_RESPONSIBLE_CHANGED:
                activities.push({
                    children: (
                        <ActivityItem
                            label={t("issues.activity.issueResponsibleChanged", {
                                // @ts-ignore
                                responsible: responsibleMap[item.context.responsible],
                            })}
                            time={item.createTime}
                        />
                    ),
                    dot: <SwapOutlined style={{ color: StyleGuide.palette.amber }} />,
                });
                break;
            case ActivityType.ACTIVITY_ISSUE_COMPLETED:
                activities.push({
                    children: (
                        <ActivityItem
                            label={t("issues.activity.issueResolved")}
                            time={item.createTime}
                        />
                    ),
                    dot: (
                        <CheckCircleOutlined
                            style={{ color: StyleGuide.palette.success }}
                        />
                    ),
                });
                break;
            case ActivityType.ACTIVITY_ISSUE_NOTE_ADDED:
                activities.push({
                    children: (
                        <ActivityItem
                            label={t("issues.activity.issueResolved")}
                            time={item.createTime}
                        />
                    ),
                    dot: <FormOutlined style={{ color: StyleGuide.palette.success }} />,
                });
                break;
            case ActivityType.ACTIVITY_ISSUE_NOTE_CHANGED:
                activities.push({
                    children: (
                        <ActivityItem
                            label={t("issues.activity.issueResolved")}
                            time={item.createTime}
                        />
                    ),
                    dot: <FormOutlined style={{ color: StyleGuide.palette.success }} />,
                });
                break;
            case ActivityType.ACTIVITY_ISSUE_NOTE_DELETED:
                activities.push({
                    children: (
                        <ActivityItem
                            label={t("issues.activity.issueResolved")}
                            time={item.createTime}
                        />
                    ),
                    dot: <FormOutlined style={{ color: StyleGuide.palette.success }} />,
                });
                break;
            case ActivityType.ACTIVITY_ISSUE_TAGS_CHANGED:
                activities.push({
                    children: (
                        <ActivityItem
                            label={t("issues.activity.tagsChanged", {
                                tags: item.context?.displayTags?.join(", "),
                            })}
                            time={item.createTime}
                            // diff={{
                            //     prev: item.context?.prevDisplayTags?.join(", "),
                            //     curr: item.context?.displayTags?.join(", "),
                            // }}
                        />
                    ),
                    dot: <TagOutlined style={{ color: StyleGuide.palette.amber }} />,
                });
                break;
            case ActivityType.ACTIVITY_ISSUE_DESCRIPTION_CHANGED:
                activities.push({
                    children: (
                        <ActivityItem
                            label={t("issues.activity.issueDescriptionChanged")}
                            time={item.createTime}
                        />
                    ),
                    dot: <FormOutlined style={{ color: StyleGuide.palette.amber }} />,
                });
                break;
            default:
                break;
        }
    });
    return activities;
}
