import firebase from "firebase/compat/app";
import { useEffect, useState } from "react";
import { TagStatus } from "../Views/Billing/Table";
import { useAppSelector } from "../hooks";

export interface AppBilling {
    id: string;
    org: {
        id: string;
        title: string;
    };
    products: {
        id: string;
        name: string;
        price: number;
        recurring: string;
        paid: boolean | null;
    }[];
    lastPayDate: Date | null;
    nextPayDate: Date;
    status: TagStatus;
    createTime: Date;
    updateTime: Date;
    note: string;
}
export default function useAppBillings() {
    const [appBilling, setappBilling] = useState<AppBilling[]>([]);
    const orgId = useAppSelector(state => state.orgs?.currentOrg?.id);
    useEffect(() => {
        if (!orgId) return;
        let unsubscribe: any = null;
        unsubscribe = firebase
            .firestore()
            .collection(`appBilling`)
            .where("org.id", "==", orgId)
            .onSnapshot(query => {
                if (!query.empty) {
                    const list: AppBilling[] = query.docs.map(
                        doc =>
                            ({
                                ...doc.data(),
                                id: doc.id,
                                nextPayDate: doc.data().nextPayDate.toDate(),
                                lastPayDate: doc.data().lastPayDate?.toDate(),
                                createTime: doc.data().createTime.toDate(),
                                updateTime: doc.data().updateTime.toDate(),
                            } as AppBilling),
                    );
                    setappBilling(list);
                }
            });
        return unsubscribe;
    }, [orgId]);
    return {
        appBilling,
    };
}
