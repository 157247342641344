import moment from "moment";
import keyBy from "lodash.keyby";
import {
    SENSORS_FETCH_REQUESTED,
    SENSORS_FETCH_SUCCEEDED,
    SENSORS_FETCH_FAILED,
    SENSOR_FETCH_REQUESTED,
    SENSOR_FETCH_SUCCEEDED,
    SENSOR_FETCH_FAILED,
    SENSORLOGS_FETCH_REQUESTED,
    SENSORLOGS_FETCH_SUCCEEDED,
    SENSORLOGS_FETCH_FAILED,
    SENSORMEASURES_FETCH_SUCCEEDED,
    GPS_SENSORLOGS_FETCH,
} from "./types";

const initialState = {
    sensors: [],
    loading: true,
    currentSensor: {
        data: [],
        logs: [],
        location: {},
        loading: true,
        sensorBoundaries: {
            topLeftBoundary: { latitude: 0, longitude: 0 },
            topRightBoundary: { latitude: 0, longitude: 0 },
            bottomRightBoundary: { latitude: 0, longitude: 0 },
            bottomLeftBoundary: { latitude: 0, longitude: 0 },
        },
    },
    selectedDate: moment
        .utc()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toISOString(),
};

export default function user(state = initialState, action) {
    switch (action.type) {
        case SENSORS_FETCH_REQUESTED:
        case SENSORLOGS_FETCH_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case GPS_SENSORLOGS_FETCH:
            return {
                ...state,
                loading: true,
            };
        case SENSORS_FETCH_SUCCEEDED:
            return {
                ...state,
                sensors: action.payload.data,
                loading: false,
            };
        case SENSORS_FETCH_FAILED:
        case SENSORLOGS_FETCH_FAILED:
            return {
                ...state,
                loading: false,
            };
        case SENSORLOGS_FETCH_SUCCEEDED:
            return {
                ...state,
                currentSensor: {
                    ...state.currentSensor,
                    data: action.payload.data,
                    logs: action.payload.data,
                },
                loading: false,
            };
        case SENSORMEASURES_FETCH_SUCCEEDED:
            return {
                ...state,
                currentSensor: {
                    ...state.currentSensor,
                    measures: action.payload.data,
                },
            };
        case SENSOR_FETCH_SUCCEEDED: {
            return {
                ...state,
                currentSensor: {
                    ...state.currentSensor,
                    ...action.payload.data,
                    loading: false,
                },
            };
        }
        case "SET_DATE":
            return {
                ...state,
                selectedDate: action.payload.date,
            };
        default:
            return state;
    }
}
