import React from "react";
import { Form, Input, Button, InputNumber, Select, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { getUniqueNumbers, parseAmountToFloat } from "../../general/helpers";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import { Product } from "../../modules/products/types";
import { addCleanLog } from "../../modules/cleanLogs/actions";
import { useAppSelector } from "../../hooks";
import { updateOrgProduct } from "../../modules/products/actions";
import useCleanLogsOptions from "../../hooks/useCleanLogOptions";
import { CleanLogsOptionsFormatted } from "../../hooks/useCleanLogOptions";
import {
    isSignificantlyDeviating,
    isSignificantlyDeviatingAdjusted,
} from "../../general/math";
import confirmModal from "../../general/confirmModal";

const FormItem = Form.Item;

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const formItemLayout = {
    wrapperCol: { span: 24 },
};

function AddLogForm({
    product,
    cleanLogOptions,
}: {
    product: Product;
    cleanLogOptions: CleanLogsOptionsFormatted[];
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const uniqLogs = useAppSelector(state =>
        getUniqueNumbers(
            state.cleanLogs.logs
                .filter(log => log.product?.id === product?.id)
                .map(log => log.amount),
        ),
    );
    const orgId = useAppSelector(state => state.orgs.currentOrg.id);
    const orgUnit = useAppSelector(state => state.orgs.currentOrg?.unit);
    const [form] = Form.useForm();
    const isMobile = useIsMobile();
    const hasOptions = cleanLogOptions && cleanLogOptions?.length > 0;
    const onFinish = (values: any) => {
        console.log({ onFinish: values });

        const isNewEntryDeviating = !uniqLogs.includes(values.amount);

        if (isNewEntryDeviating) {
            confirmModal({
                title: t("cleanlog.add.confirm.title", { amount: values.amount }),
                okType: "primary",
                onOk: () => {
                    submitLog(values);
                },
            });
        } else {
            submitLog(values);
        }
    };

    const submitLog = (values: any) => {
        if (values.optionId) {
            const option = cleanLogOptions.find(o => o.value === values.optionId);
            console.log({ option });
            if (!option) return alert("Noget gik galt");
            addCleanlogFn({
                initials: option.label,
                amount: values.amount,
                productId: product.id,
                optionId: option.value,
            });
        } else {
            addCleanlogFn({
                initials: values.initials,
                amount: values.amount,
                productId: product.id,
            });
        }
        form.resetFields();
    };

    const onFinishFailed = ({ values, errorFields }: any) => {
        form.scrollToField(errorFields[0].name);
        console.log({ values, errorFields });
    };
    const validateNum = async (rule: any, value: any) => {
        console.log({ rule, value });
        const num = parseAmountToFloat(value);

        if (isNaN(num) || num <= 0) {
            throw new Error("ERROORORRROROO");
        }
    };

    const addCleanlogFn = ({
        initials,
        amount,
        productId,
        optionId,
    }: {
        initials: string;
        amount: number;
        productId: string;
        optionId?: string;
    }) => {
        dispatch(addCleanLog({ orgId, initials, amount, productId, optionId }));

        // props.addCleanLog({
        //     initials,
        //     amount: parseAmountToFloat(amount),
        //     productId: product.id,
        // });
        dispatch(
            updateOrgProduct({
                orgId,
                productId,
                data: {
                    storage:
                        parseAmountToFloat(product.storage) - parseAmountToFloat(amount),
                },
            }),
        );
    };

    // addCleanLog={({
    //     initials,
    //     amount,
    // }: {
    //     initials: string;
    //     amount: number;
    // }) =>
    //     addCleanlogFn({
    //         initials,
    //         amount,
    //         product,
    //     })
    // }

    return (
        <>
            <Form
                form={form}
                layout={isMobile ? "horizontal" : "inline"}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}>
                <FormItem
                    name={hasOptions ? "optionId" : "initials"}
                    rules={[
                        {
                            required: true,
                            message: hasOptions
                                ? t("cleanlog.add.location.require")
                                : t("cleanlog.add.initials.require"),
                        },
                    ]}>
                    {hasOptions ? (
                        <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder={t("cleanlog.add.location.placeholder")}
                            optionFilterProp="children"
                            filterOption={(input: string, option: any) =>
                                (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input?.toLowerCase() ?? "")
                            }
                            filterSort={(optionA: any, optionB: any) =>
                                (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare((optionB?.label ?? "").toLowerCase())
                            }
                            options={cleanLogOptions}
                        />
                    ) : (
                        <Input
                            prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                            placeholder={t("cleanlog.add.initials.placeholder")}
                        />
                    )}
                </FormItem>
                <Space direction="vertical">
                    <FormItem
                        {...formItemLayout}
                        name="amount"
                        rules={[
                            {
                                required: true,
                                message: t("cleanlog.add.amount.require"),
                                validator: validateNum,
                            },
                        ]}>
                        <InputNumber
                            placeholder={t("cleanlog.add.amount.placeholder", {
                                unit: orgUnit,
                            })}
                            min={0}
                            decimalSeparator=","
                            // precision={2}
                        />
                    </FormItem>
                    <div style={{ color: "darkgray" }}>(1 ml = 0,001 liter)</div>
                </Space>
                <FormItem>
                    <Button type="primary" htmlType="submit" block={isMobile}>
                        {t("cleanlog.add.submit")}
                    </Button>
                </FormItem>
            </Form>
        </>
    );
}

export default AddLogForm;

const options = [
    {
        value: "1",
        label: "Not Identified",
    },
    {
        value: "2",
        label: "Closed",
    },
    {
        value: "3",
        label: "Communicated",
    },
    {
        value: "4",
        label: "Identified",
    },
    {
        value: "5",
        label: "Resolved",
    },
    {
        value: "6",
        label: "Cancelled",
    },
];
