import React, { useEffect } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { StyleGuide } from "styles/StyleGuide";

const config = {
    selector: ".headwayapp-updates", // CSS selector where to inject the badge
    account: "yjva9y",
};

export default function News() {
    const { t } = useTranslation();
    const translations = {
        title: t("headway.title"),
        readMore: t("headway.readMore"),
        labels: {
            new: t("headway.labels.new"),
            improvement: t("headway.labels.improvements"),
            fix: t("headway.labels.fix"),
        },
        footer: t("headway.footer"),
    };
    useEffect(() => {
        if (window.Headway) {
            window.Headway.init({ ...config, translations });
        }
        return () => {};
    }, []);

    return (
        <Button
            type="link"
            style={{
                border: `1px solid ${StyleGuide.palette.infoLight}`,
                borderRadius: 16,
            }}
            // style={{marginRight: 20, position: "relative" }}
            className="headwayapp-updates">
            {t("header.news")}
        </Button>
    );
}
