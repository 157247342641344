import { IPAD_INVENTORY_FETCH_REQUESTED, IPAD_INVENTORY_FETCH_SUCCEEDED } from "./types";
import { InventoryContextForType, InventoryType } from "../types";

export function fetchInventoryIpads() {
    return {
        type: IPAD_INVENTORY_FETCH_REQUESTED,
        payload: {},
    };
}

export function fetchInventoryIpadsSuccess(
    // @ts-ignore
    productsList: firebase.firestore.QuerySnapshot,
): {
    type: string;
    payload: InventoryContextForType<InventoryType.IPAD>[];
} {
    const ipads = productsList.docs.map(
        // @ts-ignore
        (doc: firebase.firestore.DocumentData) => {
            const data = doc.data();
            return {
                ...data,
                orgId: data?.org?.id,
                orgTitle: data?.org?.title,
                id: doc.id,
                updateTime: data?.updateTime?.toDate(),
            };
        },
    );
    return {
        type: IPAD_INVENTORY_FETCH_SUCCEEDED,
        payload: ipads,
    };
}
