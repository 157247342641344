import { CopyOutlined, DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Divider, Dropdown, Space, message, theme } from "antd";
import { MenuProps } from "antd/lib";
import React from "react";
import useDeleteTemplate from "./useDeleteTemplate";
import useDuplicateTemplate from "./DuplicateTemplate";
import { AuditTemplate } from "modules/auditTemplates/types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const { useToken } = theme;

export default function Options({
    groupId,
    templateId,
    template,
}: {
    groupId: string;
    templateId: string;
    template: AuditTemplate;
}) {
    const history = useHistory();
    const { t } = useTranslation();
    const { token } = useToken();
    const { loading, confirmDeleteItem } = useDeleteTemplate({ groupId, templateId });
    const { loading: loadingDuplicate, handleDuplicateTemplate } = useDuplicateTemplate({
        groupId,
        template,
    });
    // const handleMenuClick: MenuProps["onClick"] = e => {
    //     // message.info("Click on menu item.");
    //     console.log("click", e);
    // };
    const items: MenuProps["items"] = [
        {
            label: t("audit.templates.duplicate"),
            key: "1",
            icon: <CopyOutlined />,
            onClick: handleDuplicateTemplate,
        },
        {
            label: t("audit.templates.delete"),
            key: "2",
            icon: <DeleteOutlined />,
            danger: true,
            onClick: confirmDeleteItem,
        },
    ];
    const menuProps = {
        items,
        // onClick: handleMenuClick,
    };

    const contentStyle: React.CSSProperties = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
    };

    const menuStyle: React.CSSProperties = {
        boxShadow: "none",
    };

    return (
        <Dropdown.Button
            menu={menuProps}
            placement="bottom"
            icon={<MoreOutlined />}
            onClick={() => {
                history.push(`/groups/${groupId}/audits/templates/${templateId}`);
            }}
            dropdownRender={menu => {
                console.log({ menu });
                return (
                    <div style={contentStyle}>
                        {/* <DeleteTemplate orgId="orgId" templateId="templateId" /> */}
                        {React.cloneElement(menu as React.ReactElement, {
                            style: menuStyle,
                        })}
                        {/* <Divider style={{ margin: 0 }} />
                        <Space style={{ padding: 8 }}>
                            <Button type="primary">Click me!</Button>
                        </Space> */}
                    </div>
                );
            }}>
            {t("audit.templates.viewmore")}
        </Dropdown.Button>
    );
}
