import keyBy from "lodash.keyby";
import { ProductState, ProductActionTypes } from "./types";
import {
    PRODUCTS_FETCH_REQUESTED,
    PRODUCTS_FETCH_SUCCEEDED,
    PRODUCTS_FETCH_FAILED,
    ORG_PRODUCTS_FETCH_REQUESTED,
    ORG_PRODUCTS_FETCH_SUCCEEDED,
    ORG_PRODUCTS_FETCH_FAILED,
} from "./types";

const initialState: ProductState = {
    loading: true,
    list: [],
    productsMap: {},
    orgProducts: [],
    orgProductsMap: {},
};

export default function products(
    state = initialState,
    action: ProductActionTypes,
): ProductState {
    switch (action.type) {
        case PRODUCTS_FETCH_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case PRODUCTS_FETCH_SUCCEEDED:
            return {
                ...state,
                loading: false,
                list: action.payload.products,
                productsMap: keyBy(action.payload.products, product => product.id),
            };
        case PRODUCTS_FETCH_FAILED:
            return {
                ...state,
                loading: false,
            };

        case ORG_PRODUCTS_FETCH_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case ORG_PRODUCTS_FETCH_SUCCEEDED:
            return {
                ...state,
                loading: false,
                orgProducts: action.payload.products,
                orgProductsMap: keyBy(action.payload.products, product => product.id),
            };
        case ORG_PRODUCTS_FETCH_FAILED:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
}
