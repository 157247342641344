import firebase from "firebase/compat/app";
import { useEffect, useState } from "react";
import { TagStatus } from "../Views/Billing/Table";
import { useAppSelector } from "../hooks";
import { AppBilling } from "./useOrgAppBillings";

export default function useAppBilling({ billingId }: { billingId: string }) {
    const [appBilling, setappBilling] = useState<AppBilling | null>(null);

    useEffect(() => {
        console.log(billingId);
        let unsubscribe: any = null;
        if (billingId) {
            unsubscribe = firebase
                .firestore()
                .collection(`appBilling`)
                .doc(billingId)
                .onSnapshot(doc => {
                    if (doc.exists) {
                        const data = doc.data();
                        if (!data) return;
                        setappBilling({
                            ...data,
                            id: doc.id,
                            nextPayDate: data.nextPayDate.toDate(),
                            lastPayDate: data.lastPayDate?.toDate(),
                            createTime: data.createTime.toDate(),
                            updateTime: data.updateTime.toDate(),
                        } as AppBilling);
                    }
                });
        }
        return () => {
            console.log("unsubscribe");
            unsubscribe?.();
            setappBilling(null);
        };
    }, [billingId]);
    return {
        appBilling,
    };
}
