import React, { useState } from "react";
import { Button, Modal, Select, Tooltip, Typography } from "antd";
import useAuditTemplateCategories from "modules/auditTemplateCategories/useAuditTemplateCategories";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../hooks";
import AdminOnly from "components/AdminOnly";
import { StyleGuide } from "styles/StyleGuide";
import { InfoCircleOutlined } from "@ant-design/icons";
import useAuditByAuditTemplateIdCount from "hooks/useAuditByAuditTemplateIdCount";
import firebase from "firebase/compat/app";
import Message, { Type } from "components/Notification/Message";

const db = firebase.firestore();

export default function AuditTemplateCategory({
    orgId,
    auditTemplateId,
    auditTemplateCategory,
    updateAuditTemplate,
}: {
    orgId: string;
    auditTemplateId: string;
    auditTemplateCategory?: string | null;
    updateAuditTemplate: ({
        orgId,
        auditTemplateId,
        data: { auditTemplateCategory },
    }: {
        orgId: string;
        auditTemplateId: string;
        data: { auditTemplateCategory: string | null };
    }) => Promise<void>;
}) {
    const [isLoading, setLoading] = useState(false);
    const [visible, setvisible] = useState(false);
    const groupId = useAppSelector(state => state.orgs.currentOrg?.groupId);
    const { t } = useTranslation();
    const { loading, auditTemplateCategories } = useAuditTemplateCategories({
        orgId,
        groupId,
    });

    const count = useAuditByAuditTemplateIdCount(orgId, auditTemplateId);

    const onSelectCategory = async (value: string | undefined) => {
        try {
            if (!orgId || !auditTemplateId) {
                throw new Error(
                    "[AuditTemplateCategory]: orgId or auditTemplateId is null",
                );
            }
            await updateAuditTemplate({
                orgId,
                auditTemplateId,
                data: {
                    auditTemplateCategory: value ?? null,
                },
            });
            if (count > 0 && value) {
                setvisible(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onUpdateAuditCategory = async () => {
        try {
            if (!orgId || !auditTemplateCategory) {
                throw new Error(
                    `orgId=${orgId} or auditTemplateCategory=${auditTemplateCategory} missing`,
                );
            }

            setLoading(true);
            const batch = db.batch();
            const auditRefs = await db
                .collection("orgs")
                .doc(orgId)
                .collection("auditSchedules")
                .where("templateId", "==", auditTemplateId)
                .get();
            auditRefs.docs.forEach(async doc => {
                batch.set(doc.ref, { auditTemplateCategory }, { merge: true });
            });
            await batch.commit();
            setvisible(false);
            Message({
                key: "audit-template-category-update",
                message: `Opgaverne blev opdateret`,
            });
        } catch (error) {
            console.error(error);
            Message({
                key: "audit-template-category-update",
                type: Type.ERROR,
                message: `Der skete en fejl`,
                description: `Kunne ikke opdatere, prøv venligst igen`,
            });
        } finally {
            setLoading(false);
        }
    };

    const currentCategory = auditTemplateCategories.find(
        category => category.id === auditTemplateCategory,
    );
    return (
        <AdminOnly>
            <div>
                <div
                    style={{
                        marginTop: 16,
                        borderBottom: `1px solid ${StyleGuide.palette.grey200}`,
                        marginBottom: 16,
                    }}
                />
                <Tooltip title="Denne kategori hjælper med at gruppere opgaver for at kunne analysere registereringer">
                    <Typography.Text>
                        {t("Opgave kategori")} <InfoCircleOutlined />
                    </Typography.Text>
                </Tooltip>
                <div style={{ marginBottom: 4 }} />
                <Select
                    value={auditTemplateCategory}
                    style={{ width: "100%", minWidth: 200 }}
                    placeholder="Vælg katagori"
                    onChange={onSelectCategory}
                    allowClear
                    showSearch
                    optionFilterProp="label"
                    loading={loading}
                    disabled={loading}
                    options={auditTemplateCategories.map(category => ({
                        value: category.id,
                        label: category.name,
                    }))}
                />
                <Modal
                    title={`Der er ${count} audits der kan opdateres med den nye kategori ${
                        currentCategory?.name || "Ingen"
                    }`}
                    key="audit-template-category-update"
                    // width="100%"
                    style={{ position: "relative" }}
                    open={Boolean(visible)}
                    onCancel={() => setvisible(false)}
                    footer={[]}>
                    {count > 500 ? (
                        "Der er over 500 opgaver at opdatere, kontakt venligst support"
                    ) : (
                        <Button
                            loading={isLoading}
                            type="primary"
                            block
                            onClick={onUpdateAuditCategory}>
                            Opdater
                        </Button>
                    )}
                </Modal>
            </div>
        </AdminOnly>
    );
}
