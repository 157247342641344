import { useEffect } from "react";
import { compose } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import HomeView from "./index";
import { addOrg, updateOrg } from "../../modules/orgs/actions";
import Loader from "../../components/Loader";
import { AppState } from "../../store";
import useQueryParams from "../../hooks/useQueryParams";
import { useAppSelector } from "../../hooks";

interface IProps {
    loading: boolean;
    uid: string;
    email: string;
    updateOrg: ({ orgId, data }: { orgId: string; data: { isDeleted: boolean } }) => void;
    match: any;
}

function HomeViewContainer({ loading, updateOrg }: IProps) {
    const query = useQueryParams();
    const groupId = query.get("groupId");

    const orgs = useAppSelector(state =>
        groupId
            ? state.orgs.orgs.filter(org => org.groupId === groupId)
            : state.orgs.orgs,
    );
    const groups = useAppSelector(state => state.groups.groups);

    const deleteOrgFunc = (orgId: string) => {
        updateOrg({ orgId, data: { isDeleted: true } });
    };
    if (loading) {
        return <Loader />;
    }
    return <HomeView orgs={orgs} deleteOrg={deleteOrgFunc} groups={groups} />;
}

function mapStateToProps(state: AppState) {
    return {
        loading: state.orgs.loading,
        orgs: state.orgs.orgs,
        // @ts-ignore
        uid: state.user.details.uid,
        // @ts-ignore
        email: state.user.details.email,
    };
}

const mapActionsToProps = {
    updateOrg,
};

const withConnect = connect(mapStateToProps, mapActionsToProps);

export default compose(withConnect, withRouter)(HomeViewContainer);
