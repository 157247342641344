import React from "react";
import keyBy from "lodash.keyby";
import FileCard from "../AuditTemplates/FileCard";
import { useAppSelector } from "../../hooks";
import { getContentTypeIconMap } from "../Reports/Report";
import { ContentType } from "general/enums";

export default function AuditGuide() {
    const { templateId, guide, auditTemplatesByKey } = useAppSelector(state => ({
        templateId: state.auditSchedules.currentAuditSchedule?.templateId,
        guide: state.auditSchedules.currentAuditSchedule.guide,
        auditTemplatesByKey: keyBy(
            state.auditTemplates.auditTemplates,
            template => template.id,
        ),
    }));
    const templateGuide = guide || auditTemplatesByKey[templateId]?.guide;
    const icon = getContentTypeIconMap(
        // @ts-ignore
        templateGuide?.contentType || null,
        templateGuide?.filePath || null,
        {
            // marginTop: 20,
            fontSize: 100,
            height: 100,
        },
    );
    if (templateGuide) {
        return (
            <FileCard
                small
                icon={icon}
                fileName={templateGuide?.fileName}
                title={
                    // @ts-ignore
                    templateGuide?.title || templateGuide?.fileName
                }
                path={templateGuide?.filePath}
                contentType={templateGuide?.contentType as ContentType}
            />
        );
    }
    return null;
}
