import React, { useState } from "react";
import { Steps, Button, message, Result } from "antd";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import firebase from "firebase/compat/app";
import { useAppSelector } from "../../../hooks";
import { Link } from "react-router-dom";
import Message, { Type } from "../../../components/Notification/Message";

const { Step } = Steps;

const steps = [
    {
        title: "Sådan virker støvbinder",
        content: (next: () => void) => <Step1 next={next} />,
    },
    {
        title: "Sådan anvendes støvbinder",
        content: (next: () => void) => <Step2 next={next} />,
    },
    {
        title: "100% naturligt produkt",
        content: (next: () => void) => <Step3 next={next} />,
    },
    {
        title: "Log i app'en",
        content: (next: () => void) => <Step4 next={next} />,
    },
    {
        title: "Certificeret",
        content: (done: () => Promise<void>) => <Step5 done={done} />,
    },
];

const Wizard = () => {
    const [current, setCurrent] = useState(0);
    const [history, setHistory] = useState(0);
    const orgId = useAppSelector(state => state.orgs.currentOrg.id);
    const userId = useAppSelector(state => state.user.details.uid);
    const [success, setSuccess] = useState<string[] | null>(null);

    const next = () => {
        if (current + 1 > history) {
            setHistory(current + 1);
        }
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };
    const done = async (names: string[]) => {
        try {
            if (!orgId) {
                throw new Error("Error");
            }

            const batch = firebase.firestore().batch();

            names.filter(Boolean).forEach(name => {
                const ref = firebase
                    .firestore()
                    .collection(`orgs/${orgId}/certifications`)
                    .doc();
                batch.set(ref, {
                    userId,
                    name,
                    createTime: new Date(),
                });
            });

            await batch.commit();
            setSuccess(names);
        } catch (error) {
            message.error("Noget gik galt. Prøv igen senere");
            console.error(error);
        }
    };

    const lastIndex = steps.length - 1;
    const nextContent =
        current === lastIndex
            ? // @ts-ignore
              steps[current].content?.(done)
            : // @ts-ignore
              steps[current].content?.(next);

    if (success) {
        return (
            <Result
                status="success"
                title={`Tillykke ${success?.join(", ")}, du/I er nu certificeret`}
                subTitle="Vi er ved at oprette dit certifikat, du vil snart kunne finde det under Rapporter i mappen Certifikater"
                extra={[
                    <Link to={`/orgs/${orgId}/reports/CERTIFICATES`}>
                        Gå til Certifikater
                    </Link>,
                ]}
            />
        );
    }

    return (
        <>
            <Steps
                current={current}
                onChange={(value: number) => {
                    if (value <= history) {
                        return setCurrent(value);
                    }
                    Message({
                        key: "wizard",
                        type: Type.ERROR,
                        message: `Du kan ikke springe over trin`,
                        description: `Gennemfør steppet du er nået til for at komme videre`,
                    });
                }}>
                {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>

            <div className="steps-content">{nextContent}</div>
        </>
    );
};

export default Wizard;

// function Controller({
//     current,
//     prev,
// }: // next,
// // onComplete,
// {
//     current: number;
//     prev: () => void;
//     next: () => void;
//     onComplete: () => {};
// }) {
//     return (
//         <div
//             className="steps-action"
//             style={{ display: "flex", justifyContent: "space-between" }}>
//             {current > 0 && (
//                 <Button style={{ margin: "0 8px" }} onClick={prev}>
//                     Tilbage
//                 </Button>
//             )}
//             {/* {current < steps.length - 1 && (
//                 <Button type="primary" onClick={next}>
//                     Næste
//                 </Button>
//             )} */}
//             {/* {current === steps.length - 1 && (
//                 <Button type="primary" onClick={onComplete}>
//                     Done
//                 </Button>
//             )} */}
//         </div>
//     );
// }
