import { useEffect } from "react";
import firebase from "firebase/compat/app";
import { RequestState } from "../../interfaces/request";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchGroups, fetchGroupsFailed, fetchGroupsSuccess } from "./actions";

function useGroups() {
    const dispatch = useAppDispatch();
    const uid = useAppSelector(state => state.user.details.uid);
    const { groups, state } = useAppSelector(state => state.groups);

    useEffect(() => {
        if (uid) {
            dispatch(fetchGroups());
            let unsubscribe: any = null;
            unsubscribe = firebase
                .firestore()
                .collection("groups")
                .where(`members.${uid}.uid`, "==", uid)
                .onSnapshot(
                    query => {
                        // if (!query.empty) {
                        const list: any[] = query.docs.map(doc => ({
                            id: doc.id,
                            ...doc.data(),
                        }));
                        dispatch(fetchGroupsSuccess(list));
                        // }
                    },
                    error => {
                        dispatch(fetchGroupsFailed());
                        console.error(error);
                    },
                );
            return unsubscribe;
        }
    }, [uid, dispatch]);
    return {
        groups,
        isLoadingGroups: state === RequestState.PENDING || state === RequestState.INITIAL,
    };
}

export default useGroups;
