import React from "react";
import { Layout } from "antd";
import Table from "./components/Table";
import SideNav from "../../Layout/SideNav";
import HeaderBar from "../../Layout/HeaderBar";
import FooterBar from "../../Layout/FooterBar";
import Loader from "../../components/Loader";
import AddOrderForm from "./AddOrderForm";
import moment from "moment";
import { parseAmountToFloat } from "general/helpers";
import { useTranslation } from "react-i18next";
import { FeatureIds } from "../../modules/featureControl/featuresConfig";
import BlurOverlayContainer from "../../components/Overlay/BlurOverlayContainer";

const { Content } = Layout;

function OrderList(props: any) {
    const { t } = useTranslation();
    const data = props.orders.map((log: any) => {
        return {
            ...log,
            key: log.id,
            createTime: moment(log.createTime.toDate()).format("Do MMM. YYYY - HH:mm"),
        };
    });
    return (
        <>
            <SideNav />
            <Layout>
                <HeaderBar />
                <BlurOverlayContainer featureKey={FeatureIds.ORDERS}>
                    <Content style={{ margin: "50px 24px 0" }}>
                        {props.loading ? (
                            <Loader />
                        ) : (
                            <>
                                <h2>{t("orders.headline")}</h2>
                                <div>
                                    <Table
                                        orgId={props.orgId}
                                        loading={props.loading}
                                        scroll={{ x: true }}
                                        data={data}
                                        updateOrder={props.updateOrder}
                                        updateOrgProduct={props.updateOrgProduct}
                                        productsMap={props.productsMap}
                                    />
                                </div>
                                <div style={{ marginTop: 8 }} />
                                <AddOrderForm
                                    addOrder={props.addOrder}
                                    products={props.products}
                                />
                            </>
                        )}
                    </Content>
                </BlurOverlayContainer>
                <FooterBar />
            </Layout>
        </>
    );
}

export default OrderList;

OrderList.defaultProps = {
    logs: [],
};
