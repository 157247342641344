import React, { useState, CSSProperties } from "react";
import ReactPlayer from "react-player";
import { Row, Col, Typography, Button, Checkbox } from "antd";
import { Colors } from "../../../Layout/Colors";
import {
    CheckOutlined,
    CheckSquareOutlined,
    CheckCircleOutlined,
} from "@ant-design/icons";

const { Title, Paragraph } = Typography;

export default function Step4({ next }: { next: () => void }) {
    const [startedVideo, setStartedVideo] = useState(false);
    return (
        <Row>
            <Col md={24}>
                <Title style={styles.body}>Log i app'en</Title>
            </Col>
            <Col xs={24} md={12}>
                <div style={styles.wrapper}>
                    {/* @ts-ignore */}
                    <ReactPlayer
                        // height={"70%"}
                        onStart={() => {}} //setStartedVideo(true)}
                        width={"100%"}
                        style={styles.player}
                        controls
                        url="https://storage.googleapis.com/akstotal-v2.appspot.com/certification/log-i-app.mp4"
                        onEnded={() => setStartedVideo(true)} //alert("Ended")}
                    />
                </div>
                {/* <div style={styles.wrapper}>
                <ReactPlayer
                    width={"75%"}
                    url="https://www.youtube.com/watch?v=ZzCdu611SFg"
                    onEnded={() => alert("Ended")}
                /> */}
            </Col>
            <Col xs={24} md={12} style={{ paddingTop: 24 }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        justifyContent: "space-between",
                        paddingBottom: 24,
                    }}>
                    <div>
                        <Title level={4} style={styles.body}>
                            Forbrug af støvbinder
                        </Title>
                        <Paragraph style={styles.body}>
                            Der følger en app med når du bruger AKS Støvbinder. App'en har
                            til formål at dokumentere forbruget samt styre beholdningen af
                            Støvbinder. Når der er lav beholdning sendes der en besked til
                            leverandøren der sender en ny portion. På den måde løber du
                            aldrig tør og skal ikke tænke på at bestille nyt.
                            {/* Ved ikke om
                    der er plads, men det kunne være fint om bruger også kendte til de
                    gængse fordele. Fx. kun et produkt til alle vaskbare gulve, mindre
                    støv i luftveje og på overfalder osv. */}
                        </Paragraph>
                        <Title level={4} style={styles.body}>
                            Dette skal gøres ved hvert brug
                        </Title>
                        <Paragraph style={styles.body}>
                            <ul style={{ listStyleType: "none" }}>
                                <li style={styles.listItem}>
                                    <CheckCircleOutlined style={styles.icon} />
                                    Under rengøringslog indtaster du dine initialer og
                                    taster den brugte mængde.
                                </li>
                                <li style={styles.listItem}>
                                    <CheckCircleOutlined style={styles.icon} />
                                    Der indtastes hver gang du bruger Støvbinder.
                                </li>
                                <li style={styles.listItem}>
                                    <CheckCircleOutlined style={styles.icon} />
                                    Se i videoen til venstre hvordan dette nemt gøres i
                                    app'en
                                </li>
                            </ul>
                        </Paragraph>
                    </div>
                    <div>
                        <Button
                            type="primary"
                            block
                            onClick={next}
                            disabled={!startedVideo}>
                            {startedVideo ? "Næste" : "Se video for at forsætte"}
                        </Button>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

const styles: { [key: string]: CSSProperties } = {
    wrapper: {
        position: "relative",
        padding: 24,
    },
    player: {
        position: "relative",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        transform: "none",
    },
    body: {
        textAlign: "left",
        paddingLeft: 24,
        paddingRight: 24,
    },
    listItem: { margin: 0, marginBottom: 16, display: "flex", alignItems: "center" },
    icon: {
        fontSize: 18,
        marginRight: 8,
        color: Colors.info,
    },
};
