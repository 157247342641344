import { InventoryItem, InventoryType } from "../types";

export const IPAD_INVENTORY_FETCH_REQUESTED = "IPAD_INVENTORY_FETCH_REQUESTED";
export const IPAD_INVENTORY_FETCH_SUCCEEDED = "IPAD_INVENTORY_FETCH_SUCCEEDED";
export const IPAD_INVENTORY_FETCH_FAILED = "IPAD_INVENTORY_FETCH_FAILED";

export interface FetchIpadInventorySuccess {
    type: typeof IPAD_INVENTORY_FETCH_SUCCEEDED;
    payload: IpadItem[];
}
export interface FetchIpadInventory {
    type: typeof IPAD_INVENTORY_FETCH_REQUESTED;
    payload: {};
}
export interface FetchIpadInventoryFailed {
    type: typeof IPAD_INVENTORY_FETCH_FAILED;
    payload: {};
}

export interface IpadItem extends InventoryItem {
    serial: string;
    sim: string;
    type: InventoryType.IPAD;
    orgId?: string;
    orgTitle?: string;
}
