import firebase from "../../firebase";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

export default function useIssueOverview() {
    const [issues, setIssues] = useState<any[]>([]);
    const [isLoading, setisLoading] = useState(true);
    const userId = useSelector((state: AppState) => state.user.details.uid);

    useEffect(() => {
        let unsubscribe: any = null;
        if (userId) {
            unsubscribe = firebase
                .firestore()
                .collection(`issues`)
                .where("users", "array-contains", userId)
                .where("isCompleted", "==", false)
                .orderBy("createTime", "desc")
                .limit(100)
                .onSnapshot(query => {
                    if (!query.empty) {
                        const list: any[] = query.docs.map(doc => ({
                            id: doc.id,
                            ...doc.data(),
                        }));
                        setIssues(list);
                    }
                    setisLoading(false);
                });
        }
        return unsubscribe;
    }, [userId]);

    return { issues, isLoading };
}
