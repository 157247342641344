import { useEffect, useReducer, useState } from "react";
import firebase from "firebase/compat/app";
import { useAppSelector } from "../../hooks";
import { DBGroupShape } from "./types";
import { useDispatch } from "react-redux";
import { fetchOrgs } from "../orgs/actions";

export interface Group {
    id: string;
    title: string;
}

function useGroupDetails(groupId: string | null) {
    const dispatch = useDispatch();
    const uid = useAppSelector(state => state.user.details.uid);
    const orgs = useAppSelector(state =>
        state.orgs.orgs.filter(org => org.groupId === groupId),
    );
    const { group, loadingGroup } = useGroup(groupId);
    // useEffect(() => {
    //     if (groupId) {
    //         dispatch(fetchOrgs(uid, groupId));
    //     }
    //     return () => {};
    // }, [uid, groupId]);

    return {
        group,
        orgs,
        isLoading: loadingGroup,
    };
}

export default useGroupDetails;

function useGroup(groupId: string | null) {
    const [loading, setLoading] = useState(false);
    const [group, setGroup] = useState<DBGroupShape | null>(null);
    useEffect(() => {
        if (groupId) {
            setLoading(true);
            let unsubscribe: any = null;
            unsubscribe = firebase
                .firestore()
                .collection("groups")
                .doc(groupId)
                .onSnapshot(
                    doc => {
                        if (doc.exists) {
                            const group = {
                                id: doc.id,
                                ...doc.data(),
                            } as DBGroupShape;
                            setGroup(group);
                        }
                        setLoading(false);
                    },
                    error => {
                        console.error(error);
                        setLoading(false);
                    },
                );
            return unsubscribe;
        }
    }, [groupId]);

    return { loadingGroup: loading, group };
}
