import React, { useEffect } from "react";
import { compose } from "redux";
import { withRouter, useParams } from "react-router";
import { connect } from "react-redux";
import Certification from "./Certification";
import { AppState } from "../../store";

function CertificationContainer(props: any) {
    const { orgId } = useParams<{ orgId: string }>();
    useEffect(() => {
        // props.fetchCleanLogs({ orgId });
        // props.fetchOrgProducts(orgId);
        return () => {};
    }, []);

    return (
        <Certification
            {...props}
            orgId={orgId}
            loading={props.loading}
            // removeMember={(memberId) => this.props.removeMember({ orgId, members: this.props.members, memberId })}
        />
    );
}

function mapStateToProps(state: AppState) {
    return {
        cleanLogs: state.cleanLogs.logs,
        loading: state.cleanLogs.loading,
        products: state.products.orgProducts,
    };
}

const mapActionsToProps = {};

const withConnect = connect(mapStateToProps, mapActionsToProps);

export default compose(withConnect, withRouter)(CertificationContainer);
