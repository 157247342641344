import { call, put, fork, takeEvery, takeLatest } from "redux-saga/effects";
import firebase, { reduxSagaFirebase } from "../../firebase";
import {
    fetchAuditTemplatesSuccess,
    fetchAuditTemplateSuccess,
    fetchAuditTemplateTasksSuccess,
} from "./actions";
import {
    AUDIT_TEMPLATES_FETCH_SUCCEEDED,
    AUDIT_TEMPLATES_FETCH_FAILED,
    AUDIT_TEMPLATES_FETCH_REQUESTED,
    AUDIT_TEMPLATE_FETCH_SUCCEEDED,
    AUDIT_TEMPLATE_FETCH_FAILED,
    AUDIT_TEMPLATE_FETCH_REQUESTED,
    AUDIT_TEMPLATE_UPDATE_SUCCEEDED,
    AUDIT_TEMPLATE_UPDATE_FAILED,
    AUDIT_TEMPLATE_UPDATE_REQUESTED,
    AUDIT_TEMPLATE_CREATE_SUCCEEDED,
    AUDIT_TEMPLATE_CREATE_FAILED,
    AUDIT_TEMPLATE_CREATE_REQUESTED,
    AUDIT_TEMPLATE_DELETE_SUCCEEDED,
    AUDIT_TEMPLATE_DELETE_FAILED,
    AUDIT_TEMPLATE_DELETE_REQUESTED,
    AUDIT_TEMPLATE_TASKS_FETCH_SUCCEEDED,
    AUDIT_TEMPLATE_TASKS_FETCH_FAILED,
    AUDIT_TEMPLATE_TASKS_FETCH_REQUESTED,
    AUDIT_TEMPLATE_TASK_ADD_SUCCEEDED,
    AUDIT_TEMPLATE_TASK_ADD_FAILED,
    AUDIT_TEMPLATE_TASK_ADD_REQUESTED,
    AUDIT_TEMPLATE_TASK_DELETE_SUCCEEDED,
    AUDIT_TEMPLATE_TASK_DELETE_FAILED,
    AUDIT_TEMPLATE_TASK_DELETE_REQUESTED,
    AUDIT_TEMPLATE_TASK_UPDATE_SUCCEEDED,
    AUDIT_TEMPLATE_TASK_UPDATE_FAILED,
    AUDIT_TEMPLATE_TASK_UPDATE_REQUESTED,
    AUDIT_TEMPLATE_ADD_SUCCEEDED,
    AUDIT_TEMPLATE_ADD_FAILED,
    AUDIT_TEMPLATE_ADD_REQUESTED,
    AUDIT_TEMPLATE_FILE_DELETE_REQUESTED,
    AUDIT_TEMPLATE_FILE_DELETE_FAILED,
} from "./types";
// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchAuditTemplates({ payload }: { payload: { orgId: string } }) {
    const { orgId } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.syncCollection,
            firebase
                .firestore()
                .collection(`orgs/${orgId}/auditTemplates`)
                .orderBy("createTime", "desc"),
            { successActionCreator: fetchAuditTemplatesSuccess },
        );
    } catch (e: any) {
        yield put({ type: AUDIT_TEMPLATES_FETCH_FAILED, message: e.message });
    }
}

export function* fetchAuditTemplatesSaga() {
    // @ts-ignore
    yield takeLatest(AUDIT_TEMPLATES_FETCH_REQUESTED, fetchAuditTemplates);
}

// FETCH ISSUE
function* fetchAuditTemplate({
    payload,
}: {
    payload: { orgId: string; auditTemplateId: string };
}) {
    const { orgId, auditTemplateId } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.syncDocument,
            `orgs/${orgId}/auditTemplates/${auditTemplateId}`,
            { successActionCreator: fetchAuditTemplateSuccess },
        );
    } catch (e: any) {
        yield put({ type: AUDIT_TEMPLATE_FETCH_FAILED, message: e.message });
    }
}

export function* fetchAuditTemplateSaga() {
    // @ts-ignore
    yield takeLatest(AUDIT_TEMPLATE_FETCH_REQUESTED, fetchAuditTemplate);
}
function* updateAuditTemplate({
    payload,
}: {
    payload: { orgId: string; auditTemplateId: string; title: string };
}) {
    const { orgId, auditTemplateId, title } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.updateDocument,
            `orgs/${orgId}/auditTemplates/${auditTemplateId}`,
            {
                title,
            },
        );
    } catch (e: any) {
        yield put({ type: AUDIT_TEMPLATE_UPDATE_FAILED, message: e.message });
    }
}

export function* updateAuditTemplateSaga() {
    // @ts-ignore
    yield takeLatest(AUDIT_TEMPLATE_UPDATE_REQUESTED, updateAuditTemplate);
}
function* removeAuditTemplateFile({
    payload,
}: {
    payload: { orgId: string; auditTemplateId: string };
}) {
    const { orgId, auditTemplateId } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.setDocument,
            `orgs/${orgId}/auditTemplates/${auditTemplateId}`,
            {
                guide: null,
            },
            { merge: true },
        );
    } catch (e: any) {
        yield put({ type: AUDIT_TEMPLATE_FILE_DELETE_FAILED, message: e.message });
    }
}

export function* removeAuditTemplateFileSaga() {
    // @ts-ignore
    yield takeLatest(AUDIT_TEMPLATE_FILE_DELETE_REQUESTED, removeAuditTemplateFile);
}

function* fetchAuditTemplateTasks({
    payload,
}: {
    payload: { orgId: string; auditTemplateId: string };
}) {
    const { orgId, auditTemplateId } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.syncCollection,
            firebase
                .firestore()
                .collection(`orgs/${orgId}/auditTemplates/${auditTemplateId}/tasks`)
                .orderBy("createTime", "desc"),
            { successActionCreator: fetchAuditTemplateTasksSuccess },
        );
    } catch (e: any) {
        yield put({ type: AUDIT_TEMPLATE_TASKS_FETCH_FAILED, message: e.message });
    }
}

export function* fetchAuditTemplateTasksSaga() {
    // @ts-ignore
    yield takeLatest(AUDIT_TEMPLATE_TASKS_FETCH_REQUESTED, fetchAuditTemplateTasks);
}

function* addAuditTemplateTask({
    payload,
}: {
    payload: { orgId: string; auditTemplateId: string; title: string; order: number };
}) {
    const { orgId, auditTemplateId, title, order } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.addDocument,
            `orgs/${orgId}/auditTemplates/${auditTemplateId}/tasks`,
            {
                description: title,
                createTime: new Date(),
                order,
            },
        );
        yield put({ type: AUDIT_TEMPLATE_TASK_ADD_SUCCEEDED });
    } catch (e: any) {
        yield put({ type: AUDIT_TEMPLATE_TASK_ADD_FAILED, message: e.message });
    }
}

export function* addAuditTemplateTaskSaga() {
    // @ts-ignore
    yield takeLatest(AUDIT_TEMPLATE_TASK_ADD_REQUESTED, addAuditTemplateTask);
}
function* deleteAuditTemplateTask({
    payload,
}: {
    payload: { orgId: string; auditTemplateId: string; taskId: string };
}) {
    const { orgId, auditTemplateId, taskId } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.deleteDocument,
            `orgs/${orgId}/auditTemplates/${auditTemplateId}/tasks/${taskId}`,
        );
        yield put({ type: AUDIT_TEMPLATE_TASK_DELETE_SUCCEEDED });
    } catch (e: any) {
        yield put({ type: AUDIT_TEMPLATE_TASK_DELETE_FAILED, message: e.message });
    }
}

export function* deleteAuditTemplateTaskSaga() {
    // @ts-ignore
    yield takeLatest(AUDIT_TEMPLATE_TASK_DELETE_REQUESTED, deleteAuditTemplateTask);
}

function* updateAuditTemplateTask({
    payload,
}: {
    payload: { orgId: string; auditTemplateId: string; taskId: string; title: string };
}) {
    const { orgId, auditTemplateId, taskId, title } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.updateDocument,
            `orgs/${orgId}/auditTemplates/${auditTemplateId}/tasks/${taskId}`,
            {
                description: title,
            },
        );
        yield put({ type: AUDIT_TEMPLATE_TASK_UPDATE_SUCCEEDED });
    } catch (e: any) {
        yield put({ type: AUDIT_TEMPLATE_TASK_UPDATE_FAILED, message: e.message });
    }
}

export function* updateAuditTemplateTaskSaga() {
    // @ts-ignore
    yield takeLatest(AUDIT_TEMPLATE_TASK_UPDATE_REQUESTED, updateAuditTemplateTask);
}

// function* deleteOrg(action) {
//   try {
//     console.log('addOrg', action.payload)
//     const orgs = yield call(reduxSagaFirebase.firestore.updateDocument, `orgs/${action.payload}`, {
//       isDeleted: true,
//     });
//     yield put({ type: ISSUE_DELETE_SUCCEEDED });
//   } catch (e) {
//     yield put({ type: ISSUE_DELETE_FAILED, message: e.message });
//   }
// }

// export function* deleteOrgSaga() {
//   yield takeLatest(ISSUE_DELETE_REQUESTED, deleteOrg);
// }

function* addAuditTemplate({ payload }: { payload: { orgId: string; title: string } }) {
    const { orgId, title } = payload;

    try {
        yield call(
            reduxSagaFirebase.firestore.addDocument,
            `orgs/${orgId}/auditTemplates`,
            {
                title: title,
                createTime: new Date(),
            },
        );
        yield put({ type: AUDIT_TEMPLATE_ADD_SUCCEEDED });
    } catch (e: any) {
        yield put({ type: AUDIT_TEMPLATE_ADD_FAILED, message: e.message });
    }
}

export function* addAuditTemplateSaga() {
    // @ts-ignore
    yield takeLatest(AUDIT_TEMPLATE_ADD_REQUESTED, addAuditTemplate);
}
