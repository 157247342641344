import { Card, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import { CommentOutlined, PaperClipOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { AuditTemplate } from "../../../modules/auditTemplates/types";
import Options from "./Options";
import TemplateTaskCount from "./TemplateTaskCount";
import { StyleGuide } from "styles/StyleGuide";

export default function AuditTemplateItem({
    readOnly = false,
    orgId,
    item,
}: {
    readOnly?: boolean;
    orgId: string;
    item: AuditTemplate;
}) {
    const history = useHistory();
    const { t } = useTranslation();
    return (
        <Card
            style={{
                minHeight: "40px",
                backgroundColor: "#fff",
                padding: 0,
                marginTop: 4,
                cursor: "pointer",
            }}
            bodyStyle={{
                padding: 0,
                display: "flex",
                alignItems: "center",
            }}>
            <div
                style={{
                    padding: 8,
                    display: "flex",
                    flexGrow: 1,
                    textAlign: "left",
                    alignItems: "center",
                }}
                onClick={() =>
                    history.push(
                        `/orgs/${orgId}/audit/templates/${item.id}${
                            readOnly ? "/readonly" : ""
                        }`,
                    )
                }>
                {/* <BarsOutlined /> */}
                <div style={{ marginRight: 6 }} />
                {item.title}
            </div>
            <div style={{ padding: 8, display: "flex", alignItems: "center" }}>
                {item?.note && (
                    <>
                        <Tooltip
                            title={
                                <div>
                                    {t("audit.templates.item.tooltipNote")}
                                    <div>{item.note}</div>
                                </div>
                            }>
                            <CommentOutlined
                                style={{
                                    color: StyleGuide.palette.grey800,
                                    fontSize: 16,
                                }}
                            />
                        </Tooltip>
                        <div style={{ marginRight: 12 }} />
                    </>
                )}
                {item?.guide?.isDeleted === false && (
                    <>
                        <Tooltip title={t("audit.templates.item.tooltipGuide")}>
                            <PaperClipOutlined
                                style={{
                                    color: StyleGuide.palette.grey800,
                                    fontSize: 16,
                                }}
                            />
                        </Tooltip>
                        <div style={{ marginRight: 12 }} />
                    </>
                )}
                <TemplateTaskCount orgId={orgId} templateId={item.id} />

                <Options
                    readOnly={readOnly}
                    orgId={orgId}
                    templateId={item.id}
                    template={item}
                />
            </div>
        </Card>
    );
}
