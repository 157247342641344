import { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import AuditTemplateList from "./AuditTemplateList";
import { useAppSelector } from "../../../hooks";
import {
    fetchGroupAuditTemplates,
    addGroupAuditTemplate,
} from "../../../modules/groupAuditTemplates/actions";
import { AuditTemplate } from "../../../modules/auditTemplates/types";

function AuditTemplateListContainer() {
    const dispatch = useDispatch();
    const { groupId } = useParams<{ groupId: string }>();
    const { auditTemplates, loading } = useAppSelector(state => ({
        auditTemplates: state.groupAuditTemplates.groupAuditTemplates?.sort(
            (a: AuditTemplate, b: AuditTemplate) => {
                const aTitle = a?.title?.toLowerCase();
                const bTitle = b?.title?.toLowerCase();
                if (aTitle > bTitle) return 1;
                if (aTitle < bTitle) return -1;
                return 0;
            },
        ),
        loading: state.auditTemplates.loading,
    }));

    useEffect(() => {
        dispatch(fetchGroupAuditTemplates({ groupId }));

        return () => {};
    }, [groupId, dispatch]);

    const dispatchAddAuditTemplate = (title: string) => {
        dispatch(addGroupAuditTemplate({ groupId, title }));
    };

    return (
        <AuditTemplateList
            loading={loading}
            auditTemplates={auditTemplates}
            groupId={groupId}
            addAuditTemplate={dispatchAddAuditTemplate}
        />
    );
}

export default AuditTemplateListContainer;
