import { useSelector } from "react-redux";
import { AppState } from "../../store";
import firebase from "firebase/compat/app";

export default function useNotifications() {
    const userId = useSelector((state: AppState) => state.user.details.uid);
    const hasDailyDigestEmail = useSelector(
        (state: AppState) => state.user.userDoc.dailyDigestEmail,
    );

    const onUpdateDailyDigestEmail = async (checked: boolean) => {
        if (userId) {
            await firebase.firestore().collection("users").doc(userId).set(
                {
                    dailyDigestEmail: checked,
                },
                { merge: true },
            );
        }
    };
    return {
        hasDailyDigestEmail,
        onUpdateDailyDigestEmail,
    };
}
