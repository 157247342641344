import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import AirQualitySensor from "./AirQualitySensor";
import { AppState } from "../../store";
import { endOfWeek, format, getISOWeek } from "date-fns";
import { useGetAirQuality } from "./useGetAirQualityData";
import {
    fetchSensor,
    fetchSensorLogs,
    fetchSensorMeasures,
    setDate,
} from "../../modules/sensors/actions";
import isEmpty from "lodash.isempty";
import SideNav from "../../Layout/SideNav";
import { Layout, Row, Col, DatePicker } from "antd";
import HeaderBar from "../../Layout/HeaderBar";
import AdminOnly from "../../components/AdminOnly";
import { startOfWeek } from "date-fns/esm";
import locale from "antd/es/date-picker/locale/da_DK";
import { AdminAndMichaelAndHenning, AdminAndMichael } from "../../components/AdminOnly";
import ReportCheckbox from "./AqSensor/Report";

const { Content } = Layout;

function Page({ children }: any) {
    return (
        <>
            <SideNav />
            <Layout>
                <HeaderBar />
                <Content style={{ margin: "50px 24px 0" }}>
                    <Row gutter={16}>
                        <Col md={24} xs={24} style={{ minHeight: "600px" }}>
                            {children}
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </>
    );
}

function AirQualitySensorDetailsContainer(props: any) {
    const { orgId, sensorId } = props.match.params;
    const [date, setdate] = useState<string | null>(null);
    useEffect(() => {
        props.fetchSensor({ orgId, sensorId });
        return () => {};
    }, []);
    console.log({ date });

    const { sensor, isLoading } = useGetAirQuality(orgId, props.deviceId, date);

    if (isLoading) {
        return (
            <Page>
                <div>Loading...</div>
            </Page>
        );
    }
    if (isEmpty(sensor) || !sensor?.datapoints || sensor?.datapoints.length < 1) {
        return (
            <Page>
                <div>no results</div>
            </Page>
        );
    }
    const data = sensor?.datapoints
        .map(log => ({
            name: format(log[0] * 1000, "MMM dd - HH:mm"),
            [sensor.units[1]]: log[1],
            [sensor.units[2]]: log[2],
            [sensor.units[3]]: log[3],
            [sensor.units[4]]: log[4],
            [sensor.units[5]]: log[5],
            [sensor.units[6]]: log[6],
            time: log[0],
        }))
        .sort((a, b) => (a.time < b.time ? -1 : 1));

    const onChangeDate = (date: any) => {
        if (!date) return;
        const dateObj = date.toDate();

        const dateToUse = format(startOfWeek(dateObj), "yyyy-MM-dd");

        setdate(`${dateToUse}T00:00:00.000Z`);
    };
    const getDateDisplay = () => {
        if (!date) return null;
        const week = getISOWeek(new Date(date)) + 1;
        return `Viser data fra ${format(new Date(date), "d. MMM yyyy")} til ${format(
            endOfWeek(new Date(date)),
            "d. MMM yyyy",
        )} - Uge ${week}`;
    };
    return (
        <Page>
            {/* <AdminAndMichaelAndHenning> */}
            <h2>{getDateDisplay()}</h2>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}>
                {/* @ts-ignore */}
                <DatePicker
                    locale={locale}
                    onChange={onChangeDate}
                    picker="week"
                    allowClear={false}
                />
                <div>
                    <AdminAndMichael>
                        <>
                            <ReportCheckbox />
                            <div style={{ marginRight: 14 }} />
                        </>
                    </AdminAndMichael>
                </div>
            </div>
            {/* </AdminAndMichaelAndHenning> */}

            <AirQualitySensor
                title={props.title}
                data={data}
                units={sensor?.units}
                thresholds={props.thresholds}
            />
        </Page>
    );
}

function mapStateToProps(state: AppState) {
    return {
        title: state.sensors.currentSensor.title,
        deviceId: state.sensors.currentSensor.deviceId,
        thresholds: state.sensors.currentSensor.thresholds,
        loading: state.sensors.loading,
    };
}

const mapActionsToProps = {
    fetchSensor,
    fetchSensorLogs,
    fetchSensorMeasures,
    setDate,
};

const withConnect = connect(mapStateToProps, mapActionsToProps);

export default compose(withConnect, withRouter)(AirQualitySensorDetailsContainer);
