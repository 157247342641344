import { Row, Col, Layout } from "antd";
import HeaderBar from "../../Layout/HeaderBar";
import FooterBar from "../../Layout/FooterBar";
import PasswordForm from "./PasswordForm";

const { Content } = Layout;

interface Props {}

function SensorList(_: Props) {
    return (
        <>
            <Layout>
                <HeaderBar />
                <Content style={{ margin: "50px 24px 0" }}>
                    <Row gutter={8}>
                        <Col md={12}>
                            <PasswordForm />
                        </Col>
                    </Row>
                </Content>
                <FooterBar />
            </Layout>
        </>
    );
}

export default SensorList;
