import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInventoryFoobot } from "../../../modules/inventory/foobot/actions";
import { FoobotItem } from "../../../modules/inventory/foobot/types";
import { AppState } from "../../../store";

function sortByDeviceId(a: FoobotItem, b: FoobotItem) {
    return a.deviceId.localeCompare(b.deviceId);
}

function useInventoryFoobot() {
    const dispatch = useDispatch();
    const foobots = useSelector((state: AppState) =>
        state.inventory.foobotIds
            .map(id => state.inventory.inventoryMap[id] as FoobotItem)
            .sort(sortByDeviceId),
    ) as FoobotItem[];

    useEffect(() => {
        dispatch(fetchInventoryFoobot());
    }, [dispatch]);
    return { foobots };
}

export default useInventoryFoobot;
