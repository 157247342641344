import { useEffect } from "react";
import { fetchFeatures } from "./actions";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../store";

export function useFeatures() {
    const dispatch = useDispatch();
    const orgId = useSelector((state: AppState) => state.orgs.currentOrg.id);
    console.log({ orgId });

    useEffect(() => {
        if (orgId) {
            dispatch(fetchFeatures({ orgId }));
        }
        return () => {};
    }, [orgId]);
}
