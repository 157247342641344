import firebase from "../../firebase";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

export default function useActivity() {
    const [activity, setActivity] = useState<any[]>([]);
    const { orgId } = useParams<{ orgId: string }>();

    useEffect(() => {
        let unsubscribe: any = null;
        unsubscribe = firebase
            .firestore()
            .collection(`activity`)
            .orderBy("createTime", "desc")
            .limit(100)
            .onSnapshot(query => {
                if (!query.empty) {
                    const list: any[] = query.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    setActivity(list);
                }
            });
        return unsubscribe;
    }, [orgId]);

    return activity;
}
