import { HourTrackersActionTypes, HourTrackerState } from "./types";
import keyBy from "lodash.keyby";
import {
    HOURTRACKERS_FETCH_REQUESTED,
    HOURTRACKERS_FETCH_SUCCEEDED,
    HOURTRACKERS_FETCH_FAILED,
} from "./types";

const initialState: HourTrackerState = {
    list: [],
    map: {},
    loading: false,
};

export default function hourLogs(
    state = initialState,
    action: HourTrackersActionTypes,
): HourTrackerState {
    switch (action.type) {
        case HOURTRACKERS_FETCH_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case HOURTRACKERS_FETCH_SUCCEEDED:
            return {
                ...state,
                list: action.payload.data,
                map: keyBy(action.payload.data, "id"),
                loading: false,
            };
        case HOURTRACKERS_FETCH_FAILED:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
}
