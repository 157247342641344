import { call, put, fork, takeEvery, takeLatest } from "redux-saga/effects";
import firebase, { reduxSagaFirebase } from "../../firebase";
import {
    fetchGroupAuditTemplatesSuccess,
    fetchGroupAuditTemplateSuccess,
    fetchGroupAuditTemplateTasksSuccess,
} from "./actions";
import {
    GROUP_AUDIT_TEMPLATES_FETCH_SUCCEEDED,
    GROUP_AUDIT_TEMPLATES_FETCH_FAILED,
    GROUP_AUDIT_TEMPLATES_FETCH_REQUESTED,
    GROUP_AUDIT_TEMPLATE_FETCH_SUCCEEDED,
    GROUP_AUDIT_TEMPLATE_FETCH_FAILED,
    GROUP_AUDIT_TEMPLATE_FETCH_REQUESTED,
    GROUP_AUDIT_TEMPLATE_UPDATE_SUCCEEDED,
    GROUP_AUDIT_TEMPLATE_UPDATE_FAILED,
    GROUP_AUDIT_TEMPLATE_UPDATE_REQUESTED,
    GROUP_AUDIT_TEMPLATE_CREATE_SUCCEEDED,
    GROUP_AUDIT_TEMPLATE_CREATE_FAILED,
    GROUP_AUDIT_TEMPLATE_CREATE_REQUESTED,
    GROUP_AUDIT_TEMPLATE_DELETE_SUCCEEDED,
    GROUP_AUDIT_TEMPLATE_DELETE_FAILED,
    GROUP_AUDIT_TEMPLATE_DELETE_REQUESTED,
    GROUP_AUDIT_TEMPLATE_TASKS_FETCH_SUCCEEDED,
    GROUP_AUDIT_TEMPLATE_TASKS_FETCH_FAILED,
    GROUP_AUDIT_TEMPLATE_TASKS_FETCH_REQUESTED,
    GROUP_AUDIT_TEMPLATE_TASK_ADD_SUCCEEDED,
    GROUP_AUDIT_TEMPLATE_TASK_ADD_FAILED,
    GROUP_AUDIT_TEMPLATE_TASK_ADD_REQUESTED,
    GROUP_AUDIT_TEMPLATE_TASK_DELETE_SUCCEEDED,
    GROUP_AUDIT_TEMPLATE_TASK_DELETE_FAILED,
    GROUP_AUDIT_TEMPLATE_TASK_DELETE_REQUESTED,
    GROUP_AUDIT_TEMPLATE_TASK_UPDATE_SUCCEEDED,
    GROUP_AUDIT_TEMPLATE_TASK_UPDATE_FAILED,
    GROUP_AUDIT_TEMPLATE_TASK_UPDATE_REQUESTED,
    GROUP_AUDIT_TEMPLATE_ADD_SUCCEEDED,
    GROUP_AUDIT_TEMPLATE_ADD_FAILED,
    GROUP_AUDIT_TEMPLATE_ADD_REQUESTED,
    GROUP_AUDIT_TEMPLATE_FILE_DELETE_REQUESTED,
    GROUP_AUDIT_TEMPLATE_FILE_DELETE_FAILED,
} from "./types";
// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchGroupAuditTemplates({ payload }: { payload: { groupId: string } }) {
    const { groupId } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.syncCollection,
            firebase
                .firestore()
                .collection(`groups/${groupId}/groupAuditTemplates`)
                .orderBy("createTime", "desc"),
            { successActionCreator: fetchGroupAuditTemplatesSuccess },
        );
    } catch (e: any) {
        yield put({ type: GROUP_AUDIT_TEMPLATES_FETCH_FAILED, message: e.message });
    }
}

export function* fetchGroupAuditTemplatesSaga() {
    // @ts-ignore
    yield takeLatest(GROUP_AUDIT_TEMPLATES_FETCH_REQUESTED, fetchGroupAuditTemplates);
}

// FETCH ISSUE
function* fetchGroupAuditTemplate({
    payload,
}: {
    payload: { groupId: string; auditTemplateId: string };
}) {
    const { groupId, auditTemplateId } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.syncDocument,
            `groups/${groupId}/groupAuditTemplates/${auditTemplateId}`,
            { successActionCreator: fetchGroupAuditTemplateSuccess },
        );
    } catch (e: any) {
        yield put({ type: GROUP_AUDIT_TEMPLATE_FETCH_FAILED, message: e.message });
    }
}

export function* fetchGroupAuditTemplateSaga() {
    // @ts-ignore
    yield takeLatest(GROUP_AUDIT_TEMPLATE_FETCH_REQUESTED, fetchGroupAuditTemplate);
}
function* updateGroupAuditTemplate({
    payload,
}: {
    payload: { groupId: string; auditTemplateId: string; title: string };
}) {
    const { groupId, auditTemplateId, title } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.updateDocument,
            `groups/${groupId}/groupAuditTemplates/${auditTemplateId}`,
            {
                title,
            },
        );
    } catch (e: any) {
        yield put({ type: GROUP_AUDIT_TEMPLATE_UPDATE_FAILED, message: e.message });
    }
}

export function* updateGroupAuditTemplateSaga() {
    // @ts-ignore
    yield takeLatest(GROUP_AUDIT_TEMPLATE_UPDATE_REQUESTED, updateGroupAuditTemplate);
}
function* removeGroupAuditTemplateFile({
    payload,
}: {
    payload: { groupId: string; auditTemplateId: string };
}) {
    const { groupId, auditTemplateId } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.setDocument,
            `groups/${groupId}/groupAuditTemplates/${auditTemplateId}`,
            {
                guide: null,
            },
            { merge: true },
        );
    } catch (e: any) {
        yield put({ type: GROUP_AUDIT_TEMPLATE_FILE_DELETE_FAILED, message: e.message });
    }
}

export function* removeGroupAuditTemplateFileSaga() {
    yield takeLatest(
        // @ts-ignore
        GROUP_AUDIT_TEMPLATE_FILE_DELETE_REQUESTED,
        removeGroupAuditTemplateFile,
    );
}

function* fetchGroupAuditTemplateTasks({
    payload,
}: {
    payload: { groupId: string; auditTemplateId: string };
}) {
    const { groupId, auditTemplateId } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.syncCollection,
            firebase
                .firestore()
                .collection(
                    `groups/${groupId}/groupAuditTemplates/${auditTemplateId}/tasks`,
                )
                .orderBy("createTime", "desc"),
            { successActionCreator: fetchGroupAuditTemplateTasksSuccess },
        );
    } catch (e: any) {
        yield put({ type: GROUP_AUDIT_TEMPLATE_TASKS_FETCH_FAILED, message: e.message });
    }
}

export function* fetchGroupAuditTemplateTasksSaga() {
    yield takeLatest(
        // @ts-ignore
        GROUP_AUDIT_TEMPLATE_TASKS_FETCH_REQUESTED,
        fetchGroupAuditTemplateTasks,
    );
}

function* addGroupAuditTemplateTask({
    payload,
}: {
    payload: { groupId: string; auditTemplateId: string; title: string; order: number };
}) {
    const { groupId, auditTemplateId, title, order } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.addDocument,
            `groups/${groupId}/groupAuditTemplates/${auditTemplateId}/tasks`,
            {
                description: title,
                createTime: new Date(),
                order,
            },
        );
        yield put({ type: GROUP_AUDIT_TEMPLATE_TASK_ADD_SUCCEEDED });
    } catch (e: any) {
        yield put({ type: GROUP_AUDIT_TEMPLATE_TASK_ADD_FAILED, message: e.message });
    }
}

export function* addGroupAuditTemplateTaskSaga() {
    // @ts-ignore
    yield takeLatest(GROUP_AUDIT_TEMPLATE_TASK_ADD_REQUESTED, addGroupAuditTemplateTask);
}
function* deleteGroupAuditTemplateTask({
    payload,
}: {
    payload: { groupId: string; auditTemplateId: string; taskId: string };
}) {
    const { groupId, auditTemplateId, taskId } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.deleteDocument,
            `groups/${groupId}/groupAuditTemplates/${auditTemplateId}/tasks/${taskId}`,
        );
        yield put({ type: GROUP_AUDIT_TEMPLATE_TASK_DELETE_SUCCEEDED });
    } catch (e: any) {
        yield put({ type: GROUP_AUDIT_TEMPLATE_TASK_DELETE_FAILED, message: e.message });
    }
}

export function* deleteGroupAuditTemplateTaskSaga() {
    yield takeLatest(
        // @ts-ignore
        GROUP_AUDIT_TEMPLATE_TASK_DELETE_REQUESTED,
        deleteGroupAuditTemplateTask,
    );
}

function* updateGroupAuditTemplateTask({
    payload,
}: {
    payload: { groupId: string; auditTemplateId: string; taskId: string; title: string };
}) {
    const { groupId, auditTemplateId, taskId, title } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.updateDocument,
            `groups/${groupId}/groupAuditTemplates/${auditTemplateId}/tasks/${taskId}`,
            {
                description: title,
            },
        );
        yield put({ type: GROUP_AUDIT_TEMPLATE_TASK_UPDATE_SUCCEEDED });
    } catch (e: any) {
        yield put({ type: GROUP_AUDIT_TEMPLATE_TASK_UPDATE_FAILED, message: e.message });
    }
}

export function* updateGroupAuditTemplateTaskSaga() {
    yield takeLatest(
        // @ts-ignore
        GROUP_AUDIT_TEMPLATE_TASK_UPDATE_REQUESTED,
        updateGroupAuditTemplateTask,
    );
}

// function* deleteOrg(action) {
//   try {
//     console.log('addOrg', action.payload)
//     const orgs = yield call(reduxSagaFirebase.firestore.updateDocument, `groups/${action.payloadgroupA`, {
//       isDeleted: true,
//     });
//     yield put({ type: ISSUE_DELETE_SUCCEEDED });
//   } catch (e) {
//     yield put({ type: ISSUE_DELETE_FAILED, message: e.message });
//   }
// }

// export function* deleteOrgSaga() {
//   yield takeLatest(ISSUE_DELETE_REQUESTED, deleteOrg);
// }

function* addGroupAuditTemplate({
    payload,
}: {
    payload: { groupId: string; title: string };
}) {
    const { groupId, title } = payload;

    try {
        yield call(
            reduxSagaFirebase.firestore.addDocument,
            `groups/${groupId}/groupAuditTemplates`,
            {
                title: title,
                createTime: new Date(),
            },
        );
        yield put({ type: GROUP_AUDIT_TEMPLATE_ADD_SUCCEEDED });
    } catch (e: any) {
        yield put({ type: GROUP_AUDIT_TEMPLATE_ADD_FAILED, message: e.message });
    }
}

export function* addGroupAuditTemplateSaga() {
    // @ts-ignore
    yield takeLatest(GROUP_AUDIT_TEMPLATE_ADD_REQUESTED, addGroupAuditTemplate);
}
