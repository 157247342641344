import {
    ClockCircleOutlined,
    PauseCircleFilled,
    PauseCircleOutlined,
} from "@ant-design/icons";
import { Colors } from "Layout/Colors";
import { Button, Modal, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import confirmModal from "general/confirmModal";
import { useAppSelector } from "hooks";
import { AuditScheduleTask } from "modules/auditSchedule/types";
import React, { useEffect, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";
interface Props {
    taskId: string;
    updateAuditScheduleTask: (
        data: Pick<
            AuditScheduleTask,
            "onHoldDescription" | "isOnHold" | "onHoldTime" | "onHoldBy"
        >,
        id: string,
    ) => void;
}
export default function AuditTaskOnHoldAction({
    taskId,
    updateAuditScheduleTask,
}: Props) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const task = useAppSelector(
        state => state.auditSchedules.currentAuditSchedule.tasks[taskId],
    );
    const user = useAppSelector(
        state => state.orgs.currentOrg.members[state.user.details.uid],
    );
    // const [isOnHold, setisOnHold] = useState(false);
    const [onHoldDescription, setOnHoldDescription] = useState(task.onHoldDescription);
    // const [form] = Form.useForm();
    const toggleOnHold = () => {
        confirmModal({
            title: "Er du sikker på du vil fjerne opgaven fra hold?",
            onOk: () => {
                updateAuditScheduleTask({ isOnHold: false }, taskId);
            },
        });
    };
    useEffect(() => {
        if (open) {
            setOnHoldDescription(task.onHoldDescription);
        }
    }, [open]);

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = () => {
        /**
         * TODO: is this the way I want to do this?
         */
        updateAuditScheduleTask(
            {
                onHoldDescription: onHoldDescription || "",
                isOnHold: true,
                onHoldTime: new Date(),
                onHoldBy: {
                    uid: user.uid,
                    name: user.name || user.email,
                },
            },
            taskId,
        );
        setOpen(false);
    };

    const handleCancel = () => {
        console.log("Clicked cancel button");
        setOpen(false);
    };

    return (
        <>
            {task.isOnHold ? (
                <Tooltip
                    title={
                        <div>
                            <div>{t("Sat på hold fordi")}:</div>
                            {task.onHoldDescription}
                        </div>
                    }>
                    <Button
                        onClick={toggleOnHold}
                        style={{ color: Colors.warning, minWidth: 132 }}
                        icon={<ClockCircleOutlined style={{ color: Colors.warning }} />}>
                        {t("Sat på hold")}
                    </Button>
                </Tooltip>
            ) : (
                <Tooltip
                    title={
                        task.isCompleted
                            ? t(
                                  "Opgaven er allerede godkendt og kan derfor ikke sættes på hold",
                              )
                            : ""
                    }>
                    <Button
                        style={{ minWidth: 132 }}
                        disabled={task.isCompleted}
                        // type="primary"
                        onClick={showModal}
                        // style={{ color: Colors.warning }}
                        icon={
                            <PauseCircleOutlined /*style={{ color: Colors.warning }}*/ />
                        }>
                        {t("Sæt på hold")}
                    </Button>
                </Tooltip>
            )}
            <Modal
                destroyOnClose
                title="Opgaven sættes på hold fordi"
                open={open}
                okText="Sæt på hold"
                cancelText="Fortryd"
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}>
                <div>
                    <TextArea
                        value={onHoldDescription}
                        placeholder="Beskriv hvorfor opgaven sættes på hold"
                        onChange={e => setOnHoldDescription(e.target.value)}
                    />
                </div>
            </Modal>
        </>
    );
}
