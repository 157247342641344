export const ORDERS_FETCH_REQUESTED = "ORDERS_FETCH_REQUESTED";
export const ORDERS_FETCH_SUCCEEDED = "ORDERS_FETCH_SUCCEEDED";
export const ORDERS_FETCH_FAILED = "ORDERS_FETCH_FAILED";

export const ORG_ORDERS_FETCH_REQUESTED = "ORG_ORDERS_FETCH_REQUESTED";
export const ORG_ORDERS_FETCH_SUCCEEDED = "ORG_ORDERS_FETCH_SUCCEEDED";
export const ORG_ORDERS_FETCH_FAILED = "ORG_ORDERS_FETCH_FAILED";

export const ORDERS_CREATE_REQUESTED = "ORDERS_CREATE_REQUESTED";
export const ORDERS_CREATE_SUCCEEDED = "ORDERS_CREATE_SUCCEEDED";
export const ORDERS_CREATE_FAILED = "ORDERS_CREATE_FAILED";

export const ORDERS_UPDATE_REQUESTED = "ORDERS_UPDATE_REQUESTED";
export const ORDERS_UPDATE_SUCCEEDED = "ORDERS_UPDATE_SUCCEEDED";
export const ORDERS_UPDATE_FAILED = "ORDERS_UPDATE_FAILED";

export const ORDERS_DELETE_REQUESTED = "ORDERS_DELETE_REQUESTED";
export const ORDERS_DELETE_SUCCEEDED = "ORDERS_DELETE_SUCCEEDED";
export const ORDERS_DELETE_FAILED = "ORDERS_DELETE_FAILED";
