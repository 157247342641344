import { call, put, fork, takeEvery, takeLatest } from "redux-saga/effects";
import firebase, { reduxSagaFirebase } from "../../firebase";
import { fetchFilesSuccess, deleteFileSuccess } from "./actions";
import {
    FILES_FETCH_SUCCEEDED,
    FILES_FETCH_FAILED,
    FILES_FETCH_REQUESTED,
    FILE_DELETE_SUCCEEDED,
    FILE_DELETE_FAILED,
    FILE_DELETE_REQUESTED,
    AUDIT_FILES_FETCH_SUCCEEDED,
    AUDIT_FILES_FETCH_FAILED,
    AUDIT_FILES_FETCH_REQUESTED,
    AUDIT_FILE_DELETE_SUCCEEDED,
    AUDIT_FILE_DELETE_FAILED,
    AUDIT_FILE_DELETE_REQUESTED,
} from "./types";
import Message, { Type } from "components/Notification/Message";

function* fetchFiles({ payload }: { payload: { orgId: string; issueId: string } }) {
    const { orgId, issueId } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.syncCollection,
            `orgs/${orgId}/issues/${issueId}/files`,
            { successActionCreator: fetchFilesSuccess },
        );
    } catch (e: any) {
        yield put({ type: FILES_FETCH_FAILED, message: e.message });
    }
}

export function* fetchFilesSaga() {
    // @ts-ignore
    yield takeLatest(FILES_FETCH_REQUESTED, fetchFiles);
}

function* deleteFile({
    payload,
}: {
    payload: { orgId: string; issueId: string; fileId: string };
}) {
    const { orgId, issueId, fileId } = payload;
    try {
        yield call(
            // @ts-ignore
            reduxSagaFirebase.firestore.deleteDocument,
            `orgs/${orgId}/issues/${issueId}/files/${fileId}`,
            { successActionCreator: deleteFileSuccess },
        );

        // yield put(deleteFileSuccess(files))
    } catch (e: any) {
        yield put({ type: FILE_DELETE_FAILED, message: e.message });
    }
}

export function* deleteFileSaga() {
    // @ts-ignore
    yield takeLatest(FILE_DELETE_REQUESTED, deleteFile);
}

function* fetchAuditFiles({ payload }: { payload: { orgId: string; auditId: string } }) {
    const { orgId, auditId } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.syncCollection,
            `orgs/${orgId}/auditSchedules/${auditId}/files`,
            { successActionCreator: fetchFilesSuccess },
        );
    } catch (e: any) {
        yield put({ type: AUDIT_FILES_FETCH_FAILED, message: e.message });
    }
}

export function* fetchAuditFilesSaga() {
    // @ts-ignore
    yield takeLatest(AUDIT_FILES_FETCH_REQUESTED, fetchAuditFiles);
}

function* deleteAuditFile({
    payload,
}: {
    payload: { orgId: string; auditId: string; fileId: string };
}) {
    const { orgId, auditId, fileId } = payload;
    const messageKey = "removeAuditFile";
    try {
        Message({
            type: Type.LOADING,
            key: messageKey,
            message: `Loading...`,
            // description: `${payload.title} blev oprettet og kan nu søges frem i listen her`,
        });
        yield call(
            // @ts-ignore
            reduxSagaFirebase.firestore.deleteDocument,
            `orgs/${orgId}/auditSchedules/${auditId}/files/${fileId}`,
            { successActionCreator: deleteFileSuccess },
        );
        Message({
            key: messageKey,
            message: `Filen blev slettet`,
        });
        // yield put(deleteFileSuccess(files))
    } catch (e: any) {
        yield put({ type: AUDIT_FILE_DELETE_FAILED, message: e.message });
        Message({
            key: messageKey,
            type: Type.ERROR,
            message: `Der skete en fejl`,
            description: `Filen blev ikke slettet, prøv venligst igen`,
        });
    }
}

export function* deleteAuditFileSaga() {
    // @ts-ignore
    yield takeLatest(AUDIT_FILE_DELETE_REQUESTED, deleteAuditFile);
}
