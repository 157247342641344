import { DIRECTORY_ADD_REQUESTED } from "./types";
import {
    DIRECTORIES_FETCH_REQUESTED,
    DIRECTORIES_FETCH_SUCCEEDED,
    DBDirectoryShape,
    DIRECTORY_UPDATE_REQUESTED,
} from "./types";

export interface DocumentData {
    [field: string]: any;
}

export function fetchDirectories({ orgId }: { orgId: string }) {
    return {
        type: DIRECTORIES_FETCH_REQUESTED,
        payload: {
            orgId,
        },
    };
}

export function fetchDirectoriesSuccess(
    // @ts-ignore
    directoriesList: firebase.firestore.QuerySnapshot,
): { type: string; payload: DBDirectoryShape[] } {
    const directories = directoriesList.docs.map(
        // @ts-ignore
        (directories: firebase.firestore.DocumentData) => ({
            ...directories.data(),
            id: directories.id,
        }),
    );
    return {
        type: DIRECTORIES_FETCH_SUCCEEDED,
        payload: directories,
    };
}
export function updateDirectory({
    orgId,
    directoryId,
    data,
}: {
    orgId: string;
    directoryId: string;
    data: { title: string };
}) {
    return {
        type: DIRECTORY_UPDATE_REQUESTED,
        payload: {
            orgId,
            directoryId,
            data,
        },
    };
}

export function addDirectory({
    orgId,
    title,
    directoryId,
}: {
    orgId: string;
    title: string;
    directoryId?: string;
}) {
    return {
        type: DIRECTORY_ADD_REQUESTED,
        payload: {
            orgId,
            title,
            directoryId,
        },
    };
}
