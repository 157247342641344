import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import SensorDetails from "./GpsSensorDetails";
import { useAppSelector } from "../../hooks";
import {
    fetchSensor,
    fetchSensorLogs,
    fetchSensorMeasures,
    setDate,
} from "../../modules/sensors/actions";
import { Moment } from "moment";
import useSensorLogs from "./useSensorLogs";
import { Dayjs } from "dayjs";
import { format } from "date-fns";
import { SensorType } from "general/enums";

function isFloat(n: number) {
    return Number(n) === n && n % 1 !== 0;
}

function resolveLatLng(number: string) {
    if (number.includes && number.includes(" ")) {
        return number.split ? number.split(" ")[0] : number;
    }
    const mod = number.split && number.split(".");
    if (mod) {
        // @ts-ignore
        return mod && mod[1] && [mod[0], mod[1].match(/.{1,6}/g)[0]].join(".");
    }
    return number;
}

function SensorDetailsContainer() {
    const { orgId, sensorId } = useParams<{ orgId: string; sensorId: string }>();
    const dispatch = useDispatch();

    const {
        logs,
        measures,
        loading,
        loadingCurrent,
        lat = 55.661133,
        lng = 12.36529,
        selectedDate,
        topLeftBoundary,
        topRightBoundary,
        bottomRightBoundary,
        bottomLeftBoundary,
    } = useAppSelector(state => ({
        logs: state.sensors.currentSensor.data,
        measures: state.sensors.currentSensor.measures,
        loading: state.sensors.loading,
        loadingCurrent: state.sensors.currentSensor.loading,
        lat: state.sensors.currentSensor.location.latitude,
        lng: state.sensors.currentSensor.location.longitude,
        selectedDate: state.sensors.selectedDate,
        topLeftBoundary: [
            state.sensors.currentSensor.sensorBoundaries.topLeftBoundary.latitude,
            state.sensors.currentSensor.sensorBoundaries.topLeftBoundary.longitude,
        ],
        topRightBoundary: [
            state.sensors.currentSensor.sensorBoundaries.topRightBoundary.latitude,
            state.sensors.currentSensor.sensorBoundaries.topRightBoundary.longitude,
        ],
        bottomRightBoundary: [
            state.sensors.currentSensor.sensorBoundaries.bottomRightBoundary.latitude,
            state.sensors.currentSensor.sensorBoundaries.bottomRightBoundary.longitude,
        ],
        bottomLeftBoundary: [
            state.sensors.currentSensor.sensorBoundaries.bottomLeftBoundary.latitude,
            state.sensors.currentSensor.sensorBoundaries.bottomLeftBoundary.longitude,
        ],
    }));
    useSensorLogs({ orgId, sensorId, selectedDate });

    useEffect(() => {
        dispatch(fetchSensor({ orgId, sensorId }));

        dispatch(fetchSensorMeasures({ orgId, sensorId, selectedDate }));
        return () => {};
    }, []);

    const onDateChange = (_: Dayjs | null, dateString: string) => {
        dispatch(setDate({ date: dateString }));
    };
    // TODO: MOVE THIS TO DATABASE
    // const topLeftBoundary = this.props.topLeftBoundary;
    // const topRightBoundary = this.props.topRightBoundary;
    // const bottomRightBoundary = this.props.bottomRightBoundary;
    // const bottomLeftBoundary = this.props.bottomLeftBoundary;
    // const { lat, lng, measures } = this.props;

    const measuresFiltered = measures
        ? measures.map(({ createTime }: { createTime: { toDate: () => Date } }) => {
              const date = createTime.toDate();
              date.setHours(date.getHours() + 2);
              return `${date.getFullYear()}-${
                  date.getMonth() + 1 < 10
                      ? "0" + (date.getMonth() + 1)
                      : date.getMonth() + 1
              }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;
          })
        : [];
    const centerMap = { lat, lng };
    /**
     * TODO: can be removed when migrated to big query
     */
    const filteredLogs = logs
        .map(({ logTime, lat, lng }: any) => {
            console.log({ logTime });

            return {
                logTime: logTime.toDate(),
                lat: resolveLatLng(lat),
                lng: resolveLatLng(lng),
            };
        })
        .filter(({ lat, lng }: { lat: any; lng: any }) => {
            if (
                isFinite(lat) &&
                isFinite(lng) &&
                isFloat(parseFloat(lat)) &&
                isFloat(parseFloat(lng))
            ) {
                return true;
            }
            return false;
        })
        .filter(({ lat, lng }: { lat: number; lng: number }) => {
            if (
                !topLeftBoundary[0] &&
                !topRightBoundary[0] &&
                !bottomRightBoundary[0] &&
                !bottomLeftBoundary[0]
            )
                return true;
            if (
                lat <= topLeftBoundary[0] &&
                lng >= topLeftBoundary[1] &&
                lat <= topRightBoundary[0] &&
                lng <= topRightBoundary[1] &&
                lat >= bottomRightBoundary[0] &&
                lng <= bottomRightBoundary[1] &&
                lat >= bottomLeftBoundary[0] &&
                lng >= bottomLeftBoundary[1]
            ) {
                return true;
            }
            return false;
        });
    return (
        <SensorDetails
            loading={loading || loadingCurrent}
            data={filteredLogs}
            selectedDate={selectedDate}
            onDateChange={onDateChange}
            centerMap={centerMap}
            measures={measuresFiltered}
            boundary={{
                north: topLeftBoundary[0],
                south: bottomLeftBoundary[0],
                east: bottomRightBoundary[1],
                west: bottomLeftBoundary[1],
            }}
        />
    );
}

export default SensorDetailsContainer;
