import { Col, Layout, Row } from "antd";
import Table from "./components/Table";
import SideNav from "../../Layout/SideNav";
import HeaderBar from "../../Layout/HeaderBar";
import FooterBar from "../../Layout/FooterBar";
import AddMemberForm from "./AddMemberForm";
import BlurOverlayContainer from "../../components/Overlay/BlurOverlayContainer";
import { FeatureIds } from "../../modules/featureControl/featuresConfig";
import { IOrg } from "../../modules/orgs/interfaces";
import Loader from "../../components/Loader";
import MemberCard from "./MemberCard";
import { useTranslation } from "react-i18next";

const { Content } = Layout;

interface Props {
    // removeMember: (key: string) => void;
    updateMembers: ({}: any) => void;
    members: IOrg["members"]; // IMember;
    loading: boolean;
}

function MemberList(props: Props) {
    const { t } = useTranslation();
    const data = Object.keys(props.members)
        .sort((a, b) => {
            const aMember = props.members[a]?.name?.toLowerCase() || "";
            const bMember = props.members[b]?.name?.toLowerCase() || "";
            if (aMember < bMember) {
                return -1;
            }
            if (aMember > bMember) {
                return 1;
            }
            return 0;
        })
        .map(memberKey => ({
            ...props.members[memberKey],
            key: props.members[memberKey].uid,
        }));
    // @ts-ignore
    const addForm = <AddMemberForm />;
    return (
        <>
            <SideNav />
            <Layout>
                <HeaderBar />
                <BlurOverlayContainer featureKey={FeatureIds.MEMBERS}>
                    <Content style={{ margin: "24px 24px 0" }}>
                        {props.loading ? (
                            <Loader />
                        ) : (
                            <>
                                <Row>
                                    <Col
                                        style={{
                                            display: "flex",
                                            flexDirection: "row-reverse",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                        span={24}>
                                        {addForm}
                                        <h2>{t("sidenav.access")}</h2>
                                    </Col>
                                </Row>
                                <Row gutter={8}>
                                    {data.map(member => (
                                        <Col span={8}>
                                            <MemberCard member={member} />
                                        </Col>
                                    ))}
                                </Row>
                                {/* <Table
                                    //@ts-ignore
                                    scroll={{ x: true }}
                                    data={data}
                                    removeMember={props.removeMember}
                                    updateMembers={props.updateMembers}
                                /> */}
                            </>
                        )}
                    </Content>
                </BlurOverlayContainer>
                <FooterBar />
            </Layout>
        </>
    );
}

export default MemberList;
