import { Button, Card, Col, Dropdown, Row, Tooltip } from "antd";
// import TextArea from "antd/es/input/TextArea";
// import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useIssueNotes from "./useIssueNotes";
import Meta from "antd/es/card/Meta";
import { DeleteOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import { MenuProps } from "antd/lib";
import { useState } from "react";
import TextArea from "antd/es/input/TextArea";
import confirmModal from "general/confirmModal";

export default function IssueNotes(props: {
    comment: string;
    updateIssue: (data: any) => void;
}) {
    console.log({ props });

    const { notes, addNote, updateNote, deleteNote, isLoading } = useIssueNotes();
    const { t } = useTranslation();
    const [comment, setComment] = useState<string>("");
    const [isAdding, setIsAdding] = useState(false);
    // useEffect(() => setComment(props.comment), [props.comment]);

    if (isLoading) return <div>Loading...</div>;
    return (
        <Row gutter={16}>
            {notes.map(note => (
                <Col span={24} key={note.id}>
                    <NoteCard
                        id={note.id}
                        note={note}
                        updateNote={updateNote}
                        deleteNote={deleteNote}
                    />
                    <div style={{ marginBottom: 8 }} />
                </Col>
            ))}
            {!isAdding && (
                <Col span={24}>
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <Button
                            type="default"
                            block
                            size="large"
                            onClick={() => setIsAdding(true)}>
                            Tilføj note +
                        </Button>
                    </div>
                </Col>
            )}
            {isAdding && (
                <>
                    <Col span={24}>
                        <div style={{ marginBottom: 8 }} />
                        <TextArea
                            autoFocus
                            value={comment}
                            autoSize={{ minRows: 2 }}
                            onChange={e => setComment(e.target.value)}
                            placeholder={t("issues.edit.comment")}
                        />
                        <div style={{ marginBottom: 8 }} />
                        {/* <div style={{ marginTop: 8 }} /> */}
                    </Col>
                    <Col span={8}>
                        <Button
                            size="large"
                            type="default"
                            block
                            onClick={() => {
                                setComment("");
                                setIsAdding(false);
                            }}>
                            {t("general.cancel")}
                        </Button>
                    </Col>
                    <Col span={16}>
                        <Button
                            size="large"
                            type="primary"
                            block
                            onClick={async () => {
                                await addNote(comment);
                                setComment("");
                                setIsAdding(false);
                            }}>
                            {t("issues.edit.savecomment")}
                        </Button>
                    </Col>
                </>
            )}
        </Row>
    );
}

function NoteCard({
    id,
    note,
    updateNote,
    deleteNote,
}: {
    id: string;
    note: any;
    updateNote: (noteId: string, text: string) => void;
    deleteNote: (noteId: string) => void;
}) {
    const { t } = useTranslation();
    const [tmpComment, setTmpComment] = useState<string | null>(null);
    const handleDeleteNote = () => {
        console.log("DELETE", note.id);
        deleteNote(note.id);
    };
    const handleUpdateNote = () => {
        console.log("EDIT", note.id);
        setTmpComment(note.text);
    };
    const items: MenuProps["items"] = [
        {
            key: "1",
            label: (
                <Button size="small" type="link">
                    <EditOutlined />
                    {t("issues.edit.note")}
                </Button>
            ),
            onClick: handleUpdateNote,
        },
        {
            key: "2",
            label: (
                <Button size="small" type="link" danger>
                    <DeleteOutlined />
                    {t("issues.edit.delete")}
                </Button>
            ),
            onClick: () => {
                confirmModal({
                    title: t("general.confirmDelete"),
                    onOk: handleDeleteNote,
                });
            },
        },
    ];
    return (
        <Card size="small">
            {tmpComment !== null ? (
                <Row gutter={16}>
                    <Col span={24}>
                        <TextArea
                            autoFocus
                            value={tmpComment}
                            autoSize={{ minRows: 2 }}
                            onChange={e => setTmpComment(e.target.value)}
                            placeholder={t("issues.edit.comment")}
                        />
                        <div style={{ marginBottom: 8 }} />
                    </Col>

                    <Col span={8}>
                        <Button
                            size="large"
                            type="default"
                            block
                            onClick={() => {
                                setTmpComment(null);
                            }}>
                            {t("general.cancel")}
                        </Button>
                    </Col>
                    <Col span={16}>
                        <Button
                            size="large"
                            type="primary"
                            block
                            onClick={() => {
                                updateNote(id, tmpComment);
                                setTmpComment(null);
                            }}>
                            {t("issues.edit.savecomment")}
                        </Button>
                    </Col>
                </Row>
            ) : (
                <>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}>
                        <div>
                            <span style={{ fontSize: 18, whiteSpace: "pre-wrap" }}>
                                {note.text}
                            </span>
                        </div>
                        <div>
                            <Dropdown menu={{ items }} placement="bottomLeft" arrow>
                                <Button
                                    type="text"
                                    shape="circle"
                                    icon={<MoreOutlined />}
                                />
                            </Dropdown>
                        </div>
                    </div>
                    <Meta
                        description={
                            <span style={{ fontSize: 12 }}>
                                {t("date.exactFormat", {
                                    relativeTime: note.createTime,
                                    dayOfWeek: note.createTime,
                                    dayOfMonth: note.createTime,
                                    month: note.createTime,
                                    time: note.createTime,
                                })}{" "}
                                {!note.lastUpdateBy && note.createBy?.displayName && (
                                    <span>
                                        af <strong>{note.createBy.displayName}</strong>
                                    </span>
                                )}
                                {note?.updateTime && (
                                    <Tooltip
                                        title={`Seneste opdateret: ${t(
                                            "date.exactFormat",
                                            {
                                                relativeTime: note.updateTime,
                                                dayOfWeek: note.updateTime,
                                                dayOfMonth: note.updateTime,
                                                month: note.updateTime,
                                                time: note.updateTime,
                                            },
                                        )}`}>
                                        (opdateret
                                        {note.lastUpdateBy.displayName && (
                                            <>
                                                <span>
                                                    {" "}
                                                    af{" "}
                                                    <strong>
                                                        {note.lastUpdateBy.displayName}
                                                    </strong>
                                                </span>
                                            </>
                                        )}
                                        )
                                    </Tooltip>
                                )}
                            </span>
                        }
                    />
                </>
            )}
        </Card>
    );
}
