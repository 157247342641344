import { RequestState } from "interfaces/request";
import { CustomField, CustomFieldType } from "modules/audits/types";
export const GROUP_AUDIT_TEMPLATES_FETCH_SUCCEEDED =
    "GROUP_AUDIT_TEMPLATES_FETCH_SUCCEEDED";
export const GROUP_AUDIT_TEMPLATES_FETCH_FAILED = "GROUP_AUDIT_TEMPLATES_FETCH_FAILED";
export const GROUP_AUDIT_TEMPLATES_FETCH_REQUESTED =
    "GROUP_AUDIT_TEMPLATES_FETCH_REQUESTED";

export const GROUP_AUDIT_TEMPLATE_FETCH_SUCCEEDED =
    "GROUP_AUDIT_TEMPLATE_FETCH_SUCCEEDED";
export const GROUP_AUDIT_TEMPLATE_FETCH_FAILED = "GROUP_AUDIT_TEMPLATE_FETCH_FAILED";
export const GROUP_AUDIT_TEMPLATE_FETCH_REQUESTED =
    "GROUP_AUDIT_TEMPLATE_FETCH_REQUESTED";

export const GROUP_AUDIT_TEMPLATE_UPDATE_SUCCEEDED =
    "GROUP_AUDIT_TEMPLATE_UPDATE_SUCCEEDED";
export const GROUP_AUDIT_TEMPLATE_UPDATE_FAILED = "GROUP_AUDIT_TEMPLATE_UPDATE_FAILED";
export const GROUP_AUDIT_TEMPLATE_UPDATE_REQUESTED =
    "GROUP_AUDIT_TEMPLATE_UPDATE_REQUESTED";

export const GROUP_AUDIT_TEMPLATE_CREATE_SUCCEEDED =
    "GROUP_AUDIT_TEMPLATE_CREATE_SUCCEEDED";
export const GROUP_AUDIT_TEMPLATE_CREATE_FAILED = "GROUP_AUDIT_TEMPLATE_CREATE_FAILED";
export const GROUP_AUDIT_TEMPLATE_CREATE_REQUESTED =
    "GROUP_AUDIT_TEMPLATE_CREATE_REQUESTED";

export const GROUP_AUDIT_TEMPLATE_DELETE_SUCCEEDED =
    "GROUP_AUDIT_TEMPLATE_DELETE_SUCCEEDED";
export const GROUP_AUDIT_TEMPLATE_DELETE_FAILED = "GROUP_AUDIT_TEMPLATE_DELETE_FAILED";
export const GROUP_AUDIT_TEMPLATE_DELETE_REQUESTED =
    "GROUP_AUDIT_TEMPLATE_DELETE_REQUESTED";

export const GROUP_AUDIT_TEMPLATE_TASKS_FETCH_SUCCEEDED =
    "GROUP_AUDIT_TEMPLATE_TASKS_FETCH_SUCCEEDED";
export const GROUP_AUDIT_TEMPLATE_TASKS_FETCH_FAILED =
    "GROUP_AUDIT_TEMPLATE_TASKS_FETCH_FAILED";
export const GROUP_AUDIT_TEMPLATE_TASKS_FETCH_REQUESTED =
    "GROUP_AUDIT_TEMPLATE_TASKS_FETCH_REQUESTED";

export const GROUP_AUDIT_TEMPLATE_TASK_ADD_SUCCEEDED =
    "GROUP_AUDIT_TEMPLATE_TASK_ADD_SUCCEEDED";
export const GROUP_AUDIT_TEMPLATE_TASK_ADD_FAILED =
    "GROUP_AUDIT_TEMPLATE_TASK_ADD_FAILED";
export const GROUP_AUDIT_TEMPLATE_TASK_ADD_REQUESTED =
    "GROUP_AUDIT_TEMPLATE_TASK_ADD_REQUESTED";

export const GROUP_AUDIT_TEMPLATE_TASK_DELETE_SUCCEEDED =
    "GROUP_AUDIT_TEMPLATE_TASK_DELETE_SUCCEEDED";
export const GROUP_AUDIT_TEMPLATE_TASK_DELETE_FAILED =
    "GROUP_AUDIT_TEMPLATE_TASK_DELETE_FAILED";
export const GROUP_AUDIT_TEMPLATE_TASK_DELETE_REQUESTED =
    "GROUP_AUDIT_TEMPLATE_TASK_DELETE_REQUESTED";

export const GROUP_AUDIT_TEMPLATE_TASK_UPDATE_SUCCEEDED =
    "GROUP_AUDIT_TEMPLATE_TASK_UPDATE_SUCCEEDED";
export const GROUP_AUDIT_TEMPLATE_TASK_UPDATE_FAILED =
    "GROUP_AUDIT_TEMPLATE_TASK_UPDATE_FAILED";
export const GROUP_AUDIT_TEMPLATE_TASK_UPDATE_REQUESTED =
    "GROUP_AUDIT_TEMPLATE_TASK_UPDATE_REQUESTED";

export const GROUP_AUDIT_TEMPLATE_ADD_SUCCEEDED = "GROUP_AUDIT_TEMPLATE_ADD_SUCCEEDED";
export const GROUP_AUDIT_TEMPLATE_ADD_FAILED = "GROUP_AUDIT_TEMPLATE_ADD_FAILED";
export const GROUP_AUDIT_TEMPLATE_ADD_REQUESTED = "GROUP_AUDIT_TEMPLATE_ADD_REQUESTED";

export const GROUP_AUDIT_TEMPLATE_FILE_DELETE_REQUESTED =
    "GROUP_AUDIT_TEMPLATE_FILE_DELETE_REQUESTED";
export const GROUP_AUDIT_TEMPLATE_FILE_DELETE_FAILED =
    "GROUP_AUDIT_TEMPLATE_FILE_DELETE_FAILED";

export const GROUP_AUDIT_TEMPLATE_SYNC_SUCCEEDED = "GROUP_AUDIT_TEMPLATE_SYNC_SUCCEEDED";

export interface GroupAuditTemplateTask {
    id: string;
    createTime: { toDate: () => Date };
    description: string;
    order?: number;
    customFields?: CustomField[];
}

export interface GroupAuditGuide {
    filePath: string;
    contentType: string;
    fileName: string;
    isDeleted: boolean;
    path: string;
}

export interface GroupAuditTemplate {
    guide: GroupAuditGuide | null;
    // isMultiDayAudit?: boolean;
    // notifyDaysAfterDueDate?: number | null;
    title: string;
    note?: string;
    createTime: Date;
    id: string;
    auditTemplateCategory?: string | null;
}

export interface GroupAuditTemplateSync {
    auditTemplateId: string;
    createTime: Date;
    dataToSync: Partial<GroupAuditTemplate>;
    orgId: string;
    status: RequestState;
    syncTime: Date;
}

export interface GroupAuditTemplateState {
    groupAuditTemplates: GroupAuditTemplate[];
    currentGroupAuditTemplate: GroupAuditTemplate;
    currentGroupAuditTemplateTasks: GroupAuditTemplateTask[];
    groupAuditTemplateSync: GroupAuditTemplateSync | null;
    loading: boolean;
}

interface FetchGroupAuditTemplateLoading {
    type:
        | typeof GROUP_AUDIT_TEMPLATES_FETCH_REQUESTED
        | typeof GROUP_AUDIT_TEMPLATE_FETCH_REQUESTED
        | typeof GROUP_AUDIT_TEMPLATE_TASKS_FETCH_REQUESTED;
    payload: {};
}
interface FetchGroupAuditTemplateFailed {
    type:
        | typeof GROUP_AUDIT_TEMPLATES_FETCH_FAILED
        | typeof GROUP_AUDIT_TEMPLATE_FETCH_FAILED;
    payload: {};
}

interface FetchGroupAuditTemplatesSuccess {
    type: typeof GROUP_AUDIT_TEMPLATES_FETCH_SUCCEEDED;
    payload: {
        data: any;
    };
}
interface FetchGroupAuditTemplateSuccess {
    type: typeof GROUP_AUDIT_TEMPLATE_FETCH_SUCCEEDED;
    payload: {
        data: any;
    };
}
interface FetchGroupAuditTemplateTasksSuccess {
    type: typeof GROUP_AUDIT_TEMPLATE_TASKS_FETCH_SUCCEEDED;
    payload: {
        data: any[];
    };
}
interface SetGroupAuditTemplateSyncSuccess {
    type: typeof GROUP_AUDIT_TEMPLATE_SYNC_SUCCEEDED;
    payload: GroupAuditTemplateSync | null;
}

export type GroupAuditTemplateActionTypes =
    | FetchGroupAuditTemplateLoading
    | FetchGroupAuditTemplateFailed
    | FetchGroupAuditTemplatesSuccess
    | FetchGroupAuditTemplateSuccess
    | FetchGroupAuditTemplateTasksSuccess
    | SetGroupAuditTemplateSyncSuccess;
