import { AuditTask } from "hooks/useAuditTasks";
import React from "react";
import { useParams } from "react-router";
import AuditTaskList from "./AuditTaskList";
import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import firebase from "firebase/compat/app";
import Message, { Type } from "components/Notification/Message";
import { StyleGuide } from "styles/StyleGuide";
import useGroupAuditTasks from "hooks/useGroupAuditTasks";

export default function AuditTaskListContainer() {
    const { groupId, auditTemplateId } = useParams<{
        groupId: string;
        auditTemplateId: string;
    }>();
    const { loading, hasError, auditTasks } = useGroupAuditTasks({
        groupId,
        auditTemplateId,
    });

    if (loading) {
        return <LoadingOutlined />;
    }
    if (auditTasks?.length < 1) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    color: StyleGuide.palette.grey400,
                    backgroundColor: StyleGuide.palette.grey100,
                    border: `1px solid ${StyleGuide.palette.grey300}`,
                    padding: 14,
                    borderRadius: 6,
                }}>
                Ingen opgaver tilføjet
            </div>
        );
    }
    if (hasError) {
        return (
            <div>
                <h4>Couldn't load the tasks, please try refresh the page</h4>
                <Button onClick={() => window.location.reload()}>Refresh page</Button>
            </div>
        );
    }

    const onReorderTasks = async (items: AuditTask[]) => {
        try {
            Message({
                key: "updateAuditOrder",
                type: Type.LOADING,
                message: `Opdaterer...`,
                // description: `Kunne ikke nulstille timetæller, prøv venligst igen`,
            });
            const batch = firebase.firestore().batch();
            items.forEach((item, index) => {
                const ref = firebase
                    .firestore()
                    .collection(`groups`)
                    .doc(groupId)
                    .collection(`groupAuditTemplates`)
                    .doc(auditTemplateId)
                    .collection(`tasks`)
                    .doc(item.id);
                batch.set(ref, { order: index }, { merge: true });
            });
            await batch.commit();
            Message({
                key: "updateAuditOrder",
                type: Type.SUCCESS,
                message: `Alt blev opdateret korrekt`,
                // description: `Kunne ikke nulstille timetæller, prøv venligst igen`,
            });
        } catch (error) {
            console.error(error);
            Message({
                key: "updateAuditOrder",
                type: Type.ERROR,
                message: `Noget gik galt, prøv venligst igen`,
                // description: `Kunne ikke nulstille timetæller, prøv venligst igen`,
            });
        }
    };

    return <AuditTaskList tasks={auditTasks} onChange={onReorderTasks} />;
}
