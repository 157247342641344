import firebase, { firestore } from "../../firebase";
import { useEffect, useState } from "react";

export default function useHasOnHoldAuditsBySubGroup(orgIds: string[]) {
    const [audits, setAudits] = useState<any[]>([]);
    const [isLoading, setisLoading] = useState(true);
    useEffect(() => {
        let query = firestore.collectionGroup("auditSchedules");
        let unsubscribe: any = null;
        if (orgIds.length < 1) return;
        try {
            query = query
                .where("isCompleted", "==", true)
                .where("hasOnHoldTasks", "==", true)
                .where("orgId", "in", orgIds)
                .orderBy("dueDate", "desc")
                .limit(100);

            unsubscribe = query.onSnapshot(
                query => {
                    if (!query.empty) {
                        const list: any[] = query.docs.map(doc => ({
                            id: doc.id,
                            ...doc.data(),
                            orgId: doc.ref.parent.parent?.id,
                        }));
                        setAudits(list);
                    } else {
                        setAudits([]);
                    }
                    setisLoading(false);
                },
                error => {
                    console.error(error);
                },
            );
        } catch (error) {
            console.error(error);
        }
        return unsubscribe;
    }, [orgIds]);

    return { audits, isLoading };
}
