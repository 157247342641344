export interface DBReportShape {
    title?: string;
    directoryId?: string;
}

export interface ReportState extends DBReportShape {
    reports: DBReportShape[];
    loading: boolean;
}

export const REPORT_FETCH_REQUESTED = "REPORT_FETCH_REQUESTED";
export const REPORT_FETCH_SUCCEEDED = "REPORT_FETCH_SUCCEEDED";
export const REPORT_FETCH_FAILED = "REPORT_FETCH_FAILED";

export const REPORT_UPDATE_REQUESTED = "REPORT_UPDATE_REQUESTED";
export const REPORT_UPDATE_SUCCEEDED = "REPORT_UPDATE_SUCCEEDED";
export const REPORT_UPDATE_FAILED = "REPORT_UPDATE_FAILED";

interface FetchReportsSuccess {
    type: typeof REPORT_FETCH_REQUESTED;
    payload: { orgId: string };
}
interface FetchReports {
    type: typeof REPORT_FETCH_SUCCEEDED;
    payload: DBReportShape[];
}
interface FetchReportsFailed {
    type: typeof REPORT_FETCH_FAILED;
    payload: {};
}

export type ReportActionTypes = FetchReports | FetchReportsSuccess | FetchReportsFailed;
