import React, { useState } from "react";
import { Popconfirm, Layout, Row, Col, Modal } from "antd";
import HeaderBar from "../../../Layout/HeaderBar";
import FooterBar from "../../../Layout/FooterBar";
import { IOrg } from "../../../modules/orgs/interfaces";

import { DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import AddOrg from "../../Home/AddOrg";
import TableList from "./Table";
import InventoryNav from "../InventoryNav";
import {
    InventoryContextForType,
    InventoryItem,
    InventoryType,
} from "../../../modules/inventory/types";
import EditForm from "./EditForm";
import AddForm from "./AddForm";
import Total from "./Total";

const { Content } = Layout;

interface IHomeViewProps {
    foobots: InventoryContextForType<InventoryType.FOOBOT>[];
}

function InventoryFoobotView({ foobots }: IHomeViewProps) {
    const { t } = useTranslation();
    const [visible, setVisible] = useState<string | null>(null);
    const onClose = () => setVisible(null);

    return (
        <>
            <InventoryNav />
            <Layout>
                <HeaderBar />
                <Content style={{ margin: "24px 24px 0" }}>
                    <Row>
                        <Col span={24} style={{ marginBottom: 24 }}>
                            <div style={{ float: "right" }}>
                                <AddForm />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ marginBottom: 24 }}>
                            <div style={{ float: "left" }}>
                                <Total />
                            </div>
                        </Col>
                    </Row>
                    <TableList data={foobots} setVisible={setVisible} />
                </Content>
                <FooterBar />
            </Layout>
            <Modal
                title="Ret iPad"
                open={!!visible}
                // onOk={e => handleOk(e, email)}
                confirmLoading={false}
                onCancel={onClose}
                footer={[]}>
                {visible && <EditForm key={visible} id={visible} onClose={onClose} />}
            </Modal>
        </>
    );
}

export default InventoryFoobotView;
