import { FormEvent, useState } from "react";
import {
    Layout,
    Row,
    Col,
    List,
    Button,
    Input,
    Checkbox,
    Select,
    Alert,
    Typography,
    Tooltip,
} from "antd";
import SideNav from "../../Layout/SideNav";
import HeaderBar from "../../Layout/HeaderBar";
import FooterBar from "../../Layout/FooterBar";
import { EditOutlined, InfoCircleOutlined, OrderedListOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import UploadAuditTemplateFile from "./UploadGuide";
import { UploadContext } from "../../general/constants";
import { getContentTypeIconMap } from "../Reports/Report";
import FileCard from "./FileCard";
import {
    AuditTemplate,
    AuditTemplateTask as AuditTemplateTaskType,
} from "../../modules/auditTemplates/types";
import AuditTaskListContainer from "./TaskList/AuditTaskListContainer";
import range from "lodash.range";
import firebase from "firebase/compat/app";
import Message, { Type } from "components/Notification/Message";
import AuditTemplateNote from "./AuditTemplateNote";
import AuditTemplateInfo from "./AuditTemplateInfo";
import SyncAuditTemplate from "./SyncAuditTemplate";
import AuditTemplateSyncInfo from "./AuditTemplateSyncInfo";
import AuditTemplateCategory from "./AuditTemplateCategory";
import { StyleGuide } from "styles/StyleGuide";
import SaveToGroup from "./SaveToGroup";
import AksBreadcrumbs from "components/Breadcrumbs";
import GroupAuditTemplateNote from "./AuditTemplateNote";
import { ContentType } from "general/enums";

const { Content } = Layout;

// async function updateAuditTemplate({
//     orgId,
//     auditTemplateId,
//     data,
// }: {
//     orgId: string;
//     auditTemplateId: string;
//     data: Partial<AuditTemplate>;
// }) {
//     try {
//         Message({
//             key: "updateAuditTemplate",
//             type: Type.LOADING,
//             message: `Opdaterer skabelon`,
//         });
//         await firebase
//             .firestore()
//             .collection("orgs")
//             .doc(orgId)
//             .collection("auditTemplates")
//             .doc(auditTemplateId)
//             .set(data, { merge: true });
//         Message({
//             key: "updateAuditTemplate",
//             type: Type.SUCCESS,
//             message: `Skabelon blev opdateret korrekt`,
//         });
//     } catch (error) {
//         console.error(error);
//         Message({
//             key: "updateAuditTemplate",
//             type: Type.ERROR,
//             message: `Der skete en fejl`,
//             description: `Kunne ikke opdaterer skabelon, prøv venligst igen`,
//         });
//     }
// }

interface Props extends AuditTemplate {
    id: string;
    groupId: string;
    title: string;
    note?: string;
    loading: boolean;
    tasks: AuditTemplateTaskType[];
    addAuditTemplateTask: (title: string, order: number) => void;
    updateAuditTemplate: (templateTitle: string) => void;
    removeAuditTemplateFile: () => void;
}
interface State {
    visible: boolean;
    title: string;
    templateTitle: string;
    isEditing: false | string;
    isEditingTitle: boolean;
}
function AuditTemplateDetail(props: Props) {
    const [state, setState] = useState<State>({
        visible: false,
        title: "",
        templateTitle: "",
        isEditing: false,
        isEditingTitle: false,
    });
    const { t } = useTranslation();

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.addAuditTemplateTask(state.title, props.tasks.length);
        setState({ ...state, title: "" });
    };

    const icon = getContentTypeIconMap(
        props?.guide?.contentType!,
        props.guide?.filePath!,
        {
            height: 100,
            fontSize: 100,
        },
    );

    // const onSelectBackup = (value: number | undefined) => {
    //     updateAuditTemplate({
    //         orgId: props.orgId,
    //         auditTemplateId: props.id,
    //         data: {
    //             notifyDaysAfterDueDate: value ?? null,
    //         },
    //     });
    // };

    return (
        <>
            {/* <SideNav /> */}
            <Layout>
                <HeaderBar />
                <Content>
                    <Row style={{ height: "100%" }}>
                        <Col md={19} xs={24} style={{ marginTop: 24 }}>
                            <Row>
                                <Col
                                    md={24}
                                    xs={24}
                                    style={{ paddingLeft: 24, paddingRight: 24 }}>
                                    <Row>
                                        <Col md={24} xs={24}>
                                            <AksBreadcrumbs />
                                        </Col>
                                        <Col md={24} xs={24}>
                                            <AuditTemplateInfo />
                                            <AuditTemplateSyncInfo />
                                        </Col>
                                        <Col md={16} xs={24}>
                                            {/* <h1 style={{ marginTop: "0px" }}> */}
                                            {state.isEditingTitle ? (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        width: "100%",
                                                        marginTop: 7,
                                                        marginBottom: 10,
                                                        marginLeft: -12,
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                    }}>
                                                    <Input
                                                        style={{
                                                            fontSize: 28,
                                                            flexGrow: 1,
                                                            fontWeight: 700,
                                                        }}
                                                        placeholder="Skabelon titel"
                                                        defaultValue={props.title}
                                                        onChange={e =>
                                                            setState({
                                                                ...state,
                                                                templateTitle:
                                                                    e.target.value,
                                                            })
                                                        }
                                                        size="large"
                                                    />
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                        }}>
                                                        <div style={{ marginRight: 8 }} />
                                                        <Button
                                                            type="primary"
                                                            style={{ height: "40px" }}
                                                            onClick={() => {
                                                                props.updateAuditTemplate(
                                                                    state.templateTitle,
                                                                );
                                                                setState({
                                                                    ...state,
                                                                    isEditingTitle: false,
                                                                });
                                                            }}>
                                                            {t(
                                                                "audit.templates.edit.save",
                                                            )}
                                                        </Button>
                                                        <div style={{ marginRight: 8 }} />
                                                        <Button
                                                            style={{ height: "40px" }}
                                                            onClick={() =>
                                                                setState({
                                                                    ...state,
                                                                    isEditingTitle: false,
                                                                })
                                                            }>
                                                            {t(
                                                                "audit.templates.edit.cancel",
                                                            )}
                                                        </Button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <h1
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() =>
                                                        setState({
                                                            ...state,
                                                            isEditingTitle: true,
                                                        })
                                                    }>
                                                    {props.title}
                                                    <span style={{ marginRight: 8 }} />
                                                    <Button
                                                        shape="circle"
                                                        icon={<EditOutlined />}
                                                    />
                                                </h1>
                                            )}

                                            {/* </h1> */}
                                        </Col>

                                        <Col
                                            md={8}
                                            xs={24}
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "flex-end",
                                            }}>
                                            {props.guide ? (
                                                <FileCard
                                                    icon={icon}
                                                    fileName={props.guide?.fileName}
                                                    title={
                                                        // @ts-ignore
                                                        props.guide?.title ||
                                                        props.guide?.fileName
                                                    }
                                                    path={props.guide?.filePath}
                                                    removeAuditTemplateFile={
                                                        props.removeAuditTemplateFile
                                                    }
                                                    small
                                                    contentType={
                                                        props?.guide
                                                            ?.contentType as ContentType
                                                    }
                                                />
                                            ) : (
                                                <UploadAuditTemplateFile
                                                    contextId={props.id}
                                                    context={
                                                        UploadContext.GROUP_AUDIT_TEMPLATE
                                                    }
                                                />
                                            )}
                                        </Col>
                                        <Col md={24} xs={24}>
                                            <GroupAuditTemplateNote note={props.note} />
                                        </Col>
                                    </Row>
                                </Col>
                                {/* <Col md={24} xs={24}>
                                    <div
                                        style={{
                                            marginTop: 24,
                                            borderBottom: `1px solid ${StyleGuide.palette.grey300}`,
                                        }}
                                    />
                                </Col> */}
                                <Col
                                    md={24}
                                    xs={24}
                                    style={{ paddingLeft: 24, paddingRight: 24 }}>
                                    <div
                                        style={{
                                            marginTop: 24,
                                            borderRadius: 8,
                                            // padding: 16,
                                            // border: `1px solid ${StyleGuide.palette.grey300}`,
                                        }}>
                                        <div
                                            style={{
                                                border: `1px solid #d9d9d9`,
                                                // paddingTop: 24,
                                                paddingRight: 24,
                                                paddingLeft: 16,
                                                paddingBottom: 16,
                                                borderTopLeftRadius: 6,
                                                borderTopRightRadius: 6,
                                                borderBottom: 0,
                                            }}>
                                            <Typography.Title
                                                level={5}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    marginTop: 16,
                                                }}>
                                                {t("audit.templates.edit.listheadline")}
                                                <OrderedListOutlined />
                                            </Typography.Title>

                                            <AuditTaskListContainer />
                                        </div>

                                        <form
                                            onSubmit={onSubmit}
                                            style={{ marginTop: 0 }}>
                                            <Input
                                                placeholder={t(
                                                    "audit.templates.add.itemplaceholder",
                                                )}
                                                autoFocus
                                                value={state.title}
                                                onChange={e =>
                                                    setState({
                                                        ...state,
                                                        title: e.target.value,
                                                    })
                                                }
                                                style={{
                                                    borderTopLeftRadius: 0,
                                                    borderTopRightRadius: 0,
                                                }}
                                                required
                                                suffix={
                                                    <Button
                                                        type="primary"
                                                        style={{ width: "100%" }}
                                                        htmlType="submit">
                                                        {t(
                                                            "audit.templates.edit.itemsubmit",
                                                        )}
                                                    </Button>
                                                }
                                            />
                                            <div style={{ marginTop: 4 }} />
                                        </form>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            md={5}
                            xs={24}
                            style={{
                                backgroundColor: "#fff",
                                borderLeft: `1px solid ${StyleGuide.palette.grey200}`,
                            }}>
                            <div>
                                <div
                                    style={{
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                        borderBottom: `1px solid ${StyleGuide.palette.grey200}`,
                                    }}>
                                    <h4 style={{ marginTop: "20px" }}>
                                        {t("audit.templates.addtionalSettings")}
                                    </h4>
                                </div>
                                <div
                                    style={{
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                    }}>
                                    <div style={{ marginTop: 16 }} />

                                    {/* <SyncAuditTemplate /> */}

                                    <div style={{ marginTop: 16 }} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Content>
                {/* <FooterBar /> */}
            </Layout>
        </>
    );
}

export default AuditTemplateDetail;
