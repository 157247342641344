import {
    FILES_FETCH_REQUESTED,
    FILES_FETCH_SUCCEEDED,
    FILE_DELETE_REQUESTED,
    FILE_DELETE_SUCCEEDED,
    AUDIT_FILES_FETCH_REQUESTED,
    AUDIT_FILES_FETCH_SUCCEEDED,
    AUDIT_FILE_DELETE_REQUESTED,
    AUDIT_FILE_DELETE_SUCCEEDED,
} from "./types";

export function fetchFiles({ orgId, issueId }: { orgId: string; issueId: string }) {
    return {
        type: FILES_FETCH_REQUESTED,
        payload: {
            orgId,
            issueId,
        },
    };
}

export function fetchFilesSuccess(filesList: any) {
    const files = filesList.docs.map((file: any) => ({
        ...file.data(),
        fileId: file.data().id,
        id: file.id,
    }));
    console.log({ fetchFilesSuccess: files });
    return {
        type: FILES_FETCH_SUCCEEDED,
        payload: {
            data: files,
        },
    };
}

export function deleteFile({
    orgId,
    issueId,
    fileId,
}: {
    orgId: string;
    issueId: string;
    fileId: string;
}) {
    return {
        type: FILE_DELETE_REQUESTED,
        payload: {
            orgId,
            issueId,
            fileId,
        },
    };
}

export function deleteFileSuccess() {
    return {
        type: FILE_DELETE_SUCCEEDED,
        payload: {},
    };
}

export function fetchAuditFiles({ orgId, auditId }: { orgId: string; auditId: string }) {
    return {
        type: AUDIT_FILES_FETCH_REQUESTED,
        payload: {
            orgId,
            auditId,
        },
    };
}

export function fetchAuditFilesSuccess(filesList: any) {
    const files = filesList.docs.map((file: any) => ({
        ...file.data(),
        id: file.id,
    }));
    return {
        type: AUDIT_FILES_FETCH_SUCCEEDED,
        payload: {
            data: files,
        },
    };
}

export function deleteAuditFile({
    orgId,
    auditId,
    fileId,
}: {
    orgId: string;
    auditId: string;
    fileId: string;
}) {
    return {
        type: AUDIT_FILE_DELETE_REQUESTED,
        payload: {
            orgId,
            auditId,
            fileId,
        },
    };
}

export function deleteAuditFileSuccess() {
    return {
        type: AUDIT_FILE_DELETE_SUCCEEDED,
        payload: {},
    };
}
