import React from "react";
import { ActivityType } from "../enums";
import { format, formatDistanceToNow } from "date-fns";
import {
    AimOutlined,
    AlertOutlined,
    CheckSquareOutlined,
    ClearOutlined,
    ExceptionOutlined,
    FileDoneOutlined,
    UserSwitchOutlined,
    WifiOutlined,
} from "@ant-design/icons";

enum Colors {
    COMPLETED = "green",
    ERROR = "red",
    WARN = "gold",
    INFO = "#1890ff",
}

export function getActivity(activity: any) {
    switch (activity.type) {
        case ActivityType.ACTIVITY_STORAGE_UPDATE:
            return {
                time: `${formatDistanceToNow(activity?.createTime.toDate())}`,
                icon: <ClearOutlined style={{ fontSize: 40, color: Colors.INFO }} />,
                title: `Beholding ${activity?.context?.productTitle} opdateret`,
                description: `Beholdning gik fra ${activity?.context?.previousValue} til ${activity?.context?.newValue} `,
            };

        case ActivityType.ACTIVITY_FOOBOT_LOG:
            return {
                time: `${formatDistanceToNow(activity?.createTime.toDate())}`,
                icon: <WifiOutlined style={{ fontSize: 40, color: Colors.INFO }} />,
                title: `Dust sensor log - device:${activity?.context?.sensorId}`,
                description: `Logged ${activity?.context?.logCount} between ${activity?.context?.start} and ${activity?.context?.end}`,
            };

        case ActivityType.ACTIVITY_FOOBOT_LOG_ERROR:
            return {
                time: `${formatDistanceToNow(activity?.createTime.toDate())}`,
                icon: <AlertOutlined style={{ fontSize: 40, color: Colors.ERROR }} />,
                title: `Failed to log - device:${activity?.context?.sensorId}`,
                description: `Failed to log between ${activity?.context?.start} and ${activity?.context?.end}`,
            };
        case ActivityType.ACTIVITY_ISSUE_CREATE:
            return {
                time: `${formatDistanceToNow(activity?.createTime.toDate())}`,
                icon: <ExceptionOutlined style={{ fontSize: 40, color: Colors.ERROR }} />,
                title: `Ny fejlmeldning`,
                description: `Beskrivelse: ${activity?.context?.description}`,
            };
        case ActivityType.ACTIVITY_ISSUE_COMPLETED:
            return {
                time: `${formatDistanceToNow(activity?.createTime.toDate())}`,
                icon: (
                    <FileDoneOutlined style={{ fontSize: 40, color: Colors.COMPLETED }} />
                ),
                title: `Fejl rettet`,
                description: `Beskrivelse: ${activity?.context?.description}`,
            };
        case ActivityType.ACTIVITY_ISSUE_ASSIGNEE_CHANGED:
            return {
                time: `${formatDistanceToNow(activity?.createTime.toDate())}`,
                icon: <UserSwitchOutlined style={{ fontSize: 40, color: Colors.INFO }} />,
                title: `Ansvarlig ændret`,
                description: `Beskrivelse: ${activity?.context?.description}`,
            };

        case ActivityType.ACTIVITY_AUDIT_COMPLETED:
            // const tasks = {};
            const tasksCompleted = Object.entries(activity?.context?.tasks).filter(
                ([_, value]: [string, any]) => value?.isCompleted,
            );
            const tasksInComplete = Object.entries(activity?.context?.tasks).filter(
                ([_, value]: [string, any]) => !value?.isCompleted,
            );
            const warn = Object.keys(activity.context.tasks).some(
                t => !activity.context.tasks[t].isCompleted,
            );
            console.log({ warn });

            return {
                time: `${formatDistanceToNow(activity?.createTime.toDate())}`,
                icon: (
                    <CheckSquareOutlined
                        style={{
                            fontSize: 40,
                            color: warn ? Colors.WARN : Colors.COMPLETED,
                        }}
                    />
                ),
                title: `Audit afsluttet`,
                description: `Antal opgaver: ${tasksCompleted?.length} løst og ${tasksInComplete?.length} ikke løst`,
            };
        case ActivityType.AUDIT_DAYS_AFTER_DEADLINE_ALERT:
            // const tasks = {};
            // const tasksCompleted = Object.entries(activity?.context?.tasks).filter(
            //     ([_, value]: [string, any]) => value?.isCompleted,
            // );
            // const tasksInComplete = Object.entries(activity?.context?.tasks).filter(
            //     ([_, value]: [string, any]) => !value?.isCompleted,
            // );

            return {
                time: `${formatDistanceToNow(activity?.createTime.toDate())}`,
                icon: (
                    <CheckSquareOutlined style={{ fontSize: 40, color: Colors.ERROR }} />
                ),
                title: `Audit stadig ikke afsluttet`,
                description: `Udfør opgaven`,
            };
        case ActivityType.ACTIVITY_NO_GPS_LOGS:
            return {
                time: `${formatDistanceToNow(activity?.createTime.toDate())}`,
                icon: <AimOutlined style={{ fontSize: 40, color: Colors.ERROR }} />,
                title: `Manglende GPS logs`,
                description: `Mellem ${format(
                    activity?.context?.startDate?.toDate(),
                    "yyyy-MM-dd",
                )} og ${format(activity?.context?.endDate?.toDate(), "yyyy-MM-dd")}`,
            };

        default:
            throw new Error(`Activity type ${activity.type} not implemented`);
    }
}
