import { call, put, fork, takeEvery, takeLatest } from "redux-saga/effects";
import moment from "moment";
import firebase, { reduxSagaFirebase } from "../../firebase";
import { fetchOrdersSuccess, fetchOrgOrdersSuccess } from "./actions";
import {
    ORDERS_FETCH_SUCCEEDED,
    ORDERS_FETCH_FAILED,
    ORDERS_FETCH_REQUESTED,
    ORG_ORDERS_FETCH_SUCCEEDED,
    ORG_ORDERS_FETCH_FAILED,
    ORG_ORDERS_FETCH_REQUESTED,
    ORDERS_CREATE_SUCCEEDED,
    ORDERS_CREATE_FAILED,
    ORDERS_CREATE_REQUESTED,
    ORDERS_UPDATE_SUCCEEDED,
    ORDERS_UPDATE_FAILED,
    ORDERS_UPDATE_REQUESTED,
    ORDERS_DELETE_SUCCEEDED,
    ORDERS_DELETE_FAILED,
    ORDERS_DELETE_REQUESTED,
} from "./types";

function* fetchOrders({ payload }) {
    const { orgId } = payload;
    try {
        const orders = yield call(
            reduxSagaFirebase.firestore.syncCollection,
            firebase.firestore().collection(`orders`).orderBy("createTime", "desc"),
            { successActionCreator: fetchOrdersSuccess },
        );
    } catch (e) {
        yield put({ type: ORDERS_FETCH_FAILED, message: e.message });
    }
}

export function* fetchOrdersSaga() {
    yield takeLatest(ORDERS_FETCH_REQUESTED, fetchOrders);
}
function* fetchOrgOrders({ payload }) {
    const { orgId } = payload;
    try {
        const orders = yield call(
            reduxSagaFirebase.firestore.syncCollection,
            firebase
                .firestore()
                .collection(`orders`)
                .orderBy("createTime", "desc")
                .where(`orgId`, "==", orgId),
            { successActionCreator: fetchOrgOrdersSuccess },
        );
    } catch (e) {
        yield put({ type: ORG_ORDERS_FETCH_FAILED, message: e.message });
    }
}

export function* fetchOrgOrdersSaga() {
    yield takeLatest(ORG_ORDERS_FETCH_REQUESTED, fetchOrgOrders);
}

function* addOrder(action) {
    const { orgId, productId, userId, amount } = action.payload;
    try {
        const sensor = yield call(reduxSagaFirebase.firestore.addDocument, `orders`, {
            orgId,
            productId,
            userId,
            amount,
            createTime: new Date(),
            status: "awaiting",
        });
        yield put({ type: ORDERS_CREATE_SUCCEEDED });
    } catch (e) {
        yield put({ type: ORDERS_CREATE_FAILED, message: e.message });
    }
}

export function* addOrderSaga() {
    yield takeLatest(ORDERS_CREATE_REQUESTED, addOrder);
}
function* updateOrder(action) {
    const { orderId, data } = action.payload;
    try {
        yield call(reduxSagaFirebase.firestore.updateDocument, `orders/${orderId}`, {
            ...data,
        });
        yield put({ type: ORDERS_UPDATE_SUCCEEDED });
    } catch (e) {
        yield put({ type: ORDERS_UPDATE_FAILED, message: e.message });
    }
}

export function* updateOrderSaga() {
    yield takeLatest(ORDERS_UPDATE_REQUESTED, updateOrder);
}

function* deleteOrder({ payload }) {
    const { orgId, categoryId } = payload;
    try {
        yield call(
            reduxSagaFirebase.firestore.deleteDocument,
            `orgs/${orgId}/orders/${categoryId}`,
        );
        yield put({ type: ORDERS_DELETE_SUCCEEDED });
    } catch (e) {
        yield put({ type: ORDERS_DELETE_FAILED, message: e.message });
    }
}

export function* deleteOrderSaga() {
    yield takeLatest(ORDERS_DELETE_REQUESTED, deleteOrder);
}
