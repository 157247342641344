import { useState } from "react";
import { Layout, Row, Col, List, Button, Input, Select } from "antd";
import SideNav from "../../Layout/SideNav";
import HeaderBar from "../../Layout/HeaderBar";
import FooterBar from "../../Layout/FooterBar";
import { Link } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";
import { IssueItem } from "./IssueItem";
import { FeatureIds } from "../../modules/featureControl/featuresConfig";
import BlurOverlayContainer from "../../components/Overlay/BlurOverlayContainer";
import { useTranslation } from "react-i18next";
import { Issue } from "../../modules/issues/types";

const { Content } = Layout;
const { Search } = Input;

interface Props {
    loading: boolean;
    issues: Issue[];
}

function IssueOverviewList(props: Props) {
    const { t } = useTranslation();
    const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const onSearch = (value: string) => setSearchTerm(value);
    const issues = props.issues
        .filter(issue =>
            issue.description?.toLowerCase()?.includes(searchTerm?.toLocaleLowerCase()),
        )
        .filter(issue => {
            if (categoryFilter.length === 0) {
                return true;
            }
            return categoryFilter.some(e => issue.displayTags?.includes(e));
        });

    const options = issues
        .reduce((acc, issue) => {
            issue.displayTags?.forEach(tag => {
                if (!acc.includes(tag)) {
                    acc.push(tag);
                }
            });
            return acc;
        }, [] as string[])
        .map(tag => ({ value: tag, label: tag }));

    const handleChange = (value: string[]) => {
        console.log(`selected ${value}`);
        setCategoryFilter(value);
    };
    return (
        <>
            <Layout>
                <HeaderBar />
                <BlurOverlayContainer featureKey={FeatureIds.ISSUES}>
                    <Content style={{ margin: "50px 24px 0" }}>
                        <Row gutter={16}>
                            <Col md={24} xs={24}>
                                <h2>{t("issueOverview.headline")}</h2>
                                <div style={{ marginBottom: 24 }} />
                            </Col>
                            <Col md={12} xs={24}>
                                <Search
                                    placeholder={t("issues.search.placeholder")}
                                    onSearch={onSearch}
                                    allowClear
                                />
                            </Col>
                            <Col md={12} xs={12}>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{ width: "100%" }}
                                    placeholder="Søg kategorier"
                                    value={categoryFilter}
                                    onChange={handleChange}
                                    options={options}
                                />
                            </Col>

                            <Col md={24} xs={24} style={{ marginTop: 24 }}>
                                <List
                                    key="incompleted-list"
                                    className="demo-loadmore-list"
                                    loading={props.loading}
                                    itemLayout="horizontal"
                                    locale={{ emptyText: "ingen data" }}
                                    // loadMore={loadMore}
                                    dataSource={issues}
                                    renderItem={item => {
                                        const tags = item.displayTags
                                            ? item.displayTags
                                            : [];
                                        return (
                                            <IssueItem
                                                key={item.id}
                                                hasAssignee={Boolean(item?.assignee?.id)}
                                                // @ts-ignore
                                                orgId={item.orgId}
                                                {...item}
                                                tags={tags}
                                            />
                                        );
                                    }}
                                />
                            </Col>
                        </Row>
                    </Content>
                </BlurOverlayContainer>
                <FooterBar />
            </Layout>
        </>
    );
}

export default IssueOverviewList;
