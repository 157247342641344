import { Layout } from "antd";
import Table from "./components/Table";
import SideNav from "../../Layout/SideNavAks";
import HeaderBar from "../../Layout/HeaderBar";
import FooterBar from "../../Layout/FooterBar";
import Loader from "../../components/Loader";
import moment from "moment";
import { parseAmountToFloat } from "general/helpers";

const { Content } = Layout;

function OrderList(props: any) {
    let count = 0;
    const data = props.orders.map((log: any) => {
        count += parseAmountToFloat(log.amount);
        return {
            ...log,
            key: log.id,
            createTime: moment(log.createTime.toDate()).fromNow(),
        };
    });
    return (
        <>
            <SideNav />
            <Layout>
                <HeaderBar />
                <Content style={{ margin: "50px 24px 0" }}>
                    {props.loading ? (
                        <Loader />
                    ) : (
                        <>
                            <h2>Ordre:</h2>,
                            <div>
                                <Table
                                    loading={props.loading}
                                    scroll={{ x: true }}
                                    data={data}
                                    updateOrder={props.updateOrder}
                                />
                            </div>
                        </>
                    )}
                </Content>
                <FooterBar />
            </Layout>
        </>
    );
}

export default OrderList;

OrderList.defaultProps = {
    logs: [],
};
