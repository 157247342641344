import { useEffect } from "react";
import { useDispatch } from "react-redux";
import MemberList from "./MemberList";
import { fetchOrg, updateMembers, removeMember } from "../../modules/orgs/actions";
import { useAppSelector } from "../../hooks";

function MemberListContainer() {
    const dispatch = useDispatch();
    const { orgId, members, loading } = useAppSelector(state => ({
        orgId: state.orgs.currentOrg.id,
        members: state.orgs.currentOrg.members || {},
        loading: state.orgs.loading,
    }));
    useEffect(() => {
        dispatch(fetchOrg({ orgId }));
    }, [orgId, dispatch]);

    const dispatchUpdateMembers = (member: any /*IMember*/) => {
        dispatch(updateMembers({ orgId, member }));
    };

    return (
        <MemberList
            members={members}
            loading={loading}
            key={orgId}
            updateMembers={dispatchUpdateMembers}
            // removeMember={dispatchRemoveMember}
        />
    );
}

export default MemberListContainer;
