import React, { useState } from "react";
import { Form, Input, Button, InputNumber, Modal } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { parseAmountToFloat } from "../../general/helpers";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import firebase from "firebase/compat/app";
import Message, { Type } from "components/Notification/Message";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

const FormItem = Form.Item;

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const formItemLayout = {
    wrapperCol: { span: 24 },
};

function AddHourTrackerForm(props: any) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const isMobile = useIsMobile();
    const [visible, setvisible] = useState(false);
    const orgId = useSelector((state: AppState) => state.orgs.currentOrg.id);
    const userId = useSelector((state: AppState) => state.user.details.uid);

    const handleSubmit = async (values: any) => {
        const { title, startCount } = values;
        console.log({ props, values });

        try {
            if (!orgId || !userId) {
                throw new Error(`Invalid orgId=${orgId} or userId=${userId}`);
            }
            Message({
                key: "addHourTracker",
                message: "Loading...",
                // description: "Sensoren blev tilføjet korrekt",
                type: Type.LOADING,
            });
            const db = firebase.firestore();
            const batch = db.batch();
            const logRef = db.collection("orgs").doc(orgId).collection("hourLogs").doc();

            const hourTrackerRef = db
                .collection("orgs")
                .doc(orgId)
                .collection("hourTrackers")
                .doc();
            batch.set(logRef, {
                createTime: new Date(),
                count: startCount,
                userId,
                hourTrackerId: hourTrackerRef.id,
            });
            batch.set(hourTrackerRef, {
                createTime: new Date(),
                title,
                currentCount: startCount,
                totalCount: startCount,
            });

            await batch.commit();

            Message({
                key: "addHourTracker",
                message: "timetal log tilføjet",
            });

            form.resetFields();
            handleCancel();
        } catch (error) {
            console.error(error);

            Message({
                key: "addHourTracker",
                type: Type.ERROR,
                message: `Der skete en fejl`,
                description: `Kunne ikke tilføje timetal log, prøv venligst igen`,
            });
        }
    };

    const showModal = () => setvisible(true);
    const handleCancel = () => {
        setvisible(false);
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    return (
        <>
            <Button size="large" type="primary" onClick={showModal}>
                {t("hourTracker.addBtn")}
            </Button>
            <Modal
                title={t("hourTracker.addForm.header")}
                open={visible}
                // onOk={e => handleOk(e, email)}
                onCancel={handleCancel}
                width={isMobile ? "100%" : "50%"}
                centered
                footer={[]}>
                <Form
                    {...formLayout}
                    form={form}
                    onFinish={handleSubmit}
                    initialValues={{ startCount: 0 }}>
                    <Form.Item
                        label={t("hourTracker.addForm.nameLabel")}
                        name="title"
                        rules={[{ required: true, message: "Venligst udfyld navn!" }]}>
                        <Input placeholder={t("hourTracker.addForm.namePlaceholder")} />
                    </Form.Item>
                    <Form.Item
                        label={t("hourTracker.addForm.countLabel")}
                        name="startCount"
                        rules={[{ required: true, message: "Venligst udfyld timetal!" }]}>
                        <InputNumber
                            min={0}
                            placeholder={t("hourTracker.addForm.countPlaceholder")}
                        />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Button type="primary" htmlType="submit" block>
                            {t("hourTracker.addForm.add")}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default AddHourTrackerForm;
