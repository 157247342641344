import React from "react";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import useNotifications from "./useNotifications";
import { useTranslation } from "react-i18next";

export default function Notifications() {
    const { t } = useTranslation();
    const { hasDailyDigestEmail, onUpdateDailyDigestEmail } = useNotifications();
    const onChange = (e: CheckboxChangeEvent) => {
        onUpdateDailyDigestEmail(e.target.checked);
    };
    return (
        <Checkbox checked={hasDailyDigestEmail} onChange={onChange}>
            {t("profile.dailydigest")}
        </Checkbox>
    );
}
