import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import keyBy from "lodash.keyby";
import { InventoryType } from "modules/inventory/types";

export default function useInventoryTags(type?: InventoryType) {
    const [inventoryTagsMap, setInventoryTagsMap] = useState<any>({});
    const [isLoading, setisLoading] = useState<boolean>(true);

    useEffect(() => {
        let query;
        if (type) {
            query = firebase
                .firestore()
                .collection("inventoryTags")
                .where("type", "==", type);
        } else {
            query = firebase.firestore().collection("inventoryTags");
        }
        firebase.firestore().collection("inventoryTags");
        const sub = query.onSnapshot(snap => {
            if (!snap.empty) {
                const data = snap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setInventoryTagsMap(keyBy(data, "id"));
                setisLoading(false);
            }
        });

        return () => {
            sub?.();
        };
    }, []);

    return { inventoryTagsMap, isLoading };
}

export interface InventoryTagsMap {
    [key: string]: { title: string; color: string; type: InventoryType };
}
