import React from "react";
import TableEditCancel from "../../../components/Table/TableEditCancel";
import { Status } from "../../OrgOrders/components/columns";

export default function columns(
    editingKey: string,
    isEditing: (record: any) => boolean,
    save: (key: string, amount: number) => void,
    edit: (record: any) => void,
    cancel: () => void,
) {
    return [
        {
            title: "Bestillingstidspunkt",
            dataIndex: "createTime",
            // width: 300,
            editable: false,
        },
        {
            title: "Afdeling",
            dataIndex: "org",
            // width: 300,
            editable: false,
        },
        {
            title: "Mængde (l)",
            dataIndex: "amount",
            // width: 300,
            editable: false,
        },
        {
            title: "Produkt",
            dataIndex: "productId",
            // width: 200,
            editable: false,
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (_: any, record: any) => <Status record={record} />,
            // width: 200,
            editable: true,
        },
        {
            title: "",
            dataIndex: "operation",
            render: (_: any, record: any) => (
                <TableEditCancel
                    record={record}
                    editingKey={editingKey}
                    isEditing={isEditing}
                    save={save}
                    edit={edit}
                    cancel={cancel}
                />
            ),
        },
    ];
}
