import { Modal } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ModalType } from "modules/modal/reducer";
import AdvancedTaskModalContent from "Views/AuditTemplates/AdvancedTaskModalContent";
import { closeModal } from "modules/modal/actions";
import AdvancedGroupTaskModalContent from "Views/GroupAuditTemplates/AdvancedTaskModalContent";

// function getModalTitle(type: ModalType | null): string {
//     switch (type) {
//         case ModalType.ADVANCED_TASK_SETTINGS:
//             return "Tilføj specifikke felter til registreringen af opgaven";
//         default:
//             return "";
//     }
// }

export default function ModalContainer() {
    const dispatch = useAppDispatch();
    const type = useAppSelector(state => state.modals.type);
    const isOpen = Boolean(type);

    const renderContent = (type: ModalType | null) => {
        switch (type) {
            case ModalType.ADVANCED_TASK_SETTINGS:
                return <AdvancedTaskModalContent />;
            case ModalType.ADVANCED_GROUP_TASK_SETTINGS:
                return <AdvancedGroupTaskModalContent />;

            default:
                return null;
        }
    };

    const handleCancel = () => {
        dispatch(closeModal());
    };
    // const title = getModalTitle(type);
    return (
        <Modal
            // title={title}
            open={isOpen}
            destroyOnClose
            // onOk={e => handleOk(e, email)}
            onCancel={handleCancel}
            width={"70%"}
            centered
            footer={[]}>
            {renderContent(type)}
        </Modal>
    );
}
