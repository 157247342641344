import React, { Dispatch, useState, useRef, useMemo, useEffect } from "react";
import { Table, Input, Button, Avatar, Tooltip, Badge, Tag } from "antd";
import Highlighter from "react-highlight-words";
import { Link, useLocation } from "react-router-dom";
import { SearchOutlined, WarningOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { useTranslation } from "react-i18next";
import { InventoryContextForType, InventoryType } from "../../../modules/inventory/types";
import { getDateString } from "../../../general/dates";
import { InventoryTagsMap } from "Views/SensorOverview/useInventoryTags";

interface IProps {
    data: InventoryContextForType<InventoryType.SIM_CARDS>[];
    inventoryTagsMap: InventoryTagsMap;
    setVisible: Dispatch<string>;
}

function TableList(props: IProps) {
    const { search } = useLocation();

    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const iccid = searchParams.get("iccid");
    const intl = useSelector((state: AppState) => state.user.userDoc.intl);

    console.log({ iccid });

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const { t } = useTranslation();
    console.log({ searchText });
    // TODO: fix so it is search already?
    // useEffect(() => {
    //     if (iccid) {
    //         setTimeout(() => {
    //             handleSearch([iccid], () => {}, "iccid");
    //         }, 2000);
    //     }
    // }, [iccid]);

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }: {
            setSelectedKeys: (event: string | string[]) => void;
            selectedKeys: string[];
            confirm: () => void;
            clearFilters: () => void;
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={t("home.table.searchPlaceholder")}
                    value={selectedKeys[0]}
                    onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 200, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    {t("home.table.search")}
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}>
                    {t("home.table.searchReset")}
                </Button>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value: string, record: any) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
                // @ts-ignore
                setTimeout(() => searchInput.current.select());
            }
        },
        render: (text: string, _: any) => {
            if (searchedColumn === dataIndex) {
                return (
                    <Highlighter
                        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                );
            }
            return text;
        },
    });

    const handleSearch = (
        selectedKeys: string[],
        confirm: () => void,
        dataIndex: string,
    ) => {
        console.log({ selectedKeys, confirm, dataIndex });

        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        // this.setState({
        //     searchText: selectedKeys[0],
        //     searchedColumn: dataIndex,
        // });
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText("");
    };

    const columns = [
        {
            title: t("ICCID"),
            dataIndex: "iccid",
            key: "iccid",
            ...getColumnSearchProps("iccid"),
        },
        // {
        //     title: t("inventory.ipad.name"),
        //     dataIndex: "name",
        //     key: "name",
        //     width: "20%",
        //     ...getColumnSearchProps("name"),
        // },

        {
            title: t("Tags"),
            dataIndex: "tags",
            key: "tags",

            onFilter: (value: any, record: any) => record.tags?.includes(value),
            // filters: props.inventoryTagsMap
            //     ? Object.entries(props.inventoryTagsMap).map(([key, value]) => ({
            //           text: value.title,
            //           value: key,
            //       }))
            //     : [],
            render: (tags: string[]) => {
                return tags.map(id => (
                    <Tag key={id} color={props.inventoryTagsMap[id]?.color}>
                        {" "}
                        {props.inventoryTagsMap[id]?.title}
                    </Tag>
                ));
            },
        },
        {
            title: t("Note"),
            dataIndex: "connection",
            key: "connection",
            render: (connection: { id: string; title: string; type: InventoryType }) => (
                <a href={`/inventory/${connection?.type}`}>{connection?.title}</a>
            ),
        },
        {
            title: t("Note"),
            dataIndex: "note",
            key: "note",

            render: (note: string) => <Tooltip title={note}>{note}</Tooltip>,
        },
        {
            title: t("inventory.ipad.updatetime"),
            dataIndex: "updateTime",
            key: "updateTime",
            width: "20%",
            render: (updateTime: Date) => getDateString(updateTime, intl),
        },

        {
            title: "Action",
            dataIndex: "id",
            key: "id",
            render: (id: string) => <a onClick={() => props.setVisible(id)}>Ret</a>,
        },
    ]; //.filter(i => (!isAdmin(uid) ? i.key !== "members" && i.key !== "status" : i));
    // @ts-ignore
    return <Table columns={columns} rowKey="id" dataSource={props.data} />;
}

export default TableList;
