import {
    addDays,
    addMonths,
    addWeeks,
    addYears,
    startOfMonth,
    endOfMonth,
} from "date-fns";
import { getDateAndMonth, getDateOfMonth, getDayOfWeek } from "general/dates";
import { Intl, RecurringOptions } from "general/enums";
import { t } from "i18next";

enum Faktor {
    WEEKLY = 1,
    BI_WEEKLY = 2,
    TRI_WEEKLY = 3,
    MONTHLY = 1,
    BI_MONTHLY = 2,
    QUARTERLY = 3,
    SEMI_YEARLY = 6,
    YEARLY = 1,
}

export const getFirstExtendDate = (enumValue: RecurringOptions, date: Date) => {
    switch (enumValue) {
        case RecurringOptions.NEVER:
            return date;
        case RecurringOptions.WEEKLY:
            return addWeeks(date, Faktor.WEEKLY);
        case RecurringOptions.BI_WEEKLY:
            return addWeeks(date, Faktor.BI_WEEKLY);
        case RecurringOptions.TRI_WEEKLY:
            return addWeeks(date, Faktor.TRI_WEEKLY);
        case RecurringOptions.DATE_MONTHLY:
            return addMonths(date, Faktor.MONTHLY);
        case RecurringOptions.MONTHLY:
            return addMonths(date, Faktor.MONTHLY);
        case RecurringOptions.BI_MONTHLY:
            return addMonths(date, Faktor.BI_MONTHLY);
        case RecurringOptions.DATE_BI_MONTHLY:
            return addMonths(date, Faktor.BI_MONTHLY);
        case RecurringOptions.QUARTERLY:
            return addMonths(date, Faktor.QUARTERLY);
        case RecurringOptions.DATE_QUARTERLY:
            return addMonths(date, Faktor.QUARTERLY);
        case RecurringOptions.SEMI_YEARLY:
            return addMonths(date, Faktor.SEMI_YEARLY);
        case RecurringOptions.YEARLY:
            return addYears(date, Faktor.YEARLY);
        case RecurringOptions.PRIMO_MONTH:
            return startOfMonth(addMonths(date, Faktor.MONTHLY));
        case RecurringOptions.ULTIMO_MONTH:
            return endOfMonth(addMonths(date, Faktor.MONTHLY));
        default:
            throw new Error("Invalid recurring option");
    }
};

export const getScheduleByEnum = (
    enumValue: RecurringOptions,
    date: Date,
    intl: Intl,
) => {
    switch (enumValue) {
        case RecurringOptions.NEVER:
            return `${t("audit.schedule.repeatnever")}`;
        case RecurringOptions.DAILY:
            return `${t("audit.schedule.repeatdaily")}`;
        case RecurringOptions.WEEK_DAILY:
            return `${t("audit.schedule.repeatweekdaily")}`;
        case RecurringOptions.WEEKLY:
            return `${t("audit.schedule.repeatweekly")} ${getDayOfWeek(date, intl)}`;
        case RecurringOptions.BI_WEEKLY:
            return `${t("audit.schedule.repeatnbiweekly")} ${getDayOfWeek(date, intl)}`;
        case RecurringOptions.TRI_WEEKLY:
            return `${t("audit.schedule.repeatntriweekly")} ${getDayOfWeek(date, intl)}`;
        case RecurringOptions.DATE_MONTHLY:
            return `Den ${getDateOfMonth(date, intl)} ${t(
                "audit.schedule.repeatmonthly",
            )}`;
        case RecurringOptions.MONTHLY:
            return `${getDayOfWeek(date, intl)} ${t("audit.schedule.repeatmonthly")}`;
        case RecurringOptions.BI_MONTHLY:
            return `${getDayOfWeek(date, intl)} ${t("audit.schedule.repeatbimonthly")}`;
        case RecurringOptions.DATE_BI_MONTHLY:
        case RecurringOptions.PRIMO_BI_MONTH:
        case RecurringOptions.ULTIMO_BI_MONTH:
            return `Den ${getDateOfMonth(date, intl)} ${t(
                "audit.schedule.repeatbimonthly",
            )}`;
        case RecurringOptions.QUARTERLY:
        case RecurringOptions.DATE_QUARTERLY:
        case RecurringOptions.PRIMO_QUARTERLY:
        case RecurringOptions.ULTIMO_QUARTERLY:
            return `${getDayOfWeek(date, intl)} ${t("audit.schedule.repeatquarterly")}`;
        case RecurringOptions.EVERY_FOURTH_MONTHLY:
        case RecurringOptions.DATE_EVERY_FOURTH_MONTHLY:
        case RecurringOptions.PRIMO_EVERY_FOURTH_MONTH:
        case RecurringOptions.ULTIMO_EVERY_FOURTH_MONTH:
            return `${getDayOfWeek(date, intl)} ${t("audit.schedule.repeatfourmonths")}`;
        case RecurringOptions.SEMI_YEARLY:
            return `${getDayOfWeek(date, intl)} ${t("audit.schedule.repeatsemiyearly")}`;
        case RecurringOptions.YEARLY:
            return `${getDateAndMonth(date, intl)} ${t("audit.schedule.repeatyearly")}`;
        case RecurringOptions.PRIMO_MONTH:
            return `Primo ${t("audit.schedule.repeatmonthly")}`;
        case RecurringOptions.ULTIMO_MONTH:
            return `Ultimo ${t("audit.schedule.repeatmonthly")}`;
        default:
            return "audit.schedule.repeatnever";
    }
};
