import { Alert } from "antd";
import React, { useState } from "react";
import firebase from "firebase/compat/app";
import { useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import Message, { Type } from "components/Notification/Message";
import useAuditTemplateSync from "hooks/useAuditTemplateSync";
import { useParams } from "react-router";
import { RequestState } from "interfaces/request";

export default function AuditTemplateSyncInfo() {
    const { t } = useTranslation();
    const { orgId, auditTemplateId } = useParams<{
        orgId: string;
        auditTemplateId: string;
    }>();
    useAuditTemplateSync(orgId, auditTemplateId);
    const visible = useAppSelector(state => {
        if (state.auditTemplates.auditTemplateSync?.status === RequestState.PENDING) {
            return true;
        }
        return false;
    });

    // const handleClose = async () => {
    //     try {
    //         if (!uid) throw new Error("uid is not defined");
    //         await firebase
    //             .firestore()
    //             .collection("users")
    //             .doc(uid)
    //             .collection("onboarding")
    //             .doc("onboarding")
    //             .set({ auditTemplateInfo: true }, { merge: true });
    //     } catch (e) {
    //         console.error(e);
    //         Message({
    //             key: "AuditTemplateInfo",
    //             type: Type.ERROR,
    //             message: `Der skete en fejl`,
    //         });
    //     }
    // };

    if (!visible) return null;
    return (
        <Alert
            message={t("audit.templates.templateSyncInfo")}
            type="warning"
            showIcon
            // closable
            // afterClose={handleClose}
        />
    );
}
