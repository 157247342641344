import {
    REPORT_FETCH_REQUESTED,
    REPORT_FETCH_SUCCEEDED,
    DBReportShape,
    REPORT_UPDATE_REQUESTED,
} from "./types";

export interface DocumentData {
    [field: string]: any;
}

export function fetchReports({ orgId }: { orgId: string }) {
    return {
        type: REPORT_FETCH_REQUESTED,
        payload: {
            orgId,
        },
    };
}

export function fetchReportsSuccess(
    // @ts-ignore
    reportList: firebase.firestore.QuerySnapshot,
): { type: string; payload: DBReportShape[] } {
    // @ts-ignore
    const reports = reportList.docs.map((report: firebase.firestore.DocumentData) => ({
        ...report.data(),

        id: report.id,
    }));
    return {
        type: REPORT_FETCH_SUCCEEDED,
        payload: reports,
    };
}
export function updateReport({
    orgId,
    reportId,
    data,
}: {
    orgId: string;
    reportId: string;
    data: any;
}) {
    return {
        type: REPORT_UPDATE_REQUESTED,
        payload: {
            orgId,
            reportId,
            data,
        },
    };
}
