import Page from "./Page";
import AdminOnly from "../../components/AdminOnly";

function PipedriveContainer() {
    return (
        <AdminOnly>
            <Page />
        </AdminOnly>
    );
}

export default PipedriveContainer;
