import React, { useState } from "react";
import { Badge, Card, List, Popover, Space, Typography } from "antd";
import { useHistory, useParams } from "react-router";
import { CheckCircleTwoTone, LoadingOutlined, WarningOutlined } from "@ant-design/icons";
import useNeedsResolutionAudits from "modules/auditSchedule/useNeedsResolutionAudits";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Colors } from "Layout/Colors";
import {
    filterOlderAuditsByCompleteTime,
    filterRecentAuditsByCompleteTime,
} from "./auditFilter";
import { StyleGuide } from "styles/StyleGuide";
import { sortOnOrderAndCreateTime } from "hooks/useAuditTasks";
import { AuditScheduleTask } from "modules/auditSchedule/types";
import PopoverTaskDetails from "./PopoverTaskDetails";
import AuditItem from "./AuditItem";

export default function AuditWidget() {
    const history = useHistory();
    const { t } = useTranslation();
    const { orgId } = useParams<{ orgId: string }>();
    const { audits, isLoading } = useNeedsResolutionAudits(orgId);
    const [activeTab, setactiveTab] = useState("tab1");
    const auditsLast7Days = audits.filter(filterRecentAuditsByCompleteTime).length;
    const auditsBefore7Days = audits.filter(filterOlderAuditsByCompleteTime).length;

    if (isLoading) {
        return (
            <Card
                title={t("dashboard.auditsWithIssues.title")}
                headStyle={{}}
                style={{
                    width: "100%",
                    height: 467,
                    backgroundColor: "#fff",
                    color: "#001628",
                }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "370px",
                    }}>
                    <LoadingOutlined style={{ fontSize: 50 }} />
                </div>
            </Card>
        );
    }
    const tabList = [
        {
            key: "tab1",
            tab: `Seneste 7 dage ${auditsLast7Days > 0 ? `(${auditsLast7Days})` : ""}`,
        },
        {
            key: "tab2",
            tab: `Over 7 dage ${auditsBefore7Days > 0 ? `(${auditsBefore7Days})` : ""}`,
        },
    ];
    const list = audits
        .map(audit => ({
            id: audit.id,
            title: audit.templateTitle,
            completeTime: audit.completeTime,
            tasks: sortOnOrderAndCreateTime(
                Object.keys(audit.tasks)
                    .filter(t => !audit.tasks[t].isCompleted)
                    .map(t => audit.tasks[t]),
            ) as AuditScheduleTask[],
        }))
        .filter(
            activeTab === "tab1"
                ? filterRecentAuditsByCompleteTime
                : filterOlderAuditsByCompleteTime,
        );

    return (
        <Card
            title={
                <Space
                    style={{
                        display: "flex",
                        paddingLeft: 24,
                        paddingRight: 16,
                        paddingTop: 0,
                        paddingBottom: 16,
                    }}>
                    <WarningOutlined
                        style={{
                            display: "flex",
                            fontSize: 24,
                            color: Colors.hardWarning,
                        }}
                    />
                    {t("dashboard.auditsWithIssues.title")}
                </Space>
            }
            tabList={tabList}
            tabProps={{
                size: "small",
                style: { paddingLeft: 24, borderTop: "1px solid #f0f0f0" },
            }}
            onTabChange={setactiveTab}
            activeTabKey={activeTab}
            headStyle={{ padding: 0, color: "#001628" }}
            style={{
                width: "100%",
                backgroundColor: "#fff",
            }}
            bodyStyle={{
                height: 370,
                overflowY: "scroll",
                padding: 0,
                paddingTop: 0,
                paddingBottom: 0,
            }}>
            <div style={{ color: Colors.hardWarning }}>
                <List
                    itemLayout="horizontal"
                    dataSource={list}
                    locale={{
                        emptyText: (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: 48,
                                }}>
                                <CheckCircleTwoTone
                                    twoToneColor={Colors.done}
                                    style={{ fontSize: 64 }}
                                />
                                <Typography.Title level={5}>
                                    {t("dashboard.auditsWithIssues.empty")}
                                </Typography.Title>
                            </div>
                        ),
                    }}
                    renderItem={item => {
                        return (
                            <PopoverTaskDetails
                                key={item.id}
                                title="dashboard.auditsWithIssues.title"
                                color={Colors.hardWarning}
                                tasks={item.tasks}>
                                <div>
                                    <AuditItem
                                        key={item.id}
                                        taskCount={item.tasks.length}
                                        color={Colors.hardWarning}
                                        linkPath={`/orgs/${orgId}/audit/audits/${item.id}`}
                                        title={item.title}
                                        description={
                                            <>
                                                {t(
                                                    "dashboard.auditsWithIssues.listItemDescription",
                                                )}{" "}
                                                {t("general.dateToNow", {
                                                    date: item.completeTime?.toDate(),
                                                })}
                                            </>
                                        }
                                    />
                                </div>
                            </PopoverTaskDetails>
                        );
                    }}
                />
            </div>
        </Card>
    );
}
