import { firestore } from "../firebase";
import { collection, getCountFromServer, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";

export default function useAuditByAuditTemplateIdCount(
    orgId: string,
    templateId: string,
) {
    const [count, setcount] = useState(0);
    useEffect(() => {
        const getCount = async () => {
            const coll = query(
                collection(firestore, `orgs/${orgId}/auditSchedules`),
                where("templateId", "==", templateId),
            );
            const snapshot = await getCountFromServer(coll);
            setcount(snapshot.data().count);
        };
        if (orgId && templateId) {
            getCount();
        }

        return () => {};
    }, [orgId, templateId]);

    return count;
}
