import React from "react";
import { useSelector } from "react-redux";
import { GPSItem } from "../../../modules/inventory/gps/types";
import { InventoryStatus, InventoryStatusMap } from "../../../modules/inventory/types";
import { AppState } from "../../../store";
import { Col, Grid, Row, Tag } from "antd";
import { FoobotItem } from "../../../modules/inventory/foobot/types";
import { EspAqItem } from "../../../modules/inventory/esp_aq/types";

function RenderTag({ value }: { value: InventoryStatus }) {
    switch (value) {
        case InventoryStatus.NEEDS_ORDER:
            return (
                <Tag color="red">{InventoryStatusMap[InventoryStatus.NEEDS_ORDER]}</Tag>
            );
        case InventoryStatus.ORDERED:
            return <Tag color="gold">{InventoryStatusMap[InventoryStatus.ORDERED]}</Tag>;
        case InventoryStatus.IN_STORAGE:
            return (
                <Tag color="orange">{InventoryStatusMap[InventoryStatus.IN_STORAGE]}</Tag>
            );
        case InventoryStatus.CONFIG:
            return (
                <Tag color="blue-inverse">
                    {InventoryStatusMap[InventoryStatus.CONFIG]}
                </Tag>
            );
        case InventoryStatus.READY:
            return <Tag color="blue">{InventoryStatusMap[InventoryStatus.READY]}</Tag>;
        case InventoryStatus.ASSIGNED:
            return (
                <Tag color="green">{InventoryStatusMap[InventoryStatus.ASSIGNED]}</Tag>
            );
        case InventoryStatus.RETRIEVE:
            return <Tag color="cyan">{InventoryStatusMap[InventoryStatus.RETRIEVE]}</Tag>;

        case InventoryStatus.CONFIG_MOBILE:
            return (
                <Tag color="blue-inverse">
                    {InventoryStatusMap[InventoryStatus.CONFIG_MOBILE]}
                </Tag>
            );
        case InventoryStatus.CONFIG_FIRM:
            return (
                <Tag color="blue-inverse">
                    {InventoryStatusMap[InventoryStatus.CONFIG_FIRM]}
                </Tag>
            );
        case InventoryStatus.ISSUE:
            return <Tag color="red">{InventoryStatusMap[InventoryStatus.ISSUE]}</Tag>;
        case InventoryStatus.NIELS:
            return <Tag color="orange">{InventoryStatusMap[InventoryStatus.NIELS]}</Tag>;
        case InventoryStatus.SHIPOUT:
            return <Tag color="green">{InventoryStatusMap[InventoryStatus.SHIPOUT]}</Tag>;
        default:
            return null;
    }
}

export default function Total() {
    const statuses = {
        [InventoryStatus.NEEDS_ORDER]: 0,
        [InventoryStatus.ORDERED]: 0,
        [InventoryStatus.IN_STORAGE]: 0,
        [InventoryStatus.CONFIG]: 0,
        [InventoryStatus.CONFIG_MOBILE]: 0,
        [InventoryStatus.CONFIG_FIRM]: 0,
        [InventoryStatus.ISSUE]: 0,
        [InventoryStatus.NIELS]: 0,
        [InventoryStatus.SHIPOUT]: 0,
        [InventoryStatus.READY]: 0,
        [InventoryStatus.ASSIGNED]: 0,
        [InventoryStatus.RETRIEVE]: 0,
    };
    const espAq = useSelector((state: AppState) =>
        state.inventory.espAqIds.map(id => state.inventory.inventoryMap[id]),
    ) as EspAqItem[];

    const stats = espAq.reduce((acc, item) => {
        return {
            ...acc,
            [item.status]: acc[item.status] + 1,
        };

        // return acc;
    }, statuses);

    return (
        <Row gutter={16} style={{ display: "flex" }}>
            {Object.entries(stats).map(([key, value]) => (
                <Col
                    xs={4}
                    style={{
                        marginRight: 10,
                        marginBottom: 8,
                        display: "flex",
                        alignItems: "center",
                    }}>
                    {/* @ts-ignore */}
                    <RenderTag value={Number(key)} />
                    {value}
                </Col>
            ))}
        </Row>
    );
}
