import firebase, { firestore } from "../../firebase";
import { useEffect, useState } from "react";
import { AuditSchedule } from "./types";

/**
 * Filtering out if it is on hold
 * @param groupId
 * @returns
 */

export default function useNeedsResolutionAuditsByGroup(
    groupId: string,
    orgIds?: string[],
) {
    const [audits, setAudits] = useState<any[]>([]);
    const [isLoading, setisLoading] = useState(true);
    useEffect(() => {
        let unsubscribe: any = null;
        if (!groupId) return;
        try {
            let query = firestore.collectionGroup("auditSchedules");
            query = query
                .where("groupId", "==", groupId)
                .where("isCompleted", "==", true)
                .where("needsResolution", "==", true);
            if (orgIds && orgIds?.length > 0) {
                query = query.where("orgId", "in", orgIds);
            }

            query = query.orderBy("dueDate", "desc").limit(100);
            unsubscribe = query.onSnapshot(query => {
                if (!query.empty) {
                    const list: any[] = query.docs
                        .map(
                            doc =>
                                ({
                                    id: doc.id,
                                    orgId: doc.ref.parent.parent?.id,
                                    ...doc.data(),
                                } as AuditSchedule & { orgId: string }),
                        )
                        .filter(audit => !audit.hasOnHoldTasks);
                    setAudits(list);
                } else {
                    setAudits([]);
                }
                setisLoading(false);
            });
        } catch (error) {
            console.error(error);
        }
        return unsubscribe;
    }, [groupId, orgIds]);

    return { audits, isLoading };
}
