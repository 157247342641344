import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { login, sendPasswordResetEmail } from "../modules/user/actions";

export interface PasswordResetParams {
    email: string;
}

function usePasswordReset() {
    const loading = useSelector((state: AppState) => state.user.loadingForgotPassword);
    const error = useSelector((state: AppState) => state.user.errorForgotPassword);
    const success = useSelector((state: AppState) => state.user.success);

    const dispatch = useDispatch();

    const dispatchSendPasswordResetEmail = ({ email }: PasswordResetParams) =>
        dispatch(sendPasswordResetEmail({ email }));

    return {
        loading,
        error,
        success,
        dispatchSendPasswordResetEmail,
    };
}

export default usePasswordReset;
