export function getUniqueItemsBySubGroupId(data: any[]) {
    const uniqueItems = new Map();

    data.forEach(item => {
        if (item?.subGroup?.id) {
            uniqueItems.set(item.subGroup.id, item);
        }
    });

    return Array.from(uniqueItems.values());
}
