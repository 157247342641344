import React, { useEffect } from "react";
import { Form, Button, Select, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../hooks";
import { Unit } from "../../components/SelectUnit";

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    wrapperCol: { span: 24 },
};

function AddOrgOrderForm(props: any) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const orgUnit = useAppSelector(state => state.orgs.currentOrg?.unit || Unit.LITER);
    useEffect(() => {
        // form.validateFields();
    }, []);
    // To disabled submit button at the beginning.

    const handleSubmit = (values: any) => {
        console.log(values);
        props.addOrder(values);
        form.resetFields();
    };

    return (
        <Form key="addOrgOrderForm" form={form} layout="inline" onFinish={handleSubmit}>
            <FormItem
                {...formItemLayout}
                name="amount"
                rules={[{ required: true, message: "Please enter an amount!" }]}>
                <InputNumber
                    placeholder={t("orders.amount_placeholder", { unit: orgUnit })}
                    min={0}
                />
            </FormItem>
            <FormItem
                {...formItemLayout}
                style={{ minWidth: "150px" }}
                name="productId"
                rules={[{ required: true, message: "Please select a product!" }]}>
                <Select
                    placeholder={t("orders.product_placeholder")}
                    style={{ width: 200 }}>
                    {props.products.map((product: any) => (
                        <Option key={product.id} value={product.id}>
                            {product.title}
                        </Option>
                    ))}
                </Select>
            </FormItem>
            <FormItem>
                <Button type="primary" htmlType="submit">
                    {t("orders.submit")}
                </Button>
            </FormItem>
        </Form>
    );
}

export default AddOrgOrderForm;
