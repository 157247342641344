import { TASKS_FETCH_REQUESTED, TASKS_FETCH_SUCCEEDED } from "./types";

export function fetchTasks({ userId }) {
    return {
        type: TASKS_FETCH_REQUESTED,
        payload: {
            userId,
        },
    };
}

export function fetchTasksSuccess(tasksList) {
    const tasks = tasksList.docs.map(task => ({
        ...task.data(),
        id: task.id,
    }));
    return {
        type: TASKS_FETCH_SUCCEEDED,
        payload: {
            data: tasks,
        },
    };
}
