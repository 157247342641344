import React, { PureComponent } from "react";
import { format, subHours } from "date-fns";
import MediaQuery from "react-responsive";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Brush,
    ReferenceLine,
    AreaChart,
    Area,
} from "recharts";

const getThresholdByDataKey = (dataKey: string, thresholds: any) => {
    switch (dataKey) {
        case "pm_2_5":
            return (thresholds && thresholds[dataKey]) || 25;
        case "pm_10":
            return (thresholds && thresholds[dataKey]) || 25;
        case "temperature":
            return (thresholds && thresholds[dataKey]) || 30;
        case "humidity":
            return (thresholds && thresholds[dataKey]) || 60;
        case "co2":
            return (thresholds && thresholds[dataKey]) || 1300;
        case "decibel":
            return (thresholds && thresholds[dataKey]) || 300;
        case "lux":
            return (thresholds && thresholds[dataKey]) || 50;

        default:
            return "Unknown";
    }
};
const getTitleByDataKey = (dataKey: string) => {
    switch (dataKey) {
        case "pm_2_5":
            return "PM2.5 µg/m³";
        case "pm_10":
            return "PM 10 µg/m³";
        case "temperature":
            return "Temperature (°C)";
        case "humidity":
            return "Humidity (%)";
        case "co2":
            return "CO2 (ppm)";
        case "decibel":
            return "Decibel (dB)";
        case "lux":
            return "Global Index (%)";

        default:
            return "Unknown";
    }
};

const getFormattedTime = (time: number) => {
    // console.log({ time });
    if (!time) return "Invalid date";
    return format(subHours(time, 2), "dd/MM/yy - HH:mm");
};

enum dataUnit {
    UGM3 = 1,
    TEMPERATURE = 2,
    HUMIDITY = 3,
    CO2 = 4,
    VOC = 5,
    TVOC = 6,
}

const height = 400;
const width = 650;

const CustomizedYTick = (data: any) => {
    const decimals = data?.decimals || 0;
    return (
        <text {...data}>
            <tspan x={data.x} dy="0.355em">
                {data?.payload?.value?.toFixed(decimals)}
            </tspan>
        </text>
    );
};

const CustomTooltip = ({ active, payload, thresholds }: any) => {
    // console.log({ active, payload, thresholds });

    if (active) {
        const title = getTitleByDataKey(payload[0]?.dataKey);
        const time = getFormattedTime(payload[0]?.payload?.time);
        const threshold = getThresholdByDataKey(payload[0]?.dataKey, thresholds);
        const decimals = payload[0]?.dataKey === "temperature" ? 1 : 0;
        return (
            <div
                className="custom-tooltip"
                style={{
                    backgroundColor: "#fff",
                    padding: 16,
                    borderRadius: 8,
                    boxShadow: "0 1px 12px rgba(0,0,0,0.06), 0 1px 8px rgba(0,0,0,0.12)",
                }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}>
                    <div>{`${title}`}</div>
                    <div style={{ padding: 5 }} />
                    <div style={{ fontSize: 10 }}> {time}</div>
                </div>
                <hr />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>Device: </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}>
                        <div
                            style={{
                                height: 10,
                                width: 10,
                                marginRight: 10,
                                borderRadius: "50%",
                                backgroundColor: payload[0]?.color,
                            }}
                        />{" "}
                        <div>{payload[0]?.value?.toFixed(decimals)}</div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>Threshold: </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}>
                        <div
                            style={{
                                height: 10,
                                width: 10,
                                marginRight: 10,
                                borderRadius: "50%",
                                backgroundColor: "#333",
                            }}
                        />{" "}
                        <div>{threshold}</div>
                    </div>
                </div>
            </div>
        );
    }
    return null;
};

function CustomizedTick(props: any) {
    return (
        <text {...props} fontSize=".85em">
            <tspan x={props.x} dy="0.9em">
                {props.payload?.value}
            </tspan>
        </text>
    );
}

const cardStyle = {
    backgroundColor: "#fff",
    padding: 16,
    marginBottom: 8,
    borderRadius: 8,
    // @ts-ignore
    overflow: "scroll",
};

export default class AirQuality extends PureComponent<any> {
    render() {
        const {
            pm_2_5,
            pm_10,
            temperature,
            humidity,
            co2,
            co2Avg,
            decibel,
            lux,
            units,
            thresholds,
        } = this.props;

        // pm_2_5
        // pm_10
        // temperature
        // humidity
        // co2
        // decibel
        // lux

        const pm2_5Threshold = thresholds?.pm_2_5 || 250;
        const pm10Threshold = thresholds?.pm_10 || 350;
        const co2Threshold = thresholds?.ppm || 1300;
        const humidityThreshold = thresholds?.humidity || 60;
        const tmpThreshold = thresholds?.temperature || 30;
        const dbThreshold = thresholds?.decibel || 300;
        const luxThreshold = thresholds?.lux || 100;

        return (
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                }}>
                <div style={cardStyle}>
                    <h2>PM2.5 µg/m³</h2>
                    <LineChart
                        width={width}
                        height={height}
                        data={pm_2_5}
                        syncId="anyId"
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}>
                        <CartesianGrid stroke="#f4f4f5" />
                        <XAxis
                            dataKey="name"
                            stroke="grey"
                            minTickGap={20}
                            tick={<CustomizedTick />}
                        />
                        <YAxis stroke="grey" />
                        <Tooltip content={<CustomTooltip thresholds={thresholds} />} />
                        <ReferenceLine
                            y={pm2_5Threshold}
                            label=""
                            stroke="#333"
                            strokeDasharray="3 3"
                        />
                        <Line
                            type="monotone"
                            dataKey={"pm_2_5"}
                            stroke="#4a148c"
                            fill="#4a148c"
                            dot={false}
                        />
                    </LineChart>
                </div>
                <div style={cardStyle}>
                    <h2>PM 10 µg/m³</h2>
                    <LineChart
                        width={width}
                        height={height}
                        data={pm_10}
                        syncId="anyId"
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}>
                        <CartesianGrid stroke="#f4f4f5" />
                        <XAxis
                            dataKey="name"
                            stroke="grey"
                            minTickGap={20}
                            tick={<CustomizedTick />}
                        />
                        <YAxis stroke="grey" />
                        <Tooltip content={<CustomTooltip thresholds={thresholds} />} />
                        <ReferenceLine
                            y={pm10Threshold}
                            label=""
                            stroke="#333"
                            strokeDasharray="3 3"
                        />
                        <Line
                            type="monotone"
                            dataKey={"pm_10"}
                            stroke="#4a148c"
                            fill="#4a148c"
                            dot={false}
                        />
                    </LineChart>
                </div>
                <div style={cardStyle}>
                    <h2>Temperature (°C)</h2>
                    <LineChart
                        width={width}
                        height={height}
                        data={temperature}
                        syncId="anyId"
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}>
                        <CartesianGrid stroke="#f4f4f5" />
                        <XAxis
                            dataKey="name"
                            stroke="grey"
                            minTickGap={20}
                            tick={<CustomizedTick />}
                        />
                        <YAxis
                            stroke="grey"
                            tickCount={6}
                            tick={<CustomizedYTick decimals={1} />}
                            domain={["dataMin - 2", "dataMax + 2"]}
                        />
                        <Tooltip content={<CustomTooltip thresholds={thresholds} />} />
                        <ReferenceLine
                            y={tmpThreshold}
                            label=""
                            stroke="#333"
                            strokeDasharray="3 3"
                        />
                        <Line
                            type="monotone"
                            dataKey={"temperature"}
                            stroke="#ff6f00"
                            fill="#ff6f00"
                            dot={false}
                        />
                    </LineChart>
                </div>
                <div style={cardStyle}>
                    <h2>Humidity (%)</h2>
                    <LineChart
                        width={width}
                        height={height}
                        data={humidity}
                        syncId="anyId"
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}>
                        <CartesianGrid stroke="#f4f4f5" />
                        <XAxis
                            dataKey="name"
                            stroke="grey"
                            minTickGap={20}
                            tick={<CustomizedTick />}
                        />
                        <YAxis
                            stroke="grey"
                            tickCount={6}
                            tick={<CustomizedYTick />}
                            domain={["dataMin - 2", "dataMax + 2"]}
                        />
                        <Tooltip content={<CustomTooltip thresholds={thresholds} />} />
                        <ReferenceLine
                            y={humidityThreshold}
                            label=""
                            stroke="#333"
                            strokeDasharray="3 3"
                        />
                        <Line
                            type="monotone"
                            dataKey={"humidity"}
                            stroke="#0d47a1"
                            fill="#0d47a1"
                            dot={false}
                        />
                    </LineChart>
                </div>
                {co2Avg > 0 && (
                    <div style={cardStyle}>
                        <h2>CO2 (ppm)</h2>
                        <LineChart
                            width={width}
                            height={height}
                            data={co2}
                            syncId="anyId"
                            margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0,
                            }}>
                            <CartesianGrid stroke="#f4f4f5" />
                            <XAxis
                                dataKey="name"
                                stroke="grey"
                                minTickGap={20}
                                tick={<CustomizedTick />}
                            />
                            <YAxis stroke="grey" />
                            <Tooltip
                                content={<CustomTooltip thresholds={thresholds} />}
                            />
                            <ReferenceLine
                                y={co2Threshold}
                                label=""
                                stroke="#333"
                                strokeDasharray="3 3"
                            />
                            <Line
                                type="monotone"
                                dataKey={"co2"}
                                stroke="#1b5e20"
                                fill="#1b5e20"
                                dot={false}
                            />
                        </LineChart>
                    </div>
                )}
                <div style={cardStyle}>
                    <h2>Decibel (dB)</h2>
                    <LineChart
                        width={width}
                        height={height}
                        data={decibel}
                        syncId="anyId"
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}>
                        <CartesianGrid stroke="#f4f4f5" />
                        <XAxis
                            dataKey="name"
                            stroke="grey"
                            minTickGap={20}
                            tick={<CustomizedTick />}
                        />
                        <YAxis stroke="grey" />
                        <Tooltip content={<CustomTooltip thresholds={thresholds} />} />
                        <ReferenceLine
                            y={dbThreshold}
                            label=""
                            stroke="#333"
                            strokeDasharray="3 3"
                        />
                        <Line
                            type="monotone"
                            dataKey={"decibel"}
                            stroke="#82ca9d"
                            fill="#82ca9d"
                            dot={false}
                        />
                    </LineChart>
                </div>
                <div style={cardStyle}>
                    <h2>Light Intensity (lux)</h2>
                    <LineChart
                        width={width}
                        height={height}
                        data={lux}
                        syncId="anyId"
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                        }}>
                        <CartesianGrid stroke="#f4f4f5" />
                        <XAxis
                            dataKey="name"
                            stroke="grey"
                            minTickGap={20}
                            tick={<CustomizedTick />}
                        />
                        <YAxis stroke="grey" />
                        <Tooltip content={<CustomTooltip thresholds={thresholds} />} />
                        <ReferenceLine
                            y={luxThreshold}
                            label=""
                            stroke="#333"
                            strokeDasharray="3 3"
                        />
                        <Line
                            type="monotone"
                            dataKey={"lux"}
                            stroke="#757575"
                            fill="#757575"
                            dot={false}
                        />
                        <Brush dataKey="name" />
                    </LineChart>
                </div>
            </div>
        );
    }
}
