import SideNav from "../../Layout/SideNav";
import { Layout } from "antd";
import HeaderBar from "../../Layout/HeaderBar";
import CheckinContainer from "./CheckinContainer";
import BlurOverlayContainer from "../../components/Overlay/BlurOverlayContainer";
import { FeatureIds } from "../../modules/featureControl/featuresConfig";
const { Content } = Layout;

export default function Checkin() {
    // @ts-ignore
    const component = <CheckinContainer />;
    return (
        <>
            <SideNav />
            <Layout>
                <HeaderBar />
                <BlurOverlayContainer featureKey={FeatureIds.CHECK_IN}>
                    <Content style={{ margin: "50px 24px 0" }}>{component}</Content>
                </BlurOverlayContainer>
            </Layout>
        </>
    );
}
