import ActivityList from "./List";
import useActivity from "./useOrgActivity";
import { getActivity } from "../../general/Activity/helpers";

function OrgActivityListContainer() {
    const activity = useActivity();
    const mappedActivity = activity.map(getActivity);
    return <ActivityList activity={mappedActivity} />;
}

export default OrgActivityListContainer;
