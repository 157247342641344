export enum FeatureIds {
    DASHBOARD = "dashboard",
    CLEAN_LOGS = "cleanLogs",
    CERTIFICATION = "certification",
    HOUR_TRACKER = "hourTracker",
    ISSUES = "errorReporting",
    AUDITS = "audit",
    SENSORS = "sensors",
    REPORTS = "reports",
    ORDERS = "orders",
    MEMBERS = "accessControl",
    CHECK_IN = "checkIn",
    SETTINGS = "settings",
    ACTIVITY = "activity",
    BILLING = "billing",
}

const featureConfig = {
    [FeatureIds.DASHBOARD]: {
        title: "sidenav.dashboard",
        isEnabled: false,
        isDisplayed: false,
    },
    [FeatureIds.CLEAN_LOGS]: {
        title: "sidenav.cleanlog",
        isEnabled: false,
        isDisplayed: false,
    },
    [FeatureIds.CERTIFICATION]: {
        title: "sidenav.certification",
        isEnabled: false,
        isDisplayed: false,
    },
    [FeatureIds.HOUR_TRACKER]: {
        title: "sidenav.hourTracker",
        isEnabled: false,
        isDisplayed: false,
    },
    [FeatureIds.ISSUES]: {
        title: "sidenav.reportedIssues",
        isEnabled: false,
        isDisplayed: false,
    },
    [FeatureIds.AUDITS]: {
        title: "sidenav.audit",
        isEnabled: false,
        isDisplayed: false,
    },
    [FeatureIds.SENSORS]: {
        title: "sidenav.sensors",
        isEnabled: false,
        isDisplayed: false,
    },
    [FeatureIds.REPORTS]: {
        title: "sidenav.reports",
        isEnabled: false,
        isDisplayed: false,
    },
    [FeatureIds.ORDERS]: {
        title: "sidenav.orders",
        isEnabled: false,
        isDisplayed: false,
    },
    [FeatureIds.MEMBERS]: {
        title: "sidenav.access",
        isEnabled: false,
        isDisplayed: false,
    },
    [FeatureIds.CHECK_IN]: {
        title: "sidenav.checkin",
        isEnabled: false,
        isDisplayed: false,
    },
    [FeatureIds.SETTINGS]: {
        title: "sidenav.settings",
        isEnabled: false,
        isDisplayed: false,
    },
    [FeatureIds.BILLING]: {
        title: "sidenav.billing",
        isEnabled: true,
        isDisplayed: true,
    },
    [FeatureIds.ACTIVITY]: {
        title: "sidenav.activity",
        isEnabled: false,
        isDisplayed: false,
    },
};

export default featureConfig;
