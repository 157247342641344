import React, { useEffect, useState, useRef, Dispatch } from "react";
import QRCode from "qrcode";
import ReactToPrint, { useReactToPrint } from "react-to-print";

// With async/await
const generateQR = async (text: string, setqr: Dispatch<string>) => {
    try {
        const result = await QRCode.toDataURL(text, { errorCorrectionLevel: "H" });
        console.log(result);
        setqr(result);
    } catch (err) {
        console.error(err);
    }
};

class ComponentToPrint extends React.PureComponent<{ qr: string }> {
    render() {
        return <img width="100%" src={this.props.qr} />;
    }
}

export default function QrCode({ orgId, value }: { orgId: string; value: boolean }) {
    const [qr, setqr] = useState("");
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        // @ts-ignore
        content: () => componentRef?.current,
    });
    useEffect(() => {
        generateQR(`{orgId:${orgId}, isCheckingIn: ${value}}`, setqr);
        return () => {};
    }, [orgId, value]);
    return (
        <div>
            <ComponentToPrint
                // @ts-ignore
                ref={componentRef}
                qr={qr}
            />
            <button onClick={handlePrint}>Print</button>
        </div>
    );
}
