import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";
import { fetchInventorySimcards } from "modules/inventory/simcards/actions";
import { SimcardItem } from "modules/inventory/simcards/types";

// function sortByDeviceId(a: SimcardItem, b: SimcardItem) {
//     return a.deviceId.localeCompare(b.deviceId);
// }

function useInventorySimcards() {
    const dispatch = useDispatch();
    const simcards = useSelector(
        (state: AppState) =>
            state.inventory.simcardIds.map(
                id => state.inventory.inventoryMap[id] as SimcardItem,
            ),
        // .sort(sortByDeviceId),
    ) as SimcardItem[];

    useEffect(() => {
        dispatch(fetchInventorySimcards());
    }, [dispatch]);
    return { simcards };
}

export default useInventorySimcards;
