const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
const passwordLength = 10;

export function getPassword() {
    const passwordArray = [...Array(passwordLength)].map(() => {
        var randomNumber = Math.floor(Math.random() * chars.length);
        return chars.substring(randomNumber, randomNumber + 1);
    });
    return passwordArray.join("");
}
