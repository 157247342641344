import React, { useState } from "react";
import {
    Button,
    Modal,
    Form,
    InputNumber,
    Select,
    Radio,
    DatePicker,
    Row,
    Col,
    Tooltip,
} from "antd";
import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import { StyleGuide } from "styles/StyleGuide";
// import "antd/dist/antd.css"; // Import Ant Design CSS

const { Option } = Select;
const { Group: RadioGroup } = Radio;

// Usage example:
const CustomRecurrencePopup = () => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const [recurrence, setRecurrence] = useState({
        frequency: "Weekly",
        interval: 1,
        daysOfWeek: [],
        dayOfMonth: "1",
        monthOfYear: "January",
        endCondition: "Never",
        occurrences: 10,
        dayRepeat: [] as number[],
        endDate: null,
    });

    const showModal = () => {
        setIsVisible(true);
    };

    const handleOk = () => {
        setIsVisible(false);
        console.log(recurrence); // Process data here or pass to parent component
    };

    const handleCancel = () => {
        setIsVisible(false);
    };

    const onRecurrenceChange = (
        value: string | number | number[] | Dayjs | null,
        key: string,
    ) => {
        setRecurrence({ ...recurrence, [key]: value });
    };

    const getDayOfWeek = (number: number) => {
        const daysOfWeek = [
            "date.monday.letter",
            "date.tuesday.letter",
            "date.wednesday.letter",
            "date.thursday.letter",
            "date.friday.letter",
            "date.saturday.letter",
            "date.sunday.letter",
        ];
        return daysOfWeek[number - 1];
    };

    return (
        <>
            <Button type="primary" onClick={showModal}>
                Set Custom Recurrence
            </Button>
            <Modal
                title="Custom Recurrence"
                visible={isVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={600}>
                <Form>
                    <Row style={{ paddingTop: 16 }}>
                        <Col md={5} style={{ paddingTop: 4, alignItems: "center" }}>
                            Gentages hver
                        </Col>
                        <Col md={4}>
                            <Form.Item label="" style={{ marginRight: "16px" }}>
                                <InputNumber
                                    min={1}
                                    defaultValue={1}
                                    onChange={value =>
                                        onRecurrenceChange(value, "interval")
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item label="">
                                <Select
                                    defaultValue="Weekly"
                                    onChange={value =>
                                        onRecurrenceChange(value, "frequency")
                                    }>
                                    <Option value="Daily">Day(s)</Option>
                                    <Option value="Weekly">Weeks(s)</Option>
                                    <Option value="Monthly">Month(s)</Option>
                                    <Option value="Yearly">Years(s)</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {recurrence.frequency === "Weekly" && (
                        <Row>
                            <Col
                                md={5}
                                style={{
                                    paddingTop: 4,
                                    paddingBottom: 4,
                                    alignItems: "center",
                                }}>
                                Gentages på
                            </Col>
                            <Col md={19}>
                                <Form.Item label="">
                                    {[1, 2, 3, 4, 5, 6, 7].map(day => {
                                        const dayOfWeek = getDayOfWeek(day); // Returns 'Monday'
                                        const isSelected =
                                            recurrence.dayRepeat.includes(day);
                                        console.log({ dayRepeat: recurrence.dayRepeat });

                                        return (
                                            <Tooltip
                                                title={t(
                                                    dayOfWeek.replace("letter", "long"),
                                                )}>
                                                <Button
                                                    size="small"
                                                    key={day}
                                                    style={{
                                                        backgroundColor: !isSelected
                                                            ? StyleGuide.palette.grey200
                                                            : undefined,
                                                        fontSize: 12,
                                                        marginRight: 4,
                                                    }}
                                                    type={
                                                        isSelected ? "primary" : "dashed"
                                                    }
                                                    shape="circle"
                                                    onClick={() => {
                                                        const updatedDays = isSelected
                                                            ? recurrence.dayRepeat.filter(
                                                                  d => d !== day,
                                                              )
                                                            : [
                                                                  ...recurrence.dayRepeat,
                                                                  day,
                                                              ];
                                                        console.log({ updatedDays, day });

                                                        onRecurrenceChange(
                                                            updatedDays,
                                                            "dayRepeat",
                                                        );
                                                    }}>
                                                    {t(dayOfWeek)}
                                                </Button>
                                            </Tooltip>
                                        );
                                    })}
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    {/* Add other conditional fields based on frequency like dayOfMonth, monthOfYear */}
                    <Form.Item label="Ends">
                        <RadioGroup
                            onChange={e =>
                                onRecurrenceChange(e.target.value, "endCondition")
                            }>
                            <Radio value="Never">Never</Radio>
                            <Radio value="After">After</Radio>
                            <Radio value="On">On</Radio>
                        </RadioGroup>
                    </Form.Item>
                    {recurrence.endCondition === "After" && (
                        <Form.Item label="Occurrences">
                            <InputNumber
                                min={1}
                                onChange={value =>
                                    onRecurrenceChange(value, "occurrences")
                                }
                            />
                        </Form.Item>
                    )}
                    {recurrence.endCondition === "On" && (
                        <Form.Item label="End Date">
                            <DatePicker
                                onChange={value => onRecurrenceChange(value, "endDate")}
                            />
                        </Form.Item>
                    )}
                </Form>
            </Modal>
        </>
    );
};

export default CustomRecurrencePopup;
