import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { login } from "../modules/user/actions";

export interface SignInParams {
    email: string;
    password: string;
}

function useSignin() {
    const loading = useSelector((state: AppState) => state.user.loading);
    const error = useSelector((state: AppState) => state.user.error);

    const dispatch = useDispatch();

    const dispatchLogin = ({ email, password }: SignInParams) =>
        dispatch(login({ email, password }));

    return {
        loading,
        error,
        dispatchLogin,
    };
}

export default useSignin;
