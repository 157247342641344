import { RequestState } from "../../interfaces/request";
import { IOrg, Member } from "../orgs/interfaces";

export enum ActionType {
    GET_EXTERNAL_COLLABORATORS_PENDING = "GET_EXTERNAL_COLLABORATORS_PENDING",
    GET_EXTERNAL_COLLABORATORS_RESOLVED = "GET_EXTERNAL_COLLABORATORS_RESOLVED",
    GET_EXTERNAL_COLLABORATORS_REJECTED = "GET_EXTERNAL_COLLABORATORS_REJECTED",
}

export interface DBExternalCollaboratorShape {
    email: string;
    name: string;
    phone: string;
    company: string;
    ids: string[]; // all ids
    group: {
        id: string;
        title: string;
    } | null;
    subgroup: {
        id: string;
        title: string;
    } | null;
    org: {
        id: string;
        title: string;
    };
}

export interface ExternalCollaboratorState {
    externalCollaborators: DBExternalCollaboratorShape[];
    externalCollaboratorsMap: { [id: string]: DBExternalCollaboratorShape };
    state: RequestState;
}

export const FETCH_EXTERNAL_COLLABORATORS_REQUESTED =
    "FETCH_EXTERNAL_COLLABORATORS_REQUESTED";
export const FETCH_EXTERNAL_COLLABORATORS_SUCCEEDED =
    "FETCH_EXTERNAL_COLLABORATORS_SUCCEEDED";
export const FETCH_EXTERNAL_COLLABORATORS_FAILED = "FETCH_EXTERNAL_COLLABORATORS_FAILED";

interface FetchExternalCollaboratorsSuccess {
    type: typeof FETCH_EXTERNAL_COLLABORATORS_REQUESTED;
    payload: { orgId: string };
}
interface FetchExternalCollaborators {
    type: typeof FETCH_EXTERNAL_COLLABORATORS_SUCCEEDED;
    payload: DBExternalCollaboratorShape[];
}
interface FetchExternalCollaboratorsFailed {
    type: typeof FETCH_EXTERNAL_COLLABORATORS_FAILED;
    payload: {};
}

export type ExternalCollaboratorActionTypes =
    | FetchExternalCollaborators
    | FetchExternalCollaboratorsSuccess
    | FetchExternalCollaboratorsFailed;
