import useDirectories from "./useDirectories";
import { Directory } from "./Directory";
import { updateDirectory } from "../../modules/directories/actions";
import { useDispatch } from "react-redux";
import directories from "../../modules/directories/reducer";
import { DBDirectoryShape } from "modules/directories/types";

export default function Directories({
    orgId,
    directories,
}: {
    orgId: string;
    directories: DBDirectoryShape[];
}) {
    const dispatch = useDispatch();
    const dispatchUpdateDirectories = ({ orgId, directoryId, data }: any) =>
        dispatch(
            updateDirectory({
                orgId,
                directoryId,
                data,
            }),
        );
    const render = directories.map((directory: any) => (
        <Directory
            key={directory.id}
            orgId={orgId}
            {...directory}
            updateDirectory={dispatchUpdateDirectories}
        />
    ));
    return <>{render}</>;
}
