import { ReactNode } from "react";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { LegacyButtonType } from "antd/lib/button/button";
import { t } from "i18next";

const { confirm } = Modal;

interface Props {
    title?: string;
    icon?: ReactNode;
    okText?: string;
    content?: ReactNode | string;
    okType?: LegacyButtonType;
    cancelText?: string;
    onOk: VoidFunction;
    onCancel?: VoidFunction;
}

export default function confirmModal({
    title = t("general.confirmDelete"),
    okText = t("general.yes"),
    cancelText = t("general.no"),
    okType = "danger",
    content = undefined,
    icon = <ExclamationCircleOutlined />,
    onOk,
    onCancel = () => {},
}: Props) {
    confirm({
        title,
        icon,
        content,
        okText, //: t(okText) as string,
        okType,
        // okButtonProps: {
        //     disabled: true,
        // },
        cancelText,
        onOk() {
            onOk();
        },
        onCancel() {
            onCancel;
        },
    });
}
