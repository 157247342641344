import React, { Dispatch, useState, useRef } from "react";
import { Table, Input, Button, Tag } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import { useTranslation } from "react-i18next";
import {
    InventoryContextForType,
    InventoryType,
    InventoryStatus,
    InventoryStatusMap,
} from "../../../modules/inventory/types";
import { getDateString } from "../../../general/dates";

const colors = ["#f56a00", "#87d068", "#1890ff", "#fde3cf"];

function getColor(index: number) {
    const i = colors.length % index;
    console.log({ i });

    return colors[index];
}

interface IProps {
    data: InventoryContextForType<InventoryType.FOOBOT>[];
    setVisible: Dispatch<string>;
}

function TableList(props: IProps) {
    const intl = useSelector((state: AppState) => state.user.userDoc.intl);

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const { t } = useTranslation();

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }: {
            setSelectedKeys: (event: string | string[]) => void;
            selectedKeys: string[];
            confirm: () => void;
            clearFilters: () => void;
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={t("inventory.searchPlaceholder")}
                    value={selectedKeys[0]}
                    onChange={e =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 200, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    {t("inventory.search")}
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}>
                    {t("inventory.searchReset")}
                </Button>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value: string, record: any) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
                // @ts-ignore
                setTimeout(() => searchInput.current.select());
            }
        },
        render: (text: string, _: any) => {
            if (searchedColumn === dataIndex) {
                return (
                    <Highlighter
                        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                );
            }
            return text;
        },
    });

    const handleSearch = (
        selectedKeys: string[],
        confirm: () => void,
        dataIndex: string,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        // this.setState({
        //     searchText: selectedKeys[0],
        //     searchedColumn: dataIndex,
        // });
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText("");
    };

    const columns = [
        {
            title: t("inventory.ipad.serial"),
            dataIndex: "deviceId",
            key: "deviceId",
            ...getColumnSearchProps("deviceId"),
        },
        {
            title: t("inventory.ipad.name"),
            dataIndex: "name",
            key: "name",
            width: "20%",
            ...getColumnSearchProps("name"),
        },
        {
            title: t("inventory.ipad.org"),
            dataIndex: "orgTitle",
            key: "orgTitle",
            width: "20%",
            ...getColumnSearchProps("orgTitle"),
        },
        {
            title: t("inventory.ipad.status"),
            dataIndex: "status",
            key: "status",
            width: "20%",
            onFilter: (value: any, record: any) => record.status === value,
            filters: [
                {
                    text: InventoryStatusMap[InventoryStatus.NEEDS_ORDER],
                    value: InventoryStatus.NEEDS_ORDER,
                },
                {
                    text: InventoryStatusMap[InventoryStatus.ORDERED],
                    value: InventoryStatus.ORDERED,
                },
                {
                    text: InventoryStatusMap[InventoryStatus.IN_STORAGE],
                    value: InventoryStatus.IN_STORAGE,
                },
                {
                    text: InventoryStatusMap[InventoryStatus.READY],
                    value: InventoryStatus.READY,
                },
                {
                    text: InventoryStatusMap[InventoryStatus.ASSIGNED],
                    value: InventoryStatus.ASSIGNED,
                },
            ],
            render: (status: InventoryStatus) => {
                switch (status) {
                    case InventoryStatus.NEEDS_ORDER:
                        return <Tag color="red">{InventoryStatusMap[status]}</Tag>;

                    case InventoryStatus.ORDERED:
                        return <Tag color="gold">{InventoryStatusMap[status]}</Tag>;

                    case InventoryStatus.IN_STORAGE:
                        return <Tag color="orange">{InventoryStatusMap[status]}</Tag>;

                    case InventoryStatus.READY:
                        return <Tag color="blue">{InventoryStatusMap[status]}</Tag>;

                    case InventoryStatus.ASSIGNED:
                        return <Tag color="green">{InventoryStatusMap[status]}</Tag>;

                    default:
                        return <Tag color="orange">{InventoryStatusMap[status]}</Tag>;
                }
            },
        },
        {
            title: t("inventory.ipad.updatetime"),
            dataIndex: "updateTime",
            key: "updateTime",
            width: "20%",
            render: (updateTime: Date) => getDateString(updateTime, intl),
        },
        {
            title: "Action",
            dataIndex: "id",
            key: "id",
            render: (id: string) => <a onClick={() => props.setVisible(id)}>Ret</a>,
        },
    ]; //.filter(i => (!isAdmin(uid) ? i.key !== "members" && i.key !== "status" : i));
    // @ts-ignore
    return <Table columns={columns} rowKey="id" dataSource={props.data} />;
}

export default TableList;
