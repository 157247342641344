import React, { useEffect } from "react";
import { Card } from "antd";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../store";
import { Colors } from "../../Layout/Colors";
import useProducts from "../../hooks/useProducts";
import { fetchOrgProducts } from "../../modules/products/actions";
import { useTranslation } from "react-i18next";

export function getColor(num: boolean) {
    if (num) return Colors.danger;
    return Colors.done;
}

export default function StorageWidget() {
    const { t } = useTranslation();
    const { orgId } = useParams<{ orgId: string }>();
    const dispatch = useDispatch();
    const { orgProducts } = useProducts();
    const org = useSelector((state: AppState) => state.orgs.currentOrg);

    useEffect(() => {
        dispatch(fetchOrgProducts(orgId));
        return () => {};
    }, [orgId]);

    return (
        <Card
            title={t("dashboard.storage.title")}
            headStyle={{}}
            style={{
                width: "100%",
                height: 370,
                backgroundColor: "#fff",
                color: "#001628",
            }}>
            <div>
                {Object.keys(orgProducts).map(productId => {
                    const lowStorage =
                        org?.storageAlert >= orgProducts[productId]?.storage;
                    return (
                        <div
                            style={{
                                fontSize: 70,
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}>
                            <span style={{ fontSize: 24 }}>
                                {orgProducts[productId]?.title}
                                {lowStorage && (
                                    <div style={{ fontSize: 12 }}>
                                        {t("dashboard.storage.lowStorage")}
                                    </div>
                                )}
                            </span>
                            <span
                                style={{
                                    color: getColor(lowStorage),
                                }}>
                                {Math.round(orgProducts[productId]?.storage)}
                                <span style={{ fontSize: 16 }}>{org?.unit}</span>
                            </span>
                        </div>
                    );
                })}
            </div>
        </Card>
    );
}
