import { Drawer, Col, Typography, List, Space, Button, Tooltip, Avatar, Row } from "antd";
import { AppState } from "../../../store";
import { useSelector, useDispatch } from "react-redux";
import { closeGroupDrawer } from "../../../modules/drawer/actions";
import { useTranslation } from "react-i18next";
import useGroupDetails from "../../../modules/groups/useGroupsDetails";
import OrgCollapse from "./OrgPanel";
import { upperCase } from "lodash";
import { ShopOutlined, UserAddOutlined } from "@ant-design/icons";
import UserOutlined from "@ant-design/icons";
import { getColor } from "../../../general/colors";
import { getInitials } from "../../../general/initials";
import HeaderActions from "./HeaderActions";

export default function GroupsDrawer() {
    const { t } = useTranslation();
    const groupId = useSelector((state: AppState) => state.drawer.groupId);
    const productsMap = useSelector((state: AppState) => state.products.productsMap);
    const { group, orgs, isLoading } = useGroupDetails(groupId);
    const dispatch = useDispatch();

    const closeDrawer = () => {
        dispatch(closeGroupDrawer());
    };

    console.log({ group, orgs, isLoading, productsMap });
    const mainStorageProducts = Object.entries(group?.storage || {})
        .map(([productId]) => ({
            id: productId,
            storage: group?.storage[productId].storage,
            unit: group?.storage[productId].unit,
            storageAlert: group?.storage[productId].storageAlert,
        }))
        .sort((a, b) => Number(b.storage) - Number(a.storage));
    return (
        <Drawer
            title={group?.title}
            width={720}
            onClose={closeDrawer}
            open={Boolean(groupId)}
            bodyStyle={{ paddingBottom: 80 }}
            extra={group && <HeaderActions group={group} />}>
            <Row gutter={16}>
                <Col span={24}>
                    <Typography.Title level={3}>{t("Hovedlager")}</Typography.Title>
                    <List
                        style={{ marginBottom: 8 }}
                        bordered
                        locale={{ emptyText: t("Ingen produkter") }}
                        dataSource={mainStorageProducts}
                        renderItem={item => (
                            <List.Item
                                key={item.id}
                                actions={[
                                    <div
                                        style={{
                                            color:
                                                Number(item.storage) <=
                                                Number(item.storageAlert)
                                                    ? "red"
                                                    : "inherit",
                                        }}>
                                        {item.storage} {upperCase(item.unit || "")}
                                    </div>,
                                ]}>
                                {productsMap[item.id]?.title}
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
            <div style={{ padding: 24 }} />
            <Row gutter={16}>
                <Col span={24}>
                    <Typography.Title level={3}>{t("header.depts")}</Typography.Title>
                    {!isLoading && <OrgCollapse orgs={orgs} />}
                </Col>
            </Row>
        </Drawer>
    );
}
