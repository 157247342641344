export function filterRecentAuditsByDueDate(audit: any) {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    return audit.dueDate?.toDate() >= oneWeekAgo;
}
export function filterOlderAuditsByDueDate(audit: any) {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    return audit.dueDate?.toDate() < oneWeekAgo;
}
export function filterRecentAuditsByCompleteTime(audit: any) {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    return audit.completeTime?.toDate() >= oneWeekAgo;
}
export function filterOlderAuditsByCompleteTime(audit: any) {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    return audit.completeTime?.toDate() < oneWeekAgo;
}
