import React from "react";
import { Checkbox } from "antd";

const styles = {
    padding: 20,
    fontSize: "20px !important",
    backgroundColor: "#fff",
    boxShadow: "box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
};

export default function TaskItem({ title, checked, onChange, createTime }) {
    return (
        <div style={styles}>
            <Checkbox onChange={onChange} checked={checked}>
                <span style={{ fontSize: "16px" }}>{title}</span>
            </Checkbox>
            <div style={{ float: "right" }}>{createTime.toDate().toString()}</div>
        </div>
    );
}
