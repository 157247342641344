// import { Timestamp } from "@google-cloud/firestore";

export const HOURLOGS_FETCH_SUCCEEDED = "HOURLOGS_FETCH_SUCCEEDED";
export const HOURLOGS_FETCH_FAILED = "HOURLOGS_FETCH_FAILED";
export const HOURLOGS_FETCH_REQUESTED = "HOURLOGS_FETCH_REQUESTED";

export const HOURLOG_CREATE_SUCCEEDED = "HOURLOG_CREATE_SUCCEEDED";
export const HOURLOG_CREATE_FAILED = "HOURLOG_CREATE_FAILED";
export const HOURLOG_CREATE_REQUESTED = "HOURLOG_CREATE_REQUESTED";

export const HOURLOG_UPDATE_SUCCEEDED = "HOURLOG_UPDATE_SUCCEEDED";
export const HOURLOG_UPDATE_FAILED = "HOURLOG_UPDATE_FAILED";
export const HOURLOG_UPDATE_REQUESTED = "HOURLOG_UPDATE_REQUESTED";

export interface HourLog {
    amount: number;
    createTime: any; //Timestamp;
    initials: string;
    product: {
        id: string;
        title: string;
    };
}

export interface HourLogState {
    logs: HourLog[];
    loading: boolean;
}

interface FetchHourLogs {
    type: typeof HOURLOGS_FETCH_REQUESTED;
    payload: { orgId: string };
}
export interface FetchHourLogsSuccess {
    type: typeof HOURLOGS_FETCH_SUCCEEDED;
    payload: { data: HourLog[] };
}
interface FetchHourLogsFailed {
    type: typeof HOURLOGS_FETCH_FAILED;
    payload: {};
}

export type HourLogsActionTypes =
    | FetchHourLogs
    | FetchHourLogsSuccess
    | FetchHourLogsFailed;
