import { Link } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { PasswordResetParams } from "../../hooks/usePasswordReset";
import { useTranslation } from "react-i18next";
const FormItem = Form.Item;

interface Props {
    loading: boolean;
    error: string | null;
    success: string | null;
    sendPasswordResetEmail: ({ email }: { email: string }) => void;
}

function PasswordResetForm({ loading, error, success, sendPasswordResetEmail }: Props) {
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const handleSubmit = ({ email }: PasswordResetParams) => {
        sendPasswordResetEmail({ email });
    };

    return (
        <div className="login-form-container">
            <Form form={form} onFinish={handleSubmit} className="login-form">
                <FormItem
                    name="email"
                    rules={[
                        { type: "email", message: "Indtast en gyldig e-mail" },
                        { required: true, message: "Udfyld e-mail!" },
                    ]}>
                    <Input
                        prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                        placeholder="E-mail"
                    />
                </FormItem>
                <FormItem>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        loading={loading}>
                        {t("Reset password")}
                    </Button>
                    <Link to="/" className="login-form-forgot">
                        {t("Tilbage til login")}
                    </Link>
                    {error && (
                        <div style={{ color: "red", lineHeight: "18px" }}>{error}</div>
                    )}
                    {success && (
                        <div style={{ color: "green", lineHeight: "18px" }}>
                            {success}
                        </div>
                    )}
                </FormItem>
            </Form>
        </div>
    );
}

export default PasswordResetForm;
