import {
    ISSUES_COMPLETED_FETCH_FAILED,
    ISSUES_COMPLETED_FETCH_REQUESTED,
    ISSUES_COMPLETED_FETCH_SUCCEEDED,
    ISSUE_CALENDAR_CHANGE,
    IssuesActionTypes,
    IssuesState,
    TAB_STATE,
    ISSUES_FETCH_REQUESTED,
    ISSUES_FETCH_SUCCEEDED,
    ISSUES_FETCH_FAILED,
    ISSUE_FETCH_REQUESTED,
    ISSUE_FETCH_SUCCEEDED,
    ISSUE_FETCH_FAILED,
    SET_CURRENT_TAB,
} from "./types";

const initialState: IssuesState = {
    issues: [],
    // @ts-ignore
    currentIssue: {
        thumbs: [],
        tags: [],
    },
    loading: false,
    currentTab: TAB_STATE.INCOMPLETED,
    loadingCompleted: false,
    issuesCompleted: [],
    calendarDate: new Date(),
    calendarMode: "month",
};

export default function user(state = initialState, action: IssuesActionTypes) {
    switch (action.type) {
        case ISSUES_FETCH_REQUESTED:
        case ISSUE_FETCH_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case ISSUES_FETCH_SUCCEEDED:
            return {
                ...state,
                issues: action.payload.data,
                loading: false,
            };
        case ISSUES_FETCH_FAILED:
        case ISSUE_FETCH_FAILED:
            return {
                ...state,
                loading: false,
            };
        case ISSUE_FETCH_SUCCEEDED:
            return {
                ...state,
                currentIssue: action.payload.data,
                loading: false,
            };
        case SET_CURRENT_TAB:
            return {
                ...state,
                currentTab: action.payload.key,
            };

        case ISSUE_CALENDAR_CHANGE:
            return {
                ...state,
                calendarDate: action.payload.date,
                calendarMode: action.payload.mode,
            };

        case ISSUES_COMPLETED_FETCH_REQUESTED:
            return {
                ...state,
                loadingCompleted: true,
            };
        case ISSUES_COMPLETED_FETCH_SUCCEEDED:
            return {
                ...state,
                issuesCompleted: action.payload.data,
                loadingCompleted: false,
            };
        case ISSUES_COMPLETED_FETCH_FAILED:
            return {
                ...state,
                loadingCompleted: false,
            };
        default:
            return state;
    }
}
