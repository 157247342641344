import React from "react";
import { Card, Badge, List } from "antd";
import { getAlerts } from "./helpers";
import { WarningOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { CustomLog } from "./useLatestGsmPlantLog";

export default function AlertCard({
    isLoading,
    data,
}: {
    isLoading: boolean;
    data: CustomLog;
}) {
    const { t } = useTranslation();
    // const alerts = getAlerts(data?.errors);
    // const alertMsgs = alerts.map((alert: string) => (
    //     <div key={alert}>
    //         <WarningOutlined style={{ color: "red" }} /> {alert}
    //     </div>
    // ));
    // const color = alerts?.length > 0 ? "red" : "green";
    const createTime = data?.senttime ? new Date(data?.senttime * 1000) : "";
    return (
        <Card
            loading={isLoading}
            title={
                <div>
                    {/* <Badge status="processing" color={color} /> */}
                    {t("gsm.logsStatus.card_title")}
                    {data?.senttime && <> </>}
                </div>
            }
            bodyStyle={{
                padding: 0,
                paddingLeft: 16,
                height: 370,
            }}
            bordered={false}>
            <List
                dataSource={[data]}
                renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                            title={item?.message}
                            description={t("date.expressiveFormat", {
                                relativeTime: createTime,
                                dayOfWeek: createTime,
                                dayOfMonth: createTime,
                                month: createTime,
                                time: createTime,
                            })}
                        />
                    </List.Item>
                )}
            />
            {/* <div>{data ? data.message : t("gsm.alert.no_alerts")}</div>
            <div style={{ fontSize: 14, color: "grey" }}>
                {createTime &&
                    t("date.expressiveFormat", {
                        relativeTime: createTime,
                        dayOfWeek: createTime,
                        dayOfMonth: createTime,
                        month: createTime,
                        time: createTime,
                    })}
            </div> */}
        </Card>
    );
}
