import { INVOICE_FETCH_REQUESTED, INVOICE_FETCH_SUCCEEDED } from "./types";

import {
    INVOICES_FETCH_REQUESTED,
    INVOICES_FETCH_SUCCEEDED,
    DBInvoiceShape,
} from "./types";

export interface DocumentData {
    [field: string]: any;
}

export function fetchInvoices() {
    return {
        type: INVOICES_FETCH_REQUESTED,
        payload: {},
    };
}

export function fetchInvoicesSuccess(
    // @ts-ignore
    invoicesList: firebase.firestore.QuerySnapshot,
): { type: string; payload: DBInvoiceShape[] } {
    const invoices = invoicesList.docs.map(
        // @ts-ignore
        (invoices: firebase.firestore.DocumentData) => ({
            ...invoices.data(),
            id: invoices.id,
        }),
    );
    return {
        type: INVOICES_FETCH_SUCCEEDED,
        payload: invoices,
    };
}

export function fetchInvoice(invoiceId: string) {
    return {
        type: INVOICE_FETCH_REQUESTED,
        payload: { invoiceId },
    };
}

export function fetchInvoiceSuccess(
    // @ts-ignore
    invoiceDoc: firebase.firestore.DocumentSnapshot,
): { type: string; payload: DBInvoiceShape[] } {
    const invoice = {
        // @ts-ignore

        ...invoiceDoc.data(),
        id: invoiceDoc.id,
    };

    return {
        type: INVOICE_FETCH_SUCCEEDED,
        payload: [invoice],
    };
}
