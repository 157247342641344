import { useSelector } from "react-redux";
import { OrgStatusText } from "../../modules/orgs/interfaces";
import { Badge } from "antd";
import { selectOrgsStatusCount } from "../../modules/orgs/selectors";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";

export default function OrgStats() {
    const { none, progress, active, inactive } = useSelector(selectOrgsStatusCount);
    const isMobile = useIsMobile();
    const { t } = useTranslation();
    if (isMobile) return null;
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-evenly",
            }}>
            <div>
                <Badge status="default" text={t(OrgStatusText[0])} /> ({none})
            </div>
            <div>
                <Badge status="processing" text={t(OrgStatusText[1])} /> ({progress})
            </div>
            <div>
                <Badge status="success" text={t(OrgStatusText[2])} /> ({active})
            </div>
            <div>
                <Badge status="error" text={t(OrgStatusText[3])} /> ({inactive})
            </div>
        </div>
    );
}
