import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch } from "react-redux";
import IssueDetail from "./IssueDetail";
import { fetchCategories } from "../../modules/categories/actions";
import { fetchIssue, updateIssue, deleteIssue } from "../../modules/issues/actions";
import { fetchFiles, deleteFile } from "../../modules/files/actions";
import { useAppSelector } from "../../hooks";
import { firestore } from "../../firebase";
import Message, { Type } from "components/Notification/Message";
import { t } from "i18next";
import confirmModal from "general/confirmModal";

function IssueDetailContainer() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { orgId, issueId } = useParams<{ orgId: string; issueId: string }>();
    const { currentIssue, files, categories, userId, loading } = useAppSelector(
        state => ({
            currentIssue: state.issues.currentIssue,
            files: state.files.files,
            categories: state.categories.categoriesMap,
            userId: state.user.details.uid,
            loading: state.issues.loading,
        }),
    );
    useEffect(() => {
        dispatch(fetchCategories({ orgId }));
        dispatch(fetchIssue({ orgId, issueId }));
        dispatch(fetchFiles({ orgId, issueId }));

        return () => {};
    }, [orgId, issueId, dispatch]);

    const dispatchUpdateIssue = (data: any) => {
        dispatch(updateIssue({ orgId, issueId, data }));
    };
    const dispatchDeleteIssue = async () => {
        confirmModal({
            title: "Er du sikker på du vil slette denne?",
            onOk: deleteIssue,
        });
    };

    const deleteIssue = async () => {
        try {
            if (!orgId || !issueId) return alert("Something went wrong!");
            Message({
                key: "issue-delete",
                type: Type.LOADING,
                message: "Loading...",
            });
            await firestore
                .collection("orgs")
                .doc(orgId)
                .collection("issues")
                .doc(issueId)
                .delete();
            history.goBack();
            Message({
                key: "issue-delete",
                type: Type.SUCCESS,
                message: "Fejl slettet",
            });
        } catch (error) {
            console.log(error);
            Message({
                key: "issue-delete",
                type: Type.ERROR,
                message: t("general.error"),
            });
        }
    };

    const dispatchDeleteFile = ({ orgId, issueId, fileId }: any) => {
        dispatch(deleteFile({ orgId, issueId, fileId }));
    };
    const issueFiles = files.filter((file: any) => !file.context);
    const issueDocumentationFiles = files
        .filter((file: File & any) => file.context === "ISSUE_DOCUMENTATION")
        .map((file: File & any) => ({
            uid: file.id,
            name: "xxx.png",
            status: "done",
            url: file.path,
            filePath: file.filePath || file.fileId,
            thumbFilePath: file.thumbFilePath || file.fileId,
            // path: file.path,
            contentType: file.contentType,
        }));
    console.log({ currentIssue });

    return (
        <IssueDetail
            {...currentIssue}
            files={
                issueFiles.length > 0
                    ? issueFiles.map(file => ({
                          ...file,
                          filePath: file.filePath || `${file.fileId}`,
                          thumbFilePath: file.thumbFilePath || `thumb_${file.fileId}`,
                      }))
                    : // FALL BACK TO OLD FILES
                      currentIssue.files?.map((file: any) => {
                          return {
                              contentType: "image/jpeg",
                              // path: string;
                              filePath: `orgs/${orgId}/issues/${file}`,
                              thumbFilePath: file.includes("orgs")
                                  ? file
                                  : `orgs/${orgId}/issues/thumb_${file}`,
                          };
                      }) || []
            }
            updateIssue={dispatchUpdateIssue}
            categories={categories}
            documentationFiles={issueDocumentationFiles}
            orgId={orgId}
            issueId={issueId}
            userId={userId}
            deleteFile={dispatchDeleteFile}
            loading={loading}
            deleteIssue={dispatchDeleteIssue}
            tags={currentIssue?.tags}
        />
    );
}

export default IssueDetailContainer;
