import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import CreateIssue from "./CreateIssue";
import { Layout, Row, Col } from "antd";
import { fetchCategories } from "../../modules/categories/actions";
import SideNav from "../../Layout/SideNav";
import HeaderBar from "../../Layout/HeaderBar";
import FooterBar from "../../Layout/FooterBar";
import { AppState } from "../../store";
import BlurOverlayContainer from "../../components/Overlay/BlurOverlayContainer";
import { FeatureIds } from "../../modules/featureControl/featuresConfig";
import { useTranslation } from "react-i18next";
const { Content } = Layout;

interface ICategories {
    id: string;
    title: string;
}

function CreateIssueContainer() {
    const { orgId } = useParams<{ orgId: string }>();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const categories: ICategories[] = useSelector((state: AppState) =>
        (state.categories.list || []).sort((a, b) =>
            a.title.localeCompare(b.title, "da", { sensitivity: "base", numeric: true }),
        ),
    );
    useEffect(() => {
        if (orgId) {
            dispatch(fetchCategories({ orgId }));
        }
    }, [orgId, dispatch]);

    return (
        <>
            <SideNav />
            <Layout>
                <HeaderBar
                    backLink={`/orgs/${orgId}/issues`}
                    backText={t("issues.new.back")}
                />
                <BlurOverlayContainer featureKey={FeatureIds.ISSUES}>
                    <Content style={{ margin: "50px 24px 0" }}>
                        <Row gutter={16}>
                            <Col md={24} xs={24}>
                                <CreateIssue orgId={orgId} categories={categories} />
                            </Col>
                        </Row>
                    </Content>
                </BlurOverlayContainer>
                <FooterBar />
            </Layout>
        </>
    );
}

export default CreateIssueContainer;
