import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { fetchOrg } from "../modules/orgs/actions";
import Loader from "../components/Loader";
import { AppState } from "../store";

interface IProps {
    fetchOrg: ({ orgId }: { orgId: string }) => void;
}

// @ts-ignore
function WrapperHOC(Component: any) {
    return class Wrapper extends React.Component<IProps> {
        componentDidMount() {
            // @ts-ignore
            const { orgId } = this.props.match.params;
            this.props.fetchOrg({ orgId });
        }

        render() {
            // @ts-ignore
            if (this.props.loadingOrg) {
                return (
                    <div>
                        <Loader />
                    </div>
                );
            }
            return <Component {...this.props} />;
        }
    };
}

const mapActionsToProps = {
    fetchOrg,
};

function mapStateToProps(state: AppState) {
    return {
        loading: state.orgs.loading,
    };
}

const withConnect = connect(undefined, mapActionsToProps);

export default compose(withConnect, WrapperHOC);
