import { call, put, fork, takeEvery, takeLatest } from "redux-saga/effects";
import firebase, { reduxSagaFirebase } from "../../firebase";
import { SIGNIN_REQUESTED, SIGNOUT_REQUESTED, FORGOT_PASSWORD_REQUESTED } from "./types";
import {
    loginSuccess,
    loginFailed,
    signOutSuccess,
    signOutFailed,
    sendPasswordResetEmailSuccess,
    sendPasswordResetEmailFailure,
} from "./actions";

function* login({ payload }: { payload: { email: string; password: string } }) {
    const { email, password } = payload;
    try {
        yield call(reduxSagaFirebase.auth.signInWithEmailAndPassword, email, password);
        yield put(loginSuccess());
    } catch (error: any) {
        yield put(loginFailed(error));
    }
}

export function* loginSaga() {
    // @ts-ignore
    yield takeLatest(SIGNIN_REQUESTED, login);
}

function* sendPasswordResetEmail({ payload }: { payload: { email: string } }) {
    const { email } = payload;
    try {
        // @ts-ignore
        yield call(reduxSagaFirebase.auth.sendPasswordResetEmail, email);
        yield put(sendPasswordResetEmailSuccess());
    } catch (error: any) {
        yield put(sendPasswordResetEmailFailure(error));
    }
}

export function* sendPasswordResetEmailSaga() {
    // @ts-ignore
    yield takeLatest(FORGOT_PASSWORD_REQUESTED, sendPasswordResetEmail);
}

function* signOut() {
    try {
        yield call(reduxSagaFirebase.auth.signOut);
        yield put(signOutSuccess());
    } catch (error: any) {
        yield put(signOutFailed(error));
    }
}

export function* signOutSaga() {
    yield takeLatest(SIGNOUT_REQUESTED, signOut);
}
