export interface DBCheckinShape {
    // @ts-ignore
    createTime: firebase.firestore.Timestamp;
    type: CHECKIN_TYPES;
    displayName: string;
    userId: string;
}

export interface CheckinState {
    checkins: DBCheckinShape[];
    checkouts: DBCheckinShape[];
    loading: boolean;
}

export const CHECKIN_FETCH_REQUESTED = "CHECKIN_FETCH_REQUESTED";
export const CHECKIN_FETCH_SUCCEEDED = "CHECKIN_FETCH_SUCCEEDED";
export const CHECKIN_FETCH_FAILED = "CHECKIN_FETCH_FAILED";

export const CHECKOUT_FETCH_REQUESTED = "CHECKOUT_FETCH_REQUESTED";
export const CHECKOUT_FETCH_SUCCEEDED = "CHECKOUT_FETCH_SUCCEEDED";
export const CHECKOUT_FETCH_FAILED = "CHECKOUT_FETCH_FAILED";

export const CHECKIN_ADD_REQUESTED = "CHECKIN_ADD_REQUESTED";
export const CHECKIN_ADD_SUCCEEDED = "CHECKIN_ADD_SUCCEEDED";
export const CHECKIN_ADD_FAILED = "CHECKIN_ADD_FAILED";

export const CHECKOUT_ADD_REQUESTED = "CHECKOUT_ADD_REQUESTED";
export const CHECKOUT_ADD_SUCCEEDED = "CHECKOUT_ADD_SUCCEEDED";
export const CHECKOUT_ADD_FAILED = "CHECKOUT_ADD_FAILED";

interface FetchCheckinsSuccess {
    type: typeof CHECKIN_FETCH_REQUESTED;
    payload: { orgId: string };
}
interface FetchCheckins {
    type: typeof CHECKIN_FETCH_SUCCEEDED;
    payload: DBCheckinShape[];
}
interface FetchCheckinsFailed {
    type: typeof CHECKIN_FETCH_FAILED;
    payload: {};
}

interface FetchCheckoutsSuccess {
    type: typeof CHECKOUT_FETCH_REQUESTED;
    payload: { orgId: string };
}
interface FetchCheckouts {
    type: typeof CHECKOUT_FETCH_SUCCEEDED;
    payload: DBCheckinShape[];
}
interface FetchCheckoutsFailed {
    type: typeof CHECKOUT_FETCH_FAILED;
    payload: {};
}

export type CheckinActionTypes =
    | FetchCheckins
    | FetchCheckinsSuccess
    | FetchCheckinsFailed
    | FetchCheckoutsSuccess
    | FetchCheckouts
    | FetchCheckoutsFailed;

export enum CHECKIN_TYPES {
    CHECK_IN = "CHECK_IN",
    CHECK_OUT = "CHECK_OUT",
}
