import firebase from "../../firebase";
import { useEffect, useState } from "react";
import { fetchOnHoldAuditSchedulesSuccess } from "./actions";
import { useAppDispatch } from "hooks";

export default function useHasOnHoldAudits(orgId: string) {
    const dispatch = useAppDispatch();
    const [audits, setAudits] = useState<any[]>([]);
    const [isLoading, setisLoading] = useState(true);
    useEffect(() => {
        let unsubscribe: any = null;
        unsubscribe = firebase
            .firestore()
            .collection(`orgs`)
            .doc(orgId)
            .collection("auditSchedules")
            .where("isCompleted", "==", true)
            .where("hasOnHoldTasks", "==", true)
            .orderBy("dueDate", "desc")
            .limit(100)
            .onSnapshot(query => {
                if (!query.empty) {
                    const list: any[] = query.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    setAudits(list);
                    dispatch(fetchOnHoldAuditSchedulesSuccess(query));
                } else {
                    setAudits([]);
                    dispatch(fetchOnHoldAuditSchedulesSuccess({ docs: [] }));
                }
                setisLoading(false);
            });
        return unsubscribe;
    }, [orgId]);

    return { audits, isLoading };
}
