import firebase from "firebase/compat/app";
import { RecurringOptions } from "general/enums";
import { RequestState } from "interfaces/request";
import { AuditSchedule, AuditScheduleTask } from "modules/auditSchedule/types";
import { AuditTemplateTask } from "modules/auditTemplates/types";
import { useEffect, useState } from "react";

export interface AuditTask {
    id: string;
    createTime: { toDate: () => Date };
    description: string;
    order: number;
}
export default function useAuditSchedulesPreview({
    orgId,
    auditTemplateId,
    date,
}: {
    orgId: string;
    auditTemplateId: string;
    date: Date;
}) {
    const [request, setRequest] = useState<RequestState>(RequestState.INITIAL);
    const [audits, setAudits] = useState<AuditSchedule[]>([]);
    const [audits2, setAudits2] = useState<AuditSchedule[]>([]);

    useEffect(() => {
        let unsubscribe: any = null;
        if (!orgId || !auditTemplateId || !date) return;
        console.log("LOADIKNG SHIT");

        unsubscribe = firebase
            .firestore()
            .collection(`orgs`)
            .doc(orgId)
            .collection(`auditSchedules`)
            .where("templateId", "==", auditTemplateId)
            .where("recurringOption", "==", RecurringOptions.NEVER)
            .where("dueDate", ">", date)
            .onSnapshot(
                query => {
                    if (!query.empty) {
                        const list: AuditSchedule[] = query.docs.map(
                            doc =>
                                ({
                                    id: doc.id,
                                    ...doc.data(),
                                } as AuditSchedule),
                        );
                        setAudits(list);
                        setRequest(RequestState.RESOLVED);
                    } else {
                        setAudits([]);
                        setRequest(RequestState.RESOLVED);
                    }
                },
                error => {
                    console.error(error);
                    setRequest(RequestState.REJECTED);
                },
                () => {
                    console.log("completed");
                },
            );
        return unsubscribe;
    }, [auditTemplateId]);
    useEffect(() => {
        let unsubscribe: any = null;
        if (!orgId || !auditTemplateId || !date) return;
        unsubscribe = firebase
            .firestore()
            .collection(`orgs`)
            .doc(orgId)
            .collection(`auditSchedules`)
            .where("templateId", "==", auditTemplateId)
            .where("recurringOption", "!=", RecurringOptions.NEVER)
            .where("lastRecurring", "==", true)
            .where("dueDate", ">", date)
            .onSnapshot(
                query => {
                    if (!query.empty) {
                        const list: AuditSchedule[] = query.docs.map(
                            doc =>
                                ({
                                    id: doc.id,
                                    ...doc.data(),
                                } as AuditSchedule),
                        );
                        setAudits2(list);
                        setRequest(RequestState.RESOLVED);
                    } else {
                        setAudits2([]);
                        setRequest(RequestState.RESOLVED);
                    }
                },
                error => {
                    console.error(error);
                    setRequest(RequestState.REJECTED);
                },
                () => {
                    console.log("completed");
                },
            );
        return unsubscribe;
    }, [auditTemplateId]);
    return {
        audits: [...audits, ...audits2],
        loading: request === RequestState.PENDING || request === RequestState.INITIAL,
        hasError: request === RequestState.REJECTED,
    };
}
