import { QuerySnapshot } from "firebase/firestore";
import {
    GROUP_AUDIT_TEMPLATES_FETCH_REQUESTED,
    GROUP_AUDIT_TEMPLATES_FETCH_SUCCEEDED,
    GROUP_AUDIT_TEMPLATE_FETCH_REQUESTED,
    GROUP_AUDIT_TEMPLATE_FETCH_SUCCEEDED,
    GROUP_AUDIT_TEMPLATE_UPDATE_REQUESTED,
    GROUP_AUDIT_TEMPLATE_UPDATE_SUCCEEDED,
    // GROUP_AUDIT_TEMPLATE_CREATE_REQUESTED,
    GROUP_AUDIT_TEMPLATE_DELETE_REQUESTED,
    GROUP_AUDIT_TEMPLATE_TASKS_FETCH_REQUESTED,
    GROUP_AUDIT_TEMPLATE_TASKS_FETCH_SUCCEEDED,
    // SET_CURRENT_TAB,
    GROUP_AUDIT_TEMPLATE_TASK_ADD_REQUESTED,
    GROUP_AUDIT_TEMPLATE_TASK_DELETE_REQUESTED,
    GROUP_AUDIT_TEMPLATE_TASK_UPDATE_REQUESTED,
    GROUP_AUDIT_TEMPLATE_ADD_REQUESTED,
    GROUP_AUDIT_TEMPLATE_FILE_DELETE_REQUESTED,
    GROUP_AUDIT_TEMPLATE_SYNC_SUCCEEDED,
    GroupAuditTemplateSync,
} from "./types";

export function fetchGroupAuditTemplates({ groupId }: { groupId: string }) {
    return {
        type: GROUP_AUDIT_TEMPLATES_FETCH_REQUESTED,
        payload: { groupId },
    };
}

// TODO: works when upgrading to v9
export function fetchGroupAuditTemplatesSuccess(auditTemplateList: QuerySnapshot) {
    const auditTemplates = auditTemplateList.docs.map((template: any) => ({
        ...template.data(),
        id: template.id,
    }));
    return {
        type: GROUP_AUDIT_TEMPLATES_FETCH_SUCCEEDED,
        payload: {
            data: auditTemplates,
        },
    };
}

export function fetchGroupAuditTemplate({
    groupId,
    auditTemplateId,
}: {
    groupId: string;
    auditTemplateId: string;
}) {
    return {
        type: GROUP_AUDIT_TEMPLATE_FETCH_REQUESTED,
        payload: { groupId, auditTemplateId },
    };
}
// TODO: works when upgrading to v9
// @ts-ignore
export function fetchGroupAuditTemplateSuccess(auditTemplateDoc: DocumentSnapshot) {
    const auditTemplate = { id: auditTemplateDoc.id, ...auditTemplateDoc.data() };

    return {
        type: GROUP_AUDIT_TEMPLATE_FETCH_SUCCEEDED,
        payload: {
            data: auditTemplate,
        },
    };
}

export function addGroupAuditTemplate({
    groupId,
    title,
}: {
    groupId: string;
    title: string;
}) {
    return {
        type: GROUP_AUDIT_TEMPLATE_ADD_REQUESTED,
        payload: {
            groupId,
            title,
        },
    };
}

export function deleteGroupAuditTemplate(id: string) {
    return {
        type: GROUP_AUDIT_TEMPLATE_DELETE_REQUESTED,
        payload: id,
    };
}

export function updateGroupAuditTemplate({
    groupId,
    auditTemplateId,
    title,
}: {
    groupId: string;
    auditTemplateId: string;
    title: string;
}) {
    return {
        type: GROUP_AUDIT_TEMPLATE_UPDATE_REQUESTED,
        payload: { groupId, auditTemplateId, title },
    };
}

export function updateGroupAuditTemplateSuccess() {
    return {
        type: GROUP_AUDIT_TEMPLATE_UPDATE_SUCCEEDED,
        payload: {},
    };
}
export function removeGroupAuditTemplateFile({
    groupId,
    auditTemplateId,
    guide,
}: {
    groupId: string;
    auditTemplateId: string;
    guide: string | null;
}) {
    return {
        type: GROUP_AUDIT_TEMPLATE_FILE_DELETE_REQUESTED,
        payload: { groupId, auditTemplateId, guide },
    };
}

export function fetchGroupAuditTemplateTasks({
    groupId,
    auditTemplateId,
}: {
    groupId: string;
    auditTemplateId: string;
}) {
    return {
        type: GROUP_AUDIT_TEMPLATE_TASKS_FETCH_REQUESTED,
        payload: { groupId, auditTemplateId },
    };
}

// TODO: works when upgrading to v9
export function fetchGroupAuditTemplateTasksSuccess(
    // @ts-ignore
    auditTemplateTaskList: QuerySnapshot,
) {
    const auditTemplateTasks = auditTemplateTaskList.docs.map((task: any) => ({
        ...task.data(),
        id: task.id,
    }));
    return {
        type: GROUP_AUDIT_TEMPLATE_TASKS_FETCH_SUCCEEDED,
        payload: {
            data: auditTemplateTasks,
        },
    };
}

export function addGroupAuditTemplateTask({
    groupId,
    auditTemplateId,
    title,
    order,
}: {
    groupId: string;
    auditTemplateId: string;
    title: string;
    order: number;
}) {
    return {
        type: GROUP_AUDIT_TEMPLATE_TASK_ADD_REQUESTED,
        payload: {
            groupId,
            auditTemplateId,
            title,
            order,
        },
    };
}
export function deleteGroupAuditTemplateTask({
    groupId,
    auditTemplateId,
    taskId,
}: {
    groupId: string;
    auditTemplateId: string;
    taskId: string;
}) {
    return {
        type: GROUP_AUDIT_TEMPLATE_TASK_DELETE_REQUESTED,
        payload: {
            groupId,
            auditTemplateId,
            taskId,
        },
    };
}

export function updateGroupAuditTemplateTask({
    groupId,
    auditTemplateId,
    taskId,
    title,
}: {
    groupId: string;
    auditTemplateId: string;
    taskId: string;
    title: string;
}) {
    return {
        type: GROUP_AUDIT_TEMPLATE_TASK_UPDATE_REQUESTED,
        payload: {
            groupId,
            auditTemplateId,
            taskId,
            title,
        },
    };
}
export function setGroupAuditTemplateSync({
    payload,
}: {
    payload: GroupAuditTemplateSync | null;
}) {
    return {
        type: GROUP_AUDIT_TEMPLATE_SYNC_SUCCEEDED,
        payload,
    };
}
