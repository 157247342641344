export default function SensorIcon({
    width = 32,
    height = 32,
    color = "#000",
}: {
    width?: number;
    height?: number;
    color?: string;
}) {
    console.log(color);

    return (
        <svg id="icon" width={width} height={height} viewBox="0 0 32 32">
            <path
                fill={color}
                d="M30,19H26V15H24v9H8V8l9-.0009V6H13V2H11V6H8A2.002,2.002,0,0,0,6,8v3H2v2H6v6H2v2H6v3a2.0023,2.0023,0,0,0,2,2h3v4h2V26h6v4h2V26h3a2.0027,2.0027,0,0,0,2-2V21h4Z"
            />
            <path fill={color} d="M21,21H11V11H21Zm-8-2h6V13H13Z" />
            <path
                fill={color}
                d="M31,13H29A10.0117,10.0117,0,0,0,19,3V1A12.0131,12.0131,0,0,1,31,13Z"
            />
            <path
                fill={color}
                d="M26,13H24a5.0059,5.0059,0,0,0-5-5V6A7.0085,7.0085,0,0,1,26,13Z"
            />
            <rect
                id="_Transparent_Rectangle_"
                data-name="&lt;Transparent Rectangle&gt;"
                width="32"
                fill="none"
                height="32"
            />
        </svg>
    );
}
