import { InventoryItem, InventoryType } from "../types";

export const SIM_CARDS_INVENTORY_FETCH_REQUESTED = "SIM_CARDS_INVENTORY_FETCH_REQUESTED";
export const SIM_CARDS_INVENTORY_FETCH_SUCCEEDED = "SIM_CARDS_INVENTORY_FETCH_SUCCEEDED";
export const SIM_CARDS_INVENTORY_FETCH_FAILED = "SIM_CARDS_INVENTORY_FETCH_FAILED";

export interface FetchSimcardsInventorySuccess {
    type: typeof SIM_CARDS_INVENTORY_FETCH_SUCCEEDED;
    payload: SimcardItem[];
}
export interface FetchSimcardsInventory {
    type: typeof SIM_CARDS_INVENTORY_FETCH_REQUESTED;
    payload: {};
}
export interface FetchSimcardsInventoryFailed {
    type: typeof SIM_CARDS_INVENTORY_FETCH_FAILED;
    payload: {};
}

export interface SimcardItem extends InventoryItem {
    iccid: string;
    type: InventoryType.SIM_CARDS;
    tags: string[];
    orgId?: string;
    orgTitle?: string;
    connection?: {
        deviceId: string;
        title: string;
        type: InventoryType;
    };
}

export enum SimcardProvider {
    VODAFONE = 1,
    NEXCON = 2,
    LEBARA = 3,
}
