import { addMonths } from "date-fns";
import firebase, { firestore } from "../../firebase";
import { useEffect, useState } from "react";

export default function useUpcomingAuditsBySubGroup(orgIds: string[]) {
    const [audits, setIssues] = useState<any[]>([]);
    const [isLoading, setisLoading] = useState(true);
    useEffect(() => {
        let query = firestore.collectionGroup("auditSchedules");
        let unsubscribe: any = null;
        if (orgIds.length < 1) return;
        try {
            console.log(orgIds);
            query = query
                .where("isCompleted", "==", false)
                .where("dueDate", ">", new Date())
                .where("dueDate", "<", addMonths(new Date(), 1))
                .where("orgId", "in", orgIds)
                .orderBy("dueDate", "asc")
                .limit(100);

            unsubscribe = query.onSnapshot(
                querySnapshot => {
                    console.log("LOADING....");
                    if (!querySnapshot.empty) {
                        const list: any[] = querySnapshot.docs.map(doc => ({
                            id: doc.id,
                            ...doc.data(),
                            orgId: doc.ref.parent.parent?.id,
                        }));
                        setIssues(list);
                    } else {
                        setIssues([]);
                    }
                    setisLoading(false);
                },
                error => {
                    console.error(error);
                },
                () => {
                    console.log("DONE LOADING....");
                },
            );
        } catch (error) {
            console.error(error);
        }
        return () => unsubscribe?.();
    }, [orgIds]);

    return { audits, isLoading };
}
