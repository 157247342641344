import { Tooltip } from "antd";
import { useAppSelector } from "../hooks";
import { useParams, useLocation } from "react-router";
import useQueryParams from "hooks/useQueryParams";
import { useTranslation } from "react-i18next";

export default function HeaderTitle({ backText }: { backText?: string }) {
    const { t } = useTranslation();
    const location = useLocation();
    const query = useQueryParams();
    const subGroupId = query.get("subGroupId");
    const groupId = query.get("groupId");
    const { orgId } = useParams<{ orgId: string }>();
    const org = useAppSelector(state => state.orgs.currentOrg);
    const orgWithSubGroup = useAppSelector(state =>
        state.orgs.orgs.find(org => org.subGroup?.id === subGroupId),
    );
    const group = useAppSelector(state =>
        groupId ? state.groups.groupsMap[groupId] : null,
    );
    console.log({ location });

    const orgTitle = org?.title;
    if (backText) return backText;
    if (orgId) return <Tooltip title={orgTitle}>{orgTitle}</Tooltip>;
    if (orgWithSubGroup) return orgWithSubGroup.subGroup?.title;
    if (group) return group?.title;
    if (location.pathname === "/depts") return t("header.depts");
    if (location.pathname === "/groups") return t("header.groups");
    return "";
}
