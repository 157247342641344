import { useEffect } from "react";
import { Button, Form, InputNumber, Typography } from "antd";
import { useAppSelector } from "hooks";
import { CustomFieldType } from "modules/audits/types";
import { useTranslation } from "react-i18next";
import firebase from "firebase/compat/app";
import Message, { Type } from "components/Notification/Message";
import { StyleGuide } from "styles/StyleGuide";

const formLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

export default function AuditTaskCustomFieldsForm({
    orgId,
    auditScheduleId,
    taskId,
    onClose,
}: {
    orgId: string;
    auditScheduleId: string;
    taskId: string;
    onClose: () => void;
}) {
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const handleSubmit = async (values: { customFields: CustomFieldType[] }) => {
        try {
            Message({
                key: "taskUpdated",
                type: Type.LOADING,
                message: t("Loading..."),
            });
            console.log({ orgId, auditScheduleId, taskId, values });
            await firebase
                .firestore()
                .doc(`orgs/${orgId}/auditSchedules/${auditScheduleId}`)
                .set(
                    {
                        tasks: {
                            [taskId]: {
                                customFields: values.customFields,
                            },
                        },
                    },
                    { merge: true },
                );
            Message({
                key: "taskUpdated",
                message: "Opgave opdateret",
                type: Type.SUCCESS,
            });

            onClose();
        } catch (error) {
            console.error(error);
            Message({
                key: "taskUpdated",
                message: "Der skete en fejl",
                type: Type.ERROR,
            });
        }
    };
    const task = useAppSelector(
        state => state.auditSchedules.currentAuditSchedule.tasks[taskId],
    );

    useEffect(() => {
        form.validateFields();
    }, []);
    useEffect(() => {
        // set fields on taskId change
        form.setFieldsValue({ customFields: task?.customFields || [] });
    }, [taskId]);

    return (
        <Form
            {...formLayout}
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            initialValues={{ customFields: [] }}>
            <Form.List name="customFields">
                {fields => (
                    <div
                        style={{
                            display: "flex",
                            rowGap: 16,
                            flexDirection: "column",
                        }}>
                        {fields.map(field => {
                            const currentItem = form.getFieldValue([
                                "customFields",
                                field.name,
                            ]);

                            const currentType = currentItem.type;
                            const currentUnit = currentItem.unit;
                            const placeholder = currentItem.description;
                            const isFieldRequired = currentItem.required;

                            return (
                                <div>
                                    <p
                                        style={{
                                            margin: 0,
                                            color: StyleGuide.palette.grey700,
                                        }}>
                                        {currentItem.description}
                                    </p>
                                    <Form.Item
                                        key={field.key}
                                        noStyle
                                        style={{ display: "flex", width: "100%" }}
                                        rules={[
                                            {
                                                required: Boolean(isFieldRequired),
                                                message: "Venligst beskriv feltet",
                                            },
                                        ]}
                                        name={[field.name, "value"]}>
                                        <InputNumber
                                            size="large"
                                            style={{ flexGrow: 1, minWidth: "100%" }}
                                            placeholder={`${placeholder}${
                                                isFieldRequired ? "*" : ""
                                            }`}
                                            addonAfter={t(
                                                `audit_base_category_option.${currentType}.${currentUnit}`,
                                            )}
                                        />
                                    </Form.Item>
                                </div>
                            );
                        })}
                        <Form.Item
                            wrapperCol={{ span: 24 }}
                            style={{
                                margin: 0,
                                display: "flex",
                                justifyContent: "flex-end",
                            }}>
                            <Button type="primary" size="large" htmlType="submit">
                                {t("Gem")}
                            </Button>
                        </Form.Item>
                        {/* <Form.Item noStyle shouldUpdate>
                            {() => (
                                <Typography>
                                    <pre>
                                        {JSON.stringify(form.getFieldsValue(), null, 2)}
                                    </pre>
                                </Typography>
                            )}
                        </Form.Item> */}
                    </div>
                )}
            </Form.List>
        </Form>
    );
}
