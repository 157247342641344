import React from "react";
import { Layout, Row, Col, DatePicker } from "antd";
import HeaderBar from "../../Layout/HeaderBar";
import SideNav from "../../Layout/SideNav";
import LineChart from "../../components/Charts/LineChart";
import AdminOnly from "../../components/AdminOnly";

const { Content } = Layout;

function AirQualitySensor({ title, data, units, thresholds }: any) {
    return (
        <>
            <h1 style={{ fontSize: 24 }}>{title}</h1>

            <LineChart data={data} units={units} thresholds={thresholds} />
        </>
    );
}

export default AirQualitySensor;
