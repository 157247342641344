import { useEffect } from "react";
import { useParams } from "react-router";
import OrderList from "./OrderList";
import { fetchOrgOrders, updateOrder, addOrder } from "../../modules/orders/actions";
import { fetchOrgProducts, updateOrgProduct } from "../../modules/products/actions";
import { useAppDispatch, useAppSelector } from "../../hooks";

function OrderListContainer() {
    const dispatch = useAppDispatch();
    const { orgId } = useParams<{ orgId: string }>();

    const { orders, loading, uid, products, productsMap } = useAppSelector(state => ({
        orders: state.orders.list.map((order: any) => ({
            ...order,
            // @ts-ignore
            orderProductTitle: state.products.orgProductsMap[order.productId]?.title,
        })),
        loading: state.orders.loading,
        uid: state.user.details.uid,
        products: state.products.orgProducts,
        productsMap: state.products.orgProductsMap,
    }));

    useEffect(() => {
        if (orgId) {
            dispatch(fetchOrgOrders(orgId));
            dispatch(fetchOrgProducts(orgId));
        }
    }, [orgId]);

    const userId = uid;
    return (
        <OrderList
            // {...props}
            orgId={orgId}
            orders={orders}
            loading={loading}
            addOrder={({ amount, productId }: any) =>
                dispatch(addOrder(orgId, productId, userId, amount))
            }
            updateOrder={({ orderId, data }: any) =>
                dispatch(updateOrder({ orderId, data }))
            }
            updateOrgProduct={({ productId, data }: any) =>
                dispatch(updateOrgProduct({ orgId, productId, data }))
            }
            products={products}
            productsMap={productsMap}
            // removeMember={(memberId) => this.props.removeMember({ orgId, members: this.props.members, memberId })}
        />
    );
}

export default OrderListContainer;
