import keyBy from "lodash.keyby";
import { CategoryActionTypes, CategoriesState } from "./types";
import {
    CATEGORIES_FETCH_REQUESTED,
    CATEGORIES_FETCH_SUCCEEDED,
    CATEGORIES_FETCH_FAILED,
} from "./types";

const initialState: CategoriesState = {
    loading: true,
    list: [],
    categoriesMap: {},
};

export default function user(
    state = initialState,
    action: CategoryActionTypes,
): CategoriesState {
    switch (action.type) {
        case CATEGORIES_FETCH_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case CATEGORIES_FETCH_SUCCEEDED:
            return {
                ...state,
                loading: true,
                list: action.payload.categories,
                categoriesMap: keyBy(action.payload.categories, category => category.id),
            };
        case CATEGORIES_FETCH_FAILED:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
}
