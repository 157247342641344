import { call, put, takeLatest } from "redux-saga/effects";
import firebase, { reduxSagaFirebase } from "../../../firebase";
import * as actionType from "./types";
import { InventoryType } from "../types";
import { fetchInventoryEspAqSuccess } from "./actions";

// FOOBOT
function* fetchInventoryEspAq() {
    try {
        yield call(
            reduxSagaFirebase.firestore.syncCollection,
            firebase
                .firestore()
                .collection(`inventory`)
                .where("type", "==", InventoryType.ESP_AQ),
            { successActionCreator: fetchInventoryEspAqSuccess },
        );
    } catch (e: any) {
        yield put({ type: actionType.ESP_AQ_INVENTORY_FETCH_FAILED, message: e.message });
    }
}

export function* fetchInventoryEspAqSaga() {
    // @ts-ignore
    yield takeLatest(actionType.ESP_AQ_INVENTORY_FETCH_REQUESTED, fetchInventoryEspAq);
}
