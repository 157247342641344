import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import orgsReducer from "./modules/orgs/reducer";
import issuesReducer from "./modules/issues/reducer";
import filesReducer from "./modules/files/reducer";
import sensorsReducer from "./modules/sensors/reducer";
import userReducer from "./modules/user/reducer";
import cleanLogsReducer from "./modules/cleanLogs/reducer";
import hourLogsReducer from "./modules/hourLogs/reducer";
import hourTrackerReducer from "./modules/hourTracker/reducer";
import auditTemplatesReducer from "./modules/auditTemplates/reducer";
import auditSchedulesReducer from "./modules/auditSchedule/reducer";
import auditsReducer from "./modules/audits/reducer";
import categoriesReducer from "./modules/categories/reducer";
import ordersReducer from "./modules/orders/reducer";
import productsReducer from "./modules/products/reducer";
import tasksReducer from "./modules/tasks/reducer";
import reportsReducer from "./modules/reports/reducer";
import checkinsReducer from "./modules/checkin/reducer";
import directories from "./modules/directories/reducer";
import drawer from "./modules/drawer/reducer";
import inventory from "./modules/inventory/reducer";
import invoices from "./modules/invoices/reducer";
import features from "./modules/featureControl/reducer";
import groups from "./modules/groups/reducer";
import groupAuditTemplates from "./modules/groupAuditTemplates/reducer";
import modals from "./modules/modal/reducer";
import externalCollaborators from "./modules/externalCollaborators/reducer";

export default combineReducers({
    routing: routerReducer,
    orgs: orgsReducer,
    issues: issuesReducer,
    files: filesReducer,
    sensors: sensorsReducer,
    user: userReducer,
    cleanLogs: cleanLogsReducer,
    hourLogs: hourLogsReducer,
    hourTracker: hourTrackerReducer,
    auditTemplates: auditTemplatesReducer,
    auditSchedules: auditSchedulesReducer,
    audits: auditsReducer,
    categories: categoriesReducer,
    orders: ordersReducer,
    products: productsReducer,
    tasks: tasksReducer,
    reports: reportsReducer,
    checkins: checkinsReducer,
    directories: directories,
    drawer: drawer,
    modals: modals,
    inventory: inventory,
    invoices: invoices,
    features: features,
    groups: groups,
    groupAuditTemplates: groupAuditTemplates,
    externalCollaborators: externalCollaborators,
});
