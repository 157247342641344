import React, { useEffect, useState } from "react";
import {
    Modal,
    Form,
    Select,
    Input,
    Button,
    Tag,
    InputNumber,
    Space,
    Checkbox,
    DatePicker,
} from "antd";
import { DeleteOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import TableList, { StatusMap } from "./Table";
import Message, { Type } from "components/Notification/Message";

import firebase from "firebase/compat/app";
import { TagStatus } from "./Table";
import { AppProducts } from "../../hooks/useAppProducts";
import { useAppSelector } from "../../hooks";
import TextArea from "antd/lib/input/TextArea";
import { Dayjs } from "dayjs";

const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
    wrapperCol: { span: 24 },
};

const tagOptions: TagStatus[] = ["overdue", "paid", "pending", "unpaid"];

export default function ModalBilling({ appProducts }: { appProducts: AppProducts[] }) {
    const [loading, setloading] = useState(false);
    const [form] = Form.useForm();
    const orgId = useAppSelector(state => state.orgs.currentOrg.id);
    const orgTitle = useAppSelector(state => state.orgs.currentOrg.title);
    const [visible, setVisible] = useState(false);
    const onClose = () => setVisible(false);

    const [error, setError] = useState<string | null>(null);
    const { t } = useTranslation();

    useEffect(() => {
        form.validateFields();
        return () => {};
    }, []);

    const onFinish = async (values: {
        products: { product: string; price: number; recurring: boolean }[];
        lastPayDate: Dayjs | null;
        nextPayDate: Dayjs;
        status: TagStatus;
        note: string;
    }) => {
        // remove key as it will not be store like this

        if (!orgId) return alert("something went wrong");
        if (!values.products || values.products?.length < 1) {
            return alert("Need at least one product");
        }
        try {
            setloading(true);
            Message({
                key: "addAppProduct",
                message: "Loading...",
                // description: "Sensoren blev tilføjet korrekt",
                type: Type.LOADING,
            });

            await firebase
                .firestore()
                .collection("appBilling")
                .add({
                    org: {
                        id: orgId,
                        title: orgTitle,
                    },
                    products: values.products.map(p => ({
                        id: p.product,
                        name: appProducts.find(ap => ap.id === p.product)?.name,
                        price: p.price,
                        recurring: p.recurring,
                    })),
                    lastPayDate: values.lastPayDate?.toDate() || null,
                    nextPayDate: values.nextPayDate?.toDate(),
                    status: values.status,
                    createTime: new Date(),
                    updateTime: new Date(),
                    note: values.note || "",
                });
            onClose();
            form.resetFields();
            Message({
                key: "addAppProduct",
                message: `Alt blev tilføjet korrekt`,
            });
        } catch (error) {
            console.error(error);
            Message({
                key: "addAppProduct",
                type: Type.ERROR,
                message: `Der skete en fejl`,
                description: `Kunne ikke oprette Abonnement, prøv venligst igen`,
            });
        } finally {
            setloading(false);
        }
    };
    return (
        <>
            <Button
                type="primary"
                block
                htmlType="button"
                onClick={() => setVisible(true)}>
                Tilføj abonnement
            </Button>
            <Modal
                title="Tilføj abonnement"
                open={!!visible}
                // onOk={e => handleOk(e, email)}
                confirmLoading={false}
                onCancel={onClose}
                footer={[]}>
                <Form form={form} onFinish={onFinish}>
                    {/* <FormItem
                    {...formItemLayout}
                    name="serial"
                    rules={[{ required: true, message: "Please input serial!" }]}>
                    <Input type="text" placeholder={t("inventory.ipad.serial")} />
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    name="model"
                    rules={[{ required: true, message: "Please input model!" }]}>
                    <Input type="text" placeholder={t("inventory.ipad.model")} />
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    name="name"
                    rules={[{ required: true, message: "Please input name!" }]}>
                    <Input type="text" placeholder={t("inventory.ipad.name")} />
                </FormItem> */}
                    <Form.List name="products">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                        key={key}
                                        style={{
                                            display: "flex",
                                            marginBottom: 8,
                                            width: "100%",
                                        }}
                                        align="baseline">
                                        <Form.Item
                                            {...restField}
                                            style={{ minWidth: "150px" }}
                                            name={[name, "product"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vælg produkt",
                                                },
                                            ]}>
                                            <Select placeholder="Select product">
                                                {appProducts.map(product => {
                                                    return (
                                                        <Option value={product.id}>
                                                            {product.name}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            {...restField}
                                            style={{ minWidth: "150px" }}
                                            name={[name, "price"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vælg pris",
                                                },
                                            ]}>
                                            <InputNumber
                                                min={0}
                                                placeholder={"Pris"} //t("inventory.ipad.sim")}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            // label="Genta"
                                            name={[name, "recurring"]}
                                            valuePropName="checked">
                                            <Checkbox>Gentages</Checkbox>
                                        </Form.Item>
                                        <MinusCircleOutlined
                                            onClick={() => remove(name)}
                                        />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add({ recurring: true })}
                                        block
                                        icon={<PlusOutlined />}>
                                        Tilføj produkt
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>

                    {/* <FormItem
                    {...formItemLayout}
                    style={{ minWidth: "150px" }}
                    name="product"
                    rules={[{ required: true, message: "Please select a product!" }]}>
                    <Select placeholder="Select product">
                        {appProducts.map(product => {
                            return <Option value={product.id}>{product.name}</Option>;
                        })}
                    </Select>
                </FormItem> */}
                    {/* <FormItem
                    {...formItemLayout}
                    name="price"
                    rules={[{ required: true, message: "Please input price!" }]}>
                    <InputNumber
                        type="text"
                        min={0}
                        placeholder={"Pris"} //t("inventory.ipad.sim")}
                    />
                </FormItem> */}
                    <FormItem
                        {...formItemLayout}
                        style={{ minWidth: "150px" }}
                        name="lastPayDate"
                        label="Seneste betalingsdato (kan være blank)"
                        rules={[
                            { required: false, message: "Vælg næste betalingsdato!" },
                        ]}>
                        {/* @ts-ignore */}
                        <DatePicker />
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label="Betalings status"
                        style={{ minWidth: "150px" }}
                        name="status"
                        rules={[{ required: true, message: "Vælg status" }]}>
                        <Select placeholder="Vælg status">
                            {tagOptions.map(tag => {
                                const color =
                                    tag === "overdue"
                                        ? "red"
                                        : tag === "paid"
                                        ? "green"
                                        : tag === "pending"
                                        ? "orange"
                                        : "blue";
                                return (
                                    <Option value={tag}>
                                        <Tag color={color}>{StatusMap[tag]}</Tag>
                                    </Option>
                                );
                            })}
                        </Select>
                    </FormItem>

                    <FormItem
                        {...formItemLayout}
                        style={{ minWidth: "150px" }}
                        name="nextPayDate"
                        label="Næste betalingsdato"
                        rules={[
                            { required: true, message: "Vælg næste betalingsdato!" },
                        ]}>
                        {/* @ts-ignore */}
                        <DatePicker />
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        style={{ minWidth: "150px" }}
                        name="note">
                        <TextArea rows={4} placeholder={t("Note")} />
                    </FormItem>

                    <FormItem>
                        <Button type="primary" block htmlType="submit" loading={false}>
                            {"Tilføj"}
                        </Button>
                    </FormItem>
                </Form>
                {error && <div style={{ color: "red" }}>{error}</div>}
            </Modal>
        </>
    );
}
