import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import firebase from "firebase/compat/app";
import { useSelector } from "react-redux";
import { AppState } from "../../../store";
import useGenerateSensorReport from "./useGenerateReportConfig";

const ReportCheckbox = () => {
    const { orgId, deviceId, sensorId, type } = useSelector((state: AppState) => ({
        orgId: state.sensors.currentSensor.orgId,
        deviceId: state.sensors.currentSensor.deviceId,
        sensorId: state.sensors.currentSensor.id,
        type: state.sensors.currentSensor.type,
    }));
    console.log({ orgId, deviceId, sensorId, type });

    const { loading, config } = useGenerateSensorReport(sensorId);

    const onChange = async (e: CheckboxChangeEvent) => {
        console.log("checked = ", e.target.checked);
        if (e.target.checked) {
            await enableWeeklyReport();
        } else {
            await disableWeeklyReport();
        }
    };

    const enableWeeklyReport = async () => {
        if (!deviceId || !orgId || !sensorId) {
            return alert("Missing deviceId, orgId or sensorId");
        }
        try {
            await firebase
                .firestore()
                .collection("generateReportConfig")
                .doc(sensorId)
                .set(
                    {
                        updateTime: new Date(),
                        deviceId,
                        orgId,
                        sensorId,
                        isActive: true,
                        type,
                    },
                    { merge: true },
                );
        } catch (error) {
            console.error(error);
        }
    };
    const disableWeeklyReport = async () => {
        if (!deviceId || !orgId || !sensorId) {
            return alert("Missing deviceId, orgId or sensorId");
        }
        try {
            await firebase
                .firestore()
                .collection("generateReportConfig")
                .doc(sensorId)
                .set(
                    {
                        updateTime: new Date(),
                        deviceId,
                        orgId,
                        sensorId,
                        isActive: false,
                        type,
                    },
                    { merge: true },
                );
        } catch (error) {
            console.error(error);
        }
    };

    const label = `${
        config?.isActive ? "Uge rapport slået til" : "Uge rapport slået fra"
    }`;

    return (
        <Checkbox checked={config?.isActive} disabled={loading} onChange={onChange}>
            {label}
        </Checkbox>
    );
};

export default ReportCheckbox;
