import { CloseOutlined, FilePdfOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, DatePicker, Form, Modal, Radio, Row, Select } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import da from "antd/es/date-picker/locale/da_DK";
import { useTranslation } from "react-i18next";
import Search from "antd/es/input/Search";
import SearchCategory from "./SearchCategory";
import { StyleGuide } from "styles/StyleGuide";
import { useAppSelector } from "hooks";
// import { size } from "../../../functions/src/reports/Heatmap/config";
import OrgsSelect from "Views/DashboardOverAllContainer/OrgsSelect";
import { getUniqueItemsBySubGroupId } from "./getSubGroups";
import { format } from "date-fns";
import { fakeAPICall } from "general/helpers";

const FormItem = Form.Item;
const { RangePicker } = DatePicker;

// Base64 encoding function
function base64Encode(data: any) {
    return btoa(encodeURIComponent(data));
}

const dateOptions = [
    { label: "Dato", value: "date" },
    { label: "Uge", value: "week" },
    { label: "Måned", value: "month" },
    { label: "Kvartal", value: "quarter" },
    { label: "År", value: "year" },
];

const formatOptionsMap = {
    time: "HH:mm",
    date: "dddd Do MMM YYYY - (Uge ww)",
    week: "Uge ww - YYYY",
    month: "MMM YYYY",
    quarter: "YYYY-[Q]Q",
    year: "YYYY",
};

export default function ReportModal({
    groupId,
    orgs,
}: // orgIds,
{
    groupId: string;
    orgs: any[];
    // orgIds: string[];
}) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isOpen, setisOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [useSubGroup, setUseSubGroup] = useState(false);
    const [dateType, setDateType] = useState<
        "time" | "date" | "week" | "month" | "quarter" | "year"
    >("quarter");
    const groupTitle = useAppSelector(state => state.groups.groupsMap[groupId]?.title);

    const handleClose = () => setisOpen(false);
    const completedTasks = Form.useWatch("completedTasks", { form, preserve: true });
    const incompleteTasks = Form.useWatch("incompleteTasks", { form, preserve: true });
    const selectedOrgIds: string[] = Form.useWatch("orgIds", { form, preserve: true });
    const subGroups = getUniqueItemsBySubGroupId(orgs).map(org => ({
        id: org?.subGroup?.id,
        title: org?.subGroup.title,
    }));

    console.log({ subGroups });

    const handleSubmit = async (values: any) => {
        try {
            setIsSubmitting(true);
            console.log({
                ...values,
                dateInterval: values.dateInterval.map((date: dayjs.Dayjs) =>
                    format(date.toDate(), "yyyy-MM-dd"),
                ),
                groupId: groupId,
                groupTitle,
                useSubGroup,
                dateType,
            });

            // fake api call

            const encodedValues = base64Encode(
                JSON.stringify({
                    ...values,
                    groupId: groupId,
                    groupTitle,
                    useSubGroup,
                    dateType,
                }),
            );

            await fakeAPICall();
            window.open(`/reports/audits?q=${encodedValues}`, "_blank");
            setisOpen(false);
        } catch (error) {
            console.log(error);
        } finally {
            setIsSubmitting(false);
        }
    };
    return (
        <>
            <Button onClick={() => setisOpen(true)} icon={<FilePdfOutlined />}>
                Rapporter
            </Button>
            <Modal
                className="schedule-modal"
                width={"60%"}
                style={{
                    position: "relative",
                }}
                styles={{
                    body: {
                        padding: 0,
                    },
                }}
                open={isOpen}
                onCancel={handleClose}
                footer={[]}>
                <Row>
                    <Col span={24} style={{ padding: 24 }}>
                        <h1>
                            {t("dashboard.reportModal.modalTitle", {
                                entityName: groupTitle,
                            })}
                        </h1>

                        <Form
                            form={form}
                            layout="inline"
                            initialValues={{
                                incompleteTasks: true,
                                completedTasks: true,
                                dateInterval: undefined,
                            }}
                            onFinish={handleSubmit}>
                            <Row gutter={24} style={{ width: "100%", marginBottom: 16 }}>
                                <Col md={24}>
                                    <Row style={{ width: "100%", marginBottom: 16 }}>
                                        <Col md={24}>
                                            <FormItem
                                                name="categories"
                                                style={{ width: "100%" }}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message:
                                                            "Please select a template!",
                                                    },
                                                ]}>
                                                <SearchCategory />
                                            </FormItem>
                                            <div
                                                style={{
                                                    textAlign: "right",
                                                    color: StyleGuide.palette.grey600,
                                                }}>
                                                * efterlad feltet tomt for at vælge alle
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%", marginBottom: 16 }}>
                                        {subGroups?.length > 0 && (
                                            <Col md={24} style={{ textAlign: "right" }}>
                                                <Radio.Group
                                                    options={[
                                                        {
                                                            label: "Brug specfik afdeling",
                                                            value: false,
                                                        },
                                                        {
                                                            label: "Brug generel afdeling",
                                                            value: true,
                                                        },
                                                    ]}
                                                    onChange={e =>
                                                        setUseSubGroup(e.target.value)
                                                    }
                                                    value={useSubGroup}
                                                    optionType="button"
                                                    buttonStyle="solid"
                                                />
                                            </Col>
                                        )}

                                        <Col md={24} style={{ marginTop: 8 }}>
                                            <FormItem
                                                name="orgIds"
                                                style={{ width: "100%" }}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message:
                                                            "Please select a template!",
                                                    },
                                                ]}>
                                                {/* @ts-ignore */}
                                                <OrgsSelect
                                                    size="large"
                                                    orgs={useSubGroup ? subGroups : orgs}
                                                    orgIds={selectedOrgIds}
                                                    mode="multiple"
                                                    setOrgIds={(
                                                        newValue: string[] | string,
                                                    ) => {
                                                        form.setFieldValue(
                                                            "orgIds",
                                                            newValue as string[],
                                                        );
                                                    }}
                                                />
                                            </FormItem>
                                            <div
                                                style={{
                                                    textAlign: "right",
                                                    color: StyleGuide.palette.grey600,
                                                }}>
                                                * efterlad feltet tomt for at vælge alle
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row style={{ width: "100%", marginBottom: 16 }}>
                                        <Col md={8}>{t("audit.schedule.datelabel")}</Col>
                                        <Col md={16} style={{ textAlign: "right" }}>
                                            <Radio.Group
                                                //@ts-ignore
                                                options={dateOptions}
                                                onChange={e =>
                                                    setDateType(e.target.value)
                                                }
                                                value={dateType}
                                                optionType="button"
                                                buttonStyle="solid"
                                            />
                                        </Col>
                                        <Col md={24} style={{ marginTop: 8 }}>
                                            <FormItem
                                                name="dateInterval"
                                                style={{ width: "100%" }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please select a date!",
                                                    },
                                                ]}>
                                                <RangePicker
                                                    // placeholder="Vælg dato"
                                                    format={formatOptionsMap[dateType]} //"dddd Do MMM YYYY - (Uge ww)"}
                                                    style={{ width: "100%" }}
                                                    size="large"
                                                    picker={dateType}
                                                    locale={da}
                                                />
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Col>
                                {/* <Col
                                    md={8}
                                    style={{
                                        borderLeft: "1px solid",
                                        borderColor: StyleGuide.palette.grey300,
                                    }}>
                                    <FormItem
                                        name="completedTasks"
                                        valuePropName="checked">
                                        <Checkbox
                                            checked={completedTasks}
                                            onChange={e => {
                                                form.setFieldValue(
                                                    "completedTasks",
                                                    e.target.checked,
                                                );
                                            }}>
                                            Inkludér afsluttede opgaver
                                        </Checkbox>
                                        <InfoCircleOutlined />
                                    </FormItem>
                                    <FormItem
                                        name="incompleteTasks"
                                        valuePropName="checked">
                                        <Checkbox
                                            checked={incompleteTasks}
                                            onChange={e => {
                                                form.setFieldValue(
                                                    "incompleteTasks",
                                                    e.target.checked,
                                                );
                                            }}>
                                            Inkludér uafsluttede opgaver
                                        </Checkbox>
                                        <InfoCircleOutlined />
                                    </FormItem>
                                </Col> */}
                            </Row>

                            <FormItem style={{ width: "100%" }}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={isSubmitting}
                                    block
                                    style={{ width: "100%" }}>
                                    {t("audit.schedule.submit")}
                                </Button>
                            </FormItem>
                        </Form>
                    </Col>
                </Row>
            </Modal>
        </>
    );
}
