import { Checkbox, Row, Col, Card } from "antd";
import { useTranslation } from "react-i18next";
import { difference } from "lodash";

export default function FeatureToggles({ options, selected, onUpdateFeatures }: any) {
    const { t } = useTranslation();

    const onChange = (list: any) => {
        const addedFeatures = difference(list, selected).filter(Boolean);
        const disabledFeatures = difference(selected, list).filter(Boolean);
        const featureId = [...addedFeatures, ...disabledFeatures][0];
        const shouldEnable = addedFeatures.length > 0;

        onUpdateFeatures(featureId, shouldEnable ? true : false);
    };

    return (
        <>
            <Checkbox.Group
                style={{ width: "100%" }}
                value={selected}
                onChange={onChange}>
                <Row gutter={[16, 24]}>
                    {Object.keys(options)
                        .sort((a: string, b: string) => (a > b ? 1 : -1))
                        .map(key => {
                            const option = options[key];
                            return (
                                <Col span={6} key={key}>
                                    <Card>
                                        <Checkbox value={key}>{t(option.title)}</Checkbox>
                                    </Card>
                                </Col>
                            );
                        })}
                </Row>
            </Checkbox.Group>
        </>
    );
}
