import React from "react";
import { Card } from "antd";
import { useParams } from "react-router";
import useUnfinishedIssues from "../../modules/issues/useUnfinishedIssues";
import { isThisWeek } from "date-fns";
import { Colors } from "../../Layout/Colors";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export function getColor(num: number) {
    if (num === 0) return Colors.done;
    if (num < 3) return Colors.warning;
    return Colors.danger;
}

export default function IssuesStatsWidget() {
    const { t } = useTranslation();
    const { orgId } = useParams<{ orgId: string }>();
    const { issues, isLoading } = useUnfinishedIssues(orgId);
    if (isLoading) {
        return (
            <Card
                title={t("dashboard.issues.title")}
                headStyle={{}}
                style={{
                    width: "100%",
                    height: 370,
                    backgroundColor: "#fff",
                    color: "#001628",
                }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "264px",
                    }}>
                    <LoadingOutlined style={{ fontSize: 50 }} />
                </div>
            </Card>
        );
    }
    console.log({ issues });
    let thisWeek = 0;
    let rest = 0;
    issues.forEach(k => {
        if (isThisWeek(k.createTime.toDate())) {
            thisWeek += 1;
        } else {
            rest += 1;
        }
    });

    const total = thisWeek + rest;

    return (
        <Card
            title={t("dashboard.issues.title")}
            headStyle={{}}
            style={{
                width: "100%",
                height: 370,
                backgroundColor: "#fff",
                color: "#001628",
            }}>
            <div>
                <div
                    style={{
                        fontSize: 70,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                    <span style={{ fontSize: 24 }}>
                        {t("dashboard.issues.issuesThisWeek")}:
                    </span>
                    <span style={{ color: getColor(thisWeek) }}>{thisWeek}</span>
                </div>
                <div
                    style={{
                        fontSize: 70,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                    <span style={{ fontSize: 24 }}>
                        {t("dashboard.issues.issuesTotalUnresolved")}:
                    </span>
                    <span style={{ color: getColor(total) }}>{total}</span>
                </div>
            </div>
        </Card>
    );
}
