import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInventoryIpads } from "../../../modules/inventory/ipad/actions";
import { AppState } from "../../../store";
import { IpadItem } from "../../../modules/inventory/ipad/types";

function sortBySerial(a: IpadItem, b: IpadItem) {
    return a.serial.localeCompare(b.serial);
}

function useInventoryIpads() {
    const dispatch = useDispatch();
    const ipads = useSelector((state: AppState) =>
        state.inventory.ipadIds
            .map(id => state.inventory.inventoryMap[id] as IpadItem)
            .sort(sortBySerial),
    ) as IpadItem[];

    useEffect(() => {
        dispatch(fetchInventoryIpads());
    }, [dispatch]);
    return { ipads };
}

export default useInventoryIpads;
