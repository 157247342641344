import { firestore } from "../firebase";
import { useEffect, useState } from "react";
import { Org } from "../../functions/src/api/orgs";

export default function useOrgsByGroup(groupId: string | null) {
    const [orgs, setOrgs] = useState<any[]>([]);
    const [isLoading, setisLoading] = useState(true);
    useEffect(() => {
        let unsubscribe: any = null;
        if (!groupId) return;
        unsubscribe = firestore
            .collection(`orgs`)
            .where("groupId", "==", groupId)
            .where("isDeleted", "==", false)
            .limit(100)
            .onSnapshot(query => {
                if (!query.empty) {
                    const list: any[] = query.docs.map(
                        doc =>
                            ({
                                id: doc.id,
                                ...doc.data(),
                            } as Org),
                    );

                    setOrgs(list);
                } else {
                    setOrgs([]);
                }
                setisLoading(false);
            });
        return unsubscribe;
    }, [groupId]);

    return { orgs, isLoading };
}
